import { Row, Col, Grid, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Vacancy } from "src/types/work.type";
import moment from "moment";

type ProjectCard = {
  key: number;
  id: number;
  selectedCard: number;
  setSelectedCard: Function;
  vacancyItemData: Vacancy | undefined;
  setVisible: Function;
};

export default function ProjectItem({
  key,
  id,
  selectedCard,
  setSelectedCard,
  vacancyItemData,
  setVisible,
}: ProjectCard) {
  const screens = Grid.useBreakpoint();

  return (
    <>
      <div
        onClick={() => {
          setSelectedCard(id);
        }}
        className="projects-card-list-item"
        style={selectedCard === id ? { borderLeft: "3px solid #35DF90" } : {}}
      >
        <Row>
          <Col span={screens.md ? 16 : 24}>
            <div
              className="projects-card-header"
              style={
                screens.md
                  ? { borderBottom: "0px" }
                  : { borderBottom: "0px", padding: "16px 20px" }
              }
            >
              {vacancyItemData?.position_extra
                ? `${vacancyItemData?.title.replaceAll("-", " ")} - ${
                    vacancyItemData?.position_extra
                  }`
                : vacancyItemData?.title.replaceAll("-", " ")}
            </div>
          </Col>
          <Col
            span={screens.md ? 8 : 24}
            style={
              screens.md
                ? { display: "flex", alignItems: "center" }
                : { marginLeft: "20px", marginBottom: "16px" }
            }
          >
            <span className="sub-detail-2">
              {`€${Number(vacancyItemData?.minimum_rate)} - ${Number(
                vacancyItemData?.maximum_rate
              )}`}{" "}
              p/u
            </span>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid #F6F6F6" }}>
          <Col
            flex="42.5%"
            className="projects-card-detail-header"
            style={{
              borderRight: "1px solid #F6F6F6",
              display: "grid",
              placeItems: "center",
            }}
          >
            <span className="sub-detail-1" style={{ marginBottom: "4px" }}>
              Geintereseerden
            </span>
            <span className="">
              {vacancyItemData?.interested_freelancer.length}
            </span>
          </Col>
          <Col
            flex="42.5%"
            className="projects-card-detail-header"
            style={{
              borderRight: "1px solid #F6F6F6",
              display: "grid",
              placeItems: "center",
            }}
          >
            <span className="sub-detail-1" style={{ marginBottom: "4px" }}>
              Datum
            </span>
            <span className="sub-detail-3">{`${moment(
              vacancyItemData?.start_date
            ).format("DD-MM-yyyy")}`}</span>
          </Col>
          <Col
            flex="15%"
            className="projects-card-detail-header"
            style={{ display: "grid", placeItems: "center" }}
          >
            <Button
              type="link"
              style={{ padding: "0px" }}
              onClick={() => {
                setVisible(true);
              }}
            >
              <DeleteOutlined style={{ fontSize: "18px", color: "#A5A6A7" }} />
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
