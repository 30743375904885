import {
  Layout,
  Divider,
  Input,
  Row,
  Col,
  Button,
  message,
} from 'antd';
import { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import './styles.css';
import EditUser from './EditUser';
import UserItem from './UserItem';
import axios from 'axios';
import { useQuery } from 'react-query';
import { ChildUser, NewChildUser } from 'src/types/user.type';
import { useUser } from 'src/hooks/useUser';
import { BASE_URL } from 'src/config/default';
import { getHttpHeader } from 'src/hooks/useHttpHeader';

export default function Users() {

  const { Content } = Layout;
  const [[enableVisibleForm, addNew, selectedId], setEnableVisibleForm] = useState([false, false, null]);
  const {user} = useUser()

  const SuccessMsg = (content: string) => {
    message.success(content);
  }

  const ErrorMsg = (content: string) => {
    message.error(content);
  }

  const fetchData = async () => {
    return (await axios.get(`${BASE_URL}api/companies/${user.id}/`, getHttpHeader())).data.child_users;
  }

  const { data, refetch } = useQuery<ChildUser[]>('child_users', fetchData);

  const updateDate = async (value: ChildUser) => {
    try {
      await axios({
        method: 'PUT',
        url: `${BASE_URL}api/company_users/${value.id}/`,
        headers: getHttpHeader().headers,
        data: value
      }).then(res => {
        refetch();
        setEnableVisibleForm([false, true, null]);
      }).catch(res => {
        ErrorMsg("Er is helaas iets misgegaan, controleer de ingevulde gegevens")
      })
    } catch (err) {
      console.error(err)
    }
  }

  const removeUser = async (value: ChildUser) => {
    try {
      await axios.delete(`${BASE_URL}api/company_users/${value.id}/`, getHttpHeader())
        .then(res => {
          refetch();
        })
        .catch(res => {
          ErrorMsg("Er is helaas iets misgegaan, controleer de ingevulde gegevens")
        })
    } catch (err) {
      console.error(err)
    }
  }

  const addUser = async (value: ChildUser) => {
    try {
      const newData: NewChildUser = {
        first_name: value.first_name,
        last_name: value.last_name,
        username: value.email,
        email: value.email,
        location: value.location,
        website: value.website,
        permissions: value.permissions
      }

      // console.log(newData)

      await axios({
        method: 'POST',
        url: `${BASE_URL}api/company_users/`,
        headers: getHttpHeader().headers,
        data: newData
      }).then(res => {
        SuccessMsg("De gebruiker is aangemaakt")
        refetch();
        setEnableVisibleForm([false, true, null]);
      }).catch(res => {
        ErrorMsg("Er is helaas iets misgegaan, controleer de ingevulde gegevens")
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Row justify={'center'} style={{ backgroundColor: '#f6f6f6', minHeight: '72vh' }}>
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout className="container">
            <Content className='web-content' style={{
              padding: "1.5rem 0px",
              minHeight: '88vh',
            }}>
              {enableVisibleForm && <EditUser
                data={data && data.find(e => { return e.id === selectedId })}
                newUser={addNew}
                setEnableVisibleForm={setEnableVisibleForm}
                updateDate={updateDate}
                addUser={addUser}
              />}
              {!enableVisibleForm && <div>
                <Row style={{ backgroundColor: '#f6f6f6' }}>
                  <Col xl={24} lg={24} md={24} sm={0} xs={0}>
                    <Row style={{ marginTop: 25 }}>
                      <Col span={6}>
                        <div className='web-header'>Gebruikers</div>
                      </Col>
                      <Col span={18}>
                        <Input size={'large'} placeholder="Zoeken" prefix={<SearchOutlined />} style={{ maxHeight: '45px', padding:'0 11px' }}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Divider className='web-divider-dark' />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Button onClick={e => { setEnableVisibleForm([!enableVisibleForm, true, null]) }} className='add-user-btn'>Gebruiker aanmaken +</Button>
                <div className='account-authentication-header' >
                  Gebruiker
                </div >
                {data && data.map(e => {
                  return <UserItem data={e} removeUser={removeUser} enableVisibleForm={enableVisibleForm} setEnableVisibleForm={setEnableVisibleForm} key={e.id} />
                })}

              </div>}
            </Content>
          </Layout>
        </Col>
      </Row>

    </>
  )
}