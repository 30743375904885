import React, { Dispatch, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Avatar } from '@chakra-ui/react';
import ContactsList from '../../components/contacts/contacts-list';
import MessagesWindow from '../../components/messages/messages-window';
import { ChatService } from '../../services/chat.service';
import { ActionType, Contact, initialState } from '../../store/reducers/chat.reducer';
import {
  Spin,
  Drawer,
  Popconfirm,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Divider,
} from 'antd';
import {
  ArrowLeftOutlined,
  WarningOutlined
} from '@ant-design/icons';
import './styles.css'
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Company, User } from 'src/types/user.type';
import { BASE_URL } from 'src/config/default';
import axios from 'axios';
import { getHttpHeader } from 'src/hooks/useHttpHeader';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const warningIcon = <WarningOutlined style={{ fontSize: 24 }} />;

// let chatService: ChatService | null = null;

type ChatProps = {
  dispatch: Dispatch<ActionType>;
  state: typeof initialState;
  selectedUserId?: number
  chatService: ChatService;
};

const Chat = ({ state, dispatch, selectedUserId, chatService }: ChatProps) => {

  const history = useHistory();
  const height: number = window.innerHeight - 45;
  const [selectedContantFromPath, setSelectedContantFromPath] = useState<Contact | undefined>()

  // if (!chatService) {
  //   chatService = new ChatService(dispatch, state);
  // }

  const selectedContactKey = (Object.keys(state.contacts) as Array<unknown> as Array<number>).find(
    (key: number) => state.contacts[key].selected
  );
  const selectedContact = selectedContactKey
    ? state.contacts[selectedContactKey]
    : null;


  // Whenever contact selected update the messages seem timestamp
  useEffect(() => {
    markRead();
    // eslint-disable-next-line
  }, [selectedContact])

  // mark messages read
  const markRead = () => {
    if (selectedContact) {
      state.contacts[selectedContact.id].messages.forEach(e => {
        if (!e.readTime) {
          console.log("updating time", e);
          chatService?.updateReadTime(e);
        }
      })
    }
  }

  // Select the contact user via the path parameter
  useEffect(() => {
    if (selectedUserId !== undefined && selectedContantFromPath === undefined) {
      console.log("USE EFFECT", state, selectedUserId)
      let temp;
      if (selectedUserId && state.connected && state.contacts) {
        temp = state.contacts[selectedUserId]
      }
      if (temp) {
        setSelectedContantFromPath(temp)
      }
    }
    // eslint-disable-next-line
  }, [selectedUserId, state.contacts])

  useEffect(() => {
    if (selectedContantFromPath) {
      console.log("Seting contant user")
      dispatch({ type: "selectContact", payload: selectedContantFromPath })
    }
    // eslint-disable-next-line
  }, [selectedContantFromPath])

  const userData = useQuery<User | Company>(`user_details_${selectedContact?.id}`, async () => {
    if (selectedContact?.company) {
      return (await axios.get(`${BASE_URL}api/companies/${selectedContact?.id}`, getHttpHeader())).data
    } else {
      return (await axios.get(`${BASE_URL}api/freelancers/${selectedContact?.id}`, getHttpHeader())).data
    }
  }, { enabled: false })

  const handleChatContentView = () => {
    if (selectedContact) {
      return true;
    } else {
      return false;
    }
  }

  const DeleteIcon = () => (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 4H14.25V2.125C14.25 1.29766 13.5773 0.625 12.75 0.625H5.25C4.42266 0.625 3.75 1.29766 3.75 2.125V4H0.75C0.335156 4 0 4.33516 0 4.75V5.5C0 5.60313 0.084375 5.6875 0.1875 5.6875H1.60312L2.18203 17.9453C2.21953 18.7445 2.88047 19.375 3.67969 19.375H14.3203C15.1219 19.375 15.7805 18.7469 15.818 17.9453L16.3969 5.6875H17.8125C17.9156 5.6875 18 5.60313 18 5.5V4.75C18 4.33516 17.6648 4 17.25 4ZM5.4375 2.3125H12.5625V4H5.4375V2.3125ZM14.1398 17.6875H3.86016L3.29297 5.6875H14.707L14.1398 17.6875Z"
        fill="#A5A6A7"
      />
    </svg>
  );

  return (
    <div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          <Row justify='space-between' style={{ marginTop: 25 }}>
            <Col flex="150px">
              <div className='web-header'>Chat</div>
              {/* <Button onClick={() => {
                chatService?.emitMessageCount()
              }}>Call msg count</Button>
              <Button onClick={markRead}>mark read</Button> */}
              {/* <Button onClick={() => console.log(state)}>
                Show chat state
              </Button> */}
            </Col>
          </Row>
          <Divider className='web-divider-dark' />
          <Box style={{ backgroundColor: 'white' }}>
            <Spin
              style={{ maxHeight: '100%' }}
              tip={state.otherLogin ? "Ingelogd op een ander apparaat." : "Verbinding..."}
              spinning={!state.connected || state.otherLogin}
              indicator={state.otherLogin ? warningIcon : antIcon}
            >
              <Flex height="85vh">
                <ContactsList state={state} dispatch={dispatch} />
                {selectedContact && (
                  <MessagesWindow
                    state={state}
                    dispatch={dispatch}
                    selectedContact={selectedContact}
                    chatService={chatService}
                  />
                )}
              </Flex>
            </Spin>
          </Box>
        </Col>

        {/* Mobile View */}
        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          <Drawer
            title={<div><ArrowLeftOutlined className='notification-back-icon'
              onClick={() => history.push('/connect/connections')}
            /><span className="chat-contact-list-header-mobile">Chat</span></div>}
            placement="right"
            closable={false}
            visible={true}
            width={'100%'}
            getContainer={false}
            style={{
              textAlign: 'start',
              height: height
            }}
            headerStyle={{
              display: 'grid',
              border: '0px'
            }}
            bodyStyle={{
              backgroundColor: 'white',
              width: "100%",
              padding: '0px',
              // height: '100%',
            }}
            className='mobile-notification'
          >
            <ContactsList state={state} dispatch={dispatch} />
          </Drawer>

          <Drawer
            title={<div>
              <Row>
                <Col span={3}>
                  <ArrowLeftOutlined className='chat-back-icon'
                    onClick={() => dispatch({ type: 'selectContact', payload: null })} />
                </Col>
                <Col span={4}>
                  <Avatar bg="white" src={userData.data?.profile_pic || ''} name={userData.data?.first_name} zIndex="10" />
                </Col>
                <Col span={15} style={{ alignSelf: 'center' }}>
                  {selectedContact?.firstName}
                </Col>
                <Col span={2} style={{ paddingTop: '14px', paddingLeft: '10px' }}>
                  <Popconfirm
                    placement="bottomRight"
                    title={
                      <div
                        style={{
                          width: '240px',
                          fontSize: '14px',
                        }}
                      >
                        Weet je zeker dat je de chat wilt verwijderen?
                    </div>}
                    onConfirm={() => selectedContact && chatService && chatService.deleteHistory(selectedContact)}
                    // onCancel={ }
                    okText="Ja, verwijderen"
                    cancelText="Annuleren"
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#">
                      <DeleteIcon />
                    </a>
                  </Popconfirm>
                  {/* </Box> */}
                </Col>
              </Row>
            </div>}
            placement="right"
            closable={false}
            visible={handleChatContentView()}
            width={'100%'}
            getContainer={false}
            style={{
              textAlign: 'start',
              height: height
            }}
            headerStyle={{
              display: 'grid',
              border: '0px'
            }}
            bodyStyle={{
              backgroundColor: 'white',
              width: "100%",
              padding: '0px',
              height: '100%'
            }}
            className='mobile-notification'
          >
            {selectedContact && (
              <MessagesWindow
                state={state}
                dispatch={dispatch}
                selectedContact={selectedContact}
                chatService={chatService}
              />
            )}
          </Drawer>
        </Col>
      </Row>
    </div >
  );
};

export default Chat;
