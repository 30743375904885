export interface Permission {
  permission: PermissionType,
  permission_description?: string
}

export enum PermissionType {
  ACCEPT_HOURS = "AH",
  MANAGE_CONNECTIONS = "MC",
  CREATE_ASSIGNMENT = "CA"
}

const MANAGE_CONNECTIONS: Permission = {
  permission: PermissionType.MANAGE_CONNECTIONS,
  permission_description: "Manage Connections"
}
const CREATE_ASSIGNMENT: Permission = {
  permission: PermissionType.CREATE_ASSIGNMENT,
  permission_description: "Create new assignments"
}
const ACCEPT_HOURS: Permission = {
  permission: PermissionType.ACCEPT_HOURS,
  permission_description: "Accept hours"
}

export const getPermissionData = (type: PermissionType) => {
  switch (type) {
    case PermissionType.ACCEPT_HOURS:
      return ACCEPT_HOURS;
    case PermissionType.MANAGE_CONNECTIONS:
      return MANAGE_CONNECTIONS;
    case PermissionType.CREATE_ASSIGNMENT:
      return CREATE_ASSIGNMENT;
  }
}

