import { Row, Divider, Button } from "antd";
import { EnvironmentOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Briefcase from "src/icons/briefcase.svg";
import ProfileRounded from "src/icons/profile.svg";
import Calendar from "src/icons/calendar.svg";
import axios from "axios";
import { useQuery } from "react-query";
import { User } from "src/types/user.type";
import moment from "moment";
import { useHistory } from "react-router";
import { useQueryParams } from "src/hooks/useQueryParams";
import { getHttpHeader } from "src/hooks/useHttpHeader";

type FreelancerDetailsProps = {
  freelancerId: number | undefined;
  proposal: any;
};

const formatDate = (date: string) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD-MM-YYYY");
};

export default function FreelancerDetails({
  freelancerId,
  proposal,
}: FreelancerDetailsProps) {
  const BASE_URL = process.env.REACT_APP_BACKEND;

  const history = useHistory();
  const params = useQueryParams();

  const invoiceId = params.get("inv_id");
  const week = params.get("week");

  const navigateBack = () => {
    let url = `/work/project?`;

    if (invoiceId) {
      url += `inv_id=${invoiceId}`;
    }

    if (invoiceId && week) {
      url += `&week=${week}`;
    } else if (!invoiceId && week) {
      url = `week=${week}`;
    }

    history.push(url);
  };

  const { data: freelancer } = useQuery<User>(
    `freelancerDetails${freelancerId}`,
    async () => {
      return (
        await axios.get(
          `${BASE_URL}api/freelancers/${freelancerId}/`,
          getHttpHeader()
        )
      ).data;
    }
  );

  return (
    <div style={{ background: "white" }}>
      <Button onClick={navigateBack} size="large" type="link">
        <ArrowLeftOutlined style={{ color: "#002333", fontSize: "20px" }} />
      </Button>
      <Row justify="center" style={{ marginBlockStart: "-60px" }}>
        {freelancer?.profile_pic && (
          <img className="image1" src={freelancer?.profile_pic} alt="user" />
        )}
      </Row>
      <Row justify="center" className="freelancer-details-header">
        {`${freelancer?.first_name}`}
      </Row>
      <Row justify="center" className="freelancer-details-header-sub">
        {freelancer?.position?.name && `${freelancer?.position?.name}`}
      </Row>
      <Row style={{ padding: "10px 24px" }}>
        <Row className="freelancer-details-data" style={{ marginTop: "36px" }}>
          <EnvironmentOutlined className="freelancer-details-icon" />{" "}
          {freelancer?.region?.name}
        </Row>
        <Divider className="freelancer-details-divider" />
        <Row className="freelancer-details-data">
          <img
            src={Briefcase}
            alt=""
            className="freelancer-details-icon"
            style={{ height: "16px" }}
          />
          {freelancer?.position?.name}
        </Row>
        <Divider className="freelancer-details-divider" />
        <Row className="freelancer-details-data">
          <img
            src={ProfileRounded}
            alt=""
            className="freelancer-details-icon"
            style={{ height: "18px" }}
          />
          {freelancer?.specialty.map((e: any, index: number) => (
            <span key={e.name}>{`${e.name}${
              index !== freelancer?.specialty.length - 1 ? ", " : ""
            }`}</span>
          ))}
        </Row>
        <Divider className="freelancer-details-divider" />
        <Row className="freelancer-details-data">
          <img
            src={Calendar}
            alt=""
            className="freelancer-details-icon"
            style={{ height: "18px" }}
          />
          {formatDate(proposal?.start_date)} -{" "}
          {proposal?.continuous ? "Doorlopend" : formatDate(proposal?.end_date)}
        </Row>
      </Row>
    </div>
  );
}
