import React, { Dispatch, useEffect } from "react";

import { Avatar, AvatarBadge, Box, Text } from "@chakra-ui/react";
import {
  Contact,
  ActionType,
  initialState,
} from "../../store/reducers/chat.reducer";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import nl from "dayjs/locale/nl";
import { MessageType } from "../../types/chat-message.type";
import { PaperClipOutlined, FormOutlined } from "@ant-design/icons";
import { Grid } from "antd";
import "./styles.css";
import { useQuery } from "react-query";
import { Company, User } from "src/types/user.type";
import { BASE_URL } from "src/config/default";
import axios from "axios";
import { getHttpHeader } from "src/hooks/useHttpHeader";

dayjs.extend(relativeTime);
dayjs.locale(nl);

export const AvatarIcon = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.56 14.1667C11.56 14.9491 10.9221 15.5833 10.1352 15.5833C9.34829 15.5833 8.71037 14.9491 8.71037 14.1667C8.71037 13.3843 9.34829 12.75 10.1352 12.75C10.9221 12.75 11.56 13.3843 11.56 14.1667Z"
      fill="#002333"
    />
    <path
      d="M18.6842 15.5833C19.4711 15.5833 20.109 14.9491 20.109 14.1667C20.109 13.3843 19.4711 12.75 18.6842 12.75C17.8973 12.75 17.2594 13.3843 17.2594 14.1667C17.2594 14.9491 17.8973 15.5833 18.6842 15.5833Z"
      fill="#002333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4444 4.12195e-05L14.4097 0C6.54057 0 0.161377 6.34263 0.161377 14.1667C0.161377 21.9907 6.54057 28.3333 14.4097 28.3333C22.2788 28.3333 28.658 21.9907 28.658 14.1667C28.658 6.71832 22.8767 0.61253 15.5324 0.043334L15.5333 0.039957C15.169 0.0126232 14.8058 -0.000554354 14.4444 4.12195e-05ZM14.4097 25.5C20.705 25.5 25.8084 20.4259 25.8084 14.1667C25.8084 13.177 25.6808 12.2169 25.441 11.3018C20.3366 11.661 15.4582 9.2432 12.6436 5.10349C10.5815 7.19505 7.71043 8.49527 4.53389 8.50359C3.56533 10.1698 3.01104 12.1039 3.01104 14.1667C3.01104 20.4259 8.11439 25.5 14.4097 25.5ZM14.379 2.83337L14.4434 2.83338L14.4097 2.83333L14.379 2.83337Z"
      fill="#002333"
    />
  </svg>
);

type ContactCardProps = {
  dispatch: Dispatch<ActionType>;
  state: typeof initialState;
  contact: Contact;
};

const ContactCard = ({ dispatch, state, contact }: ContactCardProps) => {
  const selected = contact.selected;
  const lastMessage = [...contact.messages].pop();
  let message = <></>;

  const screens = Grid.useBreakpoint();
  switch (lastMessage?.type) {
    case MessageType.text:
      message = (
        <span>
          {lastMessage?.from.id === state.user?.id && <span>Jij:&nbsp;</span>}
          {String(lastMessage.message).length > 25
            ? `${String(lastMessage.message).slice(0, 25)}...`
            : String(lastMessage.message)}
        </span>
      );
      break;
    case MessageType.attachment:
      message = (
        <span style={{ display: "flex", alignItems: "center" }}>
          {lastMessage?.from.id === state.user?.id && <span>Jij:&nbsp;</span>}
          <PaperClipOutlined style={{ marginRight: "3px" }} /> Bijlage
        </span>
      );
      break;
    case MessageType.jobProposal:
      message = (
        <span style={{ display: "flex", alignItems: "center" }}>
          {lastMessage?.from.id === state.user?.id && <span>Jij:&nbsp;</span>}
          <FormOutlined style={{ marginRight: "3px" }} /> Job voorstel
        </span>
      );
      break;
  }

  const {
    data: userData,
    refetch: userDataRefetch,
    isLoading: isUserDataLoading,
  } = useQuery<User | Company>(
    `user_details_${contact.id}`,
    async () => {
      if (contact.company) {
        return (
          await axios.get(
            `${BASE_URL}api/companies/${contact.id}`,
            getHttpHeader()
          )
        ).data;
      } else {
        return (
          await axios.get(
            `${BASE_URL}api/freelancers/${contact.id}`,
            getHttpHeader()
          )
        ).data;
      }
    },
    { enabled: false }
  );

  useEffect(() => {
    userDataRefetch();
    // eslint-disable-next-line
  }, []);

  if (isUserDataLoading)
    return (
      <Box
        // p="6"
        // m="4"
        // rounded="lg"
        // flexBasis="45%"
        // width="341px"
        // d="flex"
        bgColor={selected ? "#F6F6F6" : "transparent"}
        onClick={() =>
          dispatch({
            type: "selectContact",
            payload: { ...contact, name: userData?.company_name ?? "" },
          })
        }
        className={screens.md ? "contact-card" : "contact-card-mobile"}
      />
    );

  return (
    <Box
      // p="6"
      // m="4"
      // rounded="lg"
      // flexBasis="45%"
      // width="341px"
      // d="flex"
      bgColor={selected ? "#F6F6F6" : "transparent"}
      onClick={() =>
        dispatch({
          type: "selectContact",
          payload: { ...contact, name: userData?.company_name ?? "" },
        })
      }
      className={screens.md ? "contact-card" : "contact-card-mobile"}
    >
      <Avatar
        height="36px"
        width="36px"
        mt="auto"
        mb="auto"
        mr="20px"
        bg="white"
        backgroundColor="red"
        // icon={<AvatarIcon />}
        name={
          contact.company
            ? `${userData?.company_name}`
            : `${contact.firstName} ${contact.lastName}`
        }
        src={userData?.profile_pic || ""}
      >
        <AvatarBadge
          boxSize="1em"
          bg={
            contact.connected
              ? "green.500"
              : contact.deleted
              ? "grey"
              : "red.500"
          }
        />
      </Avatar>
      <Box textAlign="left">
        <Text fontSize="sm" fontWeight="600" mb=".6rem">
          {contact.company
            ? `${userData?.company_name}`
            : `${contact.firstName} ${contact.lastName}`}
        </Text>
        <Text fontSize="sm" fontWeight="400">
          {message}
        </Text>
      </Box>
      <Box mt="auto" mb="auto" ml="auto">
        <Text fontSize="sm" fontWeight="400" color="#A5A6A7">
          {lastMessage && dayjs(lastMessage.ts).format("HH:mm")}
        </Text>
      </Box>
    </Box>
  );
};

export default ContactCard;
