import { Divider, Row, Col } from "antd";
import {
  EuroCircleOutlined,
  ExclamationCircleOutlined,
  LoginOutlined,
  ClockCircleOutlined,
  BuildOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/entity/auth";
import { Route } from "react-router-dom";
import AppHeader from "./AppHeader";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";

export default function MobileSubMenu() {
  const dispatch = useDispatch();
  const { user } = useUser();

  const linkStyle = {
    color: "inherit",
    backgroundColor: "#FFFF",
  };

  return (
    <Route exact path="/mobilesubmenu">
      <AppHeader />
      <div className="mobile-sub-title-container">
        <div className="mobile-submenu-header">Instellingen</div>
        <Divider className="mobile-divider" />

        <Row justify="center">
          <Col className="mobile-sub-menu">
            <div className="mobile-sub-menu-header">Instellingen</div>
            <Link
              style={linkStyle}
              to={
                UserTypes.Company === user.user_type ||
                UserTypes.Child_User === user.user_type
                  ? "/user/profile/editprofile"
                  : "/user/profile/editprofilefreelancer"
              }
            >
              <div className="mobile-sub-menu-item" key="5">
                <BuildOutlined className="mobile-sub-menu-logo" />
                Bedrijf
                <RightOutlined className="mobile-sub-menu-logo-end" />
              </div>
            </Link>
            <Divider className="mobile-sub-menu-divider" />
            {/* {(UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type) && (
              <Link style={linkStyle} to="/user/accountauthentication">
                <div className="mobile-sub-menu-item" key="6">
                  <UserOutlined className="mobile-sub-menu-logo" />
                  Gebruikers
                  <RightOutlined className="mobile-sub-menu-logo-end" />
                </div>
              </Link>
            )} 
            {(UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type) && (
              <Divider className="mobile-sub-menu-divider" />
            )} */}
            <Link style={linkStyle} to="/user/invoice">
              <div className="mobile-sub-menu-item" key="7">
                <EuroCircleOutlined className="mobile-sub-menu-logo" />
                Alle facturen
                <RightOutlined className="mobile-sub-menu-logo-end" />
              </div>
            </Link>
            <Divider className="mobile-sub-menu-divider" />
            {(UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type) && (
              <Link style={linkStyle} to="/user/activitylog">
                <div className="mobile-sub-menu-item" key="8">
                  <ClockCircleOutlined className="mobile-sub-menu-logo" />
                  Activiteitenlogboek
                  <RightOutlined className="mobile-sub-menu-logo-end" />
                </div>
              </Link>
            )}
            {(UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type) && (
              <Divider className="mobile-sub-menu-divider" />
            )}
            {/* <Link style={linkStyle} to="#"> */}
            <a href="https://www.job.nl/help">
              <div
                className="mobile-sub-menu-item"
                key="9"
                style={{ color: "" }}
              >
                <ExclamationCircleOutlined className="mobile-sub-menu-logo" />
                Help
                <RightOutlined className="mobile-sub-menu-logo-end" />
              </div>
            </a>
            {/* </Link> */}
            <Divider className="mobile-sub-menu-divider" />
            <Link style={linkStyle} to="/login">
              <div
                className="mobile-sub-menu-item"
                key="10"
                onClick={() => dispatch(logout())}
              >
                <LoginOutlined className="mobile-sub-menu-logo" />
                Logout
                <RightOutlined className="mobile-sub-menu-logo-end" />
              </div>
            </Link>
          </Col>
        </Row>
      </div>
    </Route>
  );
}
