import * as io from "socket.io-client";
import { Dispatch } from "react";
import { ActionType, initialState } from "../store/reducers/chat.reducer";
import { ChatMessage } from "../types/chat-message.type";
import {
  JobProposal,
  ProposalAcceptedMessage,
} from "../types/job-proposal.type";
import { ChatUser } from "src/types/chat-user.type";

const SocketIOFileUpload = require("socketio-file-upload");

export class ChatService {
  private socket: SocketIOClient.Socket;
  private uploader: any;
  private to: ChatUser | null;
  private user: ChatUser | null;

  constructor(
    private dispatch: Dispatch<ActionType>,
    private state: typeof initialState
  ) {
    this.socket = io.connect(
      process.env.REACT_APP_CHAT_BACKEND || "http://192.168.1.114:5000"
    );
    this.socket.on("connect", this.onConnect.bind(this));
    this.uploader = new SocketIOFileUpload(this.socket);
    this.to = null;
    this.user = null;
    this.dispatch = dispatch;
  }

  onConnect() {
    this.dispatch({ type: "connect" });
    const token = localStorage.getItem("token");
    this.socket.on("users", this.onUsers.bind(this));
    this.socket.on("message", this.onMessage.bind(this));
    this.socket.on("updateuser", this.onLogin.bind(this));
    this.socket.emit("authenticate", token);
    this.socket.on("authenticated", this.onAuthenticated.bind(this));
    this.socket.on("disconnect", this.onDisconnect.bind(this));
    this.socket.on("add_connection", this.onAddConnection.bind(this));
    this.socket.on("del_connection", this.onDelConnection.bind(this));
    this.socket.on("other_login", this.onOtherLogin.bind(this));
    this.socket.on("unread-count", this.OnUnreadCount.bind(this));
    this.emitMessageCount();
  }

  OnUnreadCount(count: number) {
    this.dispatch({
      type: "unreadMessageCount",
      payload: count,
    });
  }

  emitMessageCount() {
    this.socket.emit("unread-count");
  }

  onOtherLogin() {
    this.socket.disconnect();
    this.dispatch({ type: "otherLogin" });
  }
  onDelConnection(connection: ChatUser) {
    console.log("Del connection", connection);
    this.dispatch({
      type: "addUpdateContact",
      payload: {
        ...connection,
        deleted: true,
      },
    });
  }
  onAddConnection(connection: ChatUser) {
    console.log("Add connection", connection);
    this.dispatch({
      type: "addUpdateContact",
      payload: {
        ...connection,
        deleted: false,
      },
    });
  }

  onAuthenticated(user: ChatUser) {
    this.user = user;
    this.dispatch({ type: "setUserData", payload: this.user });
  }

  onDisconnect() {
    this.dispatch({ type: "disconnect" });
  }

  onLogin(user: ChatUser) {
    this.dispatch({ type: "addUpdateContact", payload: user });
  }

  onUsers(users: ChatUser[]) {
    users.forEach((user: ChatUser) => {
      let ret: ChatUser = user;
      this.dispatch({ type: "addUpdateContact", payload: ret });
    });
  }

  onMessage(message: ChatMessage) {
    this.dispatch({ type: "addMessageToContact", payload: message });
  }

  updateReadTime(message: ChatMessage) {
    if (!message.readTime) {
      this.socket.emit("read", message);
    }
  }

  sendMessage(message: ChatMessage) {
    this.socket.emit("message", {
      ...message,
      to: { id: message.to.id },
      from: { id: message.from.id },
    });
  }

  setMetaTo(event: { file: { meta: { to: ChatUser | null } } }) {
    event.file.meta.to = this.to;
  }

  async sendFile(file: File, to: ChatUser) {
    this.to = to;
    await this.uploader.addEventListener(
      "start",
      (event: { file: { meta: { to: ChatUser } } }) =>
        (event.file.meta.to = {
          id: to?.id,
          firstName: to?.firstName,
          lastName: to?.lastName,
          name: to?.name,
          userType: to?.userType,
        })
    );
    this.uploader.submitFiles([file]);
  }

  deleteHistory(to: ChatUser) {
    this.socket.emit("clear-history", { id: to?.id });
    this.dispatch({ type: "clearHistory", payload: to });
  }

  sendProposal(proposal: JobProposal) {
    const { id, historyId, from, to } = proposal;
    this.socket.emit("job-proposal", {
      id,
      historyId,
      from: { id: from?.id },
      to: { id: to?.id },
    });
  }

  sendProposalAcceptedMessage(content: ProposalAcceptedMessage) {
    const { from, to, url, message } = content;
    this.socket.emit("message-with-url", {
      from: {
        id: from,
      },
      message: {
        message: message,
        url: url,
      },
      to: {
        id: to,
      },
    });
  }
}
