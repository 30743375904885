import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import GmailLogo from "src/gmail-icon.svg";
import FacebookLogo from "src/facebook-logo.svg";
import CompanyLogo from "src/company-logo.svg";
import axios from "axios";
import { API_URL } from "../Settings/config";
import "./index.css";

import "src/index.css";
import {
  Modal,
  Row,
  Col,
  Button,
  Divider,
  Input,
  Form,
  Grid,
  message,
} from "antd";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { FacebookProvider, Login as FbLogin } from "react-facebook";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import jwtDecode from "jwt-decode";
import { JWTDecodedUser } from "src/types/user.type";
import AuthLayout from "../../components/Common/AuthLayout";

const inputStyle = {
  height: "42px",
};

export function Login() {
  const [visible] = useState(true);
  const { useBreakpoint } = Grid;
  const history = useHistory();
  const screens = useBreakpoint();
  const [form] = useForm();

  const errorMessage = (content: string) => {
    message.error(content);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
    axios
      .post(API_URL + "account/login/", values)
      .then((res) => {
        console.log("LOGIN ===> ", res.data.user);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("loginType", "local");
        window.location.href = "/dashboard";
      })
      .catch(function(err) {
        if (err.response?.data?.details === "Profiel niet compleet.") {
          localStorage.setItem("token", err.response?.data?.token);
          localStorage.setItem("user", err.response?.data?.user);
          history.push("/register/selectprofile");
          return;
        }

        if (
          JSON.stringify(err.response?.data?.details)?.includes(
            "Email not validated."
          )
        ) {
          errorMessage("Email niet geverifieerd");
        } else {
          errorMessage(err.response?.data?.details);
        }

        if (err.response?.data?.details === "Ongeldige inloggegevens.") {
          form.setFields([
            {
              name: "username",
              errors: ["Ongeldige inloggegevens."],
            },
            {
              name: "password",
              errors: ["Ongeldige inloggegevens."],
            },
          ]);
        }
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const doSocialLogin = (socialName: string, accessToken: string) => {
    console.log("doSocialLogindoSocialLogin doSocialLogin ", accessToken);
    axios
      .post(API_URL + `account/login/${socialName}/`, {
        access_token: accessToken,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("loginType", socialName);

        if (res.data.user?.id) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("username", res.data.user.username);
          history.push("/dashboard");
        } else {
          const decodedData: JWTDecodedUser = jwtDecode(String(res.data.token));
          localStorage.setItem("user", JSON.stringify(decodedData));
          localStorage.setItem("username", decodedData.username);
          history.push("/register/selectprofile");
        }
      })
      .catch((err) => {
        console.log("Error::: ", err?.response);
        if (
          err?.response?.data?.non_field_errors?.[0] ===
          "User is already registered with this e-mail address."
        ) {
          message.error("Gebruiker is al geregistreerd met dit e-mailadres.");
        } else errorMessage(err.response?.data?.details);
      });
  };

  const handleSuccessGoogleLogin = (
    data: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("accessToken" in data) {
      doSocialLogin("google", data.accessToken);
    }
  };

  const handleFailedGoogleLogin = (error: any) => {
    if (error?.error !== "idpiframe_initialization_failed")
      message.error("Inloggen bij Google mislukt. Probeer het opnieuw.");
  };

  const handleSuccessFacebookLogin = (data: {
    tokenDetail: { accessToken: string };
  }) => {
    const { accessToken } = data.tokenDetail;
    doSocialLogin("facebook", accessToken);
  };

  const handleFailedFacebookLogin = (error: any) => {
    console.log(error);
    message.error("Inloggen bij Facebook mislukt. Probeer het opnieuw.");
  };

  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={visible}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          <Row className="login-row">
            <Col
              xl={10}
              lg={10}
              md={0}
              sm={0}
              xs={0}
              className="login-design__column"
            >
              <div className="login-design__start">
                <div>
                  <img src={CompanyLogo} alt="Company Logo"></img>
                  <div className="login-header">Welkom bij Job.nl</div>
                  <Divider className="login-devider" plain></Divider>
                  <div className="login-text">Log in om verder te gaan!</div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={24}
              sm={24}
              xs={24}
              className="login-form__column"
            >
              <Row justify="space-between">
                <Col xl={0} lg={0} md={0} sm={24} xs={24}>
                  <Row
                    justify="space-between"
                    style={{ backgroundColor: "#f6f6f6" }}
                  >
                    <Col
                      style={{
                        paddingTop: "1rem",
                        paddingLeft: "1.5rem",
                      }}
                    >
                      <img
                        className="logo"
                        src={CompanyLogo}
                        alt="Company Logo"
                      ></img>
                    </Col>
                    {/* <Col>
                      <Notifications currentPage={"loading"} />
                    </Col> */}
                  </Row>
                </Col>
              </Row>

              <div
                className="loginMain"
                style={{
                  paddingTop: "60px",
                  padding: "40px 50px",
                  textAlign: "center",
                }}
              >
                <div className="mainForm__div">
                  {screens.xs && (
                    <div className="mobile-header-sub">
                      Welkom terug bij job.nl
                    </div>
                  )}
                  <div className="buttonDiv">
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
                      buttonText="Login"
                      onSuccess={handleSuccessGoogleLogin}
                      onFailure={handleFailedGoogleLogin}
                      cookiePolicy={"single_host_origin"}
                      scope="email"
                      render={(renderProps) => (
                        <Button
                          block
                          className="auth-button"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <img
                            src={GmailLogo}
                            style={{
                              width: "23px",
                              float: "left",
                              verticalAlign: "center",
                            }}
                            alt="Gmail-logo"
                          />
                          <span
                            style={{ fontFamily: "Arimo", fontSize: "16px" }}
                          >
                            Aanmelden met Google
                          </span>
                        </Button>
                      )}
                    />
                    <FacebookProvider
                      appId={process.env.REACT_APP_FB_CLIENT_ID}
                    >
                      <FbLogin
                        scope="email"
                        onCompleted={handleSuccessFacebookLogin}
                        onError={handleFailedFacebookLogin}
                      >
                        {({
                          loading,
                          handleClick,
                        }: {
                          loading: any;
                          handleClick: any;
                        }) => (
                          <Button
                            block
                            className="auth-button"
                            onClick={handleClick}
                            disabled={loading}
                          >
                            <img
                              src={FacebookLogo}
                              style={{ float: "left", verticalAlign: "center" }}
                              alt="Facebook-logo"
                            />
                            <span
                              style={{ fontFamily: "Arimo", fontSize: "16px" }}
                            >
                              Aanmelden met Facebook
                            </span>
                          </Button>
                        )}
                      </FbLogin>
                    </FacebookProvider>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Divider
                      plain
                      style={{
                        fontWeight: 900,
                        borderTopColor: "rgba(0, 0, 0, 1)",
                        width: "100%",
                      }}
                    >
                      Of
                    </Divider>
                  </div>
                  <div className="formDiv">
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      form={form}
                      style={{ width: "100%" }}
                    >
                      <Form.Item
                        name="username"
                        rules={[
                          { required: true, message: "Vul een emailadres in!" },
                        ]}
                      >
                        <Input
                          suffix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          size="large"
                          className="login-input"
                          placeholder="Gebruikersnaam (emailadres)"
                          style={inputStyle}
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: "Vul een wachtwoord in!" },
                        ]}
                      >
                        <Input.Password
                          placeholder="Wachtwoord"
                          size="large"
                          className="login-input"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          style={inputStyle}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-primary-button"
                        >
                          Inloggen
                        </Button>
                      </Form.Item>
                    </Form>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "0px",
                        paddingTop: "0",
                      }}
                    >
                      <Link
                        to="/request-reset-password"
                        style={{
                          color: "black",
                          textDecoration: "underline",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        Wachtwoord vergeten?
                      </Link>
                    </div>
                    <Row>
                      <Col
                        xl={0}
                        lg={0}
                        md={0}
                        sm={24}
                        xs={24}
                        style={{
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Divider className="login-divider" />
                      </Col>
                    </Row>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "0px",
                        paddingTop: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <span
                        style={{ fontFamily: "Basier Square", fontWeight: 400 }}
                      >
                        Heeft u nog geen account?
                      </span>
                      {screens.xs && <div />}
                      <Link
                        to="/register"
                        style={{
                          color: "#664FF3",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        Meld u nu aan
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </AuthLayout>
  );
}
