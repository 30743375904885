import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  InputNumber,
  Radio,
  DatePicker,
  Checkbox,
  Grid,
  Select,
  message,
} from "antd";
import {
  JobProposal,
  ProposalAcceptedMessage,
} from "../../types/job-proposal.type";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import moment from "moment";
import { Company, User, UserTypes } from "src/types/user.type";
import { getHttpHeader } from "../../hooks/useHttpHeader";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./styles.css";
import { ChatUser } from "src/types/chat-user.type";
import { useUser } from "src/hooks/useUser";
import userAvatar from "../../images/json/user_image.json";

type ProposalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  userId: number;
  contact: ChatUser;
  sendProposal: (proposal: JobProposal) => void;
  sendProposalAcceptMessage: (content: ProposalAcceptedMessage) => void;
  proposal: JobProposal | null;
  setProposal: Function;
  setProjectFormVisible: Function;
};

export interface ProposalPayload {
  gross_rate: string;
  hours_per_week: number;
  vat_percentage: string;
  payment_terms: string;
  travel_allowances: string;
  extra_allowances: true;
  start_date: string;
  end_date: string;
  vacancy: number | undefined;
  freelancer: number;
  company: number;
  project: number | undefined;
  agree_job_terms: boolean;
  agree_partnership: boolean;
  created_by?: number;
  edited_by?: number;
  authorized_by?: number;
}

// const Option = Select.Option;

const radioStyle = {
  display: "block",
  height: "40px",
  lineHeight: "40px",
};

const Proposal = ({
  setVisible,
  sendProposal,
  sendProposalAcceptMessage,
  visible,
  userId,
  proposal,
  contact,
  setProposal,
  setProjectFormVisible,
}: ProposalProps) => {
  const { user } = useUser();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [form] = Form.useForm();
  const screens = Grid.useBreakpoint();

  const [proposalData, setProposalData] = useState<ProposalPayload | null>();

  const [paymentTermsValue, setPaymentTermsValue] = useState("14");
  const [travelAllowanceValue, setTravelAllowanceValue] = useState(1);
  const [durationValue, setDurationValue] = useState(1);
  // state to enable/disable proposal edit form
  const [hideEditForm, setHideEditForm] = useState(false);
  const [netHourlyRate, setNetHourlyRate] = useState(0);
  const [ratePerHourValue, setRatePerHourValue] = useState(40);
  const [weeklyHoursValue, setWeeklyHoursValue] = useState(20);
  const [weeklyNetRate, setWeeklyNetRate] = useState(0);

  const [hasVacancy, setHasVacancy] = useState(false);

  const [agreeJobTerms, setAgreeJobTerms] = useState(false);
  // const [agreePartnership, setAgreePartnership] = useState(false);

  const { data, remove, refetch } = useQuery(
    ["proposal", proposal?.id],
    async () => {
      if (proposal?.id === undefined) return;
      return await axios.get(
        `${BASE_URL}api/proposals/${proposal?.id}/`,
        getHttpHeader()
      );
    },
    { enabled: proposal === undefined ? false : true }
  );

  const userData = useQuery<User | Company>(
    `user_details_${contact.id}`,
    async () => {
      if (contact.company) {
        return (
          await axios.get(
            `${BASE_URL}api/companies/${contact.id}`,
            getHttpHeader()
          )
        ).data;
      } else {
        return (
          await axios.get(
            `${BASE_URL}api/freelancers/${contact.id}`,
            getHttpHeader()
          )
        ).data;
      }
    },
    { enabled: false }
  );

  // const { data: company } = useCompany();

  // console.log("1 ===> proposalData?.vacancy ", proposalData?.vacancy);
  // console.log("2 ===> proposalData?.edited_by ", proposalData);

  useEffect(() => {
    // console.log("======> PROPOSAL ======> ", proposal);
    if (proposal === null) {
      setHideEditForm(false);
      setProposalData(null);
      setHasVacancy(false);
      form.setFields([
        {
          name: "ratePerHour",
          value: 40,
        },
        { name: "hoursPerWeek", value: 20 },
        {
          name: "agree_job_terms",
          value: false,
        },
        {
          name: "agree_partnership",
          value: false,
        },
        {
          name: "btwPercentage",
          value: 21,
        },
        {
          name: "duration",
          value: dayjs().format("YYYY-MM-DD"),
        },
        {
          name: "end_date",
          value: null,
        },
        {
          name: "extra_allowances",
          value: false,
        },
        { name: "netHourlyRate", value: 0 },
        {
          name: "netWeeklyInvoice",
          value: 0,
        },
        { name: "paymentTerms", value: "14" },
        { name: "start_date", value: 0 },
        {
          name: "travelAllowance",
          value: 0 ? 2 : 1,
        },
        {
          name: "travelAllowanceValue",
          value: 0,
        },
        {
          name: "authorized_by",
          value: null,
        },
        {
          name: "edited_by",
          value: "",
        },
        {
          name: "vacancy",
          value: null,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [visible]);

  const prevData = useRef();

  useEffect(() => {
    if (data?.data && prevData.current !== data?.data) {
      // console.log(
      //   "Loading previous data...",
      //   "Proposal data",
      //   proposal,
      //   data?.data
      // );
      if (data?.data?.vacancy) setHasVacancy(true);
      setHideEditForm(true);
      prevData.current = data?.data;
      setProposalData({ ...(data?.data as unknown as ProposalPayload) });
      if (data?.data?.travel_allowances) {
        setTravelAllowanceValue(1);
      }

      setAgreeJobTerms(data?.data?.agree_job_terms as boolean);
      // setAgreePartnership(data?.data?.agree_partnership as boolean);
      setWeeklyHoursValue(data?.data?.hours_per_week as number);
      setRatePerHourValue(data?.data?.gross_rate as number);
      setPaymentTermsValue(data?.data?.payment_terms as string);

      form.setFields([
        {
          name: "ratePerHour",
          value: data?.data?.gross_rate ? Number(data?.data?.gross_rate) : 40,
        },
        { name: "hoursPerWeek", value: data?.data?.hours_per_week || 20 },
        {
          name: "agree_job_terms",
          value: data?.data?.agree_job_terms || false,
        },
        {
          name: "agree_partnership",
          value: data?.data?.agree_partnership || false,
        },
        {
          name: "btwPercentage",
          value: data?.data?.vat_percentage
            ? Number(data?.data?.vat_percentage)
            : 21,
        },
        {
          name: "duration",
          value: data?.data?.end_date === null ? "continuously" : 1,
        },
        {
          name: "end_date",
          value:
            data?.data?.end_date === null
              ? undefined
              : moment(data?.data?.end_date),
        },
        {
          name: "extra_allowances",
          value: data?.data?.extra_allowances || false,
        },
        { name: "netHourlyRate", value: 0 },
        {
          name: "netWeeklyInvoice",
          value: data?.data?.weekly_net_rate
            ? Number(data?.data?.weekly_net_rate)
            : 0,
        },
        { name: "paymentTerms", value: data?.data?.payment_terms || "14" },
        { name: "start_date", value: moment(data?.data?.start_date || 0) },
        {
          name: "travelAllowance",
          value: data?.data?.travel_allowances === 0 ? 2 : 1,
        },
        {
          name: "travelAllowanceValue",
          value: data?.data?.travel_allowances || 0,
        },
        {
          name: "authorized_by",
          value: data?.data?.authorized_by?.id || null,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [data, setProposalData, form]);

  const handleClosing = () => {
    // console.log("Closing and clearing the data...");
    prevData.current = undefined;
    // setProposal(null);
    form.setFields([{ name: "agree_job_terms", value: undefined }]);
    setHideEditForm(false);
    setProposalData(null);
    setAgreeJobTerms(false);
    remove();
  };

  const [saveProposal] = useMutation(
    (newProposal: ProposalPayload) =>
      axios({
        method: !proposal ? "POST" : "PATCH",
        url: `${BASE_URL}api/proposals/${proposal ? proposal?.id + "/" : ""}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: newProposal,
      }),
    {
      onSuccess: (data) => {
        const {
          data: { id },
        } = data as unknown as { data: { id: number } };

        // if hideEditForm is true send proposal else send accept message
        if (hideEditForm) sendAcceptMessage();
        else sendProposal({ id, historyId: 0 });

        setVisible(false);
      },
    }
  );

  const onFinish = async (values: any) => {
    // console.log("Form data", values);

    if (
      !hideEditForm &&
      values.duration === 1 &&
      dayjs(values.start_date).isAfter(
        dayjs(values.end_date).format("YYYY-MM-DD")
      )
    ) {
      message.error(
        "De verwachte eindatum van het project dient later te zijn dan de start datum van het project"
      );
    } else if (
      hideEditForm &&
      values.duration === 1 &&
      dayjs(proposalData?.start_date).isAfter(
        dayjs(proposalData?.end_date).format("YYYY-MM-DD")
      )
    ) {
      message.error(
        "De verwachte eindatum van het project dient later te zijn dan de start datum van het project"
      );
    } else {
      const sendData: ProposalPayload = {
        company: 1, // important
        freelancer: userId, // important
        vacancy: undefined,
        project: undefined,
        gross_rate: hideEditForm ? data?.data?.gross_rate : values.ratePerHour, // important
        hours_per_week: hideEditForm
          ? data?.data?.hours_per_week
          : values.hoursPerWeek, // important
        vat_percentage: hideEditForm
          ? data?.data?.vat_percentage
          : values.btwPercentage, // important
        travel_allowances: hideEditForm
          ? data?.data?.travelAllowance === 0
            ? 0
            : data?.data?.travel_allowances
          : values.travelAllowance === 1
          ? values.travelAllowanceValue
          : 0,
        extra_allowances: hideEditForm
          ? data?.data?.extra_allowances
          : values.extra_allowances,
        start_date: hideEditForm
          ? data?.data?.start_date
          : values.duration === 1
          ? dayjs(values.start_date).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"), // important
        end_date: hideEditForm
          ? data?.data?.end_date
          : values.duration === 1
          ? dayjs(values.end_date).format("YYYY-MM-DD")
          : null,
        payment_terms: hideEditForm
          ? data?.data?.payment_terms
          : values.paymentTerms, // important
        agree_job_terms:
          data?.data === undefined || data?.data?.created_by === user.id
            ? false
            : values.agree_job_terms,
        agree_partnership:
          data?.data === undefined || data?.data?.created_by === user.id
            ? false
            : values.agree_job_terms,
        edited_by: user.id,
        authorized_by: values.authorized_by,
      };

      if (contact.company) {
        sendData.freelancer = user.id;
        sendData.company = contact.id;
      } else {
        sendData.company = user.id;
        sendData.freelancer = contact.id;
      }

      // console.log("Sending proposal", sendData);
      saveProposal(sendData);
    }
  };

  useEffect(() => {
    setWeeklyNetRate(
      parseFloat(weeklyHoursValue.toString()) *
        parseFloat(netHourlyRate.toString())
    );
  }, [weeklyHoursValue, setWeeklyNetRate, netHourlyRate]);

  useEffect(() => {
    if (paymentTermsValue.toString() === "14") {
      if (
        UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
      ) {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) + 1);
      } else {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) - 1);
      }
    } else {
      if (
        UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
      ) {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) + 1.5);
      } else {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) - 1.5);
      }
    }
    // eslint-disable-next-line
  }, [ratePerHourValue, paymentTermsValue]);

  const onPaymentTermsChange = (e: any) => {
    checkCompanyChangesValue();
    setPaymentTermsValue(e.target.value);
    // console.log("PaymentTerms", e.target.value);
  };

  const onTravelAllowanceChange = (e: any) => {
    setTravelAllowanceValue(e.target.value);
    checkCompanyChangesValue();
  };

  const onDurationChange = (e: any) => {
    setDurationValue(e.target.value);
  };

  const handleCancelButton = () => {
    if (hideEditForm) {
      setHideEditForm(false);
    } else {
      setVisible(false);
    }
  };

  const checkCompanyChangesValue = () => {
    // console.log("Value changed....");
    if (
      UserTypes.Company === user.user_type ||
      UserTypes.Child_User === user.user_type
    ) {
      form.setFields([
        { name: "agree_job_terms", value: false },
        { name: "agree_partnership", value: false },
      ]);
    }
  };

  const onChangeHandle = (values: any, allValues: any) => {
    setAgreeJobTerms(allValues.agree_job_terms);
    // setAgreePartnership(allValues.agree_partnership)
  };

  const sendAcceptMessage = () => {
    const messageURLStr = JSON.stringify({
      freelancerURL: "/work/project",
      companyURL: `/createproject/${proposal?.id}`,
    });
    const message: ProposalAcceptedMessage = {
      url: messageURLStr,
      to: contact.id,
      from: user.id,
      message: `Logged in as ${user.first_name} ${user.last_name} - ${user.user_type}. To ${contact.firstName} ${contact.lastName}.`,
    };

    sendProposalAcceptMessage(message);
  };

  // useEffect(() => {
  //   console.log("hasVacancy ", hasVacancy);
  // }, [hasVacancy]);

  return (
    <Modal
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={771}
      footer={null}
      bodyStyle={{
        padding: "0px",
        marginBlockStart: "45px",
        marginBlockEnd: "20px",
      }}
      destroyOnClose={true}
      afterClose={handleClosing}
    >
      <div style={{ width: "100%" }}>
        <div className="profile-picture">
          <img
            className="image1"
            style={{ width: "100px", height: "100px" }}
            src={userData.data?.profile_pic || userAvatar.img}
            alt="user"
          />
        </div>
        <div className="invoice-header" style={{ paddingTop: "4rem" }}>
          <p style={{ fontSize: "26px" }}>{contact.firstName}</p>
          <p style={{ fontSize: "16px" }}>job voorstel</p>
        </div>
        <Form
          form={form}
          style={{ marginTop: "2rem", width: "100%" }}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ratePerHour: proposalData?.gross_rate || 40,
            hoursPerWeek: proposalData?.hours_per_week || 20,
            acceptAgreement: false,
            agree_job_terms: false,
            // agree_partnership: false,
            btwPercentage: proposalData?.vat_percentage || 21,
            duration: 1,
            end_date: proposalData?.end_date || 0,
            extra_allowances: proposalData?.extra_allowances || false,
            netHourlyRate: 0,
            netWeeklyInvoice: 0,
            paymentTerms: proposalData?.payment_terms || "14",
            start_date: proposalData?.start_date || 0,
            travelAllowance: proposalData?.travel_allowances || 1,
            travelAllowanceValue: proposalData?.travel_allowances || 0,
            authorized_by: proposalData?.authorized_by || null,
          }}
          onValuesChange={onChangeHandle}
        >
          {/* Row 1 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              {hideEditForm ? (
                <Form.Item
                  label={
                    <span className="invoice-input-label">Bruto uurtarief</span>
                  }
                >
                  <span className="highlighted-text">{`€ ${form.getFieldValue(
                    "ratePerHour"
                  )} p/u`}</span>
                </Form.Item>
              ) : (
                <Form.Item
                  label={
                    <span className="invoice-input-label">Bruto uurtarief</span>
                  }
                >
                  <Form.Item name="ratePerHour" noStyle>
                    <InputNumber
                      formatter={(value) => `€ ${value}`}
                      min={0}
                      max={500}
                      onChange={(value) => {
                        checkCompanyChangesValue();
                        if (value) setRatePerHourValue(value as number);
                      }}
                    />
                  </Form.Item>
                  <span className="ant-form-text"> per uur</span>
                </Form.Item>
              )}
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              {hideEditForm ? (
                <Form.Item
                  label={
                    <span className="invoice-input-label">Verwachte inzet</span>
                  }
                >
                  <span className="highlighted-text">{`${form.getFieldValue(
                    "hoursPerWeek"
                  )} uur p/w`}</span>
                </Form.Item>
              ) : (
                <Form.Item
                  label={
                    <span className="invoice-input-label">Verwachte inzet</span>
                  }
                >
                  <Form.Item name="hoursPerWeek" noStyle>
                    <InputNumber
                      min={0}
                      max={56}
                      onChange={(value) => {
                        checkCompanyChangesValue();
                        if (value)
                          setWeeklyHoursValue(parseFloat(value.toString()));
                      }}
                    />
                  </Form.Item>
                  <span className="ant-form-text"> uur per week</span>
                </Form.Item>
              )}
            </Col>
          </Row>

          {/* Row 2 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              {hideEditForm ? (
                <Form.Item
                  label={
                    <span className="invoice-input-label">Betaaltermijnen</span>
                  }
                >
                  <span className="highlighted-text">{`${form.getFieldValue(
                    "paymentTerms"
                  )} dagen €${
                    form.getFieldValue("paymentTerms") === "14" ? "1" : "1,5"
                  } p/u`}</span>
                </Form.Item>
              ) : (
                <Form.Item
                  label={
                    <span className="invoice-input-label">Betaaltermijnen</span>
                  }
                  tooltip={{
                    title:
                      user.user_type === UserTypes.Freelancer
                        ? "Standaard gaan wij uit van een 14 dagen betaaltermijn. In geval u met de opdrachtgever een langere betaaltermijn afspreekt brengen wij een iets hogere vergoeding in rekening als risicopremie voor incourantie. Later in het proces kunt u per factuur aangeven of u gebruik wilt maken van onze factoringservice."
                        : "Dit is de vergoeding aan Job.nl, wij gaan standaard uit van 14 dagen betaaltermijn.",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Form.Item name="paymentTerms" noStyle>
                    <Radio.Group
                      onChange={onPaymentTermsChange}
                      value={paymentTermsValue}
                      defaultValue={"14"}
                    >
                      <Radio style={radioStyle} value={"14"}>
                        <span className="highlighted-text">14 dagen</span> a{" "}
                        <span className="highlighted-text">€1 p/u</span>
                      </Radio>
                      <Radio style={radioStyle} value={"30"}>
                        <span className="highlighted-text">30 dagen</span> a{" "}
                        <span className="highlighted-text">€1,5 p/u</span>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>
              )}
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">
                    Verwachte netto weekfactuur
                  </span>
                }
              >
                <Form.Item name="netWeeklyInvoice" noStyle></Form.Item>
                {hideEditForm ? (
                  <span>
                    Op basis van ingevulde tariefstelling
                    <span className="highlighted-text">{` €${weeklyNetRate} `}</span>
                    per week (ex. btw)
                  </span>
                ) : (
                  <span className="ant-form-text">
                    {" "}
                    Op basis van ingevulde tariefstelling
                    <span className="highlighted-text">
                      {" "}
                      € {weeklyNetRate}
                    </span>{" "}
                    per week (ex. btw)
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>

          {/* Row 3 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">Netto uurtarief</span>
                }
              >
                <Form.Item name="netHourlyRate" noStyle>
                  {true ? (
                    <span className="highlighted-text">
                      € {String(netHourlyRate).replaceAll(".", ",")}{" "}
                    </span>
                  ) : (
                    <InputNumber decimalSeparator="," min={1} />
                  )}
                </Form.Item>
                <span className="ant-form-text">
                  <span className="highlighted-text"> p/u</span>
                </span>
              </Form.Item>
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              {hideEditForm ? (
                <Form.Item
                  label={
                    <span className="invoice-input-label">BTW percentage</span>
                  }
                >
                  <span className="highlighted-text">{`${form.getFieldValue(
                    "btwPercentage"
                  )}%`}</span>
                </Form.Item>
              ) : (
                <Form.Item
                  label={
                    <span className="invoice-input-label">BTW percentage</span>
                  }
                >
                  <Form.Item name="btwPercentage" noStyle>
                    <Select
                      onChange={(value) => {
                        checkCompanyChangesValue();
                      }}
                      style={{ width: 85 }}
                    >
                      <Select.Option value={0}>0%</Select.Option>
                      <Select.Option value={9}>9%</Select.Option>
                      <Select.Option value={21}>21%</Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              )}
            </Col>
          </Row>

          {/* Row 4 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              {hideEditForm ? (
                <Form.Item
                  label={
                    <span className="invoice-input-label">
                      Reiskostenvergoeding
                    </span>
                  }
                >
                  <span>
                    {form.getFieldValue("travelAllowance") === 1 ? (
                      <>
                        <span className="highlighted-text">{`€ ${form.getFieldValue(
                          "travelAllowanceValue"
                        )}`}</span>
                        {` per kilometer`}
                        <span></span>
                      </>
                    ) : (
                      <span className="highlighted-text">
                        {"Geen reiskosten vergoeding"}
                      </span>
                    )}
                  </span>
                </Form.Item>
              ) : (
                <Form.Item
                  label={
                    <span className="invoice-input-label">
                      Reiskostenvergoeding
                    </span>
                  }
                >
                  <Form.Item name="travelAllowance" noStyle>
                    <Radio.Group
                      onChange={(e: any) => onTravelAllowanceChange(e)}
                      value={travelAllowanceValue}
                    >
                      <Radio style={radioStyle} value={1}>
                        <Form.Item name="travelAllowanceValue" noStyle>
                          <InputNumber
                            onChange={() => checkCompanyChangesValue()}
                            decimalSeparator=","
                            formatter={(value) => `€ ${value}`}
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                        <span className="ant-form-text"> per kilometer</span>
                      </Radio>
                      <Radio style={radioStyle} value={2}>
                        Geen reiskosten vergoeding
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>
              )}
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              {hideEditForm ? (
                <Form.Item
                  label={<span className="invoice-input-label">Looptijd</span>}
                >
                  <span className="highlighted-text">
                    {form.getFieldValue("duration") === "continuously" ||
                    data?.data?.end_date === null
                      ? "Doorlopend"
                      : `${moment(
                          new Date(form.getFieldValue("start_date"))
                        ).format("DD-MM-YYYY")} 
                        tot 
                        ${moment(
                          new Date(form.getFieldValue("end_date"))
                        ).format("DD-MM-YYYY")}`}
                  </span>
                </Form.Item>
              ) : (
                <Form.Item
                  label={<span className="invoice-input-label">Looptijd</span>}
                >
                  <Form.Item name="duration" noStyle>
                    <Radio.Group
                      onChange={(e: any) => {
                        onDurationChange(e);
                        checkCompanyChangesValue();
                      }}
                      value={durationValue}
                    >
                      <Radio
                        style={radioStyle}
                        value={1}
                        className="duration-row"
                      >
                        <Form.Item
                          name="start_date"
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                          }}
                        >
                          <DatePicker
                            className="date-pickes"
                            format="DD-MM-YYYY"
                          />
                        </Form.Item>
                        <span className="ant-form-text"> {"-"}</span>
                        <Form.Item
                          name="end_date"
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                          }}
                        >
                          <DatePicker
                            className="date-pickes"
                            format="DD-MM-YYYY"
                          />
                        </Form.Item>
                      </Radio>
                      <Radio style={radioStyle} value={"continuously"}>
                        Doorlopend
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">Extra kosten</span>
                }
              >
                <Form.Item
                  name="extra_allowances"
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox disabled={hideEditForm}>Onkosten</Checkbox>
                </Form.Item>
                <span className="ant-form-text"></span>
              </Form.Item>
            </Col>
            {/* <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">
                    {UserTypes.Freelancer === user.user_type
                      ? `Wie is verantwoordelijk voor autorisatie? Nader te bepalen door de opdrachtgever`
                      : `Wie is verantwoordelijk voor autorisatie`}
                  </span>
                }
                name="authorized_by"
              >
                {hideEditForm && user?.user_type !== "company" ? (
                  <span className="highlighted-text">
                    {data?.data?.authorized_by?.first_name}
                    {`  `}
                    {data?.data?.authorized_by?.last_name}
                  </span>
                ) : user?.user_type === "company" ? (
                  <Select
                    placeholder="selecteer gebruiker"
                    onChange={(val: number) => {
                      // mutateAuthorizeBy({ childUserId: val });
                      form.setFieldsValue({ authorized_by: val });
                    }}
                    // disabled={mutationVals.isLoading}
                    // loading={mutationVals.isLoading}
                  >
                    {company?.child_users.map((child) => {
                      if (
                        !child.permissions.find(
                          (perm) => perm.permission === "MC"
                        )
                      ) {
                        return null;
                      }

                      return (
                        <Option key={child.id} value={child.id}>
                          {child.first_name} {child.last_name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : null}
              </Form.Item>
            </Col> */}
          </Row>

          {/* <Row className="invoice-input-row" style={UserTypes.Freelancer === user.user_type ? {} : { display: 'none' }}> */}

          <Row className="invoice-input-row">
            <Col span={24} className="invoice-input invoice-input-column-left">
              <Form.Item>
                <Form.Item
                  name="agree_job_terms"
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox
                  // defaultChecked
                  // onChange={(e) =>
                  //   setAcceptAgreement(e.target.checked)
                  // }
                  // checked={acceptAgreement}
                  // disabled={!proposalData || proposalData?.created_by === user.id}
                  >
                    {"Ik heb de "}
                    <a
                      style={{ color: "#35df90" }}
                      href="/docs/algemene-voorwaarden.pdf"
                      download="Algemene voorwaarden.pdf"
                    >
                      algemene voorwaarden
                    </a>
                    {" van de site gelezen en ga hiermee akkoord"}
                  </Checkbox>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <div className="button-stack">
            <Button
              className="cancel-button"
              style={{ backgroundColor: "#664FF3" }}
              onClick={() => handleCancelButton()}
              disabled={
                proposalData?.edited_by !== user?.id && !hasVacancy
                  ? false
                  : true
              }
            >
              {/* Adjust proposal : Cancel */}
              {hideEditForm ? "Voorstel aanpassen" : "Annuleren"}
            </Button>

            {UserTypes.Company === user.user_type ||
            UserTypes.Child_User === user.user_type ? (
              // buttons for company
              hideEditForm ? (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="accept-button"
                  disabled={
                    proposalData?.edited_by !== user?.id && !hasVacancy
                      ? !agreeJobTerms
                      : true
                  }
                >
                  {/* Assign project */}
                  Project toewijzen
                </Button>
              ) : proposalData?.created_by === undefined ? (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="accept-button"
                  disabled={
                    proposalData?.edited_by !== user?.id && !hasVacancy
                      ? !agreeJobTerms
                      : true
                  }
                >
                  {/* Create project */}
                  Project aanmaken
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="accept-button"
                  disabled={
                    proposalData?.edited_by !== user?.id && !hasVacancy
                      ? !agreeJobTerms
                      : true
                  }
                >
                  {/* Send proposal */}
                  Voorstel versturen
                </Button>
              )
            ) : // buttons for freelancer
            hideEditForm ? (
              <Button
                htmlType="submit"
                type="primary"
                className="accept-button"
                disabled={
                  proposalData?.edited_by !== user?.id && !hasVacancy
                    ? !agreeJobTerms
                    : true
                }
              >
                {/* Accept proposal */}
                Voorstel accepteren
              </Button>
            ) : (
              <Button
                htmlType="submit"
                type="primary"
                className="accept-button"
                disabled={
                  proposalData?.edited_by !== user?.id && !hasVacancy
                    ? !agreeJobTerms
                    : true
                }
              >
                {/* Send proposal */}
                Voorstel versturen
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default Proposal;
