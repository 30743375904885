import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal, Avatar, Row, Col, Form, InputNumber, Grid } from "antd";
import Icon from "@ant-design/icons";
import { AvatarIcon } from "../contacts/contact-card";
import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import { UserTypes } from "src/types/user.type";
import "./styles.css";
import { useUser } from "src/hooks/useUser";
import useCalculateNetHourlyRate from "src/hooks/useCalculateNetHourlyRate";

type ProposalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  proposalId: number | null | undefined;
};

export interface ProposalPayload {
  gross_rate: string;
  hours_per_week: number;
  vat_percentage: string;
  payment_terms: string;
  travel_allowances: string;
  extra_allowances: true;
  start_date: string;
  end_date: string;
  vacancy: number | undefined;
  freelancer: number;
  company: number | undefined;
  project: number | undefined;
  agree_job_terms: boolean;
  agree_partnership: boolean;
}

const Proposal = ({ visible, setVisible, proposalId }: ProposalProps) => {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [form] = Form.useForm();
  const setProposalData = useState<ProposalPayload>()[1];

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  // const [paymentTermsValue] = useState("14");
  const setTravelAllowanceValue = useState(1)[1];

  // const [netHourlyRate, setNetHourlyRate] = useState(0);
  // const [ratePerHourValue, setRatePerHourValue] = useState(40);
  // const [weeklyHoursValue, setWeeklyHoursValue] = useState(20);
  // const [weeklyNetRate, setWeeklyNetRate] = useState(0);

  // console.log(travelAllowanceValue, weeklyNetRate);

  const { user } = useUser();

  const { data } = useQuery(
    ["proposal", proposalId],
    () =>
      axios.get(`${BASE_URL}api/proposals/${proposalId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    { enabled: !!proposalId }
  );
  const prevData = useRef();

  const { netHourlyRate } = useCalculateNetHourlyRate({
    ratePerHourValue: String(data?.data?.gross_rate),
    paymentTermsValue: String(data?.data?.payment_terms),
  });

  useEffect(() => {
    console.log("USEEFFECT");
    if (data?.data && prevData.current !== data.data) {
      console.log("Updating form...");
      prevData.current = data.data;
      // console.log(data.data);
      setProposalData({ ...(data?.data as unknown as ProposalPayload) });
      if (data?.data?.travel_allowances) {
        setTravelAllowanceValue(1);
      }
      // setWeeklyHoursValue(data?.data?.hours_per_week as number);
      // setRatePerHourValue(data?.data?.gross_rate as number);
      form.setFields([
        { name: "ratePerHour", value: data?.data?.gross_rate },
        { name: "hoursPerWeek", value: data?.data?.hours_per_week },
        {
          name: "acceptAgreement",
          value: data?.data?.agree_job_terms,
        },
        { name: "agreement", value: data?.data?.agree_partnership },
        { name: "btwPercentage", value: data?.data?.vat_percentage },
        { name: "duration", value: 1 },
        { name: "end_date", value: moment(data?.data?.end_date) },
        {
          name: "extra_allowances",
          value: data?.data?.extra_allowances,
        },
        { name: "netHourlyRate", value: 0 },
        { name: "netWeeklyInvoice", value: 0 },
        { name: "paymentTerms", value: data?.data?.payment_terms },
        { name: "start_date", value: moment(data?.data?.start_date) },
        {
          name: "travelAllowance",
          value: data?.data?.travel_allowances ? 1 : 2,
        },
        {
          name: "travelAllowanceValue",
          value: data?.data?.travel_allowances,
        },
        {
          name: "authorized_by",
          value: data?.data?.authorized_by?.id,
        },
      ]);
    }
  }, [data, form, setProposalData, setTravelAllowanceValue]);

  const onFinish = async (values: any) => {
    console.log("Form data", values);
  };

  const calculateWeeklyNetRate = (
    ratePerHour: string,
    hoursPerWeek: string,
    paymentTerms: string
  ) => {
    try {
      if (
        UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
      ) {
        return (
          parseFloat(hoursPerWeek) *
          (parseFloat(ratePerHour) + paymentTerms === "14" ? 1 : 1.5)
        );
      } else {
        return (
          parseFloat(hoursPerWeek) *
          (parseFloat(ratePerHour) - (paymentTerms === "14" ? 1 : 1.5))
        );
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={771}
      footer={null}
      bodyStyle={{
        padding: "0px",
        marginBlockStart: "45px",
        marginBlockEnd: "20px",
      }}
    >
      <div style={{ width: "100%" }}>
        <div className="profile-picture">
          <Avatar size={90} icon={<Icon component={AvatarIcon} />} />
        </div>
        <div className="invoice-header">
          <p style={{ fontSize: "26px" }}>{user.first_name}</p>
          <p style={{ fontSize: "16px" }}>Job overeenkomst</p>
        </div>
        <Form
          form={form}
          style={{ marginTop: "2rem" }}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ratePerHour: 0,
            hoursPerWeek: 0,
            acceptAgreement: false,
            agreement: false,
            btwPercentage: 0,
            duration: 0,
            end_date: 0,
            extra_allowances: false,
            netHourlyRate: 0,
            netWeeklyInvoice: 0,
            paymentTerms: "-",
            start_date: 0,
            travelAllowance: 0,
            travelAllowanceValue: 0,
          }}
        >
          {/* <Button onClick={() => setDisableFields(!disableFields)}>
            Change
          </Button> */}

          {/* Row 1 */}
          <Row
            className="invoice-input-row"
            style={{ borderTop: "3px solid #F9F9FC" }}
          >
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">Tariefstelling </span>
                }
              >
                <Form.Item name="ratePerHour" noStyle>
                  <span className="highlighted-text">{`€ ${Number(
                    form.getFieldValue("ratePerHour")
                  )} p/u`}</span>
                </Form.Item>
                <span className="ant-form-text"></span>
              </Form.Item>
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">
                    Gemiddeld aantal uren
                  </span>
                }
              >
                <Form.Item name="hoursPerWeek" noStyle>
                  <span className="highlighted-text">{`${form.getFieldValue(
                    "hoursPerWeek"
                  )} uur p/w`}</span>
                </Form.Item>
                <span className="ant-form-text"></span>
              </Form.Item>
            </Col>
          </Row>

          {/* Row 2 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">Betaaltermijnen</span>
                }
              >
                <Form.Item name="paymentTerms" noStyle>
                  <span className="highlighted-text">{`${form.getFieldValue(
                    "paymentTerms"
                  )} dagen €${
                    form.getFieldValue("paymentTerms") === "14" ? "1" : "1,5"
                  } p/u`}</span>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">Netto weekfactuur</span>
                }
              >
                {/* <Form.Item name="netWeeklyInvoice" noStyle>
                  <InputNumber min={1} max={60} />
                </Form.Item> */}
                <span className="highlighted-text">
                  {`Op basis van ingevulde tariefstelling
                  €${calculateWeeklyNetRate(
                    form.getFieldValue("ratePerHour"),
                    form.getFieldValue("hoursPerWeek"),
                    form.getFieldValue("paymentTerms")
                  )} per week (ex. btw)`}
                </span>
              </Form.Item>
            </Col>
          </Row>

          {/* Row 3 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">Netto uurtarief</span>
                }
              >
                <Form.Item name="netHourlyRate" noStyle>
                  {true ? (
                    <span className="highlighted-text">€ {netHourlyRate} </span>
                  ) : (
                    <InputNumber formatter={(value) => `€ ${value}`} min={1} />
                  )}
                </Form.Item>
                <span className="ant-form-text">
                  <span className="highlighted-text"> p/u</span>
                </span>
              </Form.Item>
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">BTW percentage</span>
                }
              >
                <Form.Item name="btwPercentage" noStyle>
                  <span className="highlighted-text">{`${Number(
                    form.getFieldValue("btwPercentage")
                  )}%`}</span>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>

          {/* Row 4 */}
          <Row className="invoice-input-row">
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-right"
            >
              <Form.Item
                label={
                  <span className="invoice-input-label">
                    Reiskostenvergoeding
                  </span>
                }
              >
                <Form.Item name="travelAllowance" noStyle>
                  <span className="highlighted-text">
                    {form.getFieldValue("travelAllowance") === 1
                      ? `€ ${Number(
                          form.getFieldValue("travelAllowanceValue")
                        )} cent per kilometer`
                      : "Geen reiskosten vergoeding"}
                  </span>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col
              span={screens.md === true ? 12 : 24}
              className="invoice-input invoice-input-column-left"
            >
              <Form.Item
                label={<span className="invoice-input-label">Looptijd</span>}
              >
                <Form.Item name="duration" noStyle>
                  <span className="highlighted-text">
                    {form.getFieldValue("duration") === "continuously"
                      ? "Doorlopend"
                      : `${moment(
                          new Date(form.getFieldValue("start_date"))
                        ).format("DD-MM-YYYY")} 
                        - 
                        ${moment(
                          new Date(form.getFieldValue("end_date"))
                        ).format("DD-MM-YYYY")}`}
                  </span>
                </Form.Item>
                {/* <span className="ant-form-text"> uur per week</span> */}
              </Form.Item>
            </Col>
          </Row>

          {/* Row 5 */}
          <Row className="invoice-input-row">
            <Col span={24} className="invoice-input invoice-input-column-right">
              <Form.Item
                label={
                  <span className="invoice-input-label">Extra kosten</span>
                }
              >
                <span className="highlighted-text">Ja</span>
              </Form.Item>
            </Col>
          </Row>

          <Row className="invoice-input-row">
            <Col span={24} className="invoice-input invoice-input-column-left">
              <Form.Item>
                <Form.Item name="agreement" valuePropName="checked" noStyle>
                  Algemene voorwaarden
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>

          <Row className="invoice-input-row">
            <Col span={24} className="invoice-input invoice-input-column-left">
              <Form.Item>
                <Form.Item
                  name="acceptAgreement"
                  valuePropName="checked"
                  noStyle
                >
                  Zie ook:{" "}
                  <a href="http://belastingdienst.nl">
                    http://belastingdienst.nl
                  </a>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default Proposal;
