import React from "react";
import CompanyLogo from "src/company-logo.svg";
import { Modal, Row, Col, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import "src/index.css";
import AuthLayout from "src/components/Common/AuthLayout";

export function ResetEmailSent() {
  // const onFinish = (values: any) => {
  //   console.log('Success:', values);
  // };

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log('Failed:', errorInfo);
  // };
  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={true}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          <Row className="login-row">
            <Col
              xl={10}
              lg={10}
              md={0}
              sm={0}
              xs={0}
              className="login-design__column"
            >
              <div className="login-design__start">
                <div>
                  <img src={CompanyLogo} alt="Company Logo"></img>
                  <div className="login-header">
                    Wachtwoord vergeten? Geen probleem!
                  </div>
                  <Divider className="login-devider" plain></Divider>
                  <div className="login-text">
                    Wij helpen u met het
                    <br />
                    resetten van uw wachtwoord.
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={24}
              sm={24}
              xs={24}
              className="login-form__column"
            >
              <div style={{ marginTop: "25%", textAlign: "center" }}>
                <div className="graditutde-main">
                  <div className="reset-password-header">
                    Uw wachtwoord <br />
                    is verstuurd naar uw emailadres.
                  </div>
                  <div className="reset-password-from__div">
                    <div
                      className="reset-password-footer"
                      style={{ textAlign: "center" }}
                    >
                      U ontvangt een een email met daarin een link, klik hierop{" "}
                      <br />
                      om uw wachtwoord te resetten.
                    </div>
                  </div>
                  <Button className="login-small-btn">
                    <Link to="/login">Inloggen</Link>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </AuthLayout>
  );
}
