import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Grid, message, Modal, Row, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useUser } from "src/hooks/useUser";
import Connected from "src/modules/Connect/Icons/connected.png";
import Pending from "src/modules/Connect/Icons/request_connection.png";
import { FollowConnection } from "src/types/connections.type";
import { VacancySearch } from "src/types/work.type";
import userAvatar from "../../images/json/user_image.json";

type CompanyDetailModalProps = {
  id: number | undefined;
  setSelectedUserId: Function;
  refetchSearchList: Function;
  vacancyData: VacancySearch | undefined;
};

type Response = {
  count: number;
  next: string;
  previous: string;
  results: FollowConnection[];
};

type Response_Specialities = {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
    }
  ];
};

export default function CompanyDetailModal({
  id,
  setSelectedUserId,
  refetchSearchList,
  vacancyData,
}: CompanyDetailModalProps) {
  const screens = Grid.useBreakpoint();
  const [isFollow, setIsFollow] = useState(false);
  const { user } = useUser();
  const [isLoadingSync, setLoadingSync] = useState(false);

  const ErrorMsg = (content: string) => {
    message.error(content);
  };

  // const successMessage = (msg: string) => {
  //   message.success(msg);
  // }

  const BASE_URL = process.env.REACT_APP_BACKEND;
  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const followData = useQuery<Response>(
    `followData${vacancyData?.company.id}`,
    async () => {
      if (vacancyData?.company.id)
        return (
          await axios.get(
            `${BASE_URL}api/follow?company_id=${vacancyData?.company.id}`,
            config
          )
        ).data;
    }
  );

  const { data: specialitiesList } = useQuery<Response_Specialities>(
    "specialties",
    async () => {
      return (await axios.get(BASE_URL + "api/specialty?limit=400")).data;
    }
  );

  const { data: regionData } = useQuery<{ id: number; name: string }>(
    `Region_${vacancyData?.region}`,
    async () => {
      if (vacancyData?.region) {
        return (await axios.get(`${BASE_URL}api/region/${vacancyData?.region}`))
          .data;
      }
    }
  );

  const { data: positionList } = useQuery("positionList", async () => {
    return (await axios.get(BASE_URL + `api/position/?limit=400`)).data;
  });

  const followCompany = async () => {
    setLoadingSync(true);
    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}api/follow/`,
        headers: config.headers,
        data: {
          follow_to_id: vacancyData?.company.id,
        },
      });
      setLoadingSync(false);
      followData.refetch();
    } catch (error: any) {
      setLoadingSync(false);
      ErrorMsg(error?.response?.data);
    }
  };

  const unfollowCompany = async () => {
    setLoadingSync(true);
    try {
      await axios({
        method: "DELETE",
        url: `${BASE_URL}api/follow/${followData.data?.results[0].id}`,
        headers: config.headers,
      });
      setLoadingSync(false);
      followData.refetch();
    } catch (error: any) {
      setLoadingSync(false);
      ErrorMsg(JSON.stringify(error?.response?.data));
    }
  };

  const setVacancyInterested = async () => {
    setLoadingSync(true);
    if (vacancyData) {
      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}api/vacancies/${vacancyData.id}/show_interest/`,
          headers: config.headers,
        });
        await followCompany();
        refetchSearchList();
        setSelectedUserId(undefined);
      } catch (error) {
        console.error(error);
        ErrorMsg("Error");
        setLoadingSync(false);
      }
    }
  };

  const removeVacancyInterested = async () => {
    if (vacancyData) {
      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}api/vacancies/${vacancyData.id}/remove_interest/`,
          headers: config.headers,
        });

        await unfollowCompany();
        refetchSearchList();
        setSelectedUserId(undefined);
      } catch (error) {
        console.error(error);
        ErrorMsg("Error");
        setLoadingSync(false);
      }
    }
  };

  const isInterested = useMemo(() => {
    if (
      vacancyData?.interested_freelancer.find((e) => {
        return e.id === user.id;
      }) === undefined
    ) {
      return false;
    } else {
      return true;
    }
  }, [vacancyData, user.id]);

  const getSpecialities = useMemo(() => {
    let tempList: any[] = [];
    vacancyData?.specialty?.forEach((e, index) => {
      specialitiesList?.results.forEach((v) => {
        if (v.id === e) {
          tempList.push(
            <span className="webspecialization">{`${v.name}${
              vacancyData && index !== vacancyData?.specialty?.length - 1
                ? ", "
                : ""
            }`}</span>
          );
        }
      });
    });
    return tempList;
  }, [vacancyData, specialitiesList]);

  const getPositions = () => {
    let tempList: any[] = [];

    positionList?.results.forEach((position: { id: Number; name: string }) => {
      if (
        position?.id === vacancyData?.position &&
        position?.name !== "Anders (geef door aan info@job.nl)" &&
        position?.name !== "Anders"
      ) {
        tempList.push(
          <span className="webspecialization">{position.name}</span>
        );
      }
    });

    return tempList;
  };
  return (
    <Modal
      title={undefined}
      centered
      visible={id !== undefined ? true : false}
      footer={null}
      width={screens.md ? "60%" : "90%"}
      onCancel={() => {
        setSelectedUserId(undefined);
        setIsFollow(false);
      }}
      bodyStyle={{
        padding: "0px",
        marginBlockStart: "75px",
        marginBlockEnd: "25px",
      }}
      style={{
        maxWidth: "780px",
      }}
    >
      <Row justify="space-between" className="border-style-row">
        <Col span={24}>
          {isFollow && (
            <Button
              type="text"
              icon={<LeftOutlined />}
              style={{ top: 30, left: 30, zIndex: 100 }}
              onClick={() => {
                setIsFollow(false);
              }}
            ></Button>
          )}
          <div style={{ textAlign: "center" }}>
            <div className="parent" style={{ top: "-30px" }}>
              <Row justify="center">
                <Col
                  className=""
                  style={{
                    border: "3px solid #FFF",
                    borderRadius: "100%",
                    width: "78px",
                    height: "78px",
                    backgroundColor: "#000233",
                    top: "1px",
                  }}
                >
                  <img
                    className="image1"
                    // style={
                    //   vacancyData?.company?.connection?.status ===
                    //   ConnectionStatusValues.CONNECTED
                    //     ? {}
                    //     : { filter: "blur(3px)" }
                    // }
                    src={vacancyData?.company.profile_pic || userAvatar.img}
                    alt="company"
                  />
                </Col>
              </Row>
              {vacancyData?.company?.connection?.status === "pending" && (
                <img
                  className="image2"
                  style={{ top: "50px", left: "52%", height: "28px" }}
                  src={Pending}
                  alt="status"
                />
              )}
              {vacancyData?.company?.connection?.status === "connected" && (
                <img
                  className="image2"
                  style={{ top: "50px", left: "52%", height: "28px" }}
                  src={Connected}
                  alt="status"
                />
              )}
              <div className="freelancer-detail-header-column">
                <div className="comapny-detail-header-button">
                  {`${vacancyData?.company?.company_name}`}
                </div>
                <Button onClick={() => setIsFollow(true)} type="link">
                  <span className="comapny-detail-header">
                    Toon meer bedrijfsdetails / volg bedrijf
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {!isFollow ? (
            <>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Regio</div>
                  <div>{regionData?.name}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Periode</div>
                  <div>
                    {vacancyData?.end_date ? (
                      <>
                        {moment(vacancyData?.start_date).format("DD-MM-yyyy")}
                        <span style={{ fontWeight: "normal" }}> tot </span>
                        {moment(vacancyData?.end_date).format("DD-MM-yyyy")}
                      </>
                    ) : (
                      "Doorlopend"
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Werklast</div>
                  <div>{`${vacancyData?.hours_per_week} uur p/w`}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Vergoeding</div>
                  <div>
                    {`${Number(vacancyData?.minimum_rate)} - ${Number(
                      vacancyData?.maximum_rate
                    )} p/u`}
                  </div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Specialisme</div>
                  <div>{getSpecialities}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Vacature</div>
                  <div>{getPositions()}</div>
                </Col>
              </Row>
              <Row
                style={{ display: "block" }}
                className={
                  screens.md ? "comapny-detail-cell" : "freelancer-detail-cell"
                }
              >
                <div className="detail-label">Omschrijving</div>
                <p className="display-linebreak">{vacancyData?.description}</p>
              </Row>
            </>
          ) : (
            <>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Sector</div>
                  <div>{vacancyData?.company?.sector?.name}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Locatie</div>
                  <div>{vacancyData?.company?.region?.name}</div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Werknemers</div>
                  <div>{vacancyData?.company?.number_of_employees}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Website</div>
                  <div>{vacancyData?.company?.website}</div>
                </Col>
              </Row>
              <Row
                className={
                  screens.md ? "comapny-detail-cell" : "freelancer-detail-cell"
                }
              >
                <Col>
                  <div className="detail-label">Bedrijfsomschrijving</div>
                  <div>{vacancyData?.company?.description}</div>
                </Col>
              </Row>
            </>
          )}

          <Row justify="end" style={{ marginBlockStart: "24px" }}>
            {isFollow ? (
              <Button
                block
                size="large"
                className="btn-font"
                loading={isFollow && isLoadingSync}
                disabled={!(user.is_finqle_active && user.is_payment_active)}
                style={{
                  backgroundColor: "#002333",
                  color: "#FFF",
                  border: "0px",
                }}
                onClick={() =>
                  followData.data?.count === 0
                    ? followCompany()
                    : unfollowCompany()
                }
              >
                {followData.data?.count === 0 ? `Volg bedrijf` : `Ontvolgen`}
              </Button>
            ) : (
              <>
                <Col span={12}>
                  <Button
                    block
                    size="large"
                    type="link"
                    // className='btn-font'
                    disabled={!isInterested}
                    // loading={connectionStatus && connectionStatus.status === 'connected' && isLoadingSync}
                    className={
                      !isInterested
                        ? "btn-font vacancy-notinterested-btn-disabled"
                        : "btn-font vacancy-notinterested-btn"
                    }
                    style={
                      screens.md
                        ? { border: "0px" }
                        : {
                            minHeight: "85px",
                          }
                    }
                    onClick={() => removeVacancyInterested()}
                  >
                    Niet interessant
                  </Button>
                </Col>
                <Col span={12}>
                  {!(user.is_finqle_active && user.is_payment_active) ? (
                    <Tooltip
                      title={
                        "Je dient je te verifiëren om op vacatures te reageren"
                      }
                    >
                      <Button
                        block
                        className={
                          isInterested
                            ? "btn-font vacancy-interested-btn-disabled"
                            : "btn-font vacancy-interested-btn"
                        }
                        size="large"
                        type="primary"
                        disabled={
                          (isInterested &&
                            user.is_finqle_active &&
                            user.is_payment_active) ||
                          !(user.is_finqle_active && user.is_payment_active)
                        }
                        style={
                          screens.md
                            ? { border: "0px" }
                            : {
                                border: "0px",
                                whiteSpace: "inherit",
                                fontSize: "16px",
                                inlineSize: "auto",
                                minHeight: "85px",
                              }
                        }
                        loading={isLoadingSync}
                        onClick={() => setVacancyInterested()}
                      >
                        {isInterested
                          ? "Jouw profiel is met de opdrachtgever gedeeld"
                          : "Geef interesse door aan opdrachtgever"}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      block
                      className={
                        isInterested
                          ? "btn-font vacancy-interested-btn-disabled"
                          : "btn-font vacancy-interested-btn"
                      }
                      size="large"
                      type="primary"
                      disabled={
                        (isInterested &&
                          user.is_finqle_active &&
                          user.is_payment_active) ||
                        !(user.is_finqle_active && user.is_payment_active)
                      }
                      style={
                        screens.md
                          ? { border: "0px" }
                          : {
                              border: "0px",
                              whiteSpace: "inherit",
                              fontSize: "16px",
                              inlineSize: "auto",
                              minHeight: "85px",
                            }
                      }
                      loading={isLoadingSync}
                      onClick={() => setVacancyInterested()}
                    >
                      {isInterested
                        ? "Jouw profiel is met de opdrachtgever gedeeld"
                        : "Geef interesse door aan opdrachtgever"}
                    </Button>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
