import { useState } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  Grid,
  Input,
  Drawer,
  Menu,
  Dropdown,
} from "antd";
import ProjectOverview from "./Project-Overview";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Files from "../Files";
import FreelancerDetails from "./FreelancerDetails";
import HourlyOverview from "./Hourly-Overview";
import Toggle from "src/icons/toggle-ff.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { User } from "src/types/user.type";
import userAvatar from "../../../../images/json/user_image.json";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import Layout from "antd/lib/layout/layout";

enum SubMenuTypes {
  PROJECT_OVERVIEW,
  HOUR_OVERVIEW,
  FILES,
  CHAT,
}
const BASE_URL = process.env.REACT_APP_BACKEND;

export default function ProjectDetails() {
  //@ts-ignore
  const { selectedProposal } = useParams<{}>();

  const [currentSubMenu, setCurrentSubMenu] = useState<SubMenuTypes>(
    SubMenuTypes.HOUR_OVERVIEW
  );
  const screens = Grid.useBreakpoint();

  const { data: proposal } = useQuery(
    [`proposal_project`, selectedProposal],
    () => {
      return axios
        .get(`${BASE_URL}api/proposals/${selectedProposal}/`, getHttpHeader())
        .then((data) => data.data);
    },
    { enabled: !!selectedProposal }
  );

  const { data: freelancerData } = useQuery<User>(
    `freelancerDetails${proposal?.freelancer}`,
    async () => {
      return (
        await axios.get(
          `${BASE_URL}api/freelancers/${proposal?.freelancer}/`,
          getHttpHeader()
        )
      ).data;
    },
    {
      enabled: !!proposal,
    }
  );

  const handleMenuChange = (value: any) => {
    switch (value) {
      case "PROJECT_OVERVIEW":
        setCurrentSubMenu(SubMenuTypes.PROJECT_OVERVIEW);
        break;
      case "HOUR_OVERVIEW":
        setCurrentSubMenu(SubMenuTypes.HOUR_OVERVIEW);
        break;
      case "FILES":
        setCurrentSubMenu(SubMenuTypes.FILES);
        break;
      case "CHAT":
        setCurrentSubMenu(SubMenuTypes.CHAT);
        break;
      default:
        break;
    }
  };

  const history = useHistory();

  const menu = (
    <Menu
      style={{
        backgroundColor: "#664FF3",
      }}
      mode="inline"
      onClick={(e) => handleMenuChange(e.key)}
    >
      <Menu.Item
        className="mobile-sub-menu-items menu-group-item"
        style={{ borderBottom: "1px solid #FFF" }}
        key={"PROJECT_OVERVIEW"}
      >
        Projectoverzicht
      </Menu.Item>
      <Menu.Item
        className="mobile-sub-menu-items menu-group-item"
        style={{ borderBottom: "1px solid #FFF" }}
        key={"HOUR_OVERVIEW"}
      >
        Urenoverzicht
      </Menu.Item>
      <Menu.Item
        className="mobile-sub-menu-items menu-group-item"
        style={{ borderBottom: "1px solid #FFF" }}
        key={"FILES"}
      >
        {" "}
        Bestanden
      </Menu.Item>
      <Link to={`/connect/chat/${proposal?.freelancer}`}>
        <Menu.Item
          className="mobile-sub-menu-items menu-group-item"
          key={"CHAT"}
        >
          Chat
        </Menu.Item>
      </Link>
    </Menu>
  );

  return (
    <>
      {!screens.md && (
        <Drawer
          title={undefined}
          placement="right"
          closable={false}
          onClose={() => history.goBack()}
          visible={selectedProposal !== undefined ? true : false}
          width="90%"
          bodyStyle={{
            padding: "0px",
          }}
        >
          <div className="freelancer-details-mobile-header">
            <Button type="link" onClick={() => history.goBack()}>
              <ArrowLeftOutlined style={{ fontSize: "18px", color: "white" }} />
            </Button>
            <img
              className="freelance-profile"
              src={
                freelancerData?.profile_pic
                  ? freelancerData?.profile_pic
                  : userAvatar.img
              }
              alt="user"
            />
            <span className="freelancer-details-mobile">
              {freelancerData?.first_name}
            </span>
          </div>
          <div style={{ padding: "0px 18px" }}>
            <div className="mobile-submenu-header">ZZP - weergave</div>
            <Divider className="mobile-divider" />
            <div>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomCenter"
              >
                <Button className="mobile-sub-menu-button">
                  <img
                    style={{ marginRight: "5px" }}
                    src={Toggle}
                    alt="toggle"
                  />
                  Menu
                </Button>
              </Dropdown>
            </div>
            <div style={{ marginBlockStart: "16px" }}>
              {currentSubMenu === SubMenuTypes.PROJECT_OVERVIEW && (
                <ProjectOverview ProposalData={proposal} />
              )}
              {currentSubMenu === SubMenuTypes.HOUR_OVERVIEW && (
                <HourlyOverview id={selectedProposal} ProposalData={proposal} />
              )}
              {currentSubMenu === SubMenuTypes.FILES && (
                <Files proposalId={proposal?.id ? proposal?.id : 0} />
              )}
            </div>
          </div>
        </Drawer>
      )}
      {screens.md && (
        <Row
          justify={"center"}
          style={{
            backgroundColor: "#f6f6f6",
            minHeight: "82vh",
            marginBlockEnd: "40px",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={21} xs={21}>
            <Layout className="container">
              <Row style={{ marginBlockStart: "24px" }}>
                <Col span={6}>
                  <div className="web-header">ZZP - weergave</div>
                </Col>
                <Col span={18}>
                  <Input
                    size={"large"}
                    placeholder="Zoeken"
                    prefix={<SearchOutlined />}
                    style={{ maxHeight: "45px", padding: "0 11px" }}
                  />
                </Col>
                <Divider
                  style={{ backgroundColor: "#002333", marginTop: "4px" }}
                />
              </Row>
              <Row justify="space-between">
                {/* <Col flex="auto" > */}
                <Col
                  span={6}
                  style={{ marginBlockStart: "38px", marginRight: "22px" }}
                >
                  <FreelancerDetails
                    key={"web"}
                    freelancerId={proposal?.freelancer}
                    proposal={proposal}
                  />
                </Col>
                <Col span={17} style={{ marginBlockStart: "38px" }}>
                  <Row>
                    <Col
                      span={screens.md ? 6 : 12}
                      style={screens.md ? {} : { marginBlockEnd: "14px" }}
                    >
                      <Button
                        ghost={
                          currentSubMenu === SubMenuTypes.PROJECT_OVERVIEW &&
                          true
                        }
                        key="project-overview"
                        className="project-details-button-1"
                        onClick={() =>
                          setCurrentSubMenu(SubMenuTypes.PROJECT_OVERVIEW)
                        }
                      >
                        Projectoverzicht
                      </Button>
                    </Col>
                    <Col span={screens.md ? 6 : 12}>
                      <Button
                        block
                        ghost={
                          currentSubMenu === SubMenuTypes.HOUR_OVERVIEW && true
                        }
                        key="hours-overview"
                        className="project-details-button-1"
                        onClick={() =>
                          setCurrentSubMenu(SubMenuTypes.HOUR_OVERVIEW)
                        }
                      >
                        Urenoverzicht
                      </Button>
                    </Col>
                    <Col span={screens.md ? 6 : 12}>
                      <Button
                        block
                        ghost={currentSubMenu === SubMenuTypes.FILES && true}
                        key="files"
                        className="project-details-button-1"
                        onClick={() => setCurrentSubMenu(SubMenuTypes.FILES)}
                      >
                        Bestanden
                      </Button>
                    </Col>
                    <Col span={screens.md ? 6 : 12}>
                      <Link to={`/connect/chat/${proposal?.freelancer}`}>
                        <Button block className="project-details-button-1">
                          Chat
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBlockStart: "24px",
                      borderTop: "3px solid #EEEEEE",
                    }}
                  ></Row>
                  {currentSubMenu === SubMenuTypes.PROJECT_OVERVIEW && (
                    <ProjectOverview ProposalData={proposal} />
                  )}
                  {currentSubMenu === SubMenuTypes.HOUR_OVERVIEW && (
                    <HourlyOverview
                      id={selectedProposal}
                      ProposalData={proposal}
                    />
                  )}
                  {currentSubMenu === SubMenuTypes.FILES && (
                    <Files proposalId={selectedProposal} />
                  )}
                </Col>
              </Row>
            </Layout>
          </Col>
        </Row>
      )}
    </>
  );
}
