import React, { Dispatch, useEffect, useState } from "react";

import { Box, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

import { Grid } from "antd";
import "./styles.css";

import ContactCard from "./contact-card";
import { ActionType, initialState } from "../../store/reducers/chat.reducer";
import { useUser } from "src/hooks/useUser";
import axios from "axios";
import { BASE_URL } from "src/config/default";

type ContactsListProps = {
  dispatch: Dispatch<ActionType>;
  state: typeof initialState;
};

const ContactsList = ({ state, dispatch }: ContactsListProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();

  const { user } = useUser();

  const [activeConnections, setActiveConnections] = useState<Array<number>>([]);

  // const contactsKeys = (
  //   Object.keys(state.contacts) as Array<unknown> as Array<number>
  // ).sort((a, b) => {
  //   const aExists = state.contacts[a].messages.length > 0;
  //   const bExists = state.contacts[b].messages.length > 0;
  //   if (aExists && !bExists) return -1;
  //   if (!aExists && bExists) return 1;
  //   if (!aExists && !bExists) return 0;
  //   return (
  //     [...state.contacts[b].messages].reverse()[0]!.ts! -
  //     [...state.contacts[a].messages].reverse()[0]!.ts!
  //   );
  // });

  const getConnections = async () => {
    try {
      const res = await axios.get(`${BASE_URL}api/connection`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const connections = res.data?.results;
      // why filtering by is_unfriend? Bcuz couldn't able to set false to is_accepted when disconnecting a connection.
      const activeConnections = connections
        .filter((connection: any) => !connection?.is_unfriend)
        .map((connection: any) => {
          if (connection?.request_to === user.id)
            return connection?.request_by?.id;
          return connection?.request_to?.id;
        });
      setActiveConnections(activeConnections);
    } catch (error) {
      console.log("getConnections ", error);
    }
  };

  useEffect(() => {
    getConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("===> activeConnections ", contactsKeys, activeConnections);

  return (
    <Box className={screen.xs ? "chat-container-mobile" : "chat-container"}>
      {!screen.xs && (
        <Box className={screen.xs ? "" : "chat-search-box"}>
          <InputGroup>
            <InputLeftElement children={<SearchIcon color="#BFBFBF" />} />
            <Input
              borderRadius="2px"
              placeholder="Zoeken"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Box>
      )}
      <Box overflow="auto" flex="1">
        {activeConnections
          .filter(
            (key) =>
              state.contacts[key]?.firstName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              state.contacts[key]?.lastName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              state.contacts[key]?.name
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
          )
          .map((key, i) => {
            return (
              <ContactCard
                state={state}
                dispatch={dispatch}
                contact={state.contacts[key]}
                key={i}
              />
            );
          })}
        {!activeConnections.find(
          (key) =>
            state.contacts[key]?.firstName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            state.contacts[key]?.lastName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            state.contacts[key]?.name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
        ) &&
          state.connected && (
            <div style={{ marginTop: "1rem" }}>Geen contacten gevonden</div>
          )}
      </Box>
    </Box>
  );
};

export default ContactsList;
