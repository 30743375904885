import {
  Row,
  Col,
  Form,
  Grid,
  Typography,
  Input,
  Button,
  Upload,
  message,
} from "antd";
import "./styles.css";
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { UserTypes, User, Company } from "src/types/user.type";
import axios from "axios";
import { useQuery } from "react-query";
import { useUser } from "src/hooks/useUser";
import userAvatar from "../../../images/json/user_image.json";

export const UserAvatar = (data: User | Company) => {
  return (
    <img
      className="profile_picture"
      src={!!data?.profile_pic ? data.profile_pic : userAvatar.img}
      alt={"profile"}
    />
  );
};

const { Text, Title } = Typography;

const prefix = (isEditing: boolean) => <Text disabled={!isEditing}>+31</Text>;

export default function EditFreelancerProfile() {
  const screens = Grid.useBreakpoint();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>();
  const { user } = useUser();

  const formGroupInputStyle = {
    display: "inline-block",
    width: "calc(50% - 40px)",
    margin: "0 40px 0 0 ",
    maxHeight: "70px",
  };
  const [isEditing, setEditing] = useState(false);

  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const fetchUserDetails = async () => {
    return await (
      await axios.get(`${BASE_URL}api/freelancers/${user.id}/`, config)
    ).data;
  };

  const { data, isLoading, refetch } = useQuery<User>(
    "userDetails",
    fetchUserDetails
  );

  useEffect(() => {
    console.log("DATA ===> ", data, isLoading);
    if (!isLoading) {
      form.setFields([
        {
          name: "email",
          value: data?.email,
        },
        {
          name: "first_name",
          value: data?.first_name,
        },
        {
          name: "last_name",
          value: data?.last_name,
        },
        {
          name: "telephone",
          value: data?.phone_number.substr(3, data?.phone_number.length),
        },
        {
          name: "prefix",
          value: data?.phone_number.substr(0, 3),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [data, isLoading]);

  const onFinish = async (value: any) => {
    const newData = {
      last_name: value.last_name,
      first_name: value.first_name,
      email: value.email,
      phone_number: `${value.prefix}${value.telephone}`,
    };

    await axios({
      method: "PATCH",
      url: `${BASE_URL}api/freelancers/${user.id}/`,
      data: newData,
      headers: config.headers,
    })
      .then((res) => {
        message.success("Opgeslagen.");
        setFileList([]);
        setEditing(false);
      })
      .catch((err) => {
        if (
          JSON.stringify(err.response.data).includes(
            "The phone number entered is not valid."
          )
        ) {
          form.setFields([
            {
              name: "telephone",
              errors: ["Invalid phone number"],
            },
          ]);
        } else {
          message.error("Something went wrong. Try again.");
        }
      });
  };

  const handleEditForm = () => {
    if (!isEditing) {
      setEditing(true);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      form.setFields([
        {
          name: "email",
          errors: undefined,
        },
        {
          name: "first_name",
          errors: undefined,
        },
        {
          name: "last_name",
          errors: undefined,
        },
        {
          name: "telephone",
          errors: undefined,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const uploadProps = {
    multiple: false,
    name: "profile_pic",
    fileList: fileList,
    action: `${BASE_URL}api/freelancers/${user.id}/`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    beforeUpload: (file: any) => {
      console.log(file.type);
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
          message.error("Afbeelding moet kleiner zijn dan 1 MB!");
          return false;
        }
        return true;
      } else {
        message.error(`${file.name} heeft een ongeldig bestandsformaat`);
        return false;
      }
    },
    onChange: async (info: any) => {
      let fileList = [...info.fileList];
      // Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // Read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      setFileList(fileList);

      const { status } = info.file;
      if (status === "done") {
        refetch();
        message.success(`${info.file.name} is geupload.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      {screens.md && (
        <div className="inner-sub-header">Persoonlijk account</div>
      )}
      <Row>
        <Form
          form={form}
          name="regiter-form"
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          style={{ width: "100%" }}
          onValuesChange={() => setEditing(true)}
        >
          <div>
            <div style={screens.md === true ? { padding: "24px" } : {}}>
              <Row justify="center">
                <Col
                  xl={0}
                  lg={0}
                  md={0}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}
                >
                  <Title level={5} className="mobile-header">
                    {UserTypes.Company === user.user_type ||
                    UserTypes.Child_User === user.user_type
                      ? "Bedrijfsaccount"
                      : "Persoonlijk account"}
                  </Title>
                  <br />
                </Col>
              </Row>

              <div>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {UserTypes.Freelancer === user.user_type && (
                      <>
                        {/* {!screens.md &&
                          <ProfilePicture profile_img={profile.profile_img} isEditing={isEditing} />} */}
                        {!screens.md && (
                          <>
                            <div
                              style={
                                screens.md
                                  ? { display: "grid" }
                                  : { display: "grid", placeItems: "center" }
                              }
                            >
                              {!screens.md && (
                                <>
                                  {" "}
                                  <span className="form-label">
                                    Profielfoto
                                  </span>
                                  <br />
                                  {UserAvatar(data as User)}
                                </>
                              )}
                            </div>
                            <div
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              {isEditing && (
                                // @ts-ignore
                                <Upload {...uploadProps}>
                                  <Button
                                    className="btn-upload"
                                    style={{ margin: "24px 0px 0px 0px" }}
                                    icon={<UploadOutlined />}
                                  >
                                    Upload profielfoto
                                  </Button>
                                </Upload>
                              )}
                              <br />
                            </div>
                          </>
                        )}
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Form.Item
                            label={
                              <span className="form-label">Emailadres</span>
                            }
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Emailadres is verplicht",
                              },
                              {
                                type: "email",
                                message: "Email adres wordt niet herkend",
                              },
                            ]}
                            style={
                              screens.md === true ? formGroupInputStyle : {}
                            }
                          >
                            <Input
                              disabled={!isEditing}
                              placeholder="Emailadres"
                              className="edit-profile-input"
                              onChange={handleEditForm}
                            />
                          </Form.Item>
                          {screens.md && (
                            <Form.Item
                              label={
                                <span className="form-label">Profielfoto</span>
                              }
                              style={
                                screens.md === true
                                  ? {
                                      display: "inline-block",
                                      width: "calc(50% - 40px)",
                                      margin: "0 40px 0 0 ",
                                      maxHeight: "60px",
                                    }
                                  : {}
                              }
                            >
                              {/* <ProfilePicture profile_img={profile.profile_img} isEditing={isEditing} /> */}

                              <div
                                style={
                                  screens.md
                                    ? { display: "grid" }
                                    : { display: "grid", placeItems: "center" }
                                }
                              >
                                {!screens.md && (
                                  <>
                                    {" "}
                                    <span className="form-label">
                                      Profielfoto
                                    </span>
                                    <br />{" "}
                                  </>
                                )}
                                {UserAvatar(data as User)}
                                {isEditing && (
                                  // @ts-ignore
                                  <Upload {...uploadProps}>
                                    <Button
                                      className="btn-upload"
                                      style={{ margin: "24px 0px 0px 0px" }}
                                      icon={<UploadOutlined />}
                                    >
                                      Upload profielfoto
                                    </Button>
                                  </Upload>
                                )}
                              </div>
                            </Form.Item>
                          )}
                        </Form.Item>
                        {screens.md === true && <br />}
                        <Form.Item
                          style={
                            screens.md
                              ? { marginBottom: 0, width: "50%" }
                              : { marginBottom: 0 }
                          }
                        >
                          <Form.Item
                            label={<span className="form-label">Voornaam</span>}
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: "Voornaam is verplicht",
                              },
                            ]}
                            style={
                              screens.md === true
                                ? {
                                    display: "inline-block",
                                    width: "calc(100% - 40px)",
                                    margin: "0 40px 0 0 ",
                                    maxHeight: "70px",
                                  }
                                : {}
                            }
                          >
                            <Input
                              disabled={!isEditing}
                              placeholder="Voornaam"
                              className="edit-profile-input"
                            />
                          </Form.Item>
                        </Form.Item>
                        {screens.md === true && <br />}
                        <Form.Item
                          style={
                            screens.md
                              ? { marginBottom: 0, width: "50%" }
                              : { marginBottom: 0 }
                          }
                        >
                          <Form.Item
                            label={
                              <span className="form-label">Achternaam</span>
                            }
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: "Achternaam is verplicht",
                              },
                            ]}
                            style={
                              screens.md === true
                                ? {
                                    display: "inline-block",
                                    width: "calc(100% - 40px)",
                                    margin: "0 40px 0 0 ",
                                    maxHeight: "70px",
                                  }
                                : {}
                            }
                          >
                            <Input
                              disabled={!isEditing}
                              placeholder="Achternaam"
                              className="edit-profile-input"
                            />
                          </Form.Item>
                        </Form.Item>
                        {screens.md === true && <br />}
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          className="form-column"
                        >
                          <Form.Item
                            label={
                              <span className="form-label">Telefoonnummer</span>
                            }
                            name="telephone"
                            rules={[
                              {
                                required: true,
                                message: "Telefoonnummer is verplicht",
                              },
                              {
                                pattern: new RegExp("^([0-9]{9})$"),
                                message: "Invalid Data",
                              },
                            ]}
                            style={
                              screens.md === true ? formGroupInputStyle : {}
                            }
                          >
                            <Input
                              disabled={!isEditing}
                              size="large"
                              addonBefore={prefix(isEditing)}
                              placeholder="Telefoonnummer"
                              className="edit-profile-input"
                            />
                          </Form.Item>
                        </Form.Item>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Form.Item>
            <Row justify="end" style={screens.md ? { margin: "0px 20px" } : {}}>
              {!isEditing ? (
                <Col span={screens.md ? 12 : 24}>
                  <Button
                    onClick={() => setEditing(true)}
                    block={screens.md ? false : true}
                    type="primary"
                    className={screens.md ? "form-button" : ""}
                    style={{ backgroundColor: "#002333", marginRight: "20px" }}
                  >
                    Bewerken
                  </Button>
                </Col>
              ) : (
                <>
                  <Col span={screens.md ? 6 : 12}>
                    <Button
                      onClick={() => {
                        setEditing(false);
                        refetch();
                      }}
                      block={screens.md ? false : true}
                      type="primary"
                      className={screens.md ? "form-button" : ""}
                      style={{
                        backgroundColor: "#664FF3",
                        marginRight: "20px",
                      }}
                    >
                      Annuleren
                    </Button>
                  </Col>
                  <Col span={screens.md ? 6 : 12}>
                    <Button
                      block={screens.md ? false : true}
                      type="primary"
                      className={screens.md ? "form-button" : ""}
                      htmlType="submit"
                    >
                      Opslaan
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </>
  );
}
