import React, { useState } from 'react';
import { useStripe, useElements, IdealBankElement } from '@stripe/react-stripe-js';

import IdealBankSection from './IdealBankSection';
import { Button, Grid, message } from 'antd';

type props = {
  name: string
  clientSecret: string
  setClientSecret: Function
  setLoading: Function
  setModalVisible: Function
  setPaymentSuccess: Function
}

export default function CheckoutFormIDeal({ clientSecret, setClientSecret, setLoading, setModalVisible, setPaymentSuccess, name }: props) {
  const stripe = useStripe();
  const elements = useElements();
  const screens = Grid.useBreakpoint();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setPaymentLoading(true)
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const idealBank = elements.getElement(IdealBankElement);

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName = event.target['accountholder-name'];

    const { error, paymentIntent } = await stripe.confirmIdealPayment(`${clientSecret}`, {
      payment_method: {
        //@ts-ignore
        ideal: idealBank,
        billing_details: {
          name: accountholderName.value,
        },
      },
      return_url: `${window.location.href}`,
    });

    if (error) {
      // Show error to your customer.
      console.log(error.message);
      message.error(JSON.stringify(error.message))
    }

    if (paymentIntent?.status === 'succeeded') {
      setModalVisible(false);
      setClientSecret('');
      setLoading(false);
      setPaymentSuccess(true);
    }

    // Otherwise the customer will be redirected away from your
    // page to complete the payment with their bank.
  };

  const [loading, setPaymentLoading] = useState(false);

  return (
    <form onSubmit={handleSubmit} style={screens.md ? { width: '45vw' } : { width: '100%' }}>
      <div className="form-row">
        <label>
          Name
          <input name="accountholder-name"
            value={name}
            placeholder="Name"
            required disabled={true}
            style={{ width: '100%' }} />
        </label>
      </div>
      <br />
      <div className="form-row">
        <IdealBankSection />
      </div>
      <br />
      <Button loading={loading} htmlType="submit" type='primary' size="large" disabled={!stripe} style={{ marginRight: '12px' }}>
        Betalen
      </Button>
      <Button size='large' onClick={() => {
        setModalVisible(false)
        setLoading(false)
      }}>Annuleren</Button>
    </form>
  );
}