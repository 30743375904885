import React, { useState } from "react";
import CompanyLogo from "src/company-logo.svg";
import { Modal, Row, Col, Button, Divider, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import "src/index.css";
import axios from "axios";
import AuthLayout from "../../components/Common/AuthLayout";

export function RequestResetPassword() {
  const [isLoading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BACKEND;

  const onFinish = (values: any) => {
    console.log("Success:", values);
    setLoading(true);

    axios({
      method: "POST",
      url: `${BASE_URL}api/account/reset-password/`,
      data: {
        email: String(values.email).toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setLoading(false);
        message.success("Email verzonden.");
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          "Er is iets fout gegaan. Controleer uw e-mailadres en probeer opnieuw."
        );
      });
  };

  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={true}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          <Row className="login-row">
            <Col
              xl={10}
              lg={10}
              md={0}
              sm={0}
              xs={0}
              className="login-design__column"
            >
              <div className="login-design__start">
                <div>
                  <img src={CompanyLogo} alt="Company Logo"></img>
                  <div className="login-header">
                    Wachtwoord vergeten? Geen probleem!
                  </div>
                  <Divider className="login-devider" plain></Divider>
                  <div className="login-text">
                    Wij helpen u met het
                    <br />
                    resetten van uw wachtwoord.
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={24}
              sm={24}
              xs={24}
              className="login-form__column"
            >
              <div style={{ marginTop: "25%", textAlign: "center" }}>
                <div className="graditutde-main">
                  <div className="graditude-text">Wachtwoord resetten</div>
                  <div className="reset-password-from__div">
                    <Form name="rest-password-from" onFinish={onFinish}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Vul een emailadres in!",
                          },
                          {
                            type: "email",
                            message: "Email adres wordt niet herkend",
                          },
                        ]}
                      >
                        <Input placeholder="Emailadres" size="large" />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          size="large"
                          loading={isLoading}
                          className="reset-password-btn"
                        >
                          Reset wachtwoord
                        </Button>
                      </Form.Item>
                    </Form>
                    <div className="reset-help">
                      We zullen u een email sturen met daarin een reset link.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "30px",
                    paddingTop: "5px",
                    fontSize: "14px",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <Divider className="login-divider" />
                  <span
                    style={{
                      fontFamily: "Basier Square",
                      fontWeight: 400,
                    }}
                  >
                    Heeft u al een account?{" "}
                    <Link
                      to="/login"
                      style={{
                        color: "#664FF3",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                    >
                      Inloggen
                    </Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </AuthLayout>
  );
}
