// @ts-nocheck
import React, { useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { ChatMessage, MessageType } from "../../types/chat-message.type";
import { Attachment } from "../../types/attachment.type";
import { JobProposal } from "../../types/job-proposal.type";
import { useQuery } from "react-query";
import { Proposal } from "src/types/work.type";
import axios from "axios";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useUser } from "src/hooks/useUser";
import { UserTypes } from "src/types/user.type";
import { Contact } from "../../store/reducers/chat.reducer";
import { useHistory } from "react-router";
import getRelativeTime from "../../utils/getRelativeTime";

type MessageBoxProps = {
  sent?: boolean;
  message: ChatMessage;
  hideTime?: boolean;
  showProposal: (proposal: JobProposal) => void;
  setProjectFormVisible: any;
  setProposalId: any;
  selectedContact: Contact;
};

const MessageBox = ({
  sent,
  message,
  hideTime,
  showProposal,
  setProjectFormVisible,
  setProposalId,
  selectedContact,
}: MessageBoxProps) => {
  const messageURL =
    message.type === MessageType.textWithUrl &&
    JSON.parse(message?.message?.url);
  const proposalId = messageURL?.companyURL?.split("/")?.[2];
  const history = useHistory();

  const { user } = useUser();

  const { data, refetch } = useQuery<Proposal>(
    `proposal_${proposalId}`,
    async () => {
      if (proposalId === undefined) return;
      return await (
        await axios.get(
          `${BASE_URL}api/proposals/${proposalId}`,
          getHttpHeader()
        )
      ).data;
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  let textMessage = "";
  switch (message.type) {
    case MessageType.text:
      textMessage = message.message as string;
      break;
    case MessageType.jobProposal:
      textMessage = "Bekijk job voorstel";
      break;
    case MessageType.attachment:
      const attachment = message.message as Attachment;
      textMessage = `Bekijk bijlage ${attachment.fileName}`;
      break;
    case MessageType.textWithUrl:
      if (UserTypes.Freelancer === user.user_type)
        textMessage = `Job voorstel geaccepteerd`;
      // proposal accepted by company
      else if (UserTypes.Company === user.user_type) {
        if (message.from.id === selectedContact.id) {
          textMessage =
            `${selectedContact.firstName} ${selectedContact.lastName} heeft het Job voorstel geaccepteerd, ` +
            `klik hier om het project aan te maken/toe te wijzen`;
        } else
          textMessage =
            "U heeft het Vacaturevoorstel geaccepteerd, klik hier " +
            "om het project aan te maken/toe te wijzen";
      }
      break;
  }

  return (
    <div
      style={
        // UserTypes.Freelancer === user.user_type &&
        // MessageType.textWithUrl === message.type
        //   ? { display: "none" }
        //   :
        {
          width: "auto",
          marginLeft: sent ? "auto" : "",
          marginTop: "5px",
          textAlign: sent ? "right" : "left",
        }
      }
    >
      {!hideTime && (
        <Text
          fontWeight="500"
          color="#A5A6A7"
          fontSize="14px"
          align={sent ? "right" : "left"}
          style={{ textAlign: sent ? "right" : "left" }}
        >
          {getRelativeTime(message.ts)}
        </Text>
      )}
      <Box
        style={{ display: "inline-block" }}
        bgColor={sent ? "#F6F6F6" : "#5F19DD"}
        borderRadius="5px"
      >
        <Text
          align={sent ? "right" : "left"}
          color={sent ? "#002333" : "white"}
          fontSize="14px"
          fontWeight="400"
          padding=".5rem"
          textDecoration={
            message.type === MessageType.attachment ||
            message.type === MessageType.jobProposal
              ? "underline"
              : message.type === MessageType.textWithUrl &&
                data !== undefined &&
                !data?.vacancy
              ? "underline"
              : ""
          }
          cursor={
            message.type === MessageType.attachment ||
            message.type === MessageType.jobProposal
              ? "pointer"
              : message.type === MessageType.textWithUrl &&
                data !== undefined &&
                !data?.vacancy
              ? "pointer"
              : ""
          }
          onClick={() => {
            switch (message.type) {
              case MessageType.attachment: {
                const attachment = message.message as Attachment;
                window.open(attachment?.url?.toString(), "_blank");
                break;
              }
              case MessageType.jobProposal:
                showProposal(message.message as JobProposal);
                break;
              case MessageType.textWithUrl: {
                console.log("---> data?.vacancy ", data);
                if (data !== undefined && !data?.vacancy) {
                  if (UserTypes.Freelancer === user.user_type)
                    history.push(messageURL?.freelancerURL);
                  else {
                    setProjectFormVisible(true);
                    setProposalId(proposalId);
                  }
                }
                // else window.location.href = "/work/project";
                break;
              }
            }
          }}
        >
          {textMessage}
        </Text>
      </Box>
    </div>
  );
};

export default MessageBox;
