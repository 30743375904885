import { Row, Col, Card } from "antd";
import "./style.css";
import { FileSearchOutlined, EnvironmentOutlined } from "@ant-design/icons";
import Connected from "./Icons/connected.png";
import Pending from "./Icons/request_connection.png";
import { FreelanceConnection } from "src/types/connections.type";
import { Company } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import userAvatar from "../../images/json/user_image.json";
import { Link } from "react-router-dom";

type FreelancerConnectionsProps = {
  data: FreelanceConnection;
  loading: boolean;
  key: any;
  setSelectedUserId: Function;
};

export default function ConnectItem({
  data,
  loading,
  setSelectedUserId,
}: FreelancerConnectionsProps) {
  const { user } = useUser();
  let company: Company =
    data.request_by === user.id
      ? (data.request_to as Company)
      : (data.request_by as Company);
  const { Meta } = Card;

  return (
    <section>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                marginBottom: "10px",
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                minWidth: "1366",
                cursor: "pointer",
              }}
              loading={loading}
              bodyStyle={{
                padding: "24px 0px 0px 0px",
              }}
              onClick={() => {
                setSelectedUserId(company.id);
              }}
            >
              <Row style={{ paddingBottom: "0px" }}>
                <Col span={20}>
                  <Meta
                    style={{
                      padding: "10px 10px 10px 45px",
                    }}
                    avatar={
                      <div style={{ paddingRight: "20px" }}>
                        <div className="parent">
                          <img
                            className="image1"
                            style={
                              data.is_accepted ? {} : { filter: "blur(3px)" }
                            }
                            src={company?.profile_pic || userAvatar.img}
                            alt="user"
                          />
                          {!data.is_accepted ? (
                            <img
                              className="image2"
                              src={Pending}
                              alt="status"
                            />
                          ) : (
                            <img
                              className="image2"
                              src={Connected}
                              alt="status"
                            />
                          )}
                        </div>
                      </div>
                    }
                    title={
                      <span className="web-card-title">{`${company?.company_name}`}</span>
                    }
                    description={
                      <div>
                        <div>
                          <span className="webspecialization">
                            {company?.sector?.name}
                          </span>
                        </div>
                        <div className="card-body-content">
                          {company?.description}
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingTop: "40px",
                    paddingLeft: "20px",
                  }}
                >
                  <span className="rate">
                    {/* € {data.request_to.min_rate} - {data.request_to.max_rate} */}
                  </span>
                </Col>
              </Row>
              <Row justify="space-around">
                <Col className="card-inner-border" span={12}>
                  <Link to={`/search/${company?.id}`}>
                    <span className="web-card-bottom">
                      {company?.open_vacancy_count} vacature
                    </span>
                  </Link>
                </Col>
                {/* <Col className="card-inner-border" span={8}><span className='web-card-bottom'>{company.email}</span></Col>
                <Col className="card-inner-border" span={6}><span className='web-card-bottom'>{company.open_project_count} voltooide jobs</span></Col> */}
                <Col
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #F6F6F6",
                    paddingTop: "12px",
                    paddingBottom: "14px",
                  }}
                  span={12}
                >
                  <span className="web-card-bottom">
                    {company?.region?.name}
                  </span>
                </Col>
              </Row>
            </Card>
          )}
        </Col>

        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "16px",
                marginBottom: "10px",
                minWidth: "1366",
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "16px",
                borderRadius: "5px",
              }}
              loading={loading}
              bodyStyle={{
                padding: 14,
              }}
              onClick={() => setSelectedUserId(company.id)}
            >
              <Row justify="space-between">
                <Col span={16}>
                  <div className="card-mobile-name-bold">{`${company?.company_name}`}</div>
                  {/* <div className='card-mobile-sub' style={{ overflowWrap: 'break-word' }}>{company.email}</div> */}
                  <div style={{ marginBlockEnd: "32px" }}></div>
                  {/* <div className='card-mobile-content1'>{company.finished_project_count} jaar ervaring</div> */}
                  {/* <div className='card-mobile-content2'>{company.region && company.region.name} voltooide jobs</div> */}
                  <Row>
                    <Col style={{ marginRight: "10px" }}>
                      <FileSearchOutlined
                        style={{ margin: "0px 5px", fontSize: "12px" }}
                      />
                      <span className="mobile-specialization">
                        {company?.sector?.name}
                      </span>
                    </Col>
                    <Col>
                      <EnvironmentOutlined
                        style={{ margin: "0px 5px", fontSize: "12px" }}
                      />
                      <span className="mobile-specialization">
                        {company?.region?.name}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={8}
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <Row justify="end">
                    <Col>
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          paddingRight: "10px",
                        }}
                      >
                        <div className="parent">
                          <img
                            className="image1"
                            style={
                              data.is_accepted ? {} : { filter: "blur(3px)" }
                            }
                            src={company?.profile_pic || userAvatar.img}
                            alt="user"
                          />
                          {!data.is_accepted ? (
                            <img
                              className="image2"
                              src={Pending}
                              alt="status"
                            />
                          ) : (
                            <img
                              className="image2"
                              src={Connected}
                              alt="status"
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </section>
  );
}
