import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { initialState as chatInitialState } from './chat.reducer';

export type state = {
  currentPage: string[];
  chatState: typeof chatInitialState | undefined
};

export const initialState: state = {
  currentPage: ["1"],
  chatState: undefined,
};

export const appSetting = createSlice({
  name: 'App Settings',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<string>) {
      return { ...state, currentPage: [action.payload] };
    },
    setChatMessages(state, action: PayloadAction<typeof chatInitialState>) {
      return { ...state, chatMessages: action.payload }
    }
  }
})

export const {
  setCurrentPage,
  setChatMessages
} = appSetting.actions;

export default appSetting.reducer