import { Menu, Row, Col, Divider, Dropdown, Button } from "antd";
import "./style.css";
import Toggle from "../Connect/Icons/toggle-ff.png";
import { Link, useLocation } from "react-router-dom";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import { checkPermission } from "src/hooks/checkPermission";
import { PermissionType } from "src/types/Permission";

export enum WorkSubHeaders {
  PROJECTS = "projects",
  FINISHED_PROJECTS = "finishedProjects",
  VACANCIES = "vacancies",
}

export default function SubHeader() {
  const location = useLocation();
  const { user } = useUser();

  //Setting the current active page
  const getSelectedKey = () => {
    if (location.pathname.includes("work/project")) {
      return [WorkSubHeaders.PROJECTS];
    }

    switch (location.pathname) {
      case "/work/project":
        return [WorkSubHeaders.PROJECTS];
      case "/work/finishedproject":
        return [WorkSubHeaders.FINISHED_PROJECTS];
      case "/work/vacancies":
        return [WorkSubHeaders.VACANCIES];
      default:
        return [""];
    }
  };

  const getHeader = () => {
    if (location.pathname.includes("/work/project")) {
      return "Projecten";
    } else if (location.pathname.includes("/work/finishedproject")) {
      return "Afgeronde projecten";
    } else if (location.pathname.includes("/work/vacancies")) {
      return "Vacatures";
    } else {
      return "";
    }
  };

  const linkStyle = {
    backgroundColor: "inherit",
    color: "inherit",
  };

  const getCompanyChildUserPermission = () => {
    if (
      user.user_type === UserTypes.Company ||
      (UserTypes.Child_User === user.user_type &&
        checkPermission({ user, can: PermissionType.MANAGE_CONNECTIONS }))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const menu = (
    <Menu
      selectedKeys={getSelectedKey()}
      style={{
        backgroundColor: "#664FF3",
      }}
    >
      {getCompanyChildUserPermission() && (
        <Menu.Item
          className="mobile-sub-menu-items"
          key={WorkSubHeaders.PROJECTS}
        >
          <Link to="/work/project" style={linkStyle}>
            Projecten
          </Link>
        </Menu.Item>
      )}
      {getCompanyChildUserPermission() && (
        <Menu.Item
          className="mobile-sub-menu-items"
          key={WorkSubHeaders.VACANCIES}
        >
          <Link style={linkStyle} to="/work/vacancies">
            Vacatures
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0} className="sub-menu-bar">
          <Menu
            selectedKeys={getSelectedKey()}
            mode="horizontal"
            className="sub-menu-item"
          >
            {getCompanyChildUserPermission() && (
              <Menu.Item
                key={WorkSubHeaders.PROJECTS}
                className="sub-menu-item"
              >
                <Link style={linkStyle} to="/work/project">
                  Projecten
                </Link>
              </Menu.Item>
            )}
            {getCompanyChildUserPermission() && (
              <Menu.Item
                key={WorkSubHeaders.VACANCIES}
                className="sub-menu-item"
              >
                <Link style={linkStyle} to="/work/vacancies">
                  Vacatures
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Col>
        <Col
          xl={0}
          lg={0}
          md={0}
          sm={24}
          xs={24}
          className="mobile-sub-title-container"
        >
          <div className="mobile-submenu-header">{getHeader()}</div>
          <Divider className="mobile-divider" />
          <div>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomCenter"
            >
              <Button className="mobile-sub-menu-button">
                <img style={{ marginRight: "5px" }} src={Toggle} alt="toggle" />
                Menu
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
}
