import { useState, useEffect } from "react";
import {
  Layout,
  Divider,
  Input,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Pagination,
  Grid,
} from "antd";
import { Route, useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import SubHeader from "./SubHeader";
import ConnectItem from "./ConnectItemFreelancer";
import ConnectItemCompany from "./ConnectItemCompany";
import FollowerItem from "./ConnectItemFollower";
import AppHeader from "../../components/header/AppHeader";
import "./style.css";
import Toggle from "./Icons/toggle-ff.png";
import axios from "axios";
import { useQuery } from "react-query";
import {
  FreelanceConnection,
  CompanyConnection,
  FollowConnection,
} from "src/types/connections.type";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import FreelancerDetailsModal from "./FreelancerDetailModal";
import CompanyDetailsModal from "./CompanyDetailModal";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useDebounce } from "@react-hook/debounce";
import EmptyLogo from "src/empty-state.svg";

export enum ConnectionType {
  ACCEPTED = "accepted",
  PENDING = "pending",
  FOLLOWING = "follow",
}

export type Response = {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
};

export default function Connection() {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  //@ts-ignore
  const { id } = useParams();

  const { Content } = Layout;
  const [currentSelect, setCurrentSelect] = useState(ConnectionType.ACCEPTED);
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);

  const [selectedCardId, setSelectedCardId] = useState<number | undefined>();
  const [connectionList, setConnectionList] = useState<FreelanceConnection[]>();
  const [connectionListCompany, setConnectionListCompany] = useState<
    CompanyConnection[]
  >();
  const [followersList, setFollowersList] = useState<FollowConnection[]>();

  const [acceptedConnections, setAcceptedConnections] = useState(0);
  const [pendingConnections, setPendingConnections] = useState(0);
  const [followConnections, setFollowConnections] = useState(0);

  const screens = Grid.useBreakpoint();
  /**
   * Need two states to update input value
   * realtime and make a network request with
   * a debounced value
   */
  const [search, setSearch] = useState("");
  const [debSearch, setDebSearch] = useDebounce(search, 250);

  useEffect(() => {
    setDebSearch(search);
  }, [search, setDebSearch]);

  const { isLoading, data, refetch } = useQuery<Response>(
    ["connections", currentSelect, debSearch],
    async (key: any, value: string) => {
      fetchFollowCount();
      switch (value) {
        case ConnectionType.ACCEPTED || ConnectionType.PENDING:
          return await (
            await axios.get(
              `${BASE_URL}api/connection?name=${debSearch}&limit=${limit}&offset=${(currentPage -
                1) *
                limit}`,
              getHttpHeader()
            )
          ).data;
        case ConnectionType.FOLLOWING:
          return await (
            await axios.get(
              `${BASE_URL}api/follow?name=${debSearch}&limit=${limit}&offset=${(currentPage -
                1) *
                limit}`,
              getHttpHeader()
            )
          ).data;
        default:
          return await (
            await axios.get(
              `${BASE_URL}api/connection?name=${debSearch}&limit=${limit}&offset=${(currentPage -
                1) *
                limit}`,
              getHttpHeader()
            )
          ).data;
      }
    }
  );

  const fetchFollowCount = () => {
    axios
      .get(
        `${BASE_URL}api/follow?limit=${limit}&offset=${(currentPage - 1) *
          limit}`,
        getHttpHeader()
      )
      .then((res) => {
        setFollowConnections(
          res.data.count !== followConnections
            ? res.data.count
            : followConnections
        );
      });
  };

  const refetchAllRequest = () => {
    fetchFollowCount();
    refetch();
  };

  // Filtering the data according to the user type and selected connection type
  const filerResult = (filter: string, data: Response) => {
    // Filtering the accepted and panding data by checking the is_accepted field.
    // True records are connected one and false are pending connections.
    let acceptedList = data.results.filter((e) => {
      return e.is_accepted && !e.is_unfriend;
    });
    let pendingList = data.results.filter((e) => {
      return !e.is_accepted;
    });

    // Checking whether any user id is coming by the path params. If there is any user id
    // selected we need to file filter that record to display.
    if (id) {
      const d = data?.results.filter((e) => {
        return (
          id === Number(e.request_to?.id).toString() ||
          id === Number(e.request_by?.id).toString()
        );
      });

      // Filtered data by path params needs to be assigned to accepted connection or connected connection
      acceptedList = d.filter((e) => {
        return e.is_accepted && !e.is_unfriend;
      });
      pendingList = d.filter((e) => {
        return !e.is_accepted;
      });

      console.log(acceptedList, pendingList);
    }

    // Setting the accepted and pending connection counts to the states. If values are same setting the new values.
    if (
      currentSelect === ConnectionType.ACCEPTED ||
      currentSelect === ConnectionType.PENDING
    ) {
      const acceptCon = acceptedList.length;
      setAcceptedConnections(
        acceptCon !== acceptedConnections ? acceptCon : acceptedConnections
      );
      const pendingCon = pendingList.length;
      setPendingConnections(
        pendingCon !== pendingConnections ? pendingCon : pendingConnections
      );
    } else if (currentSelect === ConnectionType.FOLLOWING) {
      setFollowConnections(
        data.results.length !== followConnections
          ? data.results.length
          : followConnections
      );
      setFollowersList(data.results);
    }

    // There are 2 types of cards available. One is for freelancer and another one for company. According to the
    // user type we need to set the connection lists
    if ("freelancer" === user.user_type) {
      if (currentSelect === ConnectionType.ACCEPTED) {
        setConnectionList(acceptedList);
      } else if (currentSelect === ConnectionType.PENDING) {
        setConnectionList(pendingList);
      }
    } else if ("company" === user.user_type) {
      if (currentSelect === ConnectionType.ACCEPTED) {
        setConnectionListCompany(acceptedList);
      } else if (currentSelect === ConnectionType.PENDING) {
        setConnectionListCompany(pendingList);
      }
    }
  };

  // Pagination Handler
  const handlePagination = async (page: number) => {
    await setCurrentPage(page);
    refetch();
  };

  // Filtering the records according to selected connection type and data
  // If the data is empty we are setting an empty array
  useEffect(() => {
    if (data && data.count !== 0) {
      filerResult(currentSelect, data as Response);
    } else {
      setConnectionList([]);
      setConnectionListCompany([]);
      setFollowersList([]);
    }
    // eslint-disable-next-line
  }, [data, currentSelect, currentPage]);

  const getHeader = () => {
    if (currentSelect === ConnectionType.ACCEPTED) {
      return `Connecties (${acceptedConnections})`;
    } else if (currentSelect === ConnectionType.PENDING) {
      return UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
        ? `Verzonden verzoeken (${pendingConnections})`
        : `Ontvangen verzoeken (${pendingConnections})`;
    } else if (currentSelect === ConnectionType.FOLLOWING) {
      return `Volgers (${followConnections})`;
    } else {
      return `Connecties (${acceptedConnections})`;
    }
  };

  const menu = (
    <Menu
      onClick={(e: any) => {
        setCurrentSelect(e.key);
      }}
      style={{ backgroundColor: "#664FF3" }}
    >
      <Menu.Item
        className="mobile-sub-menu-items"
        key={ConnectionType.ACCEPTED}
      >
        Connecties ({acceptedConnections})
      </Menu.Item>
      <Menu.Item className="mobile-sub-menu-items" key={ConnectionType.PENDING}>
        {UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
          ? `Verzonden verzoeken (${pendingConnections})`
          : `Ontvangen verzoeken (${pendingConnections})`}
      </Menu.Item>
      <Menu.Item
        className="mobile-sub-menu-items"
        key={ConnectionType.FOLLOWING}
      >
        {`${
          UserTypes.Company === user.user_type ||
          UserTypes.Child_User === user.user_type
            ? "Volgers"
            : "Volgend"
        } (${followConnections})`}
      </Menu.Item>
    </Menu>
  );

  // Get the count of the connections
  const getItemCount = () => {
    if (currentSelect === ConnectionType.ACCEPTED) {
      return acceptedConnections;
    } else if (currentSelect === ConnectionType.PENDING) {
      return pendingConnections;
    } else if (currentSelect === ConnectionType.FOLLOWING) {
      return followConnections;
    }
  };

  // console.log("LIST ", connectionListCompany, connectionList);
  // console.log("ConnectionType ", currentSelect);

  return (
    <Route path="/connect/connections">
      <AppHeader />
      <SubHeader currentSubHeader={"network"} />
      {UserTypes.Freelancer === user.user_type && (
        <CompanyDetailsModal
          id={selectedCardId}
          setSelectedUserId={setSelectedCardId}
          refetchSearchList={refetchAllRequest}
          connectionType={currentSelect}
        />
      )}
      {(UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type) && (
        <FreelancerDetailsModal
          id={selectedCardId}
          setSelectedUserId={setSelectedCardId}
          refetchSearchList={refetchAllRequest}
        />
      )}
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          <Layout className="container" style={{ minHeight: "85vh" }}>
            <Content className="web-content" style={{ minWidth: "100%" }}>
              <Row justify="space-between" style={{ marginTop: 25 }}>
                <Col flex="150px">
                  <div className="web-header">Netwerk</div>
                </Col>
                <Col flex="2 1 auto" className="web-search">
                  <Input
                    size="large"
                    placeholder="Zoeken"
                    prefix={<SearchOutlined />}
                    style={{ maxHeight: "45px", padding: "0 11px" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                <Col flex="">
                  <Row justify="space-between">
                    <Col>
                      <Button
                        onClick={() =>
                          setCurrentSelect(ConnectionType.ACCEPTED)
                        }
                        className={
                          currentSelect === ConnectionType.ACCEPTED
                            ? "search-button-selected"
                            : "search-button"
                        }
                      >
                        Connecties ({acceptedConnections})
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => setCurrentSelect(ConnectionType.PENDING)}
                        className={
                          currentSelect === ConnectionType.PENDING
                            ? "search-button-selected"
                            : "search-button"
                        }
                      >
                        {UserTypes.Company === user.user_type ||
                        UserTypes.Child_User === user.user_type
                          ? `Verzonden verzoeken (${pendingConnections})`
                          : `Ontvangen verzoeken (${pendingConnections})`}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          setCurrentSelect(ConnectionType.FOLLOWING)
                        }
                        className={
                          currentSelect === ConnectionType.FOLLOWING
                            ? "search-button-selected"
                            : "search-button"
                        }
                        style={{ marginRight: "0px" }}
                      >{`${
                        UserTypes.Company === user.user_type ||
                        UserTypes.Child_User === user.user_type
                          ? "Volgers"
                          : "Volgend"
                      } (${followConnections})`}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className="web-divider-dark" />
              {/* <Button onClick={() => setModalVisible(true)}>Send Connection Request</Button> */}
              <div
                style={
                  connectionList || connectionListCompany
                    ? {}
                    : { display: "none" }
                }
              >
                {/* for freelancer */}
                {ConnectionType.FOLLOWING !== currentSelect &&
                  connectionList?.map((data) => {
                    return (
                      <ConnectItem
                        key={data.id}
                        data={data}
                        loading={isLoading}
                        setSelectedUserId={setSelectedCardId}
                      />
                    );
                  })}

                {/* for company */}
                {ConnectionType.FOLLOWING !== currentSelect &&
                  connectionListCompany?.map((data) => {
                    return (
                      <ConnectItemCompany
                        key={data.id}
                        data={data}
                        loading={isLoading}
                        setSelectedUserId={setSelectedCardId}
                      />
                    );
                  })}
                {/* followers requests */}
                {ConnectionType.FOLLOWING === currentSelect &&
                  followersList?.map((e) => {
                    return (
                      <FollowerItem
                        key={e.id}
                        data={e}
                        loading={isLoading}
                        setSelectedUserId={setSelectedCardId}
                      />
                    );
                  })}
                {screens.md &&
                  currentSelect === ConnectionType.ACCEPTED &&
                  connectionList?.length === 0 && (
                    <Row justify="center" style={{ marginBottom: 20 }}>
                      <img alt="Empty Screen" src={EmptyLogo} width={4} />
                    </Row>
                  )}
                {!screens.md &&
                  currentSelect === ConnectionType.ACCEPTED &&
                  connectionList?.length === 0 && (
                    <Row justify="center" style={{ marginBottom: 20 }}>
                      <img alt="Empty Screen" src={EmptyLogo} width={300} />
                    </Row>
                  )}
                {screens.md &&
                  currentSelect === ConnectionType.PENDING &&
                  connectionListCompany?.length === 0 && (
                    <Row justify="center" style={{ marginBottom: 20 }}>
                      <img alt="Empty Screen" src={EmptyLogo} width={700} />
                    </Row>
                  )}
                {!screens.md &&
                  currentSelect === ConnectionType.PENDING &&
                  connectionListCompany?.length === 0 && (
                    <Row justify="center" style={{ marginBottom: 20 }}>
                      <img alt="Empty Screen" src={EmptyLogo} width={300} />
                    </Row>
                  )}
                {screens.md &&
                  currentSelect === ConnectionType.FOLLOWING &&
                  followersList?.length === 0 && (
                    <Row justify="center" style={{ marginBottom: 20 }}>
                      <img alt="Empty Screen" src={EmptyLogo} width={700} />
                    </Row>
                  )}
                {!screens.md &&
                  currentSelect === ConnectionType.FOLLOWING &&
                  followersList?.length === 0 && (
                    <Row justify="center" style={{ marginBottom: 20 }}>
                      <img alt="Empty Screen" src={EmptyLogo} width={300} />
                    </Row>
                  )}
                <Pagination
                  total={getItemCount()}
                  showTotal={(total) => `Totaal ${total} items`}
                  pageSize={limit}
                  current={currentPage}
                  onChange={(value) => handlePagination(value)}
                  className="pagination"
                />
              </div>
            </Content>
          </Layout>
        </Col>

        {/* Mobile View */}
        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          <Row justify="center">
            <Col span={24}>
              <Layout className="mobile-layout" style={{ minHeight: "75vh" }}>
                <Content className="mobile-content">
                  <Row justify="center">
                    <Col span={24}>
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomCenter"
                      >
                        <Button className="mobile-sub-menu-button">
                          <img
                            style={{ marginRight: "5px" }}
                            src={Toggle}
                            alt="toggle"
                          />
                          {getHeader()}
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                  <br />
                  {/* <Button onClick={() => setModalVisible(true)}>Send Connection Request</Button> */}
                  {ConnectionType.FOLLOWING !== currentSelect &&
                    connectionList?.map((data) => {
                      return (
                        <ConnectItem
                          key={data.id}
                          data={data}
                          loading={isLoading}
                          setSelectedUserId={setSelectedCardId}
                        />
                      );
                    })}
                  {ConnectionType.FOLLOWING !== currentSelect &&
                    connectionListCompany?.map((data) => {
                      return (
                        <ConnectItemCompany
                          key={data.id}
                          data={data}
                          loading={isLoading}
                          setSelectedUserId={setSelectedCardId}
                        />
                      );
                    })}
                  {ConnectionType.FOLLOWING === currentSelect &&
                    followersList?.map((e) => {
                      return (
                        <FollowerItem
                          key={e.id}
                          data={e}
                          loading={isLoading}
                          setSelectedUserId={setSelectedCardId}
                        />
                      );
                    })}
                  <br />
                  <Pagination
                    total={getItemCount()}
                    showTotal={(total) => `Totaal ${total} items`}
                    pageSize={limit}
                    current={currentPage}
                    onChange={(value) => handlePagination(value)}
                    className="pagination"
                    style={{ margin: "0px" }}
                    responsive={true}
                  />
                  <br />
                  <br />
                  <br />
                </Content>
              </Layout>
            </Col>
          </Row>
        </Col>
      </Row>
    </Route>
  );
}
