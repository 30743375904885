import { Dispatch } from "react";
import { Layout, Grid } from "antd";
import { Route, useParams } from "react-router-dom";
import SubHeader from "./SubHeader";
import AppHeader from "../../components/header/AppHeader";
import "./style.css";
import Chat from "../../views/chat/chat";
import { initialState, ActionType } from "../../store/reducers/chat.reducer";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { ChatService } from "src/services/chat.service";
// import { setChatMessages } from 'src/store/reducers/appSettings.reducer';
// import { useDispatch } from 'react-redux';

type ChatProps = {
  dispatch: Dispatch<ActionType>;
  state: typeof initialState;
  chatService: ChatService | null;
};

export default function Connect({ state, dispatch, chatService }: ChatProps) {
  const { Content } = Layout;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  // @ts-ignore
  let { id } = useParams();

  return (
    <Route path={["/connect/chat", "/connect/chat/:id"]}>
      <AppHeader />
      <SubHeader currentSubHeader={"chat"} />

      <ChakraProvider theme={theme}>
        <Layout className="container" style={{ backgroundColor: "#f6f6f6" }}>
          <Content
            className={screens.md ? "web-content" : "mobile-content"}
            style={{ width: "100%" }}
          >
            {chatService && (
              <Chat
                state={state}
                dispatch={dispatch}
                selectedUserId={id}
                chatService={chatService}
              />
            )}
          </Content>
        </Layout>
      </ChakraProvider>
    </Route>
  );
}
