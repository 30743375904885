import { Button, Col, Form, Grid, Input, message, Modal, Row } from "antd";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useState } from "react";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { Proposal } from "src/types/work.type";

type AddProjectFormProps = {
  modalVisible: boolean;
  setModalVisible: Function;
  proposalid: any;
};

export default function AddProjectForm({
  modalVisible,
  setModalVisible,
  proposalid,
}: AddProjectFormProps) {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (value: any) => {
    const proposalData: AxiosResponse<Proposal> = await axios.get(
      `${BASE_URL}api/proposals/${proposalid}`,
      getHttpHeader()
    );

    setIsLoading(true);

    const newData = {
      position: null,
      position_extra: value.title,
      title: value.title,
      start_date: moment().format("YYYY-MM-DD"),
      minimum_rate: 0,
      maximum_rate: proposalData.data.gross_rate,
      hours_per_week: proposalData.data.hours_per_week,
      description: "SYSTEM",
      sector: 101,
      region: 16,
      specialty: [],
      finished: false,
    };

    try {
      const res = await axios({
        method: "POST",
        url: `${BASE_URL}api/vacancies/`,
        data: newData,
        headers: getHttpHeader().headers,
      });
      await updateProposalWithVacancy(res.data.id);
    } catch (error) {
      setIsLoading(false);
      message.error("Kan project niet maken. Probeer opnieuw.");
    }
  };

  const updateProposalWithVacancy = async (vacancyId: number | undefined) => {
    try {
      await axios({
        method: "PATCH",
        url: `${BASE_URL}api/proposals/${proposalid}/`,
        data: {
          vacancy: vacancyId,
        },
        headers: getHttpHeader().headers,
      });
      setIsLoading(false);
      message.success("Project is aangemaakt");
      window.location.href = "/work/project";
    } catch (error: any) {
      setIsLoading(false);
      if (
        error?.response?.data?.vacancy ===
        `Can't assign a vacancy to a proposal without freelancer agreement`
      )
        message.error(
          `Kan geen vacature toewijzen aan een offerte zonder freelancerovereenkomst`
        );
    }
  };

  return (
    <Modal
      closable={true}
      onCancel={() => {
        setModalVisible(false);
      }}
      title={"Project aanmaken"}
      centered
      visible={modalVisible}
      footer={null}
      bodyStyle={{
        padding: 0,
      }}
      width={screens.md ? "50%" : "95%"}
    >
      <Row justify="center">
        <Col xl={22} lg={22} md={22} sm={22} xs={22}>
          <Form
            form={form}
            name="project-create"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            style={{ width: "100%" }}
          >
            <Form.Item
              name="title"
              label={
                <span className="form-label">Geef het project een naam</span>
              }
              rules={[{ required: true, message: "projectnaam is verplicht" }]}
              style={{ marginBlockEnd: 24 }}
            >
              <Input
                placeholder="Geef het project een naam"
                style={{ width: "100%" }}
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Row justify="end">
                <Col span={12}>
                  <Button
                    size="large"
                    onClick={() => {
                      setModalVisible(false);
                    }}
                    block
                    type="primary"
                    style={{
                      backgroundColor: "#664FF3",
                    }}
                  >
                    Annuleren
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Opslaan
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
