import {
  Layout,
  Divider,
  Input,
  Row,
  Col,
  Button,
  Grid,
  Drawer,
  Pagination,
  message,
  Modal,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { useQuery } from "react-query";
import VecanciesItem from "./Vecancies-Item";
import VacancyDetails from "./VecanciesDetails";
import AddVecancyForm from "./AddVecancyForm";
import { Vacancy } from "src/types/work.type";
import * as _ from "lodash";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import EmptyLogo from "src/empty-state.svg";
import { useLocation } from "react-router";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Vacancy[];
};

export default function Vacancies() {
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const location = useLocation();

  const [selectedCard, setSelectedCard] = useState<number | undefined>();
  const [isAdding, setAdding] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchByName, setSearchByName] = useState("");

  const id = location.search.replaceAll("?vacancyId=", "");

  const { data, refetch } = useQuery<Response>("vacanciesList", async () => {
    return await (
      await axios.get(
        `${BASE_URL}api/vacancies?isProject=false&title__icontains=${searchByName}&limit=${limit}&offset=${
          (currentPage - 1) * limit
        }`,
        getHttpHeader()
      )
    ).data;
  });

  useEffect(() => {
    if (id) setSelectedCard(Number(id));
  }, [id]);

  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
  const sendQuery = (query: any) => setSearchByName(query);

  const handleOnchange = (e: any) => {
    delayedQuery(e.target.value);
    // setSearchByName(e.target.value);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [searchByName, isAdding]);

  const handlePagination = async (page: number) => {
    await setCurrentPage(page);
    refetch();
  };

  const deleteVacancy = async () => {
    setConfirmLoading(true);
    await axios
      .delete(`${BASE_URL}api/vacancies/${selectedCard}`, getHttpHeader())
      .then((res) => {
        message.success("Uw vacature is verwijderd.");
        setConfirmLoading(false);
        setVisible(false);
        refetch();
      })
      .catch((err) => {
        message.error(JSON.stringify(err?.response?.data));
        setConfirmLoading(false);
      });
  };

  useEffect(() => {
    if (!isAdding) {
      refetch();
    }
    // eslint-disable-next-line
  }, [isAdding]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        title="Verwijderen"
        visible={visible}
        onOk={deleteVacancy}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Weet u zeker dat u deze vacature wilt verwijderen?</p>
      </Modal>

      {!screens.md && (
        <>
          <Drawer
            title={undefined}
            placement="right"
            closable={false}
            onClose={() => setSelectedCard(undefined)}
            visible={selectedCard !== undefined ? true : false}
            width="90%"
            bodyStyle={{
              // backgroundColor: 'red',
              padding: "0px",
            }}
          >
            {selectedCard !== undefined && (
              <VacancyDetails
                id={selectedCard}
                vacancyDetails={data?.results.find((e) => {
                  return e.id === selectedCard;
                })}
                refetch={refetch}
              />
            )}
          </Drawer>
        </>
      )}

      {isAdding && (
        <Row
          justify={"center"}
          style={{ backgroundColor: "#f6f6f6", minHeight: "72vh" }}
        >
          <Col xl={24} lg={24} md={24} sm={21} xs={21}>
            <Layout className="container">
              <Content
                className="web-content"
                style={{
                  padding: "1.5rem 0px",
                  minHeight: "88vh",
                }}
              >
                {screens.md && (
                  <Row
                    justify="space-between"
                    style={{ marginBlockStart: "25px", marginBlockEnd: "8px" }}
                  >
                    <Col span={16}>
                      <div className="web-header">Vacature aanmaken</div>
                    </Col>
                    <Col
                      span={8}
                      style={{ display: "grid", placeItems: "end" }}
                    >
                      {/* <div
                      className='web-header' style={{ fontSize: '14px' }}>
                      {`Projectnummer #123456789`}</div> */}
                    </Col>
                    <Divider className="web-divider-dark" />
                  </Row>
                )}
                <div style={{ padding: 24, backgroundColor: "white" }}>
                  <AddVecancyForm setAdding={setAdding} />
                </div>
              </Content>
            </Layout>
          </Col>
        </Row>
      )}

      {!isAdding && (
        <Row justify={"center"} className="vacancy__row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Layout className="container">
              <Content className="web-content vacancy__wrapper custom__scrollbar">
                <div>
                  <Row
                    justify="end"
                    style={{
                      backgroundColor: "#f6f6f6",
                      padding: "0px 24px",
                    }}
                  >
                    <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                      {screens.md && (
                        <Row
                          style={{
                            marginBlockStart: "25px",
                            marginBlockEnd: "8px",
                          }}
                        >
                          <Col span={10}>
                            <div className="web-header">Vacatures</div>
                          </Col>
                          <Col span={14}>
                            <Input
                              size={"large"}
                              placeholder="Zoeken"
                              prefix={<SearchOutlined />}
                              onChange={handleOnchange}
                              style={{ maxHeight: "45px", padding: "0 11px" }}
                            />
                          </Col>
                          <Divider className="web-divider-dark" />
                        </Row>
                      )}
                      <Row>
                        <Button
                          size="large"
                          type="primary"
                          className="add-new-vecancy-btn"
                          onClick={() => setAdding(true)}
                        >
                          Job plaatsen +
                        </Button>
                      </Row>
                      <Row
                        justify="space-between"
                        style={{ marginTop: "20px" }}
                      />
                      <Row
                        className="list__wrapper"
                        style={screens.md ? {} : { marginBlockEnd: "50px" }}
                      >
                        {data?.results.map((e) => {
                          return (
                            <VecanciesItem
                              key={e.id}
                              id={e.id}
                              selectedCard={
                                selectedCard !== undefined ? selectedCard : -1
                              }
                              setSelectedCard={setSelectedCard}
                              vacancyItemData={e}
                              setVisible={setVisible}
                            />
                          );
                        })}
                      </Row>
                      {screens.md && data?.results?.length === 0 && (
                        <Row justify="center" style={{ marginBottom: 20 }}>
                          <img alt="Empty Screen" src={EmptyLogo} width={700} />
                        </Row>
                      )}
                      {!screens.md && data?.results?.length === 0 && (
                        <Row justify="center" style={{ marginBottom: 20 }}>
                          <img alt="Empty Screen" src={EmptyLogo} width={300} />
                        </Row>
                      )}
                      <Row justify="center">
                        <Pagination
                          total={data?.count}
                          showTotal={(total) => `Totaal ${total} items`}
                          pageSize={limit}
                          current={currentPage}
                          onChange={(value) => handlePagination(value)}
                          className="pagination"
                          style={{ margin: "0px" }}
                          responsive={true}
                        />
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={0}
            xs={0}
            style={{ backgroundColor: "white" }}
          >
            {!!selectedCard && (
              <VacancyDetails
                id={selectedCard}
                vacancyDetails={data?.results.find((e) => {
                  return e.id === selectedCard;
                })}
                refetch={refetch}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
}
