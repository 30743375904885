import React, { useEffect, useState } from "react";
import CompanyLogo from "src/company-logo.svg";
import { Modal, Row, Col, Button, Divider, Form, Input, message } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import "./index.css";
import "src/index.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import AuthLayout from "src/components/Common/AuthLayout";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function SetPassword() {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [token, setToken] = useState<undefined | string>();
  const [email, setEmail] = useState<undefined | string>();
  const [type, setType] = useState<"subuser" | "normaluser">();

  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    setToken(String(query.get("code")));
    setEmail(String(query.get("email")));
    setType(query.get("type") === "subuser" ? "subuser" : "normaluser");
  }, [query]);

  const onFinish = async (values: any) => {
    const data = {
      email: email,
      token: token,
      new_password: values.confirmPassword,
    };

    try {
      await axios.post(`${BASE_URL}api/account/reset-password/`, data);
      type === "subuser"
        ? message.success("Account geactiveerd")
        : message.success("Uw wachtwoord is gereset");
      history.push("/login");
    } catch (error) {
      message.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={true}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          {type === "subuser" ? (
            <SubUserPasswordReset onFinish={onFinish} />
          ) : (
            <NormalUserPasswordReset onFinish={onFinish} />
          )}
        </div>
      </Modal>
    </AuthLayout>
  );
}

function NormalUserPasswordReset({ onFinish }: any) {
  return (
    <Row className="login-row">
      <Col
        xl={10}
        lg={10}
        md={0}
        sm={0}
        xs={0}
        className="login-design__column"
      >
        <div className="login-design__start">
          <div>
            <img src={CompanyLogo} alt="Company Logo"></img>
            <div className="login-header">
              Wachtwoord vergeten? Geen probleem!
            </div>
            <Divider className="login-devider" plain></Divider>
            <div className="login-text">
              Wij helpen u met het
              <br />
              resetten van uw wachtwoord.
            </div>
          </div>
        </div>
      </Col>
      <Col
        xl={14}
        lg={14}
        md={24}
        sm={24}
        xs={24}
        className="login-form__column"
      >
        <div style={{ marginTop: "25%", textAlign: "center" }}>
          <div className="graditutde-main">
            <div className="graditude-text">Nieuw wachtwoord</div>
            <div className="reset-password-from__div">
              <Form
                name="rest-password-from"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={(errorInfo: any) => {
                  console.log("Failed:", errorInfo);
                }}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Vul een wachtwoord in!",
                    },
                    {
                      min: 8,
                      message: "Wachtwoord moet minimaal 8 tekens lang zijn",
                    },
                    {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      ),
                      message: "Wachtwoord voldoet niet aan de eisen",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Wachtwoord"
                    className="normal-input-more-radius"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Bevestig uw wachtwoord",
                    },
                    {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      ),
                      message: "Wachtwoord voldoet niet aan de eisen",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Wachtwoorden komen niet overeen"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Herhaal wachtwoord"
                    className="normal-input-more-radius"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <div className="reset-help">
                  Uw wachtwoord moet tenminste 8 tekens bevatten waarvan een
                  hoofdletter, kleine letter, cijfer en speciaal teken
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className="reset-password-btn"
                  >
                    Resetten
                  </Button>
                </Form.Item>
              </Form>
              <div className="reset-help" style={{ textAlign: "center" }}>
                <span style={{ fontFamily: "Basier Square", fontWeight: 400 }}>
                  Niet veranderen?{" "}
                </span>
                <Link
                  to="/login"
                  style={{
                    color: "#664FF3",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  Inloggen
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

function SubUserPasswordReset({ onFinish }: any) {
  return (
    <Row className="login-row">
      <Col
        xl={10}
        lg={10}
        md={0}
        sm={0}
        xs={0}
        className="login-design__column"
      >
        <div className="login-design__start">
          <div>
            <img src={CompanyLogo} alt="Company Logo"></img>
            <div className="login-header">Welkom bij Job.nl</div>
            <Divider className="login-devider" plain></Divider>
            <div className="login-text">
              Maak een wachtwoord aan om uw account te activeren.
            </div>
          </div>
        </div>
      </Col>
      <Col
        xl={14}
        lg={14}
        md={24}
        sm={24}
        xs={24}
        className="login-form__column"
      >
        <div style={{ marginTop: "25%", textAlign: "center" }}>
          <div className="graditutde-main">
            <div className="graditude-text">Nieuw wachtwoord</div>
            <div className="reset-password-from__div">
              <Form
                name="rest-password-from"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={(errorInfo: any) => {
                  console.log("Failed:", errorInfo);
                }}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Vul een wachtwoord in!",
                    },
                    {
                      min: 8,
                      message: "Wachtwoord moet minimaal 8 tekens lang zijn",
                    },
                    {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      ),
                      message: "Wachtwoord voldoet niet aan de eisen",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Wachtwoord"
                    className="normal-input-more-radius"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Bevestig uw wachtwoord",
                    },
                    {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      ),
                      message: "Wachtwoord voldoet niet aan de eisen",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Wachtwoorden komen niet overeen"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Herhaal wachtwoord"
                    className="normal-input-more-radius"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <div className="reset-help">
                  Uw wachtwoord moet tenminste 8 tekens bevatten waarvan een
                  hoofdletter, kleine letter, cijfer en speciaal teken
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className="reset-password-btn"
                  >
                    Account aanmaken
                  </Button>
                </Form.Item>
              </Form>
              <div className="reset-help" style={{ textAlign: "center" }}>
                <Link
                  to="/login"
                  style={{
                    color: "#664FF3",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  Heeft u al een account?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
