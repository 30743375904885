import {
  Layout,
  // Divider,
  // Input,
  Row,
  Col,
  // Button,
  // Typography,
} from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
// import { useState } from 'react';
// import { SearchOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import { useQuery } from 'react-query';
// import jwtDecode from 'jwt-decode';
// import { ChildUser } from 'src/types/user.type';

export default function FinishedProjects() {

  const { Content } = Layout;

  return (
    <>
      <h1 className='web-header'>Finished Projects</h1>
      <Row justify={'center'} style={{ backgroundColor: '#f6f6f6', minHeight: '72vh' }}>
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout className="container">
            <Content className='web-content' style={{
              padding: "1.5rem 0px",
              minHeight: '88vh',
            }}>
              {/* <Row style={{ marginBlockStart: '25px', marginBlockEnd: '8px' }}>
                <Col span={8}>
                  <Title level={2} className='web-header'>Activiteitenlogboek</Title>
                </Col>
                <Col span={16}>
                  <Input size={'large'} placeholder="Zoeken" prefix={<SearchOutlined />} />
                </Col>
                <Divider className='web-divider-dark' />
              </Row> */}
            </Content>
          </Layout>
        </Col>
      </Row>

    </>
  )
}