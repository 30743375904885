import {
  Layout,
  Divider,
  Row,
  Col,
  Button,
  DatePicker,
  Grid,
  Drawer,
  Pagination,
} from "antd";
import { useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { useQuery } from "react-query";
import ProjectItem from "./Project-Item";
import FreelancerList from "./FreelanersList";
import { Vacancy } from "src/types/work.type";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { BASE_URL } from "src/config/default";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useEffect } from "react";
import { useHistory } from "react-router";
import EmptyLogo from "src/empty-state.svg";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Vacancy[];
};

export default function Projects() {
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);

  const queryParams = useQueryParams();

  const invoiceIdParam = queryParams.get("inv_id");
  const initialWeek = queryParams.get("week");

  const [selectedWeek, setSelectedWeek] = useState(moment().startOf("isoWeek"));
  const [weekStartDate, setWeekStartDate] = useState(
    selectedWeek.format("YYYY-MM-DD")
  );
  const [weekEndDate, setWeekEndDate] = useState(
    moment(selectedWeek)
      .add(1, "week")
      .format("YYYY-MM-DD")
  );

  const [selectedCard, setSelectedCard] = useState<number | undefined>();
  const [selectedProposal, setSelectedProposal] = useState<
    number | undefined
  >();

  const history = useHistory();

  const { data } = useQuery<Response>(
    [`ProjectList_${currentPage}`, weekStartDate, weekEndDate],
    async () => {
      return (
        await axios.get(`${BASE_URL}api/vacancies`, {
          ...getHttpHeader(),
          params: {
            isProject: true,
            limit: limit,
            offset: (currentPage - 1) * limit,
            proposal_start_date: weekEndDate,
            proposal_end_date: weekStartDate,
          },
        })
      ).data;
    }
  );

  useEffect(() => {
    if (!initialWeek) {
      return;
    }

    try {
      const w = parseInt(initialWeek);
      setSelectedWeek(
        moment()
          .isoWeek(w)
          .startOf("isoWeek")
      );
    } catch (error) {
      console.log("error not a valid week");
    }
  }, [initialWeek]);

  useEffect(() => {
    setWeekStartDate(selectedWeek.format("YYYY-MM-DD"));
    setWeekEndDate(
      moment(selectedWeek)
        .add(1, "week")
        .format("YYYY-MM-DD")
    );
  }, [selectedWeek]);

  useEffect(() => {
    if (!invoiceIdParam) {
      return;
    }

    setSelectedCard(parseInt(invoiceIdParam));
  }, [invoiceIdParam]);

  const onChange = (date: any, dateString: string) => {
    setSelectedWeek(date);
  };

  const customFormat = (value: any) => {
    const newDate = moment(value).add(6, "days");
    return `${value.format("DD MMM")} t/m ${newDate.format("DD MMM")}`;
  };

  const handleBackForwardDate = (operation: string) => {
    if (operation === "add") {
      const newDate = moment(selectedWeek).add(1, "week");
      setSelectedWeek(newDate);
    } else if (operation === "subtract") {
      const newDate = moment(selectedWeek).subtract(1, "week");
      setSelectedWeek(newDate);
    }
  };

  const handlePagination = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!selectedProposal) {
      return;
    }

    history.push(
      `/work/project/proposal/${selectedProposal}/?week=${selectedWeek.isoWeek()}&inv_id=${selectedCard}`
    );
  }, [selectedProposal, history, selectedWeek, selectedCard]);

  return (
    <>
      {!screens.md && (
        <>
          <Drawer
            title={undefined}
            placement="right"
            closable={false}
            onClose={() => setSelectedCard(undefined)}
            visible={selectedCard !== undefined ? true : false}
            width="90%"
            bodyStyle={{
              padding: "18px",
            }}
          >
            {selectedCard !== undefined && (
              <FreelancerList
                id={selectedCard}
                projectDetails={data?.results.find(
                  (e) => e.id === selectedCard
                )}
                setSelectedProposal={setSelectedProposal}
              />
            )}
          </Drawer>
        </>
      )}

      <Row
        justify={"center"}
        style={{
          backgroundColor: "#f6f6f6",
          minHeight: "82vh",
          marginBlockEnd: "40px",
        }}
      >
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout className="container">
            <Content
              className="web-content"
              style={{
                padding: "1.5rem 0px",
                minHeight: "88vh",
                // backgroundColor: 'red'
              }}
            >
              <div>
                {/* <Row> */}
                {screens.md && (
                  <Row style={{ marginTop: 25, marginBlockEnd: "20px" }}>
                    <Col span={8}>
                      <div className="web-header">Projecten</div>
                    </Col>
                    <Divider className="web-divider-dark" />
                  </Row>
                )}
                {/* </Row> */}
                <Row
                  justify="space-between"
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <Col xl={11} lg={11} md={11} sm={24} xs={24}>
                    <Row justify="space-between" className="project-bar">
                      <Col span={2} className="project-bar-icons">
                        <Button
                          type="link"
                          icon={<LeftOutlined className="project-bar-icons" />}
                          onClick={() => handleBackForwardDate("subtract")}
                        />
                      </Col>
                      <Col span={20} style={{ textAlign: "center" }}>
                        <div className="project-date-bar">
                          Week {selectedWeek.isoWeek()}
                        </div>
                        <DatePicker
                          allowClear={false}
                          bordered={false}
                          onChange={onChange}
                          picker="week"
                          className="project-date-picker"
                          inputReadOnly={true}
                          format={customFormat}
                          value={selectedWeek}
                          size="small"
                        />
                      </Col>
                      <Col span={2} className="project-bar-icons">
                        <Button
                          type="link"
                          icon={<RightOutlined className="project-bar-icons" />}
                          onClick={() => handleBackForwardDate("add")}
                        />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }} />
                    <Row style={screens.md ? {} : { marginBlockEnd: "50px" }}>
                      {data?.results.map((e) => {
                        return (
                          <ProjectItem
                            vacancyData={e}
                            key={e.id}
                            id={e.id}
                            selectedCard={
                              selectedCard !== undefined ? selectedCard : -1
                            }
                            setSelectedCard={setSelectedCard}
                          />
                        );
                      })}
                    </Row>
                    {/* {screens.md && data?.results?.length === 0 && (
                      <Row justify="center" style={{ marginBottom: 20 }}>
                        <img alt="Empty Screen" src={EmptyLogo} width={700} />
                      </Row>
                    )}
                    {!screens.md && data?.results?.length === 0 && (
                      <Row justify="center" style={{ marginBottom: 20 }}>
                        <img alt="Empty Screen" src={EmptyLogo} width={300} />
                      </Row>
                    )} */}
                    <Row justify="center">
                      <Pagination
                        total={data?.count}
                        showTotal={(total) => `Totaal ${total} items`}
                        pageSize={limit}
                        current={currentPage}
                        onChange={(value) => handlePagination(value)}
                        className="pagination"
                        style={{ margin: "0px" }}
                        responsive={true}
                      />
                    </Row>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={0} xs={0}>
                    {selectedCard !== undefined && (
                      <FreelancerList
                        projectDetails={data?.results.find(
                          (e) => e.id === selectedCard
                        )}
                        id={selectedCard}
                        setSelectedProposal={setSelectedProposal}
                      />
                    )}
                    {screens.lg && selectedCard === undefined && (
                      <Row justify="center" style={{ marginTop: 100 }}>
                        <img alt="Empty Screen" src={EmptyLogo} width={600} />
                      </Row>
                    )}
                    {!screens.lg && screens.md && selectedCard === undefined && (
                      <Row justify="start" style={{ marginTop: 20 }}>
                        <img alt="Empty Screen" src={EmptyLogo} width={550} />
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
}
