import {
  Row,
  Col,
  Form,
  Grid,
  Typography,
  Input,
  Select,
  Button,
  Upload,
  message,
  InputNumber,
} from "antd";
import "./styles.css";
import { useState, useEffect } from "react";
import { Company, UserTypes } from "src/types/user.type";
import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import { useUser } from "src/hooks/useUser";
import SectorSelect from "../../../components/common-inputs/Sector-Select";
import RegionSelect from "../../../components/common-inputs/Region-Select";
import { PermissionType } from "src/types/Permission";
import { checkPermission } from "src/hooks/checkPermission";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { UserAvatar } from "./EditFreelancerProfile";

export default function EditProfile() {
  const screens = Grid.useBreakpoint();
  const { Title } = Typography;
  const { Option } = Select;
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const [form] = Form.useForm();

  const [isEditing, setEditing] = useState(false);
  const [fileList, setFileList] = useState<any>();
  const { user } = useUser();

  const formGroupInputStyle = {
    display: "inline-block",
    width: "calc(50% - 40px)",
    margin: "0 40px 0 0 ",
    // maxHeight: '70px'
  };

  const fetchCompanyDetails = async () => {
    return await (
      await axios.get(BASE_URL + `api/companies/${user.id}/`, getHttpHeader())
    ).data;
  };

  const { data, isLoading, refetch } = useQuery<Company>(
    "companyDetails",
    fetchCompanyDetails
  );

  console.log("USER DATA ", data);

  const onRemovePic = async () => {
    const newData = {
      profile_pic: null,
    };

    try {
      const res = await axios({
        method: "PATCH",
        url: `${BASE_URL}api/companies/${user.id}/`,
        data: newData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log("RES ", res);
      return true;
    } catch (error) {
      return false;
    }
  };

  const onFinish = async (value: any) => {
    const newData = {
      sector: { id: value.sector_id },
      region: { id: value.region_id },
      email: value.email,
      first_name: value.first_name,
      company_name: value.company_name,
      description: value.job_description,
      number_of_employees: value.employee,
      established: value.opening,
      website: value.website,
    };

    await axios({
      method: "PATCH",
      url: `${BASE_URL}api/companies/${user.id}/`,
      data: newData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        // console.log(res)
        message.success("Opgeslagen");
        setEditing(false);
        refetch();
      })
      .catch((err) => {
        // console.error(err);
        message.error("Something went wrong. Try again.");
      });
  };

  useEffect(() => {
    if (data) {
      form.setFields([
        {
          name: "company_name",
          value: data.company_name,
        },
        {
          name: "sector_id",
          value: data.sector.id,
        },
        {
          name: "kvk_number",
          value: data.kvk_number,
        },
        {
          name: "region_id",
          value: data.region ? data?.region.id : undefined,
        },
        {
          name: "email",
          value: data.email,
        },
        {
          name: "website",
          value: data.website,
        },
        {
          name: "opening",
          value: data.established,
        },
        {
          name: "employee",
          value: data.number_of_employees,
        },
        {
          name: "job_description",
          value: data.description,
        },
        {
          name: "profile_picture",
          value: data?.profile_pic,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [data, isLoading]);

  const handleEditForm = () => {
    if (!isEditing) {
      setEditing(true);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      form.setFields([
        {
          name: "company_name",
          errors: undefined,
        },
        {
          name: "sector_id",
          errors: undefined,
        },
        {
          name: "kvk_number",
          errors: undefined,
        },
        {
          name: "region_id",
          errors: undefined,
        },
        {
          name: "email",
          errors: undefined,
        },
        {
          name: "website",
          errors: undefined,
        },
        {
          name: "opening",
          errors: undefined,
        },
        {
          name: "employee",
          errors: undefined,
        },
        {
          name: "job_description",
          errors: undefined,
        },
        {
          name: "profile_picture",
          errors: undefined,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  return (
    <>
      {screens.md && <div className="inner-sub-header">Bedrijfsaccount</div>}
      {(UserTypes.Company === user.user_type ||
        checkPermission({ user, can: PermissionType.CREATE_ASSIGNMENT })) && (
        <Row>
          <Form
            form={form}
            name="regiter-form"
            // initialValues={data}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            onFinishFailed={() => console.log("FinishFailed")}
            style={{ width: "100%" }}
          >
            <div
              style={
                screens.md === true
                  ? { padding: "24px", minHeight: "55vh" }
                  : {}
              }
            >
              <Row justify="center">
                <Col
                  xl={0}
                  lg={0}
                  md={0}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}
                >
                  <Title level={5} className="mobile-header">
                    {UserTypes.Company === user.user_type ||
                    UserTypes.Child_User === user.user_type
                      ? "Bedrijfsaccount"
                      : "Persoonlijk account"}
                  </Title>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      label={<span className="form-label">Bedrijfsnaam</span>}
                      name="company_name"
                      rules={[
                        {
                          // required: true,
                          message: "Bedrijfsnaam is verplicht",
                        },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <Input
                        disabled={true}
                        placeholder="Bedrijfsnaam"
                        className="edit-profile-input"
                        onChange={handleEditForm}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="form-label">Sector</span>}
                      name="sector_id"
                      rules={[
                        { required: true, message: "Sector is verplicht" },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <SectorSelect
                        disabled={!isEditing}
                        onChange={() => handleEditForm()}
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                  {screens.md === true && <br />}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      label={<span className="form-label">KVK nummer</span>}
                      name="kvk_number"
                      rules={[
                        {
                          required: true,
                          message: "KVK nummer is verplicht",
                        },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <Input
                        disabled={true}
                        onChange={handleEditForm}
                        placeholder="KVK nummer"
                        className="edit-profile-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="form-label">Regio</span>}
                      name="region_id"
                      rules={[
                        { required: true, message: "Regio is verplicht" },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <RegionSelect
                        disabled={!isEditing}
                        onChange={handleEditForm}
                        placeholder="Regio"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                  {screens.md === true && <br />}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      label={<span className="form-label">Emailadres</span>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Emailadres is verplicht",
                        },
                        {
                          type: "email",
                          message: "Email adres wordt niet herkend",
                        },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <Input
                        disabled={!isEditing}
                        onChange={handleEditForm}
                        placeholder="Emailadres"
                        className="edit-profile-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="form-label">Website</span>}
                      name="website"
                      rules={[
                        {
                          type: "url",
                          message: "Voor een geldige website in",
                        },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <Input
                        disabled={!isEditing}
                        onChange={handleEditForm}
                        placeholder="Website"
                        className="edit-profile-input"
                      />
                    </Form.Item>
                  </Form.Item>
                  {screens.md === true && <br />}
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    className="form-column"
                  >
                    <Form.Item
                      label={
                        <span className="form-label">Jaar van oprichting</span>
                      }
                      name="opening"
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      {/* <DatePicker  /> */}
                      <InputNumber
                        disabled={!isEditing}
                        onChange={handleEditForm}
                        placeholder="Jaar van oprichting"
                        // className="edit-profile-input"
                        size="large"
                        style={{ width: "100%" }}
                        min={1900}
                        max={moment().year()}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className="form-label">Aantal werknemers</span>
                      }
                      name="employee"
                      rules={[
                        {
                          required: true,
                          message: "Aantal werknemers is verplicht",
                        },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                      className="form-column"
                    >
                      <Select disabled={!isEditing} size="large">
                        <Option value="0-50">0-50</Option>
                        <Option value="50-100">50-100</Option>
                        <Option value="100-500">100-500</Option>
                        <Option value="500+">500+</Option>
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  {screens.md === true && <br />}
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    className="form-column"
                  >
                    <Form.Item
                      label={
                        <span className="form-label">Bedrijfsomschrijving</span>
                      }
                      name="job_description"
                      rules={[
                        {
                          required: true,
                          message: "Bedrijfsomschrijving is verplicht",
                        },
                      ]}
                      style={screens.md === true ? formGroupInputStyle : {}}
                    >
                      <TextArea
                        disabled={!isEditing}
                        onChange={handleEditForm}
                        autoSize={{ minRows: 7, maxRows: 7 }}
                        style={{ borderRadius: "2px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="form-label">Logo</span>}
                      name="profile_picture"
                      style={screens.md === true ? formGroupInputStyle : {}}
                      className="form-column"
                    >
                      {!isEditing ? (
                        UserAvatar(data as Company)
                      ) : (
                        <Dragger
                          disabled={!isEditing}
                          listType="picture"
                          name="profile_pic"
                          multiple={false}
                          action={`${BASE_URL}api/companies/${user.id}/`}
                          method="PATCH"
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          }}
                          fileList={fileList}
                          beforeUpload={(file: any) => {
                            console.log(file.type);
                            if (
                              file.type === "image/png" ||
                              file.type === "image/jpeg"
                            ) {
                              const isLt2M = file.size / 1024 / 1024 < 1;
                              if (!isLt2M) {
                                message.error(
                                  "Afbeelding moet kleiner zijn dan 1 MB!"
                                );
                                return false;
                              }
                              return true;
                            } else {
                              message.error(
                                `${file.name} heeft een ongeldig bestandsformaat`
                              );
                              return false;
                            }
                          }}
                          onChange={async (info: any) => {
                            let fileList = [...info.fileList];
                            // Limit the number of uploaded files
                            // Only to show two recent uploaded files, and old ones will be replaced by the new
                            fileList = fileList.slice(-1);

                            // Read from response and show file link
                            fileList = fileList.map((file) => {
                              if (file.response) {
                                // Component will show file.url as link
                                file.url = file.response.url;
                              }
                              return file;
                            });
                            setFileList(fileList);

                            const { status } = info.file;
                            if (status === "done") {
                              refetch();
                              message.success(`${info.file.name} is geupload.`);
                            } else if (status === "error") {
                              message.error(
                                `${info.file.name} file upload failed.`
                              );
                            }
                          }}
                          onRemove={onRemovePic}
                          style={{
                            minHeight: "150px",
                            border: "2px dashed rgba(80, 93, 250, 0.5)",
                            boxSizing: "border-box",
                            borderRadius: "10px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          <p className="ant-upload-text">
                            Sleep een logo <br />
                            in dit vak of klik hier
                          </p>
                        </Dragger>
                      )}
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              {screens.md && <br />}
              <Form.Item>
                <Row
                  justify="end"
                  style={screens.md ? { margin: "0px 20px" } : {}}
                >
                  {!isEditing ? (
                    <Col span={screens.md ? 12 : 24}>
                      <Button
                        onClick={() => {
                          setEditing(true);
                        }}
                        block={screens.md ? false : true}
                        type="primary"
                        className={screens.md ? "form-button-block" : ""}
                        style={{ backgroundColor: "#002333" }}
                      >
                        Bewerken
                      </Button>
                    </Col>
                  ) : (
                    <>
                      <Col span={screens.md ? 6 : 12}>
                        <Button
                          onClick={() => {
                            setEditing(false);
                            refetch();
                          }}
                          block={screens.md ? false : true}
                          type="primary"
                          className={screens.md ? "form-button" : ""}
                          style={{
                            backgroundColor: "#664FF3",
                            marginRight: "20px",
                          }}
                        >
                          Annuleren
                        </Button>
                      </Col>
                      <Col span={screens.md ? 6 : 12}>
                        <Button
                          block={screens.md ? false : true}
                          type="primary"
                          className={screens.md ? "form-button" : ""}
                          htmlType="submit"
                        >
                          Opslaan
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Form.Item>
            </div>
          </Form>
        </Row>
      )}
    </>
  );
}
