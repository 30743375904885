import { Row, Col, Grid } from "antd";
import { Proposal } from "src/types/work.type";
import moment from "moment";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useEffect, useState } from "react";
import { formatDate } from "src/utils/date";
import useCalculateNetHourlyRate from "src/hooks/useCalculateNetHourlyRate";

type ProjectOverviewProps = {
  ProposalData: Proposal | undefined;
};

type API_Reponse = {
  count: 123;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      hours: string;
      date: string;
      description: string;
      kilometers: string;
      expenses: string;
      is_submitted: true;
      proposal: number;
      invoice: 0;
    }
  ];
};

export default function ProjectOverview({
  ProposalData,
}: ProjectOverviewProps) {
  const screens = Grid.useBreakpoint();

  const MultipleQueries = () => {
    const TotalHours = useQuery<API_Reponse>(
      `Total_Hours_${ProposalData?.id}`,
      async () => {
        return (
          await axios.get(
            `${BASE_URL}api/hours/?proposal=${ProposalData?.id}&limit=250`,
            getHttpHeader()
          )
        ).data;
      }
    );

    const WeeklyHours = useQuery<API_Reponse>(
      ["HourlyList", ProposalData?.id],
      async () => {
        return (
          await axios.get(
            `${BASE_URL}api/hours/?proposal=${
              ProposalData?.id
            }&week=${moment().isoWeek()}&limit=100`,
            getHttpHeader()
          )
        ).data;
      }
    );

    return { TotalHours, WeeklyHours };
  };

  const totalHoursForProposal = MultipleQueries().TotalHours.data;
  const weeklyHours = MultipleQueries().WeeklyHours.data;
  const [totalWeekHours, setTotalWeekHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const { netHourlyRate } = useCalculateNetHourlyRate({
    ratePerHourValue: String(ProposalData?.gross_rate),
    paymentTermsValue: String(ProposalData?.payment_terms),
  });

  useEffect(() => {
    let weeklyTotalHours = 0;
    weeklyHours?.results?.forEach((e) => {
      weeklyTotalHours += Number(e.hours);
    });
    setTotalWeekHours(weeklyTotalHours);
  }, [weeklyHours]);

  useEffect(() => {
    let totalHours = 0;
    totalHoursForProposal?.results?.forEach((e) => {
      totalHours += Number(e.hours);
    });
    setTotalHours(totalHours);
  }, [totalHoursForProposal]);

  return (
    <div>
      <Row style={{}}>
        <Col
          span={screens.md ? 12 : 24}
          style={{ borderTop: "0px" }}
          className={
            screens.md
              ? "projects-overview-column-freelance add-side-border"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Projectnummer</div>
          <div className="projects-overview-detail">{`@${ProposalData?.id}`}</div>
        </Col>
        <Col
          span={screens.md ? 12 : 24}
          style={{ borderTop: "0px" }}
          className={
            screens.md
              ? "projects-overview-column-freelance"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Looptijd</div>
          <span className="projects-overview-detail">
            {ProposalData?.end_date ? (
              <>
                {formatDate(ProposalData?.start_date)}
                <span style={{ fontWeight: "normal" }}> tot </span>
                {formatDate(ProposalData?.end_date)}
              </>
            ) : (
              "Doorlopend"
            )}
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          span={screens.md ? 12 : 24}
          className={
            screens.md
              ? "projects-overview-column-freelance add-side-border"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Netto uurtarief</div>
          <div className="projects-overview-detail">€{netHourlyRate}</div>
        </Col>
        <Col
          span={screens.md ? 12 : 24}
          className={
            screens.md
              ? "projects-overview-column-freelance"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Reiskosten</div>
          <div className="projects-overview-detail">
            €{ProposalData?.travel_allowances}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column-freelance add-side-border"
              : "projects-overview-column-mobile projects-overview-column-mobile-col"
          }
        >
          <div className="projects-overview-header">Totale omzet</div>
          {/* <span className="project-details-highlighted"> €{ProposalData?.gross_rate},-</span> */}
          <span className="project-details-highlighted">
            {" "}
            €{(totalHours * Number(ProposalData?.net_rate)).toFixed(2)},-{" "}
          </span>
        </Col>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column-freelance"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Totale uren</div>
          <span className="sub-detail-2">
            {
              // ProposalData?.hours_per_week
              // moment().diff(moment(ProposalData?.start_date), 'hours')
              totalHours
            }{" "}
            uur
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column-freelance add-side-border"
              : "projects-overview-column-mobile projects-overview-column-mobile-col"
          }
        >
          <div className="projects-overview-header">Omzet deze week</div>
          {/* <span className="project-details-highlighted"> €{ProposalData?.weekly_net_rate},-</span> */}
          <span className="project-details-highlighted">
            {" "}
            €{(totalWeekHours * Number(ProposalData?.net_rate)).toFixed(2)},-
          </span>
        </Col>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column-freelance"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Uren deze week</div>
          <span className="sub-detail-2">
            {
              // ProposalData?.hours_per_week
              // moment(moment().startOf('isoWeek')).diff(moment(ProposalData?.start_date), 'hours')
              totalWeekHours
            }{" "}
            uur
          </span>
        </Col>
      </Row>
      <Row className="projects-overview-column-freelance" />
    </div>
  );
}
