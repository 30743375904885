import { Row, Col, Grid } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { Hours, Proposal } from "src/types/work.type";
import { User } from "src/types/user.type";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useEffect, useState } from "react";

type FreelancerListItemProps = {
  setSelectedUser: Function;
  proposalData: Proposal;
};

export default function FreelancerListItem({
  setSelectedUser,
  proposalData,
}: FreelancerListItemProps) {
  const screens = Grid.useBreakpoint();
  const [hoursTotal, setHoursTotal] = useState({
    rate: 0,
    travel: 0,
    expenses: 0,
  });

  const MultipleQueries = () => {
    const FreelancerDetails = useQuery<User>(
      `freelancerDetails${proposalData.freelancer}`,
      async () => {
        return (
          await axios.get(
            `${BASE_URL}api/freelancers/${proposalData.freelancer}/`,
            getHttpHeader()
          )
        ).data;
      }
    );

    const HourDetails = useQuery<{ results: Hours[] }>(
      `hour_${proposalData.freelancer}_${proposalData.id}`,
      async () => {
        return (
          await axios.get(
            `${BASE_URL}api/hours?proposal=${proposalData.id}&limit=200/`,
            getHttpHeader()
          )
        ).data;
      }
    );

    // console.log("FreelancerDetails ", FreelancerDetails);
    // console.log("HourDetails ", HourDetails);
    // console.log("proposalData ", proposalData);

    return { FreelancerDetails, HourDetails };
  };

  const freelancer = MultipleQueries().FreelancerDetails.data;
  const hours = MultipleQueries().HourDetails.data;

  useEffect(() => {
    console.log("Hours", hours);
    let expenses = 0;

    hours?.results.forEach((e) => {
      expenses += Number(e.expenses);
    });

    setHoursTotal((hoursTotal) => ({ ...hoursTotal, expenses }));
  }, [hours, setHoursTotal]);

  return (
    <Row
      className="freelancer-list-item"
      onClick={() => setSelectedUser(proposalData.id)}
    >
      <Col span={screens.md ? 9 : 14} style={{ textAlign: "start" }}>
        {`${freelancer?.first_name} ${freelancer?.last_name}`}
      </Col>
      {screens.md && <Col span={3}>{`€${Number(proposalData?.net_rate)}`}</Col>}
      {screens.md && (
        <Col span={3}>{`€${Number(proposalData?.travel_allowances)}`}</Col>
      )}
      {screens.md && <Col span={3}>{`€${hoursTotal.expenses}`}</Col>}
      <Col span={screens.md ? 6 : 10}>
        {hours?.results?.[0]?.hours_submitted_to_finqle && (
          <span className="freelancer-list-item-hightlight">Openstaand</span>
        )}
      </Col>
    </Row>
  );
}
