import {
  Row,
  Col,
  Switch,
  Button,
  Typography,
  Layout,
  Divider,
  Input,
  Form,
  Grid,
  message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ChildUser } from "src/types/user.type";
import { PermissionType, Permission } from "src/types/Permission";

export default function EditUser(props: any) {
  let data: ChildUser = props.data;

  const { Content } = Layout;
  const { Title } = Typography;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const formGroupInputStyle = {
    display: "inline-block",
    width: "calc(50% - 10px)",
    margin: "0 10px 0 0 ",
  };
  const formSingleInputStyle = { width: "49%" };

  const error = (content: string) => {
    message.error(content);
  };

  const onFinish = (values: any) => {
    const emptyData: ChildUser = {
      id: 0,
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      location: "",
      website: "",
      permissions: [],
      role: [],
    };

    if (data === undefined) {
      data = emptyData;
    }

    data.first_name = values.first_name && values.first_name;
    data.last_name = values.last_name;
    data.email = values.email;

    if (
      values.companyProfileUserManage !== undefined &&
      !values.companyProfileUserManage
    ) {
      data.permissions = data.permissions.filter((e) => {
        return e.permission !== PermissionType.CREATE_ASSIGNMENT;
      });
    } else if (
      values.companyProfileUserManage !== undefined &&
      values.companyProfileUserManage
    ) {
      const permission: Permission = {
        permission: PermissionType.CREATE_ASSIGNMENT,
      };
      if (
        !data.permissions.find((e) => {
          return e.permission === permission.permission;
        })
      ) {
        data.permissions.push(permission);
      }
    }

    if (values.manageProjects !== undefined && !values.manageProjects) {
      data.permissions = data.permissions.filter((e) => {
        return e.permission !== PermissionType.MANAGE_CONNECTIONS;
      });
    } else if (values.manageProjects !== undefined && values.manageProjects) {
      const permission: Permission = {
        permission: PermissionType.MANAGE_CONNECTIONS,
      };
      if (
        !data.permissions.find((e) => {
          return e.permission === permission.permission;
        })
      ) {
        data.permissions.push(permission);
      }
    }

    if (values.networkVacancies !== undefined && !values.networkVacancies) {
      data.permissions = data.permissions.filter((e) => {
        return e.permission !== PermissionType.ACCEPT_HOURS;
      });
    } else if (
      values.networkVacancies !== undefined &&
      values.networkVacancies
    ) {
      const permission: Permission = {
        permission: PermissionType.ACCEPT_HOURS,
      };
      if (
        !data.permissions.find((e) => {
          return e.permission === permission.permission;
        })
      ) {
        data.permissions.push(permission);
      }
    }

    if (data.permissions.length === 0) {
      error("Er zijn geen rechten gekozen")
    } else {
      if (props.newUser) {
        props.addUser(data);
      } else {
        props.updateDate(data);
      }
    }
  };

  return (
    <div>
      <Layout className="layout">
        <br />
        <Content
          className="web-content"
          style={{
            width: "100%",
            padding: 0,
          }}
        >
          <Row>
            <Col xl={24} lg={24} md={24} sm={0} xs={0}>
              <div className="web-header">
                {props.newUser ? "Gebruiker aanmaken" : "Gebruiker bewerken"}
              </div>
              <Divider className="web-divider-dark" />
              <br />
            </Col>
          </Row>
          <Row justify="center">
            <Col xl={24} lg={24} md={24} sm={21} xs={21}>
              <Form
                name="regiter-form"
                className="register-form"
                initialValues={data}
                layout="vertical"
                requiredMark={false}
                onFinish={onFinish}
              // onFinishFailed={() => console.log("FinishFailed")}
              >
                <div className="user-authentication-form">
                  <div
                    style={
                      screens.md === true
                        ? { width: "85%", padding: "40px 20px" }
                        : { width: "85%" }
                    }
                  >
                    <Row justify="center">
                      <Col
                        xl={0}
                        lg={0}
                        md={0}
                        sm={24}
                        xs={24}
                        style={{ textAlign: "center" }}
                      >
                        <Title level={5} className="mobile-header">
                          Gebruiker bewerken
                        </Title>
                        <br />
                      </Col>
                    </Row>

                    <div>
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                              label={
                                <span className="form-label">Voornaam</span>
                              }
                              name="first_name"
                              rules={[
                                {
                                  required: true,
                                  message: "Vul uw voornaam in",
                                },
                              ]}
                              style={
                                screens.md === true ? formGroupInputStyle : {}
                              }
                            >
                              <Input
                                placeholder="Voornaam"
                                className="user-authentication-input"
                              />
                            </Form.Item>
                            <Form.Item
                              label={
                                <span className="form-label">Achternaam</span>
                              }
                              name="last_name"
                              rules={[
                                {
                                  required: true,
                                  message: "Vul uw achternaam in",
                                },
                              ]}
                              style={
                                screens.md === true ? formGroupInputStyle : {}
                              }
                            >
                              <Input
                                placeholder="Achternaam"
                                className="user-authentication-input"
                              />
                            </Form.Item>
                          </Form.Item>
                          {screens.md === true && <br />}
                          <Form.Item
                            name="email"
                            label={
                              <span className="form-label">E-mailadres</span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Voer uw e-mailadres in",
                              },
                              {
                                type: "email",
                                message: "Email adres wordt niet herkend"
                              },
                            ]}
                          >
                            <Input
                              placeholder="Emailadres"
                              className="user-authentication-input"
                              style={
                                screens.md === true ? formSingleInputStyle : {}
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <div className="div-sub-heading">Rechten</div>
                      <Row className="row-style">
                        <Col span={21}>
                          <span className="user-authentication-input">
                            Bedrijfsprofiel & Gebruikers beheren
                          </span>
                        </Col>
                        <Col span={3}>
                          <Row justify="space-between">
                            <Col flex="1">
                              <Form.Item name="companyProfileUserManage">
                                <Switch
                                  defaultChecked={
                                    data &&
                                    data.permissions.some((e) => {
                                      return (
                                        e.permission ===
                                        PermissionType.CREATE_ASSIGNMENT
                                      );
                                    })
                                  }
                                  size="small"
                                  style={{
                                    color: "red",
                                    verticalAlign: "super",
                                    marginRight: "5px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col flex="1" xl={24} lg={24} md={24} sm={0} xs={0}>
                              <ExclamationCircleOutlined className="icon-align" />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-style">
                        <Col span={21}>
                          <span className="user-authentication-input">
                            Netwerk & vacatures
                          </span>
                        </Col>
                        <Col span={3}>
                          <Form.Item name="networkVacancies">
                            <Switch
                              defaultChecked={
                                data &&
                                data.permissions.some((e) => {
                                  return (
                                    e.permission === PermissionType.ACCEPT_HOURS
                                  );
                                })
                              }
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="row-style">
                        <Col span={21}>
                          <span className="user-authentication-input">
                            Projecten beheren
                          </span>
                        </Col>
                        <Col span={3}>
                          <Form.Item name="manageProjects">
                            <Switch
                              defaultChecked={
                                data &&
                                data.permissions.some((e) => {
                                  return (
                                    e.permission === PermissionType.MANAGE_CONNECTIONS
                                  );
                                })
                              }
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <Form.Item>
                  <Row>
                    <Col span={12}>
                      <Button
                        onClick={() =>
                          props.setEnableVisibleForm([false, false])
                        }
                        type="primary"
                        className="user-authentication-button"
                        style={{ backgroundColor: "#664FF3" }}
                        block
                      >
                        Annuleren
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        className="user-authentication-button"
                        htmlType="submit"
                        block
                      >
                        Opslaan
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
}
