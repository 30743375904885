import { Select } from "antd";
import { SelectProps, SelectValue } from "antd/lib/select";
import axios from "axios";
import React, { useEffect } from "react";
import { useQuery } from "react-query";

export default function BMSelect<T extends SelectValue>({
  children,
  ...props
}: SelectProps<T>) {
  const { Option } = Select;
  const BASE_URL = process.env.REACT_APP_BACKEND;

  const { data, isLoading, refetch } = useQuery<{
    results: [{ name: string; id: number }];
  }>(
    "regions",
    async () => {
      return (await axios.get(BASE_URL + "api/region?limit=50")).data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Select<T> {...props} loading={isLoading}>
      {data?.results
        .sort((a, b) => (a?.name > b?.name ? 1 : -1))
        .map((ele: any) => (
          <Option style={{ height: "40px" }} key={ele["id"]} value={ele["id"]}>
            {ele["name"]}
          </Option>
        ))}
    </Select>
  );
}
