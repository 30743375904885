import {
  Layout,
  Divider,
  Input,
  Row,
  Col,
  Button,
  Grid,
  Select,
  Drawer,
  Pagination,
  Skeleton,
} from "antd";
import AppHeader from "src/components/header/AppHeader";
import { SearchOutlined } from "@ant-design/icons";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "./style.css";
import axios from "axios";
import SearchItem from "./SearchItem";
import FilterPanel from "./FilterPanel";
import FreelancerDetailModal from "./FreelancerDetailModal";
import CompanyDetailModal from "./CompanyDetailModal";
import Toggle from "src/modules/Connect/Icons/toggle-ff.png";
import { Company, UserTypes } from "src/types/user.type";
import { useQuery } from "react-query";
import * as _ from "lodash";
import AddVacancy from "src/modules/Work/Vecancies/AddVecancyForm";
import { VacancySearch } from "src/types/work.type";
import { useUser } from "src/hooks/useUser";
import { CloseOutlined } from "@ant-design/icons";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import queryString from "query-string";
import EmptyLogo from "src/empty-state.svg";

type response = {
  count: number;
  next: string;
  previous: string;
  results: any[];
};

export default function Search() {
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();
  const { Option } = Select;
  const { user } = useUser();

  const [searchByName, setSearchByName] = useState("");

  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [limit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFiltersList, setSearchFiltersList] = useState("");
  const [filterTags, setFileterTags] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [resultCount, setResultCount] = useState<number | undefined>(0);
  const [selectedSorting, setSelectedSorting] = useState<string>("1.1");
  const [isAdding, setAdding] = useState(false);

  let { search } = useLocation();
  const queryParams = queryString.parse(search);

  const isUserInactive = !user.is_finqle_active && !user.is_payment_active;

  //@ts-ignore
  let { companyid } = useParams();
  const history = useHistory();

  const companyData = useQuery<Company>(`company_${companyid}`, async () => {
    if (companyid)
      return (
        await axios.get(
          `${BASE_URL}api/companies/${companyid}`,
          getHttpHeader()
        )
      ).data;
  });

  useEffect(() => {
    companyData.refetch();
    // eslint-disable-next-line
  }, [companyid]);

  useEffect(() => {
    const result = filterTags.find((e) => e.key === "company");
    if (companyid) {
      if (companyData.data && result === undefined) {
        const temp: any[] = [
          ...filterTags,
          <span key={"company"} className="search-filter-selected">
            {`Company ${companyData.data?.company_name}`}
            <CloseOutlined
              style={{ marginLeft: "5px" }}
              onClick={() => history.push("/search")}
            />
          </span>,
        ];
        setFileterTags(temp);
      }
    } else {
      console.log("Removing company filter...", companyid);
      const index = filterTags?.findIndex((e) => e.key === "company");
      if (index !== -1) {
        const temp = filterTags;
        temp.splice(index, 1);
        setFileterTags(temp);
      }
    }
    // eslint-disable-next-line
  }, [companyid, companyData.data]);

  const fetchData = async () => {
    if (UserTypes.Freelancer === user.user_type) {
      return (
        await axios.get(
          `${BASE_URL}api/vacancies?rate_down=${
            selectedSorting === "1.1" ? true : ""
          }&rate_up=${
            selectedSorting === "1.2" ? true : ""
          }&limit=${limit}&offset=${(currentPage - 1) * limit}&offset=${
            (currentPage - 1) * limit
          }&company=${
            companyid || ""
          }&deleted=false&isProject=false${searchFiltersList}`,
          {
            ...getHttpHeader(),
            // params: {
            //   rate_down: selectedSorting === "1.1" ? true : "",
            //   limit,
            //   offset: (currentPage - 1) * limit,
            //   title__icontains: `${searchByName}${searchFiltersList}`,
            //   company: companyid || "",
            //   deleted: false,
            //   isProject: false,
            // },
          }
        )
      ).data;
    } else if (
      UserTypes.Company === user.user_type ||
      UserTypes.Child_User === user.user_type
    ) {
      let params = `rate_down=${
        selectedSorting === "1.1" ? true : ""
      }&rate_up=${selectedSorting === "1.2" ? true : ""}&experience_down=${
        selectedSorting === "2.1" ? true : ""
      }&experience_up=${
        selectedSorting === "2.2" ? true : ""
      }&limit=${limit}&offset=${
        (currentPage - 1) * limit
      }&name=${searchByName}${searchFiltersList}`;

      const isUserActive = user.is_finqle_active && user.is_payment_active;

      if (isUserActive) {
        params += "&nonVerified=false";
      }

      return (
        await axios.get(`${BASE_URL}api/freelancers?${params}`, getHttpHeader())
      ).data;
    }
  };

  const { data, refetch, isLoading } = useQuery<response>(
    [
      "searchList",
      searchFiltersList,
      searchByName,
      companyid,
      companyData.data,
    ],
    fetchData
  );

  const handlePagination = async (page: number) => {
    await setCurrentPage(page);
    refetch();
  };

  useEffect(() => {
    if (data) {
      setResultCount(getResultCount());
    }
    // eslint-disable-next-line
  }, [data]);

  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
  const sendQuery = (query: any) => setSearchByName(query);

  const handleOnchange = (e: any) => {
    delayedQuery(e.target.value);
    // setSearchByName(e.target.value);
  };

  const getResultCount = () => {
    if (
      UserTypes.Company === user.user_type ||
      UserTypes.Child_User === user.user_type
    ) {
      if (data?.results?.length) {
        return data?.results?.length;
      } else {
        return 0;
      }
    }

    if (UserTypes.Freelancer === user.user_type) {
      if (user.is_finqle_active) {
        return data?.results?.length;
      } else {
        return 0;
      }
    }
  };

  const getSortingOptions = () => {
    const options: any[] = [];
    if (
      UserTypes.Company === user.user_type ||
      UserTypes.Child_User === user.user_type
    ) {
      options.push(<Option value="1.1">Tarief hoog naar laag</Option>);
      options.push(<Option value="1.2">Tarief laag naar hoog</Option>);
      options.push(<Option value="2.1">Ervaring hoog naar laag</Option>);
      options.push(<Option value="2.2">Ervaring laag naar hoog</Option>);
    } else {
      options.push(<Option value="1.1">Tarief hoog naar laag</Option>);
      options.push(<Option value="1.2">Tarief laag naar hoog</Option>);
    }
    return options;
  };

  // Refetch when sorting option changes
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [selectedSorting]);

  return (
    <Route path="/search">
      <AppHeader
        searchInputValue={searchByName}
        setSearchInputValue={setSearchByName}
      />
      <Row justify="center">
        <Col
          xl={0}
          lg={0}
          md={0}
          sm={24}
          xs={24}
          className="mobile-sub-title-container"
        >
          <div className="mobile-submenu-header">Zoek</div>
          <Divider className="mobile-divider" />
          <Button
            block
            className="mobile-sub-menu-button filter-mobile-btn"
            type="primary"
            onClick={() => setVisible(true)}
          >
            <img src={Toggle} alt={"toggle"} style={{ marginRight: "10px" }} />
            {`Filter ${resultCount} resultaten`}
          </Button>
        </Col>
        {!screens.md && (
          <>
            <Drawer
              title={null}
              placement="left"
              closable={false}
              onClose={() => setVisible(false)}
              visible={visible}
              width="75%"
            >
              <FilterPanel
                key={0}
                setFileterTags={setFileterTags}
                setSearchFiltersList={setSearchFiltersList}
                resultCount={resultCount}
                filterTags={filterTags}
                queryParams={queryParams}
                isUserActive={user.is_finqle_active}
              />
            </Drawer>
          </>
        )}
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout className="container" style={{ minHeight: "85vh" }}>
            <Content
              style={
                screens.md
                  ? { marginBlockStart: "25px", minWidth: "100%" }
                  : { marginBlockEnd: "60px" }
              }
            >
              {screens.md && (
                <>
                  <Row justify="space-between" style={{ marginTop: 25 }}>
                    <Col span={screens.md ? 6 : 24}>
                      <Row>
                        <div
                          className="web-header"
                          style={{ marginRight: "15%" }}
                        >
                          Zoek
                        </div>
                        {UserTypes.Company === user.user_type && (
                          <Button
                            size="large"
                            type="primary"
                            className="add-new-vecancy-btn"
                            onClick={() => setAdding(true)}
                          >
                            Job plaatsen +
                          </Button>
                        )}
                      </Row>
                    </Col>
                    <Col span={17}>
                      <Input
                        size={"large"}
                        placeholder="Zoeken"
                        prefix={<SearchOutlined />}
                        style={{ maxHeight: "45px", padding: "0 11px" }}
                        onChange={handleOnchange}
                      />
                    </Col>
                  </Row>
                  <Divider className="web-divider-dark" />
                </>
              )}

              {isAdding ? (
                <div style={{ marginBlockStart: "20px" }}>
                  <AddVacancy setAdding={setAdding} />
                </div>
              ) : (
                <>
                  <Row justify="space-between" style={{ marginTop: 25 }}>
                    {screens.md && (
                      <Col
                        span={6}
                        style={{
                          minHeight: "50vh",
                          backgroundColor: "white",
                          maxHeight: "80vh",
                        }}
                      >
                        <FilterPanel
                          key={1}
                          setFileterTags={setFileterTags}
                          setSearchFiltersList={setSearchFiltersList}
                          resultCount={resultCount}
                          filterTags={filterTags}
                          queryParams={queryParams}
                          isUserActive={user.is_finqle_active}
                        />
                      </Col>
                    )}
                    <Col
                      span={screens.md ? 17 : 24}
                      style={{ minHeight: "50vh" }}
                    >
                      {screens.md && (
                        <Row
                          justify="space-between"
                          className="selected-filter"
                        >
                          <Col span={18}>{filterTags}</Col>
                          <Col span={6} style={{ textAlign: "end" }}>
                            <span>Sorteer op </span>
                            <Select
                              defaultValue="1.1"
                              onChange={(e) => {
                                console.log(e);
                                setSelectedSorting(e as string);
                              }}
                              style={{ width: "162px", textAlign: "left" }}
                            >
                              {getSortingOptions()}
                            </Select>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        {(UserTypes.Company === user.user_type ||
                          UserTypes.Child_User === user.user_type) && (
                          <FreelancerDetailModal
                            id={selectedUserId}
                            setSelectedUserId={setSelectedUserId}
                            refetchSearchList={refetch}
                          />
                        )}
                        {UserTypes.Freelancer === user.user_type && (
                          <CompanyDetailModal
                            id={selectedUserId}
                            setSelectedUserId={setSelectedUserId}
                            refetchSearchList={refetch}
                            vacancyData={data?.results.find(
                              (e: VacancySearch) => {
                                return e.id === selectedUserId;
                              }
                            )}
                          />
                        )}
                      </Row>

                      <Row>
                        {isLoading ? (
                          <Skeleton active />
                        ) : (
                          <>
                            {(UserTypes.Company === user.user_type ||
                              UserTypes.Child_User === user.user_type) &&
                              data?.results?.map((e) => {
                                return (
                                  <SearchItem
                                    key={e.id}
                                    loading={false}
                                    id={e.id}
                                    data={e}
                                    setSelectedUserId={setSelectedUserId}
                                    isBlurred={isUserInactive}
                                  />
                                );
                              })}
                          </>
                        )}
                        {isLoading ? (
                          <Skeleton active />
                        ) : (
                          <>
                            {UserTypes.Freelancer === user.user_type &&
                              data &&
                              data.results.map((e) => {
                                return (
                                  <SearchItem
                                    key={e.id}
                                    loading={false}
                                    id={e.id}
                                    data={e}
                                    setSelectedUserId={setSelectedUserId}
                                    isBlurred={isUserInactive}
                                  />
                                );
                              })}
                          </>
                        )}
                      </Row>
                      {screens.md && data?.results?.length === 0 && (
                        <Row justify="center" style={{ marginBottom: 20 }}>
                          <img alt="Empty Screen" src={EmptyLogo} width={700} />
                        </Row>
                      )}
                      {!screens.md && data?.results?.length === 0 && (
                        <Row justify="center" style={{ marginBottom: 20 }}>
                          <img alt="Empty Screen" src={EmptyLogo} width={300} />
                        </Row>
                      )}
                      <Row
                        style={{
                          marginBlockEnd: "50px",
                          marginBlockStart: "20px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Pagination
                          total={resultCount}
                          showTotal={(total) => `Totaal ${total} items`}
                          pageSize={limit}
                          current={currentPage}
                          onChange={(value) => handlePagination(value)}
                          responsive={screens.md ? false : true}
                          className="pagination"
                        />
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Content>
          </Layout>
        </Col>
      </Row>
    </Route>
  );
}
