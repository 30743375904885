import React from "react";
import CompanyLogo from "src/company-logo.svg";
import { Modal, Row, Col, Button, Divider, Grid } from "antd";
import { Link } from "react-router-dom";

import "./index.css";
import "src/index.css";
import AuthLayout from "src/components/Common/AuthLayout";

export function Verify() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={true}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          <Row className="login-row">
            <Col
              xl={10}
              lg={10}
              md={0}
              sm={0}
              xs={0}
              className="login-design__column"
            >
              <div className="login-design__start">
                <div>
                  <img src={CompanyLogo} alt="Company Logo"></img>
                  <div className="login-header">
                    Meld u gratis aan bij job.nl
                  </div>
                  <Divider className="login-devider" plain></Divider>
                  <div className="login-text">Log in om verder te gaan!</div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={24}
              sm={24}
              xs={24}
              className="login-form__column"
            >
              <Row justify="space-between">
                <Col xl={0} lg={0} md={0} sm={24} xs={24}>
                  <Row
                    justify="space-between"
                    style={{ backgroundColor: "#f6f6f6" }}
                  >
                    <Col
                      style={{
                        paddingTop: "1rem",
                        paddingLeft: "1.5rem",
                      }}
                    >
                      <img
                        className="logo"
                        src={CompanyLogo}
                        alt="Company Logo"
                      ></img>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="verfity-success-msg">
                <div
                  className="graditutde-main"
                  style={
                    screens.xs
                      ? {
                          marginTop: "5%",
                          minHeight: "60vh",
                          padding: " 0px 30px",
                        }
                      : { marginTop: "0%" }
                  }
                >
                  <div className="graditude-text">
                    <span className="sub-header-text">Gefeliciteerd!</span>
                    <br />
                    Uw account is aangemaakt.
                  </div>
                  <div className="graditude-sub-text">
                    U ontvangt een een email met daarin een activatie link, klik
                    hierop om uw account te activeren
                  </div>
                  <div className="verify-button__div">
                    <Button className="verify-button" block>
                      <Link to="/login">Inloggen</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </AuthLayout>
  );
}
