import { Row, Col, Card } from "antd";
import "./style.css";
import { FileSearchOutlined, EnvironmentOutlined } from "@ant-design/icons";
import Connected from "src/modules/Connect/Icons/connected.png";
import Pending from "src/modules/Connect/Icons/request_connection.png";
import {
  FreelancerSearch,
  ConnectionStatusValues,
} from "src/types/common.type";
import { VacancySearch } from "src/types/work.type";
import { Specialty, UserTypes } from "src/types/user.type";
import moment from "moment";
import { useQuery } from "react-query";
import { BASE_URL } from "src/config/default";
import axios from "axios";
import userAvatar from "../../images/json/user_image.json";
import { renderText } from "src/utils/text-utils";

type SearchItemProps = {
  data: any;
  loading: boolean;
  key: any;
  id: number;
  setSelectedUserId: Function;
  isBlurred?: boolean;
};

type Response = {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
    }
  ];
};

export default function SearchItem({
  loading,
  data,
  id,
  setSelectedUserId,
  isBlurred = false,
}: SearchItemProps) {
  const userData: any = JSON.parse(localStorage.getItem("user") + "");

  let user =
    UserTypes.Company === userData.user_type ||
    UserTypes.Child_User === userData.user_type
      ? (data as FreelancerSearch)
      : undefined;
  let vacancy =
    UserTypes.Freelancer === userData.user_type
      ? (data as VacancySearch)
      : undefined;

  const { Meta } = Card;

  const { data: regionData } = useQuery<{ id: number; name: string }>(
    `Region_${vacancy?.region}`,
    async () => {
      if (vacancy?.region) {
        return (await axios.get(`${BASE_URL}api/region/${vacancy?.region}`))
          .data;
      }
    }
  );

  const { data: specialities } = useQuery<Response>("specialties", async () => {
    return (await axios.get(BASE_URL + "api/specialty?limit=400")).data;
  });

  const getSpecialities = () => {
    let tempList: any[] = [];
    vacancy?.specialty?.forEach((e, index) => {
      specialities?.results.forEach((v) => {
        if (v.id === e) {
          tempList.push(
            <span className="webspecialization">{`${v.name}${
              vacancy && index !== vacancy?.specialty?.length - 1 ? ", " : ""
            }`}</span>
          );
        }
      });
    });
    return tempList;
  };

  const handleOnClick = () => {
    // if (!userData?.is_finqle_active || !userData?.is_payment_active) {
    //   return message.warn("U moet verifiëren om details te bekijken");
    // }
    setSelectedUserId(id);
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                marginBottom: "10px",
                // minWidth: '1366',
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                // marginBottom: '10px',
                minWidth: "1366",
                cursor: "pointer",
              }}
              loading={loading}
              bodyStyle={{
                padding: "24px 0px 0px 0px",
              }}
              onClick={handleOnClick}
            >
              <Row style={{ paddingBottom: "0px" }}>
                <Col span={20}>
                  <Meta
                    style={{
                      padding: "10px 10px 10px 45px",
                    }}
                    avatar={
                      <div style={{ paddingRight: "20px" }}>
                        <div className="parent">
                          {UserTypes.Freelancer === userData.user_type ? (
                            <img
                              className="image1"
                              style={!isBlurred ? {} : { filter: "blur(3px)" }}
                              src={
                                vacancy?.company &&
                                vacancy?.company?.profile_pic
                                  ? vacancy?.company?.profile_pic
                                  : userAvatar.img
                              }
                              alt="comapny"
                            />
                          ) : (
                            <img
                              className="image1"
                              style={
                                ConnectionStatusValues.CONNECTED ===
                                  data?.connection?.status && !isBlurred
                                  ? {}
                                  : { filter: "blur(3px)" }
                              }
                              src={
                                data?.profile_pic && data?.profile_pic
                                  ? data?.profile_pic
                                  : userAvatar.img
                              }
                              alt="user"
                            />
                          )}

                          {(UserTypes.Company === userData.user_type ||
                            UserTypes.Child_User === userData.user_type) && (
                            <>
                              {data &&
                                data.connection.status ===
                                  ConnectionStatusValues.PENDING && (
                                  <img
                                    className="image2"
                                    src={Pending}
                                    alt="status"
                                  />
                                )}
                              {data &&
                                data.connection.status ===
                                  ConnectionStatusValues.CONNECTED && (
                                  <img
                                    className="image2"
                                    src={Connected}
                                    alt="status"
                                  />
                                )}{" "}
                            </>
                          )}
                          {UserTypes.Freelancer === userData.user_type && (
                            <>
                              {vacancy?.company?.connection?.status ===
                                ConnectionStatusValues.PENDING && (
                                <img
                                  className="image2"
                                  src={Pending}
                                  alt="status"
                                />
                              )}
                              {vacancy?.company?.connection?.status ===
                                ConnectionStatusValues.CONNECTED && (
                                <img
                                  className="image2"
                                  src={Connected}
                                  alt="status"
                                />
                              )}{" "}
                            </>
                          )}
                        </div>
                      </div>
                    }
                    title={
                      UserTypes.Company === userData.user_type ||
                      UserTypes.Child_User === userData.user_type ? (
                        <span className="web-card-title">
                          {user?.position?.name || "Niet zichtbaar"}
                        </span>
                      ) : (
                        <span className="web-card-title">
                          {vacancy?.title.replaceAll(" - ", " ")}
                        </span>
                      )
                    }
                    description={
                      <div>
                        <div>
                          {UserTypes.Company === userData.user_type ||
                          UserTypes.Child_User === userData.user_type ? (
                            <>
                              {userData?.is_finqle_active &&
                              userData?.is_payment_active
                                ? user?.specialty?.map(
                                    (
                                      specialty: any,
                                      index: number,
                                      items: Specialty[]
                                    ) => (
                                      <span className="webspecialization">
                                        {`${specialty?.name}${
                                          index !== items.length - 1 ? ", " : ""
                                        }`}
                                      </span>
                                    )
                                  )
                                : user?.specialty
                                    .slice(0, 3)
                                    .map(
                                      (
                                        specialty: any,
                                        index: number,
                                        items: Specialty[]
                                      ) => (
                                        <span className="webspecialization">
                                          {`${specialty?.name}${
                                            index !== items.length - 1
                                              ? ", "
                                              : ""
                                          }`}
                                        </span>
                                      )
                                    )}
                            </>
                          ) : (
                            <span className="webspecialization">
                              {getSpecialities()}
                            </span>
                          )}
                        </div>
                        <div className="card-body-content">
                          {UserTypes.Company === userData.user_type ||
                          UserTypes.Child_User === userData.user_type ? (
                            // If the user is not validated render first 5 words of the description
                            <span>
                              {userData?.is_finqle_active &&
                              userData?.is_payment_active
                                ? user?.description
                                : renderText(user?.description)}
                            </span>
                          ) : (
                            <p className="display-linebreak">
                              {userData?.is_finqle_active &&
                              userData?.is_payment_active
                                ? vacancy?.description
                                : renderText(vacancy?.description, 5)}
                            </p>
                          )}
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingTop: "40px",
                    paddingLeft: "20px",
                  }}
                >
                  {UserTypes.Company === userData.user_type ||
                  UserTypes.Child_User === userData.user_type ? (
                    <span className="rate">
                      € {user?.min_rate} - {user?.max_rate}
                    </span>
                  ) : (
                    <span className="rate">
                      € {vacancy?.minimum_rate} - {vacancy?.maximum_rate}
                    </span>
                  )}
                </Col>
              </Row>
              <Row justify="space-around">
                <Col className="card-inner-border" span={6}>
                  {UserTypes.Company === userData.user_type ||
                  UserTypes.Child_User === userData.user_type ? (
                    <span
                      className={
                        isBlurred ? "card-name blurry-text" : "card-name"
                      }
                    >
                      {user?.first_name}
                    </span>
                  ) : (
                    <span className="card-name">{`${vacancy?.company?.company_name}`}</span>
                  )}
                </Col>
                <Col className="card-inner-border" span={6}>
                  {UserTypes.Company === userData.user_type ||
                  UserTypes.Child_User === userData.user_type ? (
                    <span className="web-card-bottom">
                      {Math.trunc(
                        parseInt(user?.experience ? user.experience : "0")
                      )}{" "}
                      jaar ervaring
                    </span>
                  ) : (
                    <span className="web-card-bottom">
                      {vacancy?.hours_per_week} uur p/w
                    </span>
                  )}
                </Col>
                <Col className="card-inner-border" span={6}>
                  {UserTypes.Company === userData.user_type ||
                  UserTypes.Child_User === userData.user_type ? (
                    // <span className='web-card-bottom'>{Math.trunc(parseInt(user?.experience ? user.experience : "0"))} voltooide jobs</span>
                    <span className="web-card-bottom">
                      {user?.sector?.name || "Niet zichtbaar"}
                    </span>
                  ) : (
                    <span className="web-card-bottom">
                      {Math.abs(
                        moment(vacancy?.end_date, "yyyy-MM-dd").isoWeek() -
                          moment(vacancy?.start_date, "yyyy-MM-dd").isoWeek()
                      )}{" "}
                      weken
                    </span>
                  )}
                </Col>
                <Col
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #F6F6F6",
                    paddingTop: "12px",
                    paddingBottom: "14px",
                  }}
                  span={6}
                >
                  {UserTypes.Company === userData.user_type ||
                  UserTypes.Child_User === userData.user_type ? (
                    <span className="web-card-bottom">
                      {user?.region ? user.region.name : "Niet zichtbaar"}
                    </span>
                  ) : (
                    <span className="web-card-bottom">{regionData?.name}</span>
                  )}
                </Col>
              </Row>
            </Card>
          )}
        </Col>
        {(UserTypes.Company === userData.user_type ||
          UserTypes.Child_User === userData.user_type) && (
          <Col xl={0} lg={0} md={0} sm={24} xs={24}>
            {loading ? (
              <Card
                style={{
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: "10px",
                  minWidth: "1366",
                }}
                loading={loading}
                bodyStyle={{
                  paddingBottom: "14px",
                }}
              ></Card>
            ) : (
              <Card
                style={{
                  width: "100%",
                  marginTop: "16px",
                  borderRadius: "5px",
                }}
                loading={loading}
                bodyStyle={{
                  padding: 14,
                }}
                onClick={handleOnClick}
              >
                <Row justify="space-between">
                  <Col span={16}>
                    <div className="card-mobile-name-bold">
                      {UserTypes.Company === userData.user_type ||
                      UserTypes.Child_User === userData.user_type
                        ? user?.first_name
                        : `${vacancy?.title.replaceAll(" - ", " ")}`}
                    </div>
                    <div className="card-mobile-sub">
                      {UserTypes.Company === userData.user_type ||
                      UserTypes.Child_User === userData.user_type
                        ? user?.position
                          ? user.position.name
                          : "Niet zichtbaar"
                        : vacancy?.sector
                        ? vacancy?.sector?.name
                        : "Niet zichtbaar"}
                    </div>
                    <div className="card-mobile-content1">
                      {UserTypes.Company === userData.user_type ||
                      UserTypes.Child_User === userData.user_type
                        ? `${Math.trunc(
                            parseInt(user?.experience ? user.experience : "0")
                          )} jaar ervaring`
                        : `${vacancy?.hours_per_week}`}
                    </div>
                    <div className="card-mobile-content2">
                      {UserTypes.Company === userData.user_type ||
                      UserTypes.Child_User === userData.user_type
                        ? `${user?.finished_project_count} voltooide jobs`
                        : `${vacancy?.hours_per_week}`}
                    </div>
                    <Row>
                      <Col style={{ marginRight: "10px" }}>
                        <FileSearchOutlined
                          style={{ margin: "0px 5px", fontSize: "12px" }}
                        />
                        {/* {props.data.specilation.map((e: any, index: number) =>
                        <span className='mobile-specialization'>{`${e}${index !== props.data.specilation.length - 1 ? ", " : ""}`}</span>
                      )} */}
                        {UserTypes.Company === userData.user_type ||
                        UserTypes.Child_User === userData.user_type ? (
                          <span className="mobile-specialization">
                            {user?.sector && user.sector.name}
                          </span>
                        ) : (
                          <span className="mobile-specialization">
                            {getSpecialities()}
                          </span>
                        )}
                      </Col>
                      <Col>
                        <EnvironmentOutlined
                          style={{ margin: "0px 5px", fontSize: "12px" }}
                        />
                        {UserTypes.Company === userData.user_type ||
                        UserTypes.Child_User === userData.user_type ? (
                          <span className="mobile-specialization">
                            {user?.region && user.region.name}
                          </span>
                        ) : (
                          <span className="mobile-specializatior">
                            {regionData?.name}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <Row justify="end">
                      <Col>
                        <div
                          style={{
                            display: "grid",
                            placeItems: "center",
                            paddingRight: "10px",
                          }}
                        >
                          <div className="parent">
                            {UserTypes.Freelancer === userData.user_type ? (
                              <img
                                className="image1"
                                // style={
                                //   ConnectionStatusValues.CONNECTED ===
                                //     vacancy?.company.connection?.status &&
                                //   !isBlurred
                                //     ? {}
                                //     : { filter: "blur(3px)" }
                                // }
                                src={
                                  vacancy?.company &&
                                  vacancy?.company?.profile_pic
                                    ? vacancy?.company?.profile_pic
                                    : userAvatar.img
                                }
                                alt="comapny"
                              />
                            ) : (
                              <img
                                className="image1"
                                style={
                                  ConnectionStatusValues.CONNECTED ===
                                    data?.connection?.status && !isBlurred
                                    ? {}
                                    : { filter: "blur(3px)" }
                                }
                                src={
                                  data?.profile_pic && data?.profile_pic
                                    ? data?.profile_pic
                                    : userAvatar.img
                                }
                                alt="user"
                              />
                            )}

                            {data &&
                              data.connection.status ===
                                ConnectionStatusValues.PENDING && (
                                <img
                                  className="image2"
                                  src={Pending}
                                  alt="status"
                                />
                              )}
                            {data &&
                              data.connection.status ===
                                ConnectionStatusValues.CONNECTED && (
                                <img
                                  className="image2"
                                  src={Connected}
                                  alt="status"
                                />
                              )}
                          </div>
                          <div
                            style={{
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            {UserTypes.Company === userData.user_type ||
                            UserTypes.Child_User === userData.user_type ? (
                              <span className="mobile-rate">
                                {" "}
                                € {user?.min_rate} - {user?.max_rate}
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        )}

        {/* Freelancer view - Vacancy cards */}
        {UserTypes.Freelancer === userData.user_type && (
          <Col xl={0} lg={0} md={0} sm={24} xs={24}>
            {loading ? (
              <Card
                style={{
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: "10px",
                  minWidth: "1366",
                }}
                loading={loading}
                bodyStyle={{
                  paddingBottom: "14px",
                }}
              ></Card>
            ) : (
              <Card
                style={{
                  width: "100%",
                  marginTop: "16px",
                  borderRadius: "5px",
                }}
                loading={loading}
                bodyStyle={{
                  padding: 14,
                }}
                onClick={handleOnClick}
              >
                <Row justify="space-between">
                  <Col
                    span={8}
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <Row justify="end">
                      <Col>
                        <div
                          style={{
                            display: "grid",
                            placeItems: "center",
                            paddingRight: "15px",
                          }}
                        >
                          <div className="parent">
                            {UserTypes.Freelancer === userData.user_type ? (
                              <img
                                className="image1"
                                // style={
                                //   ConnectionStatusValues.CONNECTED ===
                                //     vacancy?.company.connection?.status &&
                                //   !isBlurred
                                //     ? {}
                                //     : { filter: "blur(3px)" }
                                // }
                                src={
                                  vacancy?.company &&
                                  vacancy?.company?.profile_pic
                                    ? vacancy?.company?.profile_pic
                                    : userAvatar.img
                                }
                                alt="comapny"
                              />
                            ) : (
                              <img
                                className="image1"
                                style={
                                  ConnectionStatusValues.CONNECTED ===
                                    data?.connection?.status && !isBlurred
                                    ? {}
                                    : { filter: "blur(3px)" }
                                }
                                src={
                                  data?.profile_pic && data?.profile_pic
                                    ? data?.profile_pic
                                    : userAvatar.img
                                }
                                alt="user"
                              />
                            )}

                            {UserTypes.Freelancer === userData.user_type && (
                              <>
                                {vacancy?.company?.connection?.status ===
                                  ConnectionStatusValues.PENDING && (
                                  <img
                                    className="image2"
                                    src={Pending}
                                    alt="status"
                                  />
                                )}
                                {vacancy?.company?.connection?.status ===
                                  ConnectionStatusValues.CONNECTED && (
                                  <img
                                    className="image2"
                                    src={Connected}
                                    alt="status"
                                  />
                                )}{" "}
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={16}>
                    <div className="card-mobile-sub">
                      {vacancy?.title.replaceAll(" - ", " ")}
                    </div>
                    <div style={{ marginBlockEnd: "10px" }}>
                      <span className="webspecialization">
                        {getSpecialities()}
                      </span>
                    </div>
                    <div className="card-normal-small">
                      {UserTypes.Company === user?.user_type ||
                      UserTypes.Child_User === userData.user_type
                        ? userData?.is_finqle_active &&
                          userData.is_payment_active
                          ? user?.description
                          : renderText(user?.description)
                        : userData?.is_finqle_active &&
                          userData?.is_payment_active
                        ? vacancy?.description
                        : renderText(vacancy?.description, 5)}
                    </div>
                  </Col>
                </Row>
                <Row style={{ height: "30px", marginBlockEnd: "5px" }}>
                  <Col
                    span={8}
                    className="place-items-center-cell card-normal-text"
                  >
                    {`${vacancy?.hours_per_week} uur p/w`}
                  </Col>
                  <Col
                    span={8}
                    className="place-items-center-cell card-normal-text"
                  >
                    {`${Math.abs(vacancy?.hours_per_week || 0)} weken`}
                  </Col>
                  <Col
                    span={8}
                    className="place-items-center-cell card-normal-text"
                  >
                    {UserTypes.Company === userData.user_type ||
                    UserTypes.Child_User === userData.user_type
                      ? `${user?.region?.name}`
                      : `${regionData?.name}`}
                  </Col>
                </Row>
                <Row style={{ height: "40px" }}>
                  <Col
                    span={12}
                    className="place-items-center-cell card-bold-text-underlined"
                    style={{ overflow: "hidden" }}
                  >
                    {UserTypes.Freelancer === userData.user_type
                      ? `${vacancy?.company?.company_name}`
                      : `${vacancy?.company?.first_name}`}
                  </Col>
                  <Col span={12} className="place-items-center-cell">
                    <div>
                      {" "}
                      <span className="card-bold-text">{`€${vacancy?.minimum_rate} - ${vacancy?.maximum_rate}`}</span>
                      EUR
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        )}
      </Row>
    </>
  );
}
