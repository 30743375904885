import { Modal, Button, Row, Col, Grid, Tooltip } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { User, UserTypes } from "src/types/user.type";
import Connected from "src/modules/Connect/Icons/connected.png";
import Pending from "src/modules/Connect/Icons/request_connection.png";
import { useQuery } from "react-query";
import Spinner from "src/components/Common/Spinner";
import { Link } from "react-router-dom";
import { ConnectionStatusValues } from "src/types/common.type";
import { useUser } from "src/hooks/useUser";
import userAvatar from "../../images/json/user_image.json";
import { checkPermission } from "src/hooks/checkPermission";
import { PermissionType } from "src/types/Permission";

type FreelancerDetailModalProps = {
  id: number | undefined;
  setSelectedUserId: Function;
  refetchSearchList: Function;
};

export default function FreelancerDetailModal({
  id,
  setSelectedUserId,
  refetchSearchList,
}: FreelancerDetailModalProps) {
  const screens = Grid.useBreakpoint();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const { user } = useUser();

  const [isLoadingSync, setLoadingSync] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState<{
    status: string;
    id: number;
  }>();

  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const MultipleQueries = () => {
    const userStatus = useQuery(`user${id}_status`, async () => {
      if (id) {
        return (
          await axios.get(`${BASE_URL}api/connection_status/${id}/`, config)
        ).data;
      }
    });
    const freelancerData = useQuery<User>(`freelancerData${id}`, async () => {
      if (id) {
        return (await axios.get(`${BASE_URL}api/freelancers/${id}`, config))
          .data;
      }
    });
    return { userStatus, freelancerData };
  };

  const { data, refetch, isFetching } = MultipleQueries().freelancerData;
  const { data: dataStatus, refetch: refetchStatus } =
    MultipleQueries().userStatus;

  useEffect(() => {
    if (dataStatus) {
      setConnectionStatus(dataStatus.connection_status);
    }
  }, [dataStatus]);

  useEffect(() => {
    if (id) {
      refetch();
    }
    // eslint-disable-next-line
  }, [id]);

  const sendConnectionRequest = async () => {
    setLoadingSync(true);

    const newData = {
      connection_to_id: id,
    };
    await axios({
      method: "POST",
      url: `${BASE_URL}api/connection/`,
      headers: config.headers,
      data: newData,
    })
      .then((res) => {
        // refetch();
        refetchStatus();
        setLoadingSync(false);
        refetchSearchList();
        // setModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingSync(false);
        if (err.response.data.request_to) {
          // ErrorMsg(err.response.data.request_to)
        } else {
          // ErrorMsg("Please recheck the username")
        }
      });
  };

  const removeConnection = async () => {
    await axios
      .delete(`${BASE_URL}api/connection/${connectionStatus?.id}/`, config)
      .then((res) => {
        refetchStatus();
        refetchSearchList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      title={undefined}
      centered
      visible={id !== undefined ? true : false}
      // onOk={undefined}
      footer={null}
      width={screens.md ? "60%" : "90%"}
      onCancel={() => {
        setSelectedUserId(undefined);
      }}
      bodyStyle={{
        padding: "0px",
        marginBlockStart: "75px",
        marginBlockEnd: "25px",
      }}
      style={{
        maxWidth: "950px",
      }}
      destroyOnClose={true}
    >
      {isFetching ? (
        <div
          style={{ minHeight: "612px", display: "grid", placeItems: "center" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <Row justify="space-between" className="border-style-row">
            <Col
              span={screens.md ? 8 : 24}
              className="freelancer-detail-header-column"
              style={screens.md ? {} : { top: "45px" }}
            >
              {connectionStatus &&
              connectionStatus.status === ConnectionStatusValues.CONNECTED
                ? `${data?.first_name} ${data?.last_name}`
                : `${data?.first_name}`}
            </Col>
            <Col
              span={screens.md ? 8 : 24}
              style={
                screens.md
                  ? { textAlign: "center" }
                  : { height: "45px", textAlign: "center" }
              }
            >
              <div>
                <div
                  className="parent"
                  style={screens.md ? { top: "-30px" } : { top: "-60px" }}
                >
                  <Row
                    justify="center"
                    style={{ width: "72px", height: "72px", margin: "0 auto" }}
                  >
                    <Col
                      style={{ backgroundColor: "white", borderRadius: "100%" }}
                    >
                      <img
                        className="image1"
                        style={
                          connectionStatus &&
                          connectionStatus.status ===
                            ConnectionStatusValues.CONNECTED
                            ? {}
                            : { filter: "blur(3px)" }
                        }
                        src={
                          data?.profile_pic ? data?.profile_pic : userAvatar.img
                        }
                        alt="user"
                      />
                    </Col>
                  </Row>
                  {connectionStatus &&
                    connectionStatus.status ===
                      ConnectionStatusValues.CONNECTED && (
                      <img
                        className="image2"
                        style={{ left: "54%", top: "50px", height: "28px" }}
                        src={Connected}
                        alt="status"
                      />
                    )}
                  {connectionStatus &&
                    connectionStatus.status ===
                      ConnectionStatusValues.PENDING && (
                      <img
                        className="image2"
                        style={{ left: "54%", top: "50px", height: "28px" }}
                        src={Pending}
                        alt="status"
                      />
                    )}
                  {screens.md && (
                    <div className="freelancer-detail-link">
                      {connectionStatus &&
                        connectionStatus.status ===
                          ConnectionStatusValues.CONNECTED &&
                        data?.website}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col
              span={screens.md ? 8 : 24}
              className="freelancer-detail-header-column"
            >
              {/* {data?.company_name} */}
              {!screens.md && (
                <div
                  className="freelancer-detail-link"
                  style={{ marginBlockStart: "5px", marginBlockEnd: "10px" }}
                >
                  {connectionStatus &&
                    connectionStatus.status ===
                      ConnectionStatusValues.CONNECTED &&
                    data?.website}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            {/* {screens.md && <><Col span={6} className='border-style-cell-right' style={{ padding: '24px' }}>
              <Row style={{ textAlign: 'left', display: 'block', marginBlockEnd: '14px' }}>
                <div className='detail-label'>Adres</div>
                <div className='freelancer-detail'>
                  {`${data?.address}`}
                </div>
              </Row>
              <Row style={{ textAlign: 'left', display: 'block', marginBlockEnd: '14px' }}>
                <div className='detail-label'>
                  Kvk
              </div>
                <div>
                  {`${data?.kvk_number}`}
                </div>
              </Row>
            </Col>
            </>} */}
            <Col span={24}>
              {/* {!screens.md && (
                <>
                  <Row className="border-style-row">
                    <Col
                      span={12}
                      className="freelancer-detail-cell border-style-cell-right"
                    >
                      <div className="detail-label">Adres</div>
                      <div>{`${data?.email}`}</div>
                    </Col>
                    <Col span={12} className="freelancer-detail-cell">
                      <div className="detail-label">Kvk</div>
                      <div>{`${data?.kvk_number}`}</div>
                    </Col>
                  </Row>
                </>
              )} */}
              <Row className="border-style-row">
                <Col
                  span={12}
                  className="freelancer-detail-cell border-style-cell-right"
                >
                  <div className="detail-label">Sector</div>
                  <div>{data?.sector?.name}</div>
                </Col>
                <Col span={12} className="freelancer-detail-cell">
                  <div className="detail-label">Functie</div>
                  <div>{data?.position?.name}</div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className="freelancer-detail-cell border-style-cell-right"
                >
                  <div className="detail-label">Aantal jaar ervaring</div>
                  <div>{Number(data?.experience)}</div>
                </Col>
                <Col span={12} className="freelancer-detail-cell">
                  <div className="detail-label">Tarief</div>
                  <div>
                    {`${Number(data?.min_rate)} - ${Number(data?.max_rate)}`}
                  </div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className="freelancer-detail-cell border-style-cell-right"
                >
                  <div className="detail-label">Specialisme</div>
                  <div>
                    {data?.specialty?.map((e: any, index: number) => (
                      <span>{`${e.name}${
                        index !== data?.specialty?.length - 1 ? ", " : ""
                      }`}</span>
                    ))}
                  </div>
                </Col>
                <Col span={12} className="freelancer-detail-cell">
                  <div className="detail-label">Regio</div>
                  <div>{data?.region?.name}</div>
                </Col>
              </Row>
              <Row
                className="freelancer-detail-cell"
                style={{ display: "block" }}
              >
                <div className="detail-label">Omschrijving</div>
                <div>{data?.description}</div>
              </Row>
              <Row
                justify="end"
                style={
                  screens.md
                    ? { marginBlockEnd: "46px", marginRight: "24px" }
                    : {}
                }
              >
                {connectionStatus &&
                  connectionStatus.status ===
                    ConnectionStatusValues.CONNECTED && (
                    <>
                      <Button
                        block={!screens.md && true}
                        type="link"
                        style={{ color: "#664FF3" }}
                        size="large"
                        onClick={() => removeConnection()}
                      >
                        Connectie opheffen
                      </Button>
                      <Link
                        to="/connect/chat"
                        style={screens.md ? {} : { width: "100%" }}
                      >
                        <Button
                          block={!screens.md && true}
                          type="primary"
                          className="btn-font"
                          size="large"
                        >
                          Stuur chatbericht
                        </Button>
                      </Link>
                    </>
                  )}
                {((UserTypes.Child_User === user.user_type &&
                  checkPermission({
                    user,
                    cans: [
                      PermissionType.CREATE_ASSIGNMENT,
                      PermissionType.ACCEPT_HOURS,
                    ],
                    logic: "or",
                  })) ||
                  user.user_type === UserTypes.Company) && (
                  <>
                    {connectionStatus?.status ===
                      ConnectionStatusValues.NOT_CONNECTED && (
                      <Tooltip
                        title={
                          !user.is_finqle_active || !user.is_payment_active
                            ? "U kunt op dit moment alleen vacatures plaatsen. Voltooi de verificatie om te connecten"
                            : null
                        }
                      >
                        <Button
                          block={!screens.md && true}
                          type="primary"
                          className="btn-font"
                          style={{
                            backgroundColor: "#000233",
                            color: "white",
                          }}
                          onClick={() => sendConnectionRequest()}
                          size="large"
                          loading={isLoadingSync}
                          disabled={
                            !(user.is_finqle_active && user.is_payment_active)
                          }
                        >
                          Connectie maken
                        </Button>
                      </Tooltip>
                    )}
                  </>
                )}
                {connectionStatus &&
                  connectionStatus.status ===
                    ConnectionStatusValues.PENDING && (
                    <>
                      {!user.is_finqle_active || !user.is_payment_active ? (
                        <Tooltip title="U kunt op dit moment alleen vacatures plaatsen. Voltooi de verificatie om te connecten">
                          <Button
                            block={!screens.md && true}
                            type="ghost"
                            className="btn-font"
                            onClick={() => removeConnection()}
                            size="large"
                            loading={isLoadingSync}
                          >
                            Verzoek intrekken
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          block={!screens.md && true}
                          type="ghost"
                          className="btn-font"
                          onClick={() => removeConnection()}
                          size="large"
                          loading={isLoadingSync}
                        >
                          Verzoek intrekken
                        </Button>
                      )}
                    </>
                  )}
                {connectionStatus &&
                  connectionStatus.status ===
                    ConnectionStatusValues.REJECTED && (
                    <Button
                      block={!screens.md && true}
                      danger
                      type="primary"
                      className="btn-font"
                      size="large"
                    >
                      Afgekeurd
                    </Button>
                  )}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}
