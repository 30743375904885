import { Row, Col, Card } from "antd";
import "./style.css";
import { FileSearchOutlined, EnvironmentOutlined } from "@ant-design/icons";
import Connected from "./Icons/connected.png";
import Pending from "./Icons/request_connection.png";
import { FollowConnection } from "src/types/connections.type";
import { Company, User } from "src/types/user.type";
import { UserTypes } from "src/types/user.type";
import { ConnectionStatusValues } from "src/types/common.type";
import { useUser } from "src/hooks/useUser";
import userAvatar from "../../images/json/user_image.json";
import { Link } from "react-router-dom";

type FreelancerConnectionsProps = {
  data: FollowConnection;
  loading: boolean;
  key: any;
  setSelectedUserId: any;
};

export default function ConnectItemFollower({
  data,
  loading,
  setSelectedUserId,
}: FreelancerConnectionsProps) {
  const { user } = useUser();
  // let company: Company = data.request_by === user.id ? data.request_to as Company : data.request_by as Company;
  const companyData =
    UserTypes.Company === data.follow_to.user_type ||
    UserTypes.Child_User === data.follow_to.user_type
      ? (data.follow_to as Company)
      : undefined;
  const freelancerData =
    UserTypes.Freelancer === data.followed_by.user_type
      ? (data.followed_by as User)
      : undefined;

  const { Meta } = Card;

  return (
    <section>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                marginBottom: "10px",
                // minWidth: '1366',
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                // marginBottom: '10px',
                minWidth: "1366",
              }}
              loading={loading}
              bodyStyle={{
                padding: "24px 0px 0px 0px",
              }}
              onClick={() => {
                if (
                  UserTypes.Company === user.user_type ||
                  UserTypes.Child_User === user.user_type
                )
                  setSelectedUserId(freelancerData?.id);
              }}
            >
              <Row style={{ paddingBottom: "0px" }}>
                <Col span={20}>
                  <Meta
                    style={{
                      padding: "10px 10px 10px 45px",
                    }}
                    avatar={
                      <div style={{ paddingRight: "20px" }}>
                        <div className="parent">
                          {UserTypes.Company === user.user_type ||
                          UserTypes.Child_User === user.user_type ? (
                            <img
                              className="image1"
                              style={
                                ConnectionStatusValues.CONNECTED ===
                                freelancerData?.connection?.status
                                  ? {}
                                  : { filter: "blur(3px)" }
                              }
                              src={
                                freelancerData?.profile_pic || userAvatar.img
                              }
                              alt="user"
                            />
                          ) : (
                            <img
                              className="image1"
                              src={companyData?.profile_pic || userAvatar.img}
                              alt="user"
                            />
                          )}

                          {(UserTypes.Company === user.user_type ||
                            UserTypes.Child_User === user.user_type) &&
                            ConnectionStatusValues.CONNECTED ===
                              freelancerData?.connection?.status && (
                              <img
                                className="image2"
                                src={Pending}
                                alt="status"
                              />
                            )}
                          {(UserTypes.Company === user.user_type ||
                            UserTypes.Child_User === user.user_type) &&
                            ConnectionStatusValues.PENDING ===
                              freelancerData?.connection?.status && (
                              <img
                                className="image2"
                                src={Connected}
                                alt="status"
                              />
                            )}

                          {UserTypes.Freelancer === user.user_type &&
                            ConnectionStatusValues.CONNECTED ===
                              companyData?.connection?.status && (
                              <img
                                className="image2"
                                src={Pending}
                                alt="status"
                              />
                            )}
                          {UserTypes.Freelancer === user.user_type &&
                            ConnectionStatusValues.PENDING ===
                              companyData?.connection?.status && (
                              <img
                                className="image2"
                                src={Connected}
                                alt="status"
                              />
                            )}
                        </div>
                      </div>
                    }
                    title={
                      <span className="web-card-title">
                        {UserTypes.Company === user.user_type ||
                        UserTypes.Child_User === user.user_type
                          ? `${freelancerData?.position.name}`
                          : `${companyData?.company_name}`}
                      </span>
                    }
                    description={
                      <div>
                        <div>
                          {freelancerData?.specialty?.map(
                            (e: any, index: number) => (
                              <span className="webspecialization">
                                {companyData?.sector?.name}
                              </span>
                            )
                          )}
                        </div>
                        <div className="card-body-content">
                          {/* {data.} */}
                          {
                            <span>
                              {" "}
                              {UserTypes.Company === user.user_type ||
                              UserTypes.Child_User === user.user_type
                                ? `${freelancerData?.description}`
                                : ``}
                            </span>
                          }
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingTop: "40px",
                    paddingLeft: "20px",
                  }}
                >
                  <span className="rate">
                    {/* € {data.request_to.min_rate} - {data.request_to.max_rate} */}
                    {UserTypes.Company === user.user_type ||
                    UserTypes.Child_User === user.user_type
                      ? `€${freelancerData?.min_rate} - ${freelancerData?.max_rate}`
                      : ""}
                  </span>
                </Col>
              </Row>
              {UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type ? (
                <Row justify="space-around">
                  <Col className="card-inner-border" span={6}>
                    <span className="web-card-bottom">
                      {`${freelancerData?.first_name} ${freelancerData?.last_name}`}
                    </span>
                  </Col>
                  <Col className="card-inner-border" span={6}>
                    <span className="web-card-bottom">
                      {`${Number(freelancerData?.experience)} jaar ervaring`}
                    </span>
                  </Col>
                  <Col className="card-inner-border" span={6}>
                    <span className="web-card-bottom">
                      {`${freelancerData?.finished_project_count} voltooide jobs`}
                    </span>
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      borderTop: "1px solid #F6F6F6",
                      paddingTop: "12px",
                      paddingBottom: "14px",
                    }}
                    span={6}
                  >
                    <span className="web-card-bottom">
                      {freelancerData?.region?.name}
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row justify="space-around">
                  <Col className="card-inner-border" span={12}>
                    <Link to={`/search/${companyData?.id}`}>
                      <span className="web-card-bottom">
                        {companyData?.open_vacancy_count} vacature
                      </span>
                    </Link>
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      borderTop: "1px solid #F6F6F6",
                      paddingTop: "12px",
                      paddingBottom: "14px",
                    }}
                    span={12}
                  >
                    <span className="web-card-bottom">
                      {companyData?.region?.name}
                    </span>
                  </Col>
                </Row>
              )}
            </Card>
          )}
        </Col>

        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "16px",
                marginBottom: "10px",
                minWidth: "1366",
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "16px",
                borderRadius: "5px",
              }}
              loading={loading}
              bodyStyle={{
                padding: 14,
              }}
            >
              <Row justify="space-between">
                {UserTypes.Company === user.user_type ||
                UserTypes.Child_User === user.user_type ? (
                  <Col span={16}>
                    <div className="card-mobile-name-bold">
                      {`${freelancerData?.first_name} ${freelancerData?.last_name}`}
                    </div>
                    <div className="card-mobile-sub">
                      {freelancerData?.sector?.name}
                    </div>
                    <div className="card-mobile-content1">
                      {`${Number(freelancerData?.experience)} jaar ervaring`}
                    </div>
                    <div className="card-mobile-content2">
                      {`${freelancerData?.finished_project_count} voltooide jobs`}
                    </div>
                    <Row>
                      <Col style={{ marginRight: "10px" }}>
                        <FileSearchOutlined
                          style={{ margin: "0px 5px", fontSize: "12px" }}
                        />
                        {freelancerData?.specialty?.map(
                          (e: any, index: number) => (
                            <span className="mobile-specialization">{`${
                              e.name
                            }${
                              index !== freelancerData?.specialty?.length - 1
                                ? ", "
                                : ""
                            }`}</span>
                          )
                        )}
                      </Col>
                      <Col>
                        <EnvironmentOutlined
                          style={{ margin: "0px 5px", fontSize: "12px" }}
                        />
                        <span className="mobile-specialization">
                          {freelancerData?.region?.name}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={16}>
                    <div className="card-mobile-name-bold">{`${companyData?.company_name}`}</div>
                    <div style={{ marginBlockEnd: "32px" }}></div>
                    <Row>
                      <Col style={{ marginRight: "10px" }}>
                        <FileSearchOutlined
                          style={{ margin: "0px 5px", fontSize: "12px" }}
                        />
                        <span className="mobile-specialization">
                          {companyData?.sector?.name}
                        </span>
                      </Col>
                      <Col>
                        <EnvironmentOutlined
                          style={{ margin: "0px 5px", fontSize: "12px" }}
                        />
                        <span className="mobile-specialization">
                          {companyData?.region?.name}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col
                  span={8}
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <Row justify="end">
                    <Col>
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          paddingRight: "10px",
                        }}
                      >
                        <div className="parent">
                          {UserTypes.Company === user.user_type ||
                          UserTypes.Child_User === user.user_type ? (
                            <img
                              className="image1"
                              src={
                                freelancerData?.profile_pic || userAvatar.img
                              }
                              alt="user"
                            />
                          ) : (
                            <img
                              className="image1"
                              src={companyData?.profile_pic || userAvatar.img}
                              alt="user"
                            />
                          )}
                          {(UserTypes.Company === user.user_type ||
                            UserTypes.Child_User === user.user_type) &&
                            ConnectionStatusValues.CONNECTED ===
                              freelancerData?.connection?.status && (
                              <img
                                className="image2"
                                src={Pending}
                                alt="status"
                              />
                            )}
                          {(UserTypes.Company === user.user_type ||
                            UserTypes.Child_User === user.user_type) &&
                            ConnectionStatusValues.PENDING ===
                              freelancerData?.connection?.status && (
                              <img
                                className="image2"
                                src={Connected}
                                alt="status"
                              />
                            )}

                          {UserTypes.Freelancer === user.user_type &&
                            ConnectionStatusValues.CONNECTED ===
                              companyData?.connection?.status && (
                              <img
                                className="image2"
                                src={Pending}
                                alt="status"
                              />
                            )}
                          {UserTypes.Freelancer === user.user_type &&
                            ConnectionStatusValues.PENDING ===
                              companyData?.connection?.status && (
                              <img
                                className="image2"
                                src={Connected}
                                alt="status"
                              />
                            )}
                        </div>
                        <div
                          style={{
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          {/* <span className='mobile-rate'> € {data.request_to.min_rate} - {data.request_to.max_rate}</span> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </section>
  );
}
