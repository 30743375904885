import {
  Row,
  Col,
  Grid,
  Input,
  Form,
  Button,
  InputNumber,
  Modal,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { Hours, HoursUpdate, Invoice, Proposal } from "src/types/work.type";
import moment from "moment";
import axios from "axios";
import { useQuery } from "react-query";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";

type HourlyOverviewProps = {
  hourlyData?: Hours | undefined;
  refetchList: Function;
  proposalData: Proposal | undefined;
  selectedWeek: moment.Moment;
};

type InvoiceRespose = {
  count: number;
  next: string | undefined;
  previous: string | undefined;
  results: Invoice[];
};

const convertToNumber = (val: any) => {
  const v = parseFloat(val);

  if (isNaN(v)) {
    return 0;
  }

  return v;
};

export default function HourlyOverviewForm({
  hourlyData,
  refetchList,
  proposalData,
  selectedWeek,
}: HourlyOverviewProps) {
  const invoiceData = useQuery<InvoiceRespose>(
    [`Invoice${proposalData?.id}`, selectedWeek.isoWeek(), selectedWeek.year()],
    async () => {
      return (
        await axios.get(`${BASE_URL}api/invoices`, {
          ...getHttpHeader(),
          params: {
            proposal: proposalData?.id,
            week: selectedWeek.isoWeek(),
            year: selectedWeek.year(),
          },
        })
      ).data;
    },
    {
      enabled: !!proposalData?.id,
    }
  );

  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [formActive, setFormActive] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invoice, setInvoice] = useState<Invoice>();

  useEffect(() => {
    if (hourlyData) {
      setSubmitted(hourlyData.is_submitted);
    }
    setFormActive(!hourlyData);
  }, [hourlyData]);

  useEffect(() => {
    setInvoice(invoiceData?.data?.results[0]);
    // console.log("DATATATATATA ====> ", invoiceData?.data?.results[0]);
  }, [invoiceData]);

  const editSavedForm = () => {
    // if (hourlyData !== undefined) {
    setFormActive(true);
    // }
    // if (formActive) {
    //   form.setFields([
    //     {
    //       name: "time",
    //       errors: undefined,
    //     },
    //   ]);
    // }
  };

  const createInvoice = async () => {
    const invoiceRes = await axios({
      url: `${BASE_URL}api/invoices/`,
      method: "POST",
      data: {
        proposal: proposalData?.id,
        week: selectedWeek.isoWeek(),
        year: selectedWeek.year(),
        is_direct_payment: false,
      },
      headers: getHttpHeader().headers,
    });

    return invoiceRes.data as Invoice;
  };

  const checkForDirectPayment = async (
    invoiceId: number,
    hours: number,
    expense: number,
    kilometers: number
  ) => {
    try {
      //* 1-get current available amount
      const availableAmountRes = await axios.post(
        `${BASE_URL}api/invoices/${invoiceId}/check_debtor_credit/`,
        {},
        getHttpHeader()
      );
      const availableCredit = availableAmountRes.data.available_credit || 0;

      //* 2-sum expense cost
      const totalCost = calculateSum(hours, expense, kilometers);

      //* 3-check if available credits is enough
      const shouldAsk = totalCost <= availableCredit * 0.8;

      //* 4-ask the user how they want to be paid
      return shouldAsk;
    } catch (error) {
      console.log("checkForDirectPayment --> ", error);
      message.error("Finqle niet geautoriseerd");
    }
  };

  const calculateSum = (hours: number, expense: number, kilometers: number) => {
    let grossRate: string | number = proposalData?.gross_rate as string;
    let expenseRate: string | number =
      proposalData?.travel_allowances as string;

    grossRate = parseFloat(grossRate);
    expenseRate = parseFloat(expenseRate);

    const hourCost = hours * grossRate;
    const expenseCost = expenseRate * expenseRate;
    const kilometersCost = expenseCost * kilometers;

    return hourCost + expenseCost + kilometersCost;
  };

  const handleSelectNormalPayment = () => {
    setVisible(false);
  };

  const handleSelectDirectPayment = async () => {
    setVisible(false);
    const invoiceId = invoice?.id;
    try {
      const updateRes = await axios.patch(
        `${BASE_URL}api/invoices/${invoiceId}/`,
        {
          is_direct_payment: true,
        },
        getHttpHeader()
      );

      if (updateRes.status === 200) {
        message.success("update succesvol");
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Hourly-Overview-Form.tsx ~ line 196 ~ handleSelectDirectPayment ~ error",
        error
      );
      message.error("er is iets fout gegaan");
    }
  };

  const sendHour = async (newData: any, isSubmit: boolean) => {
    await axios({
      method: "POST",
      url: `${BASE_URL}api/hoursets/`,
      data: newData,
      headers: getHttpHeader().headers,
    })
      .then((res) => {
        refetchList();
        setFormActive(false);
        setSubmitted(isSubmit);
        // form.resetFields();
      })
      .catch((err) => {
        console.error(err.response.data);
      });
  };

  // function to create new hour.
  const createHour = async (isSubmit: boolean) => {
    try {
      const newData = {
        date: moment(selectedWeek).format("YYYY-MM-DD"),
        description: form.getFieldValue("discription"),
        expenses: form.getFieldValue("expanse"),
        hours: form.getFieldValue("time"),
        kilometers: form.getFieldValue("distance"),
        is_submitted: isSubmit,
        proposal: proposalData?.id,
        invoice: 0,
        // invoice: invoice?.id,
      };

      const invoice = await createInvoice();
      setInvoice(invoice);
      newData.invoice = invoice.id;

      if (isSubmit) {
        const showPopup = await checkForDirectPayment(
          invoice.id,
          convertToNumber(newData.hours),
          convertToNumber(newData.expenses),
          convertToNumber(newData.kilometers)
        );

        if (showPopup) {
          setVisible(true);
        }
      }

      sendHour(newData, isSubmit);
    } catch (err) {
      console.log(err);
    }
  };

  // function to update the saved hour
  const updateHour = async (isSubmit: boolean) => {
    const newData: HoursUpdate = {
      date: moment(selectedWeek).format("YYYY-MM-DD"),
      description: form.getFieldValue("discription"),
      expenses: form.getFieldValue("expanse"),
      hours: form.getFieldValue("time"),
      kilometers: form.getFieldValue("distance"),
      is_submitted: isSubmit,
      proposal: proposalData?.id,
    };

    try {
      const res = await axios({
        method: "PATCH",
        url: `${BASE_URL}api/hoursets/${hourlyData?.id}/`,
        data: newData,
        headers: getHttpHeader().headers,
      });

      // to confirm the payment method
      if (isSubmit) {
        const showPopup = await checkForDirectPayment(
          res.data.invoice,
          convertToNumber(newData.hours),
          convertToNumber(newData.expenses),
          convertToNumber(newData.kilometers)
        );

        if (showPopup) {
          setVisible(true);
        }
      }

      refetchList();
      setFormActive(false);
      setSubmitted(isSubmit);
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const saveForm = async () => {
    if (hourlyData === undefined) createHour(false);
    else updateHour(false);
  };

  const submitForm = async (value: any) => {
    if (hourlyData === undefined) createHour(true);
    else updateHour(true);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        marginBlockStart: "20px",
      }}
    >
      {formActive ? (
        <>
          <Row>
            <Col className="hourly-detail-item-header">
              {moment(selectedWeek).format("dddd DD MMMM")}
            </Col>
          </Row>
          <br />
          <Row>
            <div style={{ width: "100%" }}>
              <Form
                form={form}
                // name="regiter-form"
                initialValues={{
                  time: hourlyData?.hours,
                  distance: hourlyData?.kilometers,
                  expanse: hourlyData?.expenses,
                  discription: hourlyData?.description,
                }}
                onFinish={submitForm}
                // onFinishFailed={onFinishFailed}
              >
                <Row
                  justify="space-between"
                  style={{ width: "100%" }}
                  className={
                    screens.md
                      ? "hourly-detail-item-body"
                      : "hourly-detail-item-body-mobile"
                  }
                >
                  <Col
                    span={screens.md ? 8 : 24}
                    style={{ display: "inline-flex" }}
                  >
                    <Form.Item name="time" style={{ display: "flex" }}>
                      <InputNumber size="large" style={{ width: "80px" }} />
                    </Form.Item>
                    <span className="project-details-label"> Uur</span>{" "}
                  </Col>
                  <Col
                    span={screens.md ? 8 : 24}
                    style={{ display: "inline-flex" }}
                  >
                    <Form.Item name="distance">
                      <InputNumber
                        // placeholder="Achternaam"
                        size="large"
                        style={{ width: "80px" }}
                      />
                    </Form.Item>
                    <span className="project-details-label"> Kilometer</span>{" "}
                  </Col>
                  <Col
                    span={screens.md ? 8 : 24}
                    style={{ display: "inline-flex" }}
                  >
                    <Form.Item name="expanse">
                      <InputNumber
                        // placeholder="Achternaam"
                        style={{ width: "80px" }}
                        size="large"
                      />
                    </Form.Item>
                    <span className="project-details-label"> Onkosten</span>
                  </Col>
                </Row>
                <Form.Item
                  name="discription"
                  className={
                    screens.md
                      ? "hourly-detail-item-body"
                      : "hourly-detail-item-body-mobile"
                  }
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Vul een emailadres in!",
                  //   },
                  //   {
                  //     type: "email",
                  //     message: "Email adres wordt niet herkend"
                  //   },
                  // ]}
                >
                  <TextArea
                    placeholder="Voer hier uw omschrijving in"
                    size="large"
                    rows={3}
                  />
                </Form.Item>

                <Row style={{ height: "40px" }}>
                  <Col span={12}>
                    <Form.Item>
                      <Button ghost block size="large" onClick={saveForm}>
                        Uren opslaan
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                      >
                        Uren indienen
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
        </>
      ) : (
        <div
          style={{
            paddingBlockEnd: "15px",
          }}
        >
          <Row>
            <Col className="hourly-detail-item-header">
              {moment(selectedWeek).format("dddd DD MMMM")}
            </Col>
          </Row>
          <br />
          <Row>
            <div style={{ width: "100%" }}>
              <Row
                justify="space-between"
                style={{ width: "100%" }}
                className={
                  screens.md
                    ? "hourly-detail-item-body"
                    : "hourly-detail-item-body-mobile"
                }
              >
                <Col
                  span={screens.md ? 8 : 24}
                  style={{ display: "inline-flex" }}
                >
                  <div style={screens.md ? {} : { marginBlockEnd: "26px" }}>
                    <span className="sub-detail-2">{`${Number(
                      // form.getFieldValue("time")
                      hourlyData?.hours
                    )} uur`}</span>
                  </div>
                </Col>
                <Col
                  span={screens.md ? 8 : 24}
                  style={{ display: "inline-flex" }}
                >
                  <div style={screens.md ? {} : { marginBlockEnd: "26px" }}>
                    <span className="sub-detail-2">
                      {`${Number(
                        // form.getFieldValue("distance")
                        hourlyData?.kilometers
                      )} km`}
                    </span>
                  </div>
                </Col>
                <Col
                  span={screens.md ? 8 : 24}
                  style={{ display: "inline-flex" }}
                >
                  <div style={screens.md ? {} : { marginBlockEnd: "26px" }}>
                    <span className="sub-detail-2">
                      {" "}
                      {`€${Number(
                        // form.getFieldValue("expanse")
                        hourlyData?.expenses
                      )} Onkosten`}
                    </span>
                  </div>
                </Col>
              </Row>

              <div
                className={
                  screens.md
                    ? "hourly-detail-item-body"
                    : "hourly-detail-item-body-mobile"
                }
              >
                <div className="project-horly-form-body">
                  {hourlyData?.description}
                </div>
              </div>

              <>
                {!isSubmitted && (
                  <Button
                    onClick={editSavedForm}
                    block
                    size="large"
                    className="project-horly-form-edit-btn"
                  >
                    Uren zijn opgeslagen. Om uren in dienen klik nogmaals.
                  </Button>
                )}
              </>
            </div>
          </Row>
        </div>
      )}
      <PaymentSelectionModal
        visible={visible}
        onSelectDirectPayment={() => handleSelectDirectPayment()}
        onSelectNormalPayment={handleSelectNormalPayment}
      />
    </div>
  );
}

type PaymentSelectionModalProps = {
  visible: boolean;
  onSelectDirectPayment: () => void;
  onSelectNormalPayment: () => void;
};

const PaymentSelectionModal = ({
  visible,
  onSelectDirectPayment,
  onSelectNormalPayment,
}: PaymentSelectionModalProps) => {
  return (
    <Modal
      closable={false}
      title={
        <div>
          <Row justify="center" style={{ padding: 20 }}>
            <Col
              style={{
                fontFamily: "Arimo",
                fontWeight: 700,
                fontSize: "16px",
                padding: 5,
                textAlign: "center",
              }}
            >
              Wilt u gebruik maken van de mogelijkheid tot direct betalen of
              wilt u de reguliere betaaltermijn aanhouden?
            </Col>
            <Col
              style={{
                fontFamily: "Arimo",
                fontWeight: 400,
                fontStyle: "italic",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Note: Additional fees will be charged.
            </Col>
          </Row>
        </div>
      }
      centered
      visible={visible}
      footer={null}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Row>
        <Col span={12}>
          <Button
            size="large"
            onClick={onSelectNormalPayment}
            style={{
              width: "100%",
              fontFamily: "Arimo",
              fontWeight: 700,
              fontSize: "16px",
              backgroundColor: "#002333",
              color: "white",
              border: "0px",
            }}
          >
            Direct betalen
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="large"
            onClick={onSelectDirectPayment}
            style={{
              width: "100%",
              fontFamily: "Arimo",
              fontWeight: 700,
              fontSize: "14px",
              color: "#002333",
            }}
          >
            Reguliere betaaltermijn aanhouden
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
