import React from "react";
import { Button, Col, Grid, Row } from "antd";
import { useHistory } from "react-router";

export const DashboardNavButton = ({
  icon,
  title,
  backgroundColor,
  color,
  gotoLink,
  onClick,
}: {
  icon: any;
  title: string;
  backgroundColor?: string;
  color?: string;
  gotoLink?: string;
  onClick?: () => void;
}) => {
  const screens = Grid.useBreakpoint();
  const history = useHistory();

  return (
    <Button
      block
      className={screens.md ? "dashboard-nav-btn" : "dashboard-nav-btn-mobile"}
      style={{
        backgroundColor: backgroundColor ?? "white",
        color: color ?? "#002333",
      }}
      onClick={() => {
        gotoLink && history.push(gotoLink);
        onClick && onClick();
      }}
    >
      <Row justify="space-between">
        <Col span={2}>{icon}</Col>
        <Col span={22}>{title}</Col>
      </Row>
    </Button>
  );
};
