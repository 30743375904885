import { Row, Col, Form, Grid, Typography, Input, Button, message } from "antd";
import "./styles.css";
import { useState } from "react";
// import { Company } from 'src/types/user.type';
import axios from "axios";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export default function ChangePassword() {
  const screens = Grid.useBreakpoint();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(true);

  const BASE_URL = process.env.REACT_APP_BACKEND;

  const formGroupInputStyle = {
    display: "inline-block",
    width: "calc(50% - 40px)",
    margin: "0 40px 0 0 ",
    minHeight: "70px",
  };

  const handleChange = (value: any) => {
    // console.log(value)
    if (form.getFieldValue("new_password") !== value) {
      form.setFields([
        {
          name: "confirm_password",
          errors: ["Wachtwoorden komen niet overeen"],
        },
      ]);
      setDisableButton(true);
    } else {
      form.setFields([
        {
          name: "confirm_password",
          errors: [],
        },
      ]);
      setDisableButton(false);
    }
  };

  const onFinish = async (value: any) => {
    // console.log(value)

    axios({
      method: "PUT",
      url: `${BASE_URL}api/account/change-password/`,
      data: {
        current_password: value.current_password,
        new_password: value.new_password,
        confirm_password: value.confirm_password,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.data[0] === "Password changed successfully.") {
          message.success("Wachtwoord succesvol veranderd.");
        }
        form.resetFields();
      })
      .catch((err) => {
        if (
          err.response.data?.current_password[0] ===
          "Current password does not match"
        ) {
          message.error("Huidig wachtwoord komt niet overeen.");
        }
      });
  };

  return (
    <>
      {screens.md && <div className="inner-sub-header">Nieuw wachtwoord</div>}
      <Row>
        <Form
          form={form}
          requiredMark={false}
          onFinish={onFinish}
          colon={false}
          // onFinishFailed={() => console.log("FinishFailed")}
          style={{ width: "100%" }}
        >
          <div>
            <div style={screens.md === true ? { padding: "24px" } : {}}>
              <Row justify="center">
                <Col
                  xl={0}
                  lg={0}
                  md={0}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}
                >
                  <Title level={5} className="mobile-header">
                    Nieuw wachtwoord
                  </Title>
                  <br />
                </Col>
              </Row>

              <div>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="current_password"
                        label={
                          <span className="form-label">Huidig wachtwoord</span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Huidig wachtwoord is verplicht",
                          },
                          // {
                          //   min: 8,
                          //   message: 'wachtwoord moet minimaal 8 tekens lang zijn'
                          // },
                          // {
                          //   pattern: new RegExp(
                          //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                          //   ),
                          //   message: "Wachtwoord voldoet niet aan de eisen",
                          // },
                        ]}
                        style={screens.md === true ? formGroupInputStyle : {}}
                      >
                        <Input.Password
                          type="password"
                          placeholder="Huidig wachtwoord"
                          className="normal-input-more-radius"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Form.Item>
                    <br />
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="new_password"
                        label={
                          <span className="form-label">Nieuw wachtwoord</span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Nieuw wachtwoord is verplicht",
                          },
                          {
                            pattern: new RegExp(
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                            ),
                            message:
                              "Uw wachtwoord moet tenminste 8 tekens bevatten waarvan een hoofdletter, kleine letter, cijfer en speciaal teken",
                          },
                        ]}
                        style={screens.md === true ? formGroupInputStyle : {}}
                      >
                        <Input.Password
                          type="password"
                          placeholder="Nieuw wachtwoord"
                          className="normal-input-more-radius"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Form.Item>
                    <br />
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        label={
                          <span className="form-label">
                            Wachtwoord herhalen
                          </span>
                        }
                        name="confirm_password"
                        rules={
                          [
                            // {
                            //   required: true,
                            //   message: "Wachtwoord herhalen is verplicht",
                            // },
                          ]
                        }
                        style={screens.md === true ? formGroupInputStyle : {}}
                      >
                        <Input.Password
                          type="password"
                          placeholder="Wachtwoord herhalen"
                          className="normal-input-more-radius"
                          onChange={(value) => handleChange(value.target.value)}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Form.Item>
            <Row justify="end" style={screens.md ? { margin: "0px 20px" } : {}}>
              <Col span={screens.md ? 6 : 12}>
                <Button
                  // onClick={() =>
                  //   props.setEnableVisibleForm([false, false])
                  // }
                  block={screens.md ? false : true}
                  type="primary"
                  className={screens.md ? "form-button" : ""}
                  style={{ backgroundColor: "#664FF3", marginRight: "20px" }}
                >
                  Annuleren
                </Button>
              </Col>
              <Col span={screens.md ? 6 : 12}>
                <Button
                  block={screens.md ? false : true}
                  type="primary"
                  className={screens.md ? "form-button" : ""}
                  htmlType="submit"
                  disabled={disableButton}
                >
                  Opslaan
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </>
  );
}
