import {
  Row
} from 'antd';
import moment from 'moment';
import ActivityListItem from './ActivityList-item';
import 'moment/locale/nl'

type Activity = {
  activity_datetime: Date
  activity_message: string
  activity_type: string
  id: number
  is_active: boolean
}

type ActivityProps = {
  date: string,
  activities: Activity[]
}

export default function ActivityList({ date, activities }: ActivityProps) {

  return (
    <>
      <Row style={{
        backgroundColor: '#000233',
        textAlign: 'center',
        height: 35,
        display: 'grid',
        placeItems: 'center',
        borderRadius: 5,
        fontFamily: 'Basier Square',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: 0,
        color: 'white',
      }}>
        {moment(date).format("dddd DD MMMM YYYY")}
      </Row>
      <Row style={{ marginBlockStart: '16px', marginBlockEnd: '32px' }}>
        {activities.map(e => {
          return (
            <ActivityListItem content={e.activity_message} />
          )
        })}
      </Row>
    </>
  );
}