import { Row, Col, Button, DatePicker, Grid, message } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import moment from "moment";
import HourlyOverviewItem from "./Hourly-Overview-Item";
import { Hours, Proposal } from "src/types/work.type";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useUser } from "src/hooks/useUser";
import { useQueryParams } from "src/hooks/useQueryParams";

type HourlyOverviewProps = {
  id: number;
  ProposalData: Proposal | undefined;
};

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Hours[];
};

export default function HourlyOverview({
  id,
  ProposalData: proposalData,
}: HourlyOverviewProps) {
  const queryParams = useQueryParams();
  const initialWeek = queryParams.get("week");
  const [selectedWeek, setSelectedWeek] = useState(moment());
  const screens = Grid.useBreakpoint();
  const { user } = useUser();

  const shouldRenderSubmitButtons =
    user?.user_type === "company" || user?.id === proposalData?.authorized_by;

  const { data, refetch } = useQuery<Response>(
    ["HourlyList", proposalData?.id, selectedWeek.isoWeek()],
    async () => {
      return (
        await axios.get(
          `${BASE_URL}api/hours/?proposal=${
            proposalData?.id
          }&week=${selectedWeek.isoWeek()}`,
          getHttpHeader()
        )
      ).data;
    },
    {
      enabled: !!proposalData,
    }
  );

  useEffect(() => {
    if (!initialWeek) {
      return;
    }

    try {
      const w = parseInt(initialWeek);
      setSelectedWeek(moment().isoWeek(w));
    } catch (error) {
      console.log("error not a valid week");
    }
  }, [initialWeek]);

  const onChange = (date: any, dateString: string) => {
    setSelectedWeek(date);
  };

  const customFormat = (value: any) => {
    return `Week ${value.isoWeek()}`;
  };

  const handleBackForwardDate = (operation: string) => {
    if (operation === "add") {
      const newDate = moment(selectedWeek).add(1, "week");
      setSelectedWeek(newDate);
    } else if (operation === "subtract") {
      const newDate = moment(selectedWeek).subtract(1, "week");
      setSelectedWeek(newDate);
    }
  };

  /**
   *
   * @param hoursetId number
   *
   * update this to have a state
   * about whether a company cliked on
   * accept / reject button
   */
  const updateCompanySubmitted = (hoursetId: number) => {
    axios
      .patch(
        `${BASE_URL}api/hours/${hoursetId}/company_submit/`,
        {
          company_submitted: true,
        },
        getHttpHeader()
      )
      .then(() => {
        refetch();
      });
  };

  const acceptHours = async (invoiceId: number, hourId: number) => {
    await axios({
      method: "POST",
      url: `${BASE_URL}api/invoices/${invoiceId}/approve/`,
      headers: getHttpHeader().headers,
    })
      .then((res) => {
        message.success("Uren zijn geaccepteerd");

        updateCompanySubmitted(hourId);
      })
      .catch((err) => {
        message.error("Er is iets fout gegaan. Probeer het nog eens.");
      });
  };

  const rejectHours = async (invoiceId: number, hourId: number) => {
    await axios({
      method: "POST",
      url: `${BASE_URL}api/invoices/${invoiceId}/disapprove/`,
      headers: getHttpHeader().headers,
    })
      .then((res) => {
        message.success(
          "Uren zijn geweigerd. Vergeet niet om toe te lichten waarom je de uren hebt geweigerd zodat de ZZPer de uren aan kan passen."
        );

        updateCompanySubmitted(hourId);
      })
      .catch((err) => {
        message.error("Er is iets fout gegaan. Probeer het nog eens.");
      });
  };

  return (
    <Row>
      <Row justify="space-between" className="project-bar">
        <Col span={2} className="project-bar-icons">
          <Button
            type="link"
            icon={<LeftOutlined className="project-bar-icons" />}
            onClick={() => handleBackForwardDate("subtract")}
          />
        </Col>
        <Col span={20} style={{ textAlign: "center" }}>
          {/* <div className="project-date-bar">Week {selectedWeek.isoWeek()}</div> */}
          <DatePicker
            allowClear={false}
            bordered={false}
            onChange={onChange}
            picker="week"
            className="project-date-picker-hourly"
            inputReadOnly={true}
            format={customFormat}
            value={selectedWeek}
            size="small"
          />
        </Col>
        <Col span={2} className="project-bar-icons">
          <Button
            type="link"
            icon={<RightOutlined className="project-bar-icons" />}
            onClick={() => handleBackForwardDate("add")}
          />
        </Col>
      </Row>
      <div
        style={
          screens.md
            ? { width: "100%", marginBlockStart: "26px" }
            : { marginBlockStart: "26px", overflow: "auto" }
        }
      >
        <Row
          justify="space-between"
          style={screens.md ? { width: "100%" } : { minWidth: "700px" }}
        >
          <Col
            span={6}
            className="hourly-overview-header-freelancer"
            style={{ textAlign: "start" }}
          >
            Dag
          </Col>
          <Col span={3} className="hourly-overview-header-freelancer">
            Uren
          </Col>
          <Col span={3} className="hourly-overview-header-freelancer">
            Reizen
          </Col>
          <Col span={3} className="hourly-overview-header-freelancer">
            Onkosten
          </Col>
          <Col span={5} className="hourly-overview-header-freelancer">
            Commentaar
          </Col>
          <Col span={1} className="hourly-overview-header-freelancer" />
          <Col span={3} className="hourly-overview-header-freelancer" />
        </Row>
        {data &&
          data.results.map((e) => {
            if (!e?.is_submitted) {
              return null;
            }

            return (
              <HourlyOverviewItem
                key={e.id}
                hoursData={e}
                shouldRenderButtons={shouldRenderSubmitButtons}
                onAcceptHours={acceptHours}
                onRejectHours={rejectHours}
              />
            );
          })}
      </div>
    </Row>
  );
}
