import axios from "axios";
import { LoginUser } from "src/types/user.type";
import { BASE_URL } from "src/config/default";

export const useUser = () => {
  const user: LoginUser = JSON.parse(localStorage.getItem("user") + "");

  const URL =
    user?.user_type === "company"
      ? `${BASE_URL}api/companies/${user.id}/`
      : `${BASE_URL}api/freelancers/${user.id}/`;

  const getUserDetails = async () => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      localStorage.setItem("user", JSON.stringify(res?.data));
    } catch (error) {
      console.log("useUser --> getUserDetails: ", error);
    }
  };

  return { user, getUserDetails };
};
