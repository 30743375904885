import { Row, Col, Divider, Grid, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Comment_Purple from "src/icons/comment_purple.svg";
import { useQuery } from "react-query";
import axios from "axios";
import { User } from "src/types/user.type";
import { useHistory } from "react-router-dom";
import { ConnectionStatusValues } from "src/types/common.type";
import Connected from "src/modules/Connect/Icons/connected.png";
import Pending from "src/modules/Connect/Icons/request_connection.png";
import userAvatar from "../../../images/json/user_image.json";
import FreelancerDetailModal from "src/modules/Search/FreelancerDetailModal";
import { useMemo, useState } from "react";
import { useUser } from "src/hooks/useUser";

type freelancerDetailProps = {
  id: number;
  removeFreelancerInterestedList: Function;
};

export default function FreelancerDetail({
  id,
  removeFreelancerInterestedList,
}: freelancerDetailProps) {
  const screens = Grid.useBreakpoint();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [isLoadingSync, setLoadingSync] = useState(false);
  const history = useHistory();
  const { user } = useUser();

  const disableOpen = useMemo(() => {
    return user?.is_finqle_active || user?.is_payment_active;
  }, [user]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const fetchFreelancerData = async () => {
    return await (
      await axios.get(`${BASE_URL}api/freelancers/${id}/`, config)
    ).data;
  };

  const { data, refetch } = useQuery<User>(
    `freelancerData${id}`,
    fetchFreelancerData
  );

  const handleChat = () => {
    if (!!data?.connection?.id) history.push(`/connect/chat/${data?.id}`);
    else setSelectedUserId(id);
  };

  const sendConnectionRequest = async () => {
    setLoadingSync(true);

    const newData = {
      connection_to_id: id,
    };

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}api/connection/`,
        headers: config.headers,
        data: newData,
      });
      setLoadingSync(false);
      refetch();
    } catch (error) {
      setLoadingSync(false);
    }
  };

  return (
    <>
      {!!selectedUserId && (
        <FreelancerDetailModal
          id={id}
          setSelectedUserId={setSelectedUserId}
          refetchSearchList={() => {}}
        />
      )}
      {screens.md && (
        <Row
          style={{
            backgroundColor: "#F6F6F6",
            marginBlockStart: "20px",
            height: "100px",
            alignItems: "center",
            cursor: disableOpen ? "pointer" : "not-allowed",
          }}
        >
          <Col
            flex="30px"
            onClick={() => {
              disableOpen && setSelectedUserId(id);
            }}
          >
            <div className="parent">
              <img
                className="freelancer-profile"
                src={data?.profile_pic ? data?.profile_pic : userAvatar.img}
                alt="user"
              />
              {data?.connection?.status === ConnectionStatusValues.PENDING && (
                <img
                  className="image2"
                  style={{ top: "36px", left: "20%", height: "24px" }}
                  src={Pending}
                  alt="status"
                />
              )}
              {data?.connection?.status ===
                ConnectionStatusValues.CONNECTED && (
                <img
                  className="image2"
                  style={{ top: "36px", left: "20%", height: "24px" }}
                  src={Connected}
                  alt="status"
                />
              )}
            </div>
          </Col>
          <Col
            flex="60%"
            onClick={() => {
              disableOpen && setSelectedUserId(id);
            }}
          >
            <div className="freelancer-card-title">
              {`${data?.first_name} ${data?.last_name} €${data?.min_rate} - €${data?.max_rate} p/u`}
            </div>
            <div>
              {/* {specialization.map(e => {
              return (<span className='specialization'> {e} </span>)
            })} */}
              <span className="specialization"> {data?.sector.name} </span>
            </div>
            <div className="freelancer-brief-intro">
              {`${data?.position?.name} • ${Math.trunc(
                parseInt(data?.experience ? data.experience : "0")
              )} jaar ervaring • ${
                data?.project_count ? data.project_count : "0"
              } jobs • ${data?.region.name}`}
            </div>
          </Col>
          <Divider
            type="vertical"
            style={{
              margin: "0px",
              height: "100%",
              backgroundColor: "#EEEEEE",
            }}
          />
          <Col flex="auto" style={{ textAlign: "center" }}>
            <Button
              disabled={!user.is_finqle_active || !user.is_payment_active}
              icon={<DeleteOutlined />}
              size="large"
              type="link"
              style={{ color: "#a5a6a7" }}
              onClick={() => disableOpen && removeFreelancerInterestedList(id)}
            ></Button>
          </Col>
          <Divider
            type="vertical"
            style={{
              margin: "0px",
              height: "100%",
              backgroundColor: "#EEEEEE",
            }}
          />
          <Col flex="auto" style={{ textAlign: "center" }}>
            {data?.connection?.status === "not connected" ? (
              <Button
                type="link"
                className="btn-font"
                onClick={() => sendConnectionRequest()}
                size="large"
                loading={isLoadingSync}
                disabled={!user.is_finqle_active || !user.is_payment_active}
                style={{ color: "#664ff3" }}
              >
                Connect
              </Button>
            ) : (
              <img
                src={Comment_Purple}
                alt="msg"
                style={{ cursor: "pointer" }}
                onClick={handleChat}
              />
            )}
          </Col>
        </Row>
      )}

      {!screens.md && (
        <Row style={{ backgroundColor: "#f6f6f6", marginBlockEnd: "10px" }}>
          <Row
            style={{
              borderBottom: "1px solid #E5E6E8",
              width: "100%",
              padding: "14px",
            }}
            onClick={() => {
              disableOpen && setSelectedUserId(id);
            }}
          >
            <Col flex="20%" style={{ display: "grid", placeItems: "center" }}>
              <img
                className=""
                style={{ width: "56px", height: "56px", borderRadius: "50%" }}
                src={data?.profile_pic || userAvatar.img}
                alt="user"
              />
            </Col>
            <Col flex="75%" style={{ paddingLeft: "10px" }}>
              <div className="mobile-card-header">
                {`${data?.first_name} ${data?.last_name} €${data?.min_rate} p/u`}
              </div>
              <div className="mobile-card-body">
                • {data?.position?.name} <br />•{" "}
                {Math.trunc(parseInt(data?.experience ? data.experience : "0"))}{" "}
                jaar ervaring <br />•{" "}
                {data?.project_count ? data.project_count : "0"} jobs <br />•{" "}
                {data?.region.name}
              </div>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{ width: "100%", padding: "14px" }}
          >
            <Col
              flex="auto"
              onClick={() => {
                disableOpen && setSelectedUserId(id);
              }}
            >
              {/* {specialization.map(e => {
                return (<span className='specialization' style={{ marginRight: '5px' }}> {e} </span>)
              })} */}
              <span className="specialization"> {data?.sector.name} </span>
            </Col>
            <Col flex="auto" style={{ textAlign: "end" }}>
              {data?.connection?.status === "not connected" ? (
                <Button
                  type="link"
                  className="mobile-card-body"
                  onClick={() => sendConnectionRequest()}
                  size="large"
                  loading={isLoadingSync}
                  disabled={!user.is_finqle_active || !user.is_payment_active}
                  style={{ color: "#664ff3" }}
                >
                  Connect
                </Button>
              ) : (
                <img
                  src={Comment_Purple}
                  alt="msg"
                  style={{ cursor: "pointer" }}
                  onClick={handleChat}
                />
              )}
              <DeleteOutlined
                className="vacancies-freelancer-detail-card-delete"
                style={{
                  marginRight: "10px",
                  marginLeft: "20px",
                }}
                onClick={() =>
                  disableOpen && removeFreelancerInterestedList(id)
                }
              />
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
}
