import { Row, Col } from "antd";
import { Proposal } from "src/types/work.type";
import moment from "moment";
import useCalculateNetHourlyRate from "src/hooks/useCalculateNetHourlyRate";

type ProjectCard = {
  id: number;
  selectedCard: number;
  setSelectedCard: Function;
  proposalData: Proposal;
  projectTitle: string;
  companyName?: string;
  isHoursRejected?: boolean | null;
};

export default function ProjectItem({
  id,
  selectedCard,
  setSelectedCard,
  proposalData,
  projectTitle,
  companyName = "",
  isHoursRejected,
}: ProjectCard) {
  const { netHourlyRate } = useCalculateNetHourlyRate({
    ratePerHourValue: String(proposalData?.gross_rate),
    paymentTermsValue: String(proposalData?.payment_terms),
  });

  return (
    <>
      <div
        onClick={() => {
          setSelectedCard(id);
        }}
        className="projects-card-list-item"
        style={selectedCard === id ? { borderLeft: "3px solid #35DF90" } : {}}
      >
        <div
          style={{ color: isHoursRejected ? "red" : "inherit" }}
          className="projects-card-header"
        >
          {companyName} {companyName ? " - " : ""}{" "}
          {projectTitle.replaceAll(" - ", " ")}
        </div>
        <Row>
          <Col
            flex="1"
            className="projects-card-detail-header"
            style={{ borderRight: "1px solid #F6F6F6" }}
          >
            <span className="sub-detail-1">Uurtarief</span>
            <br />
            <span className="sub-detail-2">€{netHourlyRate} p/u</span>
          </Col>
          <Col flex="1" className="projects-card-detail-header">
            <span className="sub-detail-1">Datum</span>
            <br />
            <span className="sub-detail-3">
              {moment(proposalData?.start_date).format("DD-MM-yyyy")}
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
}
