import { useState } from "react";
import { Row, Col, Divider, Button, Grid } from "antd";
import ProjectOverview from "./Project-Overview";
import HourlyOverview from "./Hourly-Overview";
import Files from "../Files";
import { Vacancy } from "src/types/work.type";
import { Link } from "react-router-dom";
import moment from "moment";

type ProjectDetailsProps = {
  id: number;
  vacancyData: Vacancy | undefined;
  selectedWeek: moment.Moment;
  onDataUpdated?: () => void;
};

enum SubMenuTypes {
  PROJECT_OVERVIEW,
  HOUR_OVERVIEW,
  FILES,
}

export default function ProjectDetails({
  id,
  vacancyData,
  selectedWeek,
  onDataUpdated,
}: ProjectDetailsProps) {
  const [currentSubMenu, setCurrentSubMenu] = useState<SubMenuTypes>(
    SubMenuTypes.HOUR_OVERVIEW
  );
  const screens = Grid.useBreakpoint();
  const proposalData = vacancyData?.proposals[0];

  return (
    <>
      <div
        className={
          screens.md
            ? "project-details-layout"
            : "project-details-layout-mobile"
        }
      >
        <Row>
          <Col className="project-details-header">
            {vacancyData?.title.replaceAll(" - ", " ")}
          </Col>
          <Divider style={{ backgroundColor: "#002333" }} />
        </Row>
        <Row justify="space-between">
          <Col
            span={screens.md ? 6 : 12}
            style={screens.md ? {} : { marginBlockEnd: "14px" }}
          >
            <Button
              // block
              ghost={currentSubMenu === SubMenuTypes.PROJECT_OVERVIEW && true}
              key="project-overview"
              className="project-details-button-style"
              onClick={() => setCurrentSubMenu(SubMenuTypes.PROJECT_OVERVIEW)}
            >
              Projectoverzicht
            </Button>
          </Col>
          <Col span={screens.md ? 6 : 12}>
            <Button
              block
              ghost={currentSubMenu === SubMenuTypes.HOUR_OVERVIEW && true}
              key="hours-overview"
              className="project-details-button-style"
              onClick={() => setCurrentSubMenu(SubMenuTypes.HOUR_OVERVIEW)}
            >
              Urenoverzicht
            </Button>
          </Col>
          <Col span={screens.md ? 6 : 12}>
            <Button
              block
              ghost={currentSubMenu === SubMenuTypes.FILES && true}
              key="files"
              className="project-details-button-style"
              onClick={() => setCurrentSubMenu(SubMenuTypes.FILES)}
            >
              Bestanden
            </Button>
          </Col>
          {/* <Col flex="auto"> */}
          <Col span={screens.md ? 6 : 12}>
            <Link to={`/connect/chat/${proposalData?.freelancer}`}>
              <Button block className="project-details-button-style">
                Chat
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      {currentSubMenu === SubMenuTypes.PROJECT_OVERVIEW && (
        <ProjectOverview
          key={proposalData?.id}
          proposalData={proposalData}
          onDataUpdated={onDataUpdated}
        />
      )}
      {currentSubMenu === SubMenuTypes.HOUR_OVERVIEW && (
        <HourlyOverview
          key={proposalData?.id}
          proposalData={proposalData}
          selectedWeek={selectedWeek}
        />
      )}
      {currentSubMenu === SubMenuTypes.FILES && (
        <div
          className={
            screens.md
              ? "project-details-inner"
              : "project-details-inner-mobile"
          }
        >
          <Files proposalId={proposalData?.id ? proposalData?.id : 0} />
        </div>
      )}
    </>
  );
}
