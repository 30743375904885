import { Row, Col, Grid, Switch, Button } from "antd";
import { Proposal } from "src/types/work.type";
import moment from "moment";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useEffect, useState } from "react";
import { formatDate } from "src/utils/date";
import { Link } from "react-router-dom";
import useCalculateNetHourlyRate from "src/hooks/useCalculateNetHourlyRate";

type ProjectOverviewProps = {
  proposalData: Proposal | undefined;
  onDataUpdated?: () => void;
};

type API_Reponse = {
  count: 123;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      hours: string;
      date: string;
      description: string;
      kilometers: string;
      expenses: string;
      is_submitted: true;
      proposal: number;
      invoice: 0;
    }
  ];
};

export default function ProjectOverview({
  proposalData,
  onDataUpdated,
}: ProjectOverviewProps) {
  const screens = Grid.useBreakpoint();
  // const rowStyle = { borderTop: '3px solid #F9F9FC' };
  const MultipleQueries = () => {
    const TotalHours = useQuery<API_Reponse>(
      `Total_Hours_${proposalData?.id}`,
      async () => {
        return (
          await axios.get(
            `${BASE_URL}api/hours/?proposal=${proposalData?.id}&limit=250`,
            getHttpHeader()
          )
        ).data;
      }
    );

    const WeeklyHours = useQuery<API_Reponse>("HourlyList", async () => {
      return (
        await axios.get(
          `${BASE_URL}api/hours/?proposal=${
            proposalData?.id
          }&week=${moment().isoWeek()}&limit=100`,
          getHttpHeader()
        )
      ).data;
    });

    return { TotalHours, WeeklyHours };
  };

  const { netHourlyRate } = useCalculateNetHourlyRate({
    ratePerHourValue: String(proposalData?.gross_rate),
    paymentTermsValue: String(proposalData?.payment_terms),
  });

  const totalHoursForProposal = MultipleQueries().TotalHours.data;
  const weeklyHours = MultipleQueries().WeeklyHours.data;
  const [totalWeekHours, setTotalWeekHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    let weeklyTotalHours = 0;
    weeklyHours?.results?.forEach((e) => {
      weeklyTotalHours += Number(e.hours);
    });
    setTotalWeekHours(weeklyTotalHours);
  }, [weeklyHours]);

  useEffect(() => {
    let totalHours = 0;
    totalHoursForProposal?.results?.forEach((e) => {
      totalHours += Number(e.hours);
    });
    setTotalHours(totalHours);
  }, [totalHoursForProposal]);

  const endContinousProject = () => {
    axios
      .patch(
        `${BASE_URL}api/proposals/${proposalData?.id}/`,
        {
          end_date: moment().format("yyyy-DD-MM"),
        },
        getHttpHeader()
      )
      .then((res) => {
        if (onDataUpdated) {
          onDataUpdated();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Row>
        <Col
          span={screens.md ? 12 : 24}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Projectnummer</div>
          <div className="projects-overview-detail">@{proposalData?.id}</div>
        </Col>
        <Col
          span={screens.md ? 12 : 24}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Looptijd</div>
          <span className="projects-overview-detail">
            {proposalData?.end_date ? (
              <Row justify="space-between" align="middle">
                <Col>
                  {formatDate(proposalData?.start_date)}
                  <span style={{ fontWeight: "normal" }}> tot </span>
                  {formatDate(proposalData?.end_date)}
                </Col>
                {Math.sign(
                  moment
                    .duration(moment(new Date()).diff(proposalData?.end_date))
                    .days()
                ) !== -1 && (
                  <Col>
                    <Link to={`/connect/chat/${proposalData?.freelancer}`}>
                      <Button block className="project-details-button-style">
                        Verlengen
                      </Button>
                    </Link>
                  </Col>
                )}
              </Row>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>Doorlopend</div>
                <div style={{ marginLeft: "0.4rem" }}>
                  <Switch defaultChecked onChange={endContinousProject} />
                </div>
              </div>
            )}
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          span={screens.md ? 12 : 24}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Netto uurtarief</div>
          <div className="projects-overview-detail">
            €{Number(netHourlyRate)} p/u
          </div>
        </Col>
        <Col
          span={screens.md ? 12 : 24}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Reiskosten</div>
          <div className="projects-overview-detail">
            €{Number(proposalData?.travel_allowances)} p/km
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile projects-overview-column-mobile-col"
          }
        >
          <div className="projects-overview-header">Totale omzet</div>
          {/* <span className="project-details-highlighted"> €{Number(proposalData?.weekly_net_rate)},-</span> */}
          <span className="project-details-highlighted">
            {" "}
            €{(totalHours * Number(proposalData?.net_rate)).toFixed(2)},-
          </span>
        </Col>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Totale uren</div>
          <span className="sub-detail-2">
            {/* {moment().diff(moment(proposalData?.start_date), 'hours')} */}
            {totalHours} uur
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile projects-overview-column-mobile-col"
          }
        >
          <div className="projects-overview-header">Omzet deze week</div>
          {/* <span className="project-details-highlighted"> €{Number(proposalData?.gross_rate)},-</span> */}
          <span className="project-details-highlighted">
            €{(totalWeekHours * Number(proposalData?.net_rate)).toFixed(2)},-
          </span>
        </Col>
        <Col
          span={12}
          className={
            screens.md
              ? "projects-overview-column"
              : "projects-overview-column-mobile"
          }
        >
          <div className="projects-overview-header">Uren deze week</div>
          <span className="sub-detail-2">
            {/* {moment(moment().startOf('isoWeek')).diff(moment(proposalData?.start_date), 'hours')} */}
            {totalWeekHours} uur
          </span>
        </Col>
      </Row>
    </div>
  );
}
