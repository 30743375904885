import { Row, Col, Modal, Button } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Attachment } from "src/types/common.type";
import JobProposalView from "../../../components/proposal/proposal-nonedit";
import { useUser } from "src/hooks/useUser";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Attachment[];
};

type FilesProps = {
  proposalId: number | undefined;
};

const DocumentIcon = () => {
  return (
    <svg
      width="40"
      height="52"
      viewBox="0 0 40 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4051 13.3465L27.216 1.15742C26.8762 0.817578 26.4174 0.625 25.9359 0.625H1.875C0.872461 0.625 0.0625 1.43496 0.0625 2.4375V49.5625C0.0625 50.565 0.872461 51.375 1.875 51.375H38.125C39.1275 51.375 39.9375 50.565 39.9375 49.5625V14.6322C39.9375 14.1508 39.7449 13.6863 39.4051 13.3465ZM35.7574 15.4648H25.0977V4.80508L35.7574 15.4648ZM35.8594 47.2969H4.14062V4.70312H21.2461V16.9375C21.2461 17.5684 21.4967 18.1735 21.9429 18.6196C22.389 19.0658 22.9941 19.3164 23.625 19.3164H35.8594V47.2969ZM19.5469 32.0039H9.125C8.87578 32.0039 8.67188 32.2078 8.67188 32.457V35.1758C8.67188 35.425 8.87578 35.6289 9.125 35.6289H19.5469C19.7961 35.6289 20 35.425 20 35.1758V32.457C20 32.2078 19.7961 32.0039 19.5469 32.0039ZM8.67188 24.7539V27.4727C8.67188 27.7219 8.87578 27.9258 9.125 27.9258H30.875C31.1242 27.9258 31.3281 27.7219 31.3281 27.4727V24.7539C31.3281 24.5047 31.1242 24.3008 30.875 24.3008H9.125C8.87578 24.3008 8.67188 24.5047 8.67188 24.7539Z"
        fill="#A5A6A7"
      />
    </svg>
  );
};

export default function Files({ proposalId }: FilesProps) {
  const { Dragger } = Upload;
  const [modalVisible, setModalVisible] = useState(false);
  const { user } = useUser();

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const fetchFiles = async () => {
    return (
      await axios.get(
        `${BASE_URL}api/attachments/?limit=100&owner=${user.id}&proposal=${proposalId}`,
        getHttpHeader()
      )
    ).data;
  };

  const [fileList, setFileList] = useState<any[]>([]);
  const { data, refetch } = useQuery<Response>(
    ["attachments", proposalId],
    fetchFiles,
    { enabled: !!proposalId }
  );
  const [deleteUid, setDeleteUid] = useState();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [proposalId]);

  useEffect(() => {
    let tempList: any[] = [];
    data?.results?.forEach((e) => {
      tempList.push({
        uid: e.id,
        name: e.description,
        status: "done",
        url: e.file,
        thumbUrl: e.file,
      });
    });
    setFileList(tempList);
  }, [data]);

  const props = {
    name: "file",
    multiple: false,
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [proposalId]);

  const handleRemove = (value: any) => {
    setDeleteUid(value.uid);
    setModalVisible(true);
  };

  const onChange = (info: any) => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "uploading") {
      setFileList([...fileList, info.file]);
    }
    if (status === "done") {
      message.success(`${info.file.name} is geupload.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const [proposalModalVisible, setproposalModalVisible] = useState(false);

  return (
    <>
      <JobProposalView
        visible={proposalModalVisible}
        setVisible={setproposalModalVisible}
        proposalId={proposalId}
      />
      <Modal
        closable={false}
        title={
          <div>
            <Row>
              <Col
                style={{
                  fontFamily: "Arimo",
                  fontWeight: 700,
                  fontSize: "16px",
                  padding: "25px",
                  textAlign: "center",
                }}
              >
                Weet u zeker dat u het contract wilt verwijderen?
              </Col>
            </Row>
          </div>
        }
        centered
        visible={modalVisible}
        footer={null}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Row>
          <Col span={12}>
            <Button
              size="large"
              onClick={() => setModalVisible(false)}
              style={{
                width: "100%",
                fontFamily: "Arimo",
                fontWeight: 700,
                fontSize: "16px",
                backgroundColor: "#002333",
                color: "white",
                border: "0px",
              }}
            >
              Annuleren
            </Button>
          </Col>
          <Col span={12}>
            <Button
              size="large"
              onClick={async () => {
                await axios
                  .delete(`${BASE_URL}api/attachments/${deleteUid}`, config)
                  .then((res) => {
                    message.success("Bestand is verwijdered");
                    refetch();
                    setModalVisible(false);
                    setDeleteUid(undefined);
                  })
                  .catch((err) => {
                    message.error(
                      "Er is iets fout gegaan. Probeer het opnieuw."
                    );
                  });
              }}
              style={{
                width: "100%",
                fontFamily: "Arimo",
                fontWeight: 700,
                fontSize: "16px",
                color: "#002333",
              }}
            >
              Verwijderen
            </Button>
          </Col>
        </Row>
      </Modal>
      <div>
        <Row style={{ marginBlockStart: 24 }}>
          <Col>
            <Button
              style={{
                height: 120,
                fontWeight: 600,
                fontSize: 12,
                padding: 10,
                color: "#002333",
              }}
              onClick={() => setproposalModalVisible(true)}
            >
              <div>
                <DocumentIcon /> <br />
                Job overeenkomst
              </div>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="files-header">
            *Vergeet niet uw samenwerkingsovereenkomst te uploaden.
          </Col>
        </Row>
        <Dragger
          {...props}
          fileList={[...fileList]}
          // defaultFileList={[...fileList]}
          listType="picture-card"
          className="upload-list-inline"
          style={{
            // backgroundColor: 'red',
            marginBlockEnd: "20px",
            border: "2px dashed rgba(80, 93, 250, 0.5)",
            boxSizing: "border-box",
            borderRadius: "10px",
          }}
          onRemove={handleRemove}
          onChange={onChange}
          // customRequest={sendRequest}
          headers={{
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          }}
          customRequest={async ({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials,
          }) => {
            const formdata = new FormData();
            formdata.append("file", file);
            formdata.append("proposal", `${proposalId}`);
            formdata.append("description", `${file.name}.${file.type}`);
            formdata.append("owner", String(user.id));

            axios({
              method: "post",
              url: `${BASE_URL}api/attachments/`,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
              },
              data: formdata,
              onUploadProgress: ({ total, loaded }) => {
                //@ts-ignore
                onProgress(
                  {
                    percent: Number(
                      Math.round((loaded / total) * 100).toFixed(2)
                    ),
                  },
                  file
                );
              },
            })
              .then((response: any) => {
                //@ts-ignore
                onSuccess(response, file);
                refetch();
              })
              .catch((error: any) => {
                //@ts-ignore
                onError(error, file);
              });
          }}
        >
          <div className="upload-box-text">
            <UploadOutlined className="upload-icon" />
            Sleep een bestand in dit vak of{" "}
            <span style={{ color: "#664FF3" }}>klik hier</span>{" "}
          </div>
        </Dragger>
      </div>
    </>
  );
}
