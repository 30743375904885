import './styles.css'
import {
  Row,
  Col,
  Grid,
  message
} from 'antd';
import {
  DownloadOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { UserTypes } from 'src/types/user.type';
import Euro from 'src/icons/euro.svg';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Proposal } from 'src/types/work.type';
import { useUser } from 'src/hooks/useUser';

type InvoiceItemProps = {
  proposalId: number
  week: string,
  invoiceId: number
}

export interface Invoice {
  period: string,
  start_date: string,
  expire_data: string,
  total_amount: number,
  link: string,
  isReceived: boolean
}

export default function InvoiceItem({ proposalId, week, invoiceId }: InvoiceItemProps) {

  const screens = Grid.useBreakpoint();
  const { user } = useUser()
  const BASE_URL = process.env.REACT_APP_BACKEND;

  const config = {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem('token')
    }
  }

  const fetchProposalData = async () => {
    return (await axios.get(`${BASE_URL}api/proposals/${proposalId}/`, config)).data;
  }

  const { data } = useQuery<Proposal>(`proposalData${proposalId}`, fetchProposalData)

  const downloadFile = async () => {
    await axios.get(`${BASE_URL}api/invoices/${invoiceId}/get_pdf/`, config)
      .then(res => {
        console.log(res.data)
        window.location.href = res.data.pdf;
      }).catch(err => {
        console.log(err)
        if ("Try again in 30 seconds." === err.response.data.message) {
          message.info('PDF is generating. Please try again in few seconds.')
        } else {
          message.info(JSON.stringify(err.response.data.message).replaceAll(`"`, " "))
        }
      })
  }

  return (
    <Row className='invoice-record-row'>
      <Col span={screens.md ? 8 : 4} className="invoice-record-item">
        {/* Week {moment(data?.start_date).isoWeek()} */}
        {`Week ${week}`}
      </Col>
      <Col span={screens.md ? 3 : 4} className="invoice-record-item data-center">
        {data?.start_date}
      </Col>
      <Col span={screens.md ? 3 : 4} className="invoice-record-item data-center">
        {data?.end_date}
      </Col>
      <Col span={screens.md ? 3 : 4} className="invoice-record-item data-center">
        € {data?.gross_rate}
      </Col>
      <Col span={4} className="invoice-record-item icon-style">
        <DownloadOutlined style={{ cursor: 'pointer', fontSize: '18px' }} onClick={downloadFile} />
      </Col>
      <Col span={screens.md ? 3 : 4} className="invoice-record-item icon-style" style={{ color: 'red' }}>
        {UserTypes.Freelancer === user.user_type ?
          <CloseOutlined /> : <img src={Euro} alt='' />}
      </Col>
    </Row>
  );
}