/**
 *
 * slice a text upto certain number of words
 * and attach an ellipsis at the end
 *
 * @param text
 * @param count
 * @returns string
 */
export function renderText(text: string | undefined | null, count = 5) {
  if (!text) {
    return "";
  }

  const splitText = text.split(" ");

  if (splitText.length <= count) {
    return text;
  }

  return `${splitText.slice(0, count).join(" ")}...`;
}
