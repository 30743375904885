import { NotificationContent, NotificationType } from 'src/types/Notification.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const testNotifications: NotificationContent[] = [
  {
    id: 1,
    date_created: 12333,
    date_read: null,
    date_hidden: null,
    type: NotificationType.ConnectionAccepted,
    from: 1,
    content: {
      message: "SemanticBits heeft een vacature geplaatst."
    }
  },
  {
    id: 2,
    date_created: 12333,
    date_read: null,
    date_hidden: 123,
    type: NotificationType.ConnectionAccepted,
    from: 1,
    content: {
      message: "Asad dawxw ffrfseen vacature geplaatst."
    }
  }
]

export type state = {
  noOfNewNotifications: number;
  notifications: NotificationContent[];
}

export type markReadNotification = {
  command: "update",
  id: number | null,
  data:
  {
    date_read: number,
    date_hidden: number,
  },
}

export const initialState: state = {
  noOfNewNotifications: testNotifications.length,
  notifications: []
}

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotifications(state, action: PayloadAction<NotificationContent[]>) {
      // console.log(state.notifications);
      // console.log(action.payload);
      // let newData = [...state.notifications, action.payload];
      // action.payload.forEach(value => {
      //   state.notifications.push(value);
      // })
      // return { ...state, notifications: [...state.notifications.concat(action.payload)] }
      return { ...state, notifications: action.payload }
    },
    // markNotificationReadStore: (state, action: PayloadAction<NotificationContent>) => {

    // const notificationToChange = state.notifications.find(notification => notification.id === action.payload.id);

    // console.log(`Change ${JSON.stringify(notificationToChange)}`);

    // if (notificationToChange !== undefined) {
    //   notificationToChange.type = action.payload.type
    // }
    // },
    // markAllNotificationsRead: (state) => {
    //   for (let i = 0; i < (state.notifications && state.notifications.length); i++) {
    //     state.notifications[i].date_read = new Date().getDate();
    //   }
    // },
    fetchNotification(state, action: PayloadAction<NotificationContent>) {
      // console.log(action.payload)
      // state.notifications.find(notification =>
      //   notification.id === action.payload.id ? action.payload : notification);

      // Mutable Method - Mutating the state -> which is allowed in the redux toolkit
      // let notificationToChange = state.notifications.find(notification => notification.id === action.payload.id);

      // if (notificationToChange !== undefined) {
      //   notificationToChange = action.payload
      // }
      // return { ...state }

      // Immutable Method
      return {
        ...state, notifications: state.notifications.map(notification => {
          if (notification.id === action.payload.id) {
            return { ...notification, ...action.payload }
          } else {
            return notification
          }
        })
      }
    },
    addNewNotification(state, action: PayloadAction<NotificationContent>) {
      return { ...state, notifications: [...state.notifications.concat(action.payload)] }
    },
  }
})

export const {
  fetchNotifications,
  // markNotificationReadStore,
  // markAllNotificationsRead,
  fetchNotification,
  addNewNotification,
} = notifications.actions;

export default notifications.reducer;