import React, { useState } from "react";
import { Link } from "react-router-dom";
import GmailLogo from "src/gmail-icon.svg";
import FacebookLogo from "src/facebook-logo.svg";
import CompanyLogo from "src/company-logo.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Settings/config";
import "./index.css";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import "src/index.css";
import {
  Modal,
  Row,
  Col,
  Button,
  Divider,
  Input,
  Form,
  Grid,
  message,
  Checkbox,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { FacebookProvider, Login as FbLogin } from "react-facebook";
import { JWTDecodedUser } from "src/types/user.type";
import jwtDecode from "jwt-decode";
import { useQueryParams } from "../../hooks/useQueryParams";
import AuthLayout from "../../components/Common/AuthLayout";

const inputStyle = {
  height: "42px",
};

export function Register() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [visible] = useState(true);
  const history = useHistory();
  const postUrl = API_URL + "account/register/";
  const [form] = useForm();
  const [agreement, setAgreement] = useState<boolean>(false);
  const queryParams = useQueryParams();

  const errorMessage = (content: string) => {
    message.error(content);
  };

  const doSocialLogin = (socialName: string, accessToken: string) => {
    axios
      .post(API_URL + `account/login/${socialName}/`, {
        access_token: accessToken,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("loginType", socialName);

        if (res.data.user?.id) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          history.push("/dashboard");
        } else {
          const decodedData: JWTDecodedUser = jwtDecode(String(res.data.token));
          localStorage.setItem("user", JSON.stringify(decodedData));
          localStorage.setItem("username", decodedData.username);
          history.push("/register/selectprofile");
        }
      })
      .catch((err) => {
        console.log("Error::: ", err?.response);
        if (
          err?.response?.data?.non_field_errors?.[0] ===
          "User is already registered with this e-mail address."
        ) {
          message.error("Gebruiker is al geregistreerd met dit e-mailadres.");
        } else errorMessage(err.response?.data?.details);
      });
  };

  const handleSuccessGoogleLogin = (
    data: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("accessToken" in data) {
      doSocialLogin("google", data.accessToken);
    }
  };

  const handleFailedGoogleLogin = (error: any) => {
    if (error?.error !== "idpiframe_initialization_failed")
      message.error("Inloggen bij Google mislukt. Probeer het opnieuw.");
  };

  const handleSuccessFacebookLogin = (data: {
    tokenDetail: { accessToken: string };
  }) => {
    const { accessToken } = data.tokenDetail;
    doSocialLogin("facebook", accessToken);
  };

  const handleFailedFacebookLogin = (error: any) => {
    console.log(error);
    message.error("Inloggen bij Facebook mislukt. Probeer het opnieuw.");
  };

  const onFinish = (values: any) => {
    values.email = String(values.email).toLowerCase();
    const referred_by = queryParams.get("referred_by");

    const formData = {
      ...values,
    };

    if (referred_by) {
      formData.referred_by = referred_by;
    }

    try {
      axios
        .post(postUrl, formData)
        .then((res) => {
          console.log(res);
          let token = res["data"]["token"];
          let user = res["data"]["user"];
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem(
            "username",
            values["first_name"] + " " + values["last_name"]
          );
          history.push("/register/selectprofile");
        })
        .catch(function (error) {
          if (error.response) {
            // form.getFieldInstance;
            console.log("Error msg", error.response.data);

            message.error("Dit emailadres kan niet worden gebruikt.");

            if (error.response.data?.details?.type === "user_exists") {
              form.setFields([
                {
                  name: "email",
                  errors: ["Gebruiker bestaat al."],
                },
              ]);
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={visible}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          <Row className="login-row">
            <Col
              xl={10}
              lg={10}
              md={0}
              sm={0}
              xs={0}
              className="login-design__column"
            >
              <div className="login-design__start">
                <div>
                  <img src={CompanyLogo} alt="Company Logo"></img>
                  <div className="login-header">
                    Meld u gratis <br />
                    aan bij job.nl
                  </div>
                  <Divider className="login-devider" plain></Divider>
                  <div className="login-text">Log in om verder te gaan!</div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={24}
              sm={24}
              xs={24}
              className="login-form__column"
            >
              <Row justify="space-between">
                <Col xl={0} lg={0} md={0} sm={24} xs={24}>
                  <Row
                    justify="space-between"
                    style={{ backgroundColor: "#f6f6f6" }}
                  >
                    <Col
                      style={{
                        paddingTop: "1rem",
                        paddingLeft: "1.5rem",
                      }}
                    >
                      <img
                        className="logo"
                        src={CompanyLogo}
                        alt="Company Logo"
                      ></img>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="loginMain" style={{ padding: "20px 12%" }}>
                <div className="mainForm__div" style={{ marginTop: "20%" }}>
                  {screens.xs && (
                    <div className="mobile-header-sub">
                      Welkom terug bij job.nl
                    </div>
                  )}
                  <div className="buttonDiv">
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
                      render={(renderProps) => (
                        <Button
                          block
                          className="auth-button"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <img
                            src={GmailLogo}
                            style={{
                              width: "23px",
                              float: "left",
                              verticalAlign: "center",
                            }}
                            alt="Gmail-logo"
                          />
                          <span
                            style={{ fontFamily: "Arimo", fontSize: "16px" }}
                          >
                            Aanmelden met Google
                          </span>
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={handleSuccessGoogleLogin}
                      onFailure={handleFailedGoogleLogin}
                      cookiePolicy={"single_host_origin"}
                      scope="email"
                    />
                    <FacebookProvider
                      appId={process.env.REACT_APP_FB_CLIENT_ID || ""}
                    >
                      <FbLogin
                        scope="email"
                        onCompleted={handleSuccessFacebookLogin}
                        onError={handleFailedFacebookLogin}
                      >
                        {({
                          loading,
                          handleClick,
                        }: {
                          loading: any;
                          handleClick: any;
                        }) => (
                          <Button
                            block
                            className="auth-button"
                            onClick={handleClick}
                            disabled={loading}
                          >
                            <img
                              src={FacebookLogo}
                              style={{ float: "left", verticalAlign: "center" }}
                              alt="Facebook-logo"
                            />
                            <span
                              style={{ fontFamily: "Arimo", fontSize: "16px" }}
                            >
                              Aanmelden met Facebook
                            </span>
                          </Button>
                        )}
                      </FbLogin>
                    </FacebookProvider>
                  </div>
                  <Divider
                    plain
                    style={{
                      fontWeight: 900,
                      borderTopColor: "rgba(0, 0, 0, 1)",
                      maxWidth: "341px",
                      minWidth: "100px",
                    }}
                  >
                    Of
                  </Divider>
                  <div className="formDiv">
                    <Form
                      form={form}
                      name="regiter-form"
                      className="register-form"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      style={{ width: "100%" }}
                    >
                      {/* <Form.Item className="inline-group__main">
                        <Input.Group compact style={{ maxHeight: "45px" }}> */}
                      <Row>
                        <Col
                          span={screens.md ? 12 : 24}
                          style={{
                            maxHeight: "60px",
                          }}
                        >
                          <Form.Item
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: "Vul uw voornaam in",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Voornaam"
                              className="inline-input"
                              style={screens.md ? inputStyle : {}}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={screens.md ? 12 : 24}
                          style={{
                            maxHeight: "60px",
                          }}
                        >
                          <Form.Item
                            name="last_name"
                            className="last-name-class"
                            rules={[
                              {
                                required: true,
                                message: "Vul uw achternaam in",
                              },
                            ]}
                            style={
                              screens.md ? {} : { width: "100%", marginLeft: 0 }
                            }
                          >
                            <Input
                              placeholder="Achternaam"
                              className="inline-input"
                              style={
                                screens.md ? inputStyle : { width: "100%" }
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* </Input.Group>
                      </Form.Item> */}
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Vul een emailadres in!",
                          },
                          {
                            type: "email",
                            message: "Email adres wordt niet herkend",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Emailadres"
                          className="normal-input"
                          style={inputStyle}
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Vul een wachtwoord in!",
                          },
                          {
                            min: 8,
                            message:
                              "Wachtwoord moet minimaal 8 tekens lang zijn",
                          },
                          {
                            pattern: new RegExp(
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                            ),
                            message: "Wachtwoord voldoet niet aan de eisen",
                          },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          placeholder="Kies een wachtwoord"
                          // className="normal-input"
                          // style={inputStyle}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                      <div className="regiter-terms">
                        Uw wachtwoord moet tenminste 8 tekens bevatten waarvan
                        een hoofdletter, kleine letter, cijfer en speciaal teken
                      </div>
                      <Form.Item name="password">
                        <Checkbox
                          value={agreement}
                          onChange={() => {
                            setAgreement(!agreement);
                          }}
                          style={{
                            fontFamily: "Basier Square",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Ik heb de{" "}
                          <a
                            href="/docs/algemene-voorwaarden.pdf"
                            download="Algemene voorwaarden.pdf"
                          >
                            algemene voorwaarden
                          </a>{" "}
                          van de site gelezen en ga hiermee akkoord
                        </Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          className="login-primary-button"
                          disabled={!agreement}
                        >
                          Registreren
                        </Button>
                      </Form.Item>
                    </Form>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "30px",
                        paddingTop: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Basier Square",
                          fontWeight: 400,
                        }}
                      >
                        Heeft u al een account?{" "}
                      </span>
                      {screens.xs && <div />}
                      <Link
                        to="/login"
                        style={{
                          color: "#664FF3",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                      >
                        Inloggen
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </AuthLayout>
  );
}
