import { PermissionType } from "src/types/Permission";
import { LoginUser } from "src/types/user.type";

const emptyObject = {};

interface ICanProps {
  user: LoginUser;
  can?: PermissionType;
  cans?: Array<PermissionType>;
  logic?: "or" | "and";
}

export function checkPermission({ user, can, cans, logic = "or" }: ICanProps) {
  const roles = user.permissions.map((e) => e.permission) || emptyObject;

  console.log("roles with hooks", roles, can, cans);

  if (can && roles.includes(can)) {
    return true;
  }

  if (cans && cans.length) {
    if (logic === "and") {
      if (cans.every((role) => roles.includes(role))) {
        return true;
      }
    } else if (logic === "or") {
      if (cans.some((role) => roles.includes(role))) {
        return true;
      }
    }
  }
  return null;
}
