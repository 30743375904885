import { Sector, ChildUser, Region, Position, Specialty } from "./user.type";

export enum ConnectionStatusValues {
  CONNECTED = "connected",
  PENDING = "pending",
  NOT_CONNECTED = "not connected",
  REJECTED = "rejected",
}

export interface ConnectionStatus {
  id: number;
  status: string;
}

export interface CompanySearch {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  user_type: string;
  kvk_number: string;
  sector: Sector;
  child_users: ChildUser[];
  open_project_count: number;
  finished_project_count: number;
  region: Region;
  profile_pic_url: string | null;
  connection: ConnectionStatus;
  website: string | undefined;
  project_count: number;
}

export interface FreelancerSearch {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  user_type: string | null;
  sector: Sector;
  region: Region;
  position: Position;
  experience: string | null;
  max_rate: number | null;
  min_rate: number | null;
  profile_pic_url: string | null;
  connection: ConnectionStatus;
  company_name: string;
  is_email_validated: boolean;
  is_finqle_active: boolean;
  is_payment_active: boolean;
  kvk_number: string;
  phone_number: string;
  profile_pic: string;
  specialty: Specialty[];
  verified_hours: boolean;
  description: null;
  finished_project_count: number;
  project_count: number;
}

export interface Attachment {
  id: number;
  file: string;
  proposal: number;
  description: string;
  owner: number;
}
