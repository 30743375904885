import {
  Row,
  Col,
  Form,
  Grid,
  Input,
  Button,
  InputNumber,
  message,
  DatePicker,
} from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import SectorSelect from "../../../components/common-inputs/Sector-Select";
import RegionSelect from "../../../components/common-inputs/Region-Select";
import PositionSelect from "../../../components/common-inputs/Position-Select";
import SpecialySelect from "../../../components/common-inputs/Speciality-Select";
import { useQuery } from "react-query";

type AddVecancyProps = {
  setAdding: Function;
};

const dateFormat = "DD-MM-YYYY";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
    }
  ];
};

const formGroupInputStyleLeftColumn = {
  display: "inline-block",
  padding: "20px 60px",
  marginBottom: "0px",
  minWidth: "80%",
};

const formGroupInputStyleRightColumn = {
  display: "inline-block",
  margin: "0 40px 0 0 ",
  padding: "20px 40px",
  minWidth: "80%",
};

const RightSideBorder = { borderRight: "3px solid #f6f6f6" };

export default function AddVecancyForm({ setAdding }: AddVecancyProps) {
  const screens = Grid.useBreakpoint();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [selectedSector, setSelectedSector] = useState<number | undefined>();

  useEffect(() => {
    form.setFields([
      {
        name: "position",
        value: undefined,
      },
    ]);
    // eslint-disable-next-line
  }, [selectedSector]);

  const BASE_URL = process.env.REACT_APP_BACKEND;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const { data } = useQuery<Response>(
    `positions_${selectedSector}`,
    async () => {
      return (
        await axios.get(
          BASE_URL +
            `api/position?sector=${
              selectedSector ? selectedSector : ""
            }&limit=400`
        )
      ).data;
    }
  );

  const getPositionName = (id: number) => {
    const temp = data?.results?.find((e) => e.id === id);
    console.log("Temp", temp);
    return temp?.name;
  };

  const formSingleInputStyle = {};

  const onFinish = (value: any) => {
    if (value.maximum_rate <= value.minimum_rate) {
      message.error("Maximum tarief kan niet lager zijn dan minimum tarief.");
    } else if (
      moment(value.start_date, true).isAfter(moment(value.end_date, true))
    ) {
      message.error("De einddatum kan niet voor de startdatum liggen.");
    } else {
      const newData = {
        position: value.position,
        position_extra: value.position_extra,
        start_date: moment(value.start_date, true).format("YYYY-MM-DD"),
        end_date: moment(value.end_date, true).format("YYYY-MM-DD"),
        minimum_rate: value.minimum_rate,
        maximum_rate: value.maximum_rate,
        hours_per_week: value.hours_per_week,
        description: value.description,
        sector: value.sector,
        region: value.region,
        specialty: value.specialty,
        finished: false,
        title: value.title ? value.title : getPositionName(value.position),
      };

      // console.log(newData)

      axios({
        method: "POST",
        url: `${BASE_URL}api/vacancies/`,
        data: newData,
        headers: config.headers,
      })
        .then((res) => {
          setAdding(false);
          message.success("Vacature toegevoegd");
        })
        .catch((err) => {
          console.log(err);
          message.error(
            err?.response?.data?.detail === "User is not yet onboarded"
              ? `Uw account is nog niet geverifieerd`
              : "Probeer het opnieuw"
          );
        });
    }
  };

  const [selectedDate, setSeletedDate] = useState<any | undefined>();

  return (
    <Row justify="center" style={{ backgroundColor: "white" }}>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form
          form={form}
          name="add-vacancy-form"
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          style={{ width: "100%" }}
        >
          <div
            style={
              screens.md
                ? { minHeight: "60vh" }
                : { marginBlockStart: "35px", backgroundColor: "white" }
            }
          >
            <Row
              style={
                screens.md
                  ? { borderBottom: "3px solid #f6f6f6", width: "100%" }
                  : {}
              }
              justify="center"
            >
              <Col
                span={screens.md ? 12 : 24}
                style={screens.md ? RightSideBorder : {}}
              >
                <Form.Item
                  label={<span className="form-label">Sector</span>}
                  name="sector"
                  rules={[{ required: true, message: "Sector is verplicht" }]}
                  style={
                    screens.md
                      ? formGroupInputStyleLeftColumn
                      : formSingleInputStyle
                  }
                  extra={
                    <span className="form-label">
                      Staat uw sector er niet bij, geef dit door aan info@job.nl
                    </span>
                  }
                >
                  <SectorSelect
                    placeholder="Sector"
                    size="large"
                    onChange={(value: number) => setSelectedSector(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  name="title"
                  style={
                    screens.md
                      ? formGroupInputStyleRightColumn
                      : formSingleInputStyle
                  }
                >
                  <Form.Item
                    label={<span className="form-label">Vacature naam</span>}
                    name="position_extra"
                  >
                    <Input
                      placeholder="Geef de vacature een naam"
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}}
              justify="center"
            >
              <Col
                span={screens.md ? 12 : 24}
                style={screens.md ? RightSideBorder : {}}
              >
                <Form.Item
                  label={<span className="form-label">Functie</span>}
                  name="position"
                  rules={[{ required: true, message: "Functie is verplicht" }]}
                  style={
                    screens.md === true
                      ? formGroupInputStyleLeftColumn
                      : formSingleInputStyle
                  }
                  extra={
                    <span className="form-label">
                      Staat uw functie er niet bij, geef dit door aan
                      info@job.nl
                    </span>
                  }
                >
                  <PositionSelect
                    placeholder="Functie"
                    size="large"
                    sectorId={selectedSector}
                  />
                </Form.Item>
              </Col>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label={<span className="form-label">Werklast</span>}
                  style={
                    screens.md
                      ? {
                          display: "inline-block",
                          margin: "0 40px 0 0 ",
                          padding: "20px 40px",
                        }
                      : {
                          display: "inline-block",
                          paddingTop: "16px",
                        }
                  }
                >
                  <Form.Item
                    name="hours_per_week"
                    rules={[
                      {
                        required: true,
                        message: "Werklast is verplicht",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(95px - 16px)",
                    }}
                  >
                    <InputNumber placeholder="Werklast" size="large" min={0} />
                  </Form.Item>
                  <span
                    style={
                      screens.md
                        ? {
                            marginLeft: "14px",
                            marginTop: "10px",
                            display: "inline-flex",
                          }
                        : {
                            display: "inline-flex",
                            marginLeft: "20px",
                            paddingTop: "10px",
                          }
                    }
                  >{` uur per week`}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}}
              justify="center"
            >
              <Col
                span={screens.md ? 12 : 24}
                style={screens.md ? RightSideBorder : {}}
              >
                <Form.Item
                  name="specialty"
                  label={<span className="form-label">Specialisme</span>}
                  rules={[
                    {
                      required: false,
                      message: "Specialisme is verplicht",
                      type: "array",
                    },
                  ]}
                  style={
                    screens.md === true
                      ? formGroupInputStyleLeftColumn
                      : formSingleInputStyle
                  }
                  extra={
                    <span className="form-label">
                      Staat uw specialisme er niet bij, geef dit door aan
                      info@job.nl
                    </span>
                  }
                >
                  <SpecialySelect
                    placeholder="Specialisme"
                    mode="multiple"
                    size="large"
                    filterOption={(inputValue, option) => {
                      return String(option?.children)
                        .toLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label={<span className="form-label">Vergoeding</span>}
                  style={
                    screens.md
                      ? {
                          display: "inline-block",
                          margin: "0 40px 0 0 ",
                          padding: "20px 40px",
                        }
                      : {
                          display: "inline-block",
                          paddingTop: "16px",
                        }
                  }
                >
                  <Form.Item
                    name="minimum_rate"
                    rules={[
                      {
                        required: true,
                        message: "Minimaal tarief is verplicht",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(95px - 0px)",
                    }}
                  >
                    <InputNumber placeholder="Min" size="large" min={0} />
                  </Form.Item>
                  <span style={{ fontSize: "24px" }}>-</span>
                  <Form.Item
                    name="maximum_rate"
                    rules={[
                      {
                        required: true,
                        message: "Maximaal tarief is verplicht",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(95px - 16px)",
                      margin: "0 8px",
                    }}
                  >
                    <InputNumber placeholder="Max" size="large" min={0} />
                  </Form.Item>
                  <span
                    style={
                      screens.md
                        ? {}
                        : { display: "inline-flex", marginLeft: "10px" }
                    }
                  >{` Euro per uur`}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}}
              justify="center"
            >
              <Col
                span={screens.md ? 12 : 24}
                style={screens.md ? RightSideBorder : {}}
              >
                <Form.Item
                  label={<span className="form-label">Regio</span>}
                  name="region"
                  rules={[{ required: true, message: "Regio is verplicht" }]}
                  style={
                    screens.md === true
                      ? formGroupInputStyleLeftColumn
                      : formSingleInputStyle
                  }
                >
                  <RegionSelect placeholder="Regio" size="large" />
                </Form.Item>
              </Col>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label={<span className="form-label">Periode</span>}
                  style={
                    screens.md
                      ? {
                          display: "inline-block",
                          padding: "20px 40px",
                        }
                      : {
                          display: "inline-block",
                          paddingTop: "16px",
                        }
                  }
                >
                  <Form.Item
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        message: "Startdatum is verplicht",
                      },
                    ]}
                    style={
                      screens.md
                        ? {
                            display: "inline-block",
                            width: "calc(40% - 0px)",
                          }
                        : { display: "inline-block", width: "calc(40% - 0px)" }
                    }
                  >
                    <DatePicker
                      size="large"
                      format={dateFormat}
                      onChange={(value) => setSeletedDate(value)}
                    />
                  </Form.Item>
                  <span style={{ fontSize: "24px", marginLeft: "5px" }}>-</span>
                  <Form.Item
                    name="end_date"
                    rules={[
                      {
                        required: true,
                        message: "Einddatum is verplicht",
                      },
                    ]}
                    style={
                      screens.md
                        ? {
                            display: "inline-block",
                            width: "calc(40% - 8px)",
                            margin: "0 8px",
                          }
                        : {
                            display: "inline-block",
                            width: "calc(40% - 0px)",
                            marginLeft: "5px",
                          }
                    }
                  >
                    <DatePicker
                      size="large"
                      format={dateFormat}
                      disabledDate={(current) => {
                        return current < selectedDate;
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <Form.Item
                  label={
                    <span className="form-label">Opdrachtomschrijving</span>
                  }
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Opdrachtomschrijving is verplicht",
                    },
                  ]}
                  style={
                    screens.md === true
                      ? { padding: "0px 60px", marginTop: "20px" }
                      : formSingleInputStyle
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    style={{ borderRadius: "2px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item>
            <Row justify="end" style={screens.md ? { margin: "0px 20px" } : {}}>
              <Col span={12}>
                <Button
                  size="large"
                  onClick={() => setAdding(false)}
                  block
                  type="primary"
                  // className={screens.md ? "form-button" : ""}
                  style={{ backgroundColor: "#664FF3", marginRight: "20px" }}
                >
                  Annuleren
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  block
                  type="primary"
                  // className={screens.md ? "form-button" : ""}
                  htmlType="submit"
                >
                  Opslaan
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
