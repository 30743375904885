import { Layout, Divider, Input, Row, Col, Pagination, Grid } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./styles.css";
import ActivityList from "./ActivityList";
import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import _ from "lodash";
import EmptyLogo from "src/empty-state.svg";

type Activity = {
  activity_datetime: Date;
  activity_message: string;
  activity_type: string;
  id: number;
  is_active: boolean;
};

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Activity[];
};

export default function ActivityLog() {
  const { Content } = Layout;
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const screens = Grid.useBreakpoint();

  const BASE_URL = process.env.REACT_APP_BACKEND;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const { data, refetch } = useQuery<Response>(
    `Activities${currentPage}`,
    async () => {
      return (
        await axios.get(
          `${BASE_URL}api/activities/?limit=${limit}&offset=${(currentPage -
            1) *
            limit}`,
          config
        )
      ).data;
    }
  );

  const handlePagination = async (page: number) => {
    await setCurrentPage(page);
    refetch();
  };

  const date = (d: any) => {
    return moment(d).format("YYYY-MM-DD");
  };

  const groupToSummary = (group: any, date: any) => {
    return {
      date: date,
      data: group,
    };
  };

  const finedData = _(data?.results)
    .groupBy((e) => date(e.activity_datetime))
    .map(groupToSummary)
    .value();

  return (
    <>
      <Row
        justify={"center"}
        style={{ backgroundColor: "#f6f6f6", minHeight: "72vh" }}
      >
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout className="container">
            <Content
              className="web-content"
              style={{
                padding: "1.5rem 0px",
                minHeight: "88vh",
              }}
            >
              {screens.md && (
                <Row
                  style={{ marginBlockStart: "25px", marginBlockEnd: "8px" }}
                >
                  <Col span={8}>
                    <div className="web-header">Activiteitenlogboek</div>
                  </Col>
                  <Col span={16}>
                    <Input
                      size={"large"}
                      placeholder="Zoeken"
                      prefix={<SearchOutlined />}
                      style={{ maxHeight: "45px", padding: "0 11px" }}
                    />
                  </Col>
                  <Divider className="web-divider-dark" />
                </Row>
              )}

              {finedData
                .sort((a, b) => {
                  return (
                    new Date(b.date).getDate() - new Date(a.date).getDate()
                  );
                })
                .map((e) => {
                  return <ActivityList activities={e.data} date={e.date} />;
                })}

              {screens.md && finedData?.length === 0 && (
                <Row justify="center" style={{ marginBottom: 20 }}>
                  <img alt="Empty Screen" src={EmptyLogo} width={700} />
                </Row>
              )}
              {!screens.md && finedData?.length === 0 && (
                <Row justify="center" style={{ marginBottom: 20 }}>
                  <img alt="Empty Screen" src={EmptyLogo} width={300} />
                </Row>
              )}

              <Pagination
                total={data?.count}
                showTotal={(total) => `Totaal ${total} items`}
                pageSize={limit}
                current={currentPage}
                onChange={(value) => handlePagination(value)}
                className="pagination"
                style={{ marginTop: "0px" }}
                responsive={true}
              />
            </Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
}
