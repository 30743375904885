export enum NotificationType {
  ConnectionAccepted = "connection_accepted",
  ConnectionRejected = "connection_rejected",
  ConnectionRequested = "connection_requested",
  VacancyCreated = "vacancy_created",
  HourSubmitted = "hour_submitted",
  InterestShown = "interest_shown",
}

export interface NotificationContent {
  id: number;
  date_created: number;
  date_read: number | null;
  date_hidden: number | null;
  type: NotificationType;
  from: number | null;
  content: any;
}

export interface NotificationData {
  type: string;
  content: NotificationContent[];
}

export interface NotificationUpdate {
  command: string;
  id: number;
  data: {
    date_read: number;
    date_hidden: number;
    type?: string;
  };
}
