import React, { useEffect, useMemo, useRef, useState } from "react";
import CompanyLogo from "src/company-logo.svg";
import {
  Modal,
  Row,
  Col,
  Button,
  Divider,
  Form,
  Input,
  Select,
  InputNumber,
  Grid,
  message,
  Spin,
} from "antd";
import * as _ from "lodash";

import { useHistory } from "react-router-dom";
import axios from "axios";
import RegionSelect from "../../components/common-inputs/Region-Select";
import SectorSelect from "../../components/common-inputs/Sector-Select";
import PositionSelect from "../../components/common-inputs/Position-Select";
import SpecialySelect from "../../components/common-inputs/Speciality-Select";
import "./index.css";
import "src/index.css";
import { API_URL } from "../Settings/config";
import { useForm } from "antd/lib/form/Form";
import { useQuery } from "react-query";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import AuthLayout from "../../components/Common/AuthLayout";

// type KvkObject = {
//   kvkNumber: number;
//   branchNumber: number;
//   rsin: number;
//   tradeNames: {
//     businessName: string;
//     shortBusinessName: string;
//     currentTradeNames: string[];
//     currentStatutoryNames: string[];
//   };
//   hasEntryInBusinessRegister: boolean;
//   hasNonMailingIndication: boolean;
//   isLegalPerson: boolean;
//   isBranch: boolean;
//   isMainBranch: boolean;
//   addresses: [
//     {
//       type: string;
//       street: string;
//       houseNumber: number;
//       postalCode: string;
//       city: string;
//       country: string;
//     }
//   ];
// };

type KvkObject = {
  kvkNummer: string;
  vestigingsnummer: string;
  handelsnaam: string;
  straatnaam: string;
  plaats: string;
  type: string;
  links: [
    {
      rel: string;
      href: string;
    }
  ];
};

type KvkResponse = {
  count: number;
  next: string;
  previous: string;
  results: KvkObject[];
};

const { Option } = Select;

export function Profile() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const loginType = localStorage.getItem("loginType");

  let history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [form] = useForm();

  // const [currentPage, setCurrentPage] = useState(1)
  const [selectedSector, setSelectedSector] = useState<number | undefined>();
  const [userName, setUserName] = useState("");
  const [token, setToken] = useState("");
  const [userType, setUserType] = useState("Freelancer");
  const [showSpin, setShowSpin] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState();
  const [currentPage] = useState(1);

  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 300)).current;

  const sendQuery = (query: any) => {
    setSearchInputValue(query);
  };

  const handleOnchange = (e: any) => {
    delayedQuery(e);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const userName = localStorage.getItem("username");
    const token = localStorage.getItem("token");
    let userType = history.location.state;
    if (userType === "Freelancer") {
      setUserType("freelancer");
    } else if (userType === "Company") {
      setUserType("company");
    }
    if (userName) {
      setUserName(userName);
    }
    if (token) {
      setToken(token);
    }
  }, [history.location.state]);

  const errorMsg = (content: string) => {
    message.error(content);
  };

  const getOptions = () => {
    let optionSet = [];
    for (let i = 0; i <= 50; i++) {
      optionSet.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }
    return optionSet;
  };

  const { data: KVKList, isLoading } = useQuery<KvkResponse>(
    ["KVKList", searchInputValue, currentPage],
    async () => {
      if (!searchInputValue) {
        return { results: [] };
      }

      try {
        const res = await axios({
          method: "GET",
          url: API_URL + `v2/kvk/`,
          ...getHttpHeader(),
          params: {
            handelsnaam: searchInputValue,
          },
        });

        return res.data;
      } catch (e: any) {
        if (String(e.message).includes("400")) {
          form.setFields([
            {
              name: "kvk_number",
              errors: ["Het minimum van één zoekcriterium is vereist."],
            },
          ]);
        }

        return { results: [] };
      }
    }
  );

  const options = useMemo(() => {
    if (!KVKList || !KVKList.results) {
      return [];
    }

    return KVKList.results.map((business) => {
      const name = business.handelsnaam || "";
      return {
        label: `${business.kvkNummer} - ${name}`,
        value: `${business.kvkNummer}-${name}`,
      };
    });
  }, [KVKList]);

  const onSubmitFreelancer = async (values: any) => {
    if (values.min_rate >= values.max_rate) {
      errorMsg("Het minimale tarief moet lager zijn dan het maximale tarief.");
      return;
    }

    try {
      await axios.patch(
        API_URL + "account/set_user_type/",
        { user_type: userType },
        config
      );

      const freelancerData = {
        ...values,
        kvk_number: String(values.kvk_number).slice(
          0,
          String(values.kvk_number).indexOf("-")
        ),
        company_name: String(values.kvk_number).slice(
          String(values.kvk_number).indexOf("-") + 1
        ),
        sector: { id: values.sector },
        region: { id: values.region },
        position: { id: values.position },
        specialty: values.specialties
          ? values.specialties?.map((item: number) => ({ id: item }))
          : [],
        user_type: userType,
      };

      await axios.patch(
        API_URL + `freelancers/${user.id}/`,
        freelancerData,
        config
      );

      if (loginType === "google" || loginType === "facebook") {
        setShowSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        history.push("/");
      } else history.push("/register/verify");
    } catch (error) {
      console.error("onSubmitFreelancer ==> ", error);
    }
  };

  const onSubmitCompany = async (values: any) => {
    try {
      await axios.patch(
        API_URL + "account/set_user_type/",
        { user_type: userType },
        config
      );

      const companyData = {
        kvk_number: String(values.kvk_number).slice(
          0,
          String(values.kvk_number).indexOf("-")
        ),
        company_name: String(values.kvk_number).slice(
          String(values.kvk_number).indexOf("-") + 1
        ),
        sector: { id: values.sector },
        user_type: userType,
      };

      await axios.patch(API_URL + `companies/${user.id}/`, companyData, config);

      if (loginType === "google" || loginType === "facebook") {
        setShowSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        history.push("/");
      } else history.push("/register/verify");
    } catch (error) {
      console.log("onSubmitCompany ===> ", error);
    }
  };

  // Refetching the values after sector changes
  useEffect(() => {
    // setCurrentPage(1)
    form.setFields([
      {
        name: "position",
        value: undefined,
      },
    ]);
    // eslint-disable-next-line
  }, [selectedSector]);

  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={true}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <Spin
          size="large"
          tip="Log in om de accountregistratie te bevestigen"
          spinning={showSpin}
        >
          <div className="login-body">
            <Row className="login-row">
              <Col
                xl={10}
                lg={10}
                md={0}
                sm={0}
                xs={0}
                className="login-design__column"
              >
                <div className="login-design__start">
                  <div>
                    <img src={CompanyLogo} alt="Company Logo"></img>
                    <div className="login-header">
                      Meld u gratis <br />
                      aan bij job.nl
                    </div>
                    <Divider className="login-devider" plain></Divider>
                    <div className="login-text">Log in om verder te gaan!</div>
                  </div>
                </div>
              </Col>
              <Col
                xl={14}
                lg={14}
                md={24}
                sm={24}
                xs={24}
                className="login-form__column"
              >
                <Row justify="space-between">
                  <Col xl={0} lg={0} md={0} sm={24} xs={24}>
                    <Row
                      justify="space-between"
                      style={{ backgroundColor: "#f6f6f6" }}
                    >
                      <Col
                        style={{
                          paddingTop: "1rem",
                          paddingLeft: "1.5rem",
                        }}
                      >
                        <img
                          className="logo"
                          src={CompanyLogo}
                          alt="Company Logo"
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {history.location.state === "Freelancer" && (
                  <div>
                    <div
                      className="graditutde-main"
                      style={
                        screens.xs
                          ? {
                              marginTop: "5%",
                              minHeight: "70vh",
                              padding: " 0px 30px",
                            }
                          : { marginTop: "10%", padding: "0 100px" }
                      }
                    >
                      <div className="graditude-text">
                        <span className="user-name-header">
                          Hallo {userName && userName.split(" ")[0]}{" "}
                        </span>
                        <br />
                        Vertel ons wat over jezelf!
                      </div>
                      <div className="reset-password-from__div">
                        <Form
                          name="profile-form"
                          className="register-form"
                          form={form}
                          initialValues={{ remember: true }}
                          onFinish={onSubmitFreelancer}
                        >
                          <Form.Item
                            name="kvk_number"
                            className="company-reg-input"
                            rules={[
                              {
                                required: true,
                                message: "Vul uw bedrijfsnaam of code in",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Vul uw bedrijfsnaam/kvk nummer in"
                              size="large"
                              onSearch={handleOnchange}
                              loading={isLoading}
                              options={options}
                              filterOption={false}
                              onChange={(v) => {
                                console.log(
                                  "KVK ",
                                  v,
                                  String(v).slice(0, String(v).indexOf("-")),
                                  String(v).slice(String(v).indexOf("-") + 1)
                                );
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            name="sector"
                            rules={[
                              {
                                required: true,
                                message: "Sector is verplicht",
                              },
                            ]}
                            className=""
                            extra={
                              <span className="form-label">
                                Staat uw sector er niet bij, geef dit door aan
                                info@job.nl
                              </span>
                            }
                          >
                            <SectorSelect
                              placeholder="Sector"
                              size="large"
                              onChange={(value: number) =>
                                setSelectedSector(value)
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name="position"
                            rules={[
                              {
                                required: true,
                                message: "Functie is verplicht",
                              },
                            ]}
                            className="normal-input"
                            extra={
                              <span className="form-label">
                                Staat uw functie er niet bij, geef dit door aan
                                info@job.nl
                              </span>
                            }
                          >
                            <PositionSelect
                              placeholder="Functie"
                              size="large"
                              sectorId={selectedSector}
                            />
                          </Form.Item>
                          <Form.Item
                            name="specialties"
                            rules={[
                              {
                                required: false,
                                message: "Specialisme is verplicht",
                                type: "array",
                              },
                            ]}
                            className="normal-input"
                            extra={
                              <span className="form-label">
                                Staat uw specialisme er niet bij, geef dit door
                                aan info@job.nl
                              </span>
                            }
                          >
                            <SpecialySelect
                              placeholder="Specialisme"
                              mode="multiple"
                              size="large"
                              filterOption={(inputValue, option) => {
                                return String(option?.children)
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase());
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="region"
                            rules={[
                              { required: true, message: "Regio is verplicht" },
                            ]}
                            className="normal-input"
                          >
                            <RegionSelect placeholder="Regio" size="large" />
                          </Form.Item>
                          <Form.Item
                            name="experience"
                            rules={[
                              {
                                required: true,
                                message: "Ervaringsjaren is verplicht",
                              },
                            ]}
                            className="normal-input"
                          >
                            <Select placeholder="Ervaringsjaren" size="large">
                              {/* {experience.map((ele) => (
                              <Option key={ele} value={ele}>
                                {ele}
                              </Option>
                            ))} */}
                              {getOptions()}
                            </Select>
                          </Form.Item>
                          <div className="min-header">
                            <span>Tarief per uur</span>
                          </div>
                          <Form.Item>
                            <Input.Group>
                              <Row
                                justify="space-between"
                                style={{ height: "55px" }}
                              >
                                <Col flex="49%">
                                  <Form.Item
                                    name="min_rate"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Minimaal tarief is verplicht",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      placeholder="Min"
                                      className="max-min-input"
                                      size="large"
                                      min={0}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col flex="49%">
                                  <Form.Item
                                    name="max_rate"
                                    className="last-name-class"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Maximaal tarief is verplicht",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      placeholder="Max"
                                      className="max-min-input"
                                      size="large"
                                      min={0}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Input.Group>
                          </Form.Item>

                          <Form.Item>
                            <Button
                              htmlType="submit"
                              className="login-form-button"
                            >
                              Registreer als ZZP'er
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
                {history.location.state === "Company" && (
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <div className="company-reg-form">
                      <div className="graditude-text">Zoek uw bedrijf</div>
                      <div
                        style={{
                          paddingTop: "25px",
                        }}
                      >
                        <Form
                          name="profile-form"
                          onFinish={onSubmitCompany}
                          form={form}
                        >
                          <Form.Item
                            name="kvk_number"
                            className="company-reg-input"
                            rules={[
                              {
                                required: true,
                                message: "Vul uw bedrijfsnaam of code in",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Vul uw bedrijfsnaam/kvk nummer in"
                              size="large"
                              onSearch={handleOnchange}
                              loading={isLoading}
                              options={options}
                              filterOption={false}
                            />
                          </Form.Item>

                          <Form.Item
                            name="sector"
                            className="company-reg-input"
                            rules={[
                              {
                                required: true,
                                message: "Vul uw Sector ID in",
                              },
                            ]}
                          >
                            <SectorSelect placeholder="Sector" size="large" />
                          </Form.Item>

                          <Form.Item>
                            <Button
                              htmlType="submit"
                              block
                              className="login-form-button"
                            >
                              Registreer als opdrachtgever
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Spin>
      </Modal>
    </AuthLayout>
  );
}
