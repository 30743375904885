import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Grid,
  Radio,
  Layout,
  message,
  Modal,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";
import CompanyLogo from "src/company-logo.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import VisaLogo from "src/images/visa.png";
import IdeaLogo from "src/images/ideal.png";
import MaestroLogo from "src/images/maestro.png";
import MasterLogo from "src/images/mastercard.png";
import Amex from "src/images/amex.png";
import PaymentsBy from "src/images/paymentsby.png";
import { Route, Link, useLocation } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import CheckoutFormIDeal from "./CheckoutFormIDeal";
import { useUser } from "src/hooks/useUser";

const promise = loadStripe(
  "pk_live_51IVN3gJlTYNcHJj2pjj3vFDSFU0vhIU741PASKNjqTAQrhYD2XlJEMoeT7uFjr7NZt7ZAuu9BUCNc6mafopl7rp6009LNPqbJX"
);

export default function Payment() {
  const { Content } = Layout;
  const [form] = Form.useForm();
  const screens = Grid.useBreakpoint();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const { user } = useUser();

  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const rowStyle = {
    marginBlockEnd: "15px",
  };

  const formGroupInputStyle = {
    display: "inline-block",
    width: "calc(50% - 10px)",
    margin: "0 10px 0 0 ",
    height: "50px",
  };

  const singleInputStyle = {
    display: "inline-block",
    width: "calc(100% - 10px)",
    margin: "0 10px 0 0 ",
    height: "50px",
  };

  const radioStyle = {
    display: "inline-block",
    borderBottom: "2px solid #ddd",
    padding: "12px",
    margin: "0px",
    width: "100%",
    backgroundColor: "white",
  };

  const [paymentType, setPaymentType] = useState();

  const onFinish = async (value: any) => {
    console.log(value);
    if (value.payment_type === "4" || value.payment_type === "1") {
      setLoading(true);
      axios({
        method: "POST",
        url: `${BASE_URL}api/payments/create_payment_intent/`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("Response", res);
          setModalVisible(true);
          setLoading(true);
          setClientSecret(res.data.client_secret);
        })
        .catch((err) => message.error(JSON.stringify(err.response.data)));
    } else {
      message.error("Card and iDeal payment only available.");
    }
  };

  useEffect(() => {
    if (paymentSuccess) {
      let user = JSON.parse(localStorage.getItem("user") || "");
      user.is_payment_active = true;

      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [paymentSuccess]);

  const location = useLocation();
  useEffect(() => {
    if (
      location.search.includes("redirect_status=succeeded") &&
      location.search.includes(clientSecret)
    ) {
      setModalVisible(false);
      setClientSecret("");
      setLoading(false);
      setPaymentSuccess(true);
      window.location.href = "/dashboard?showfinglemsg=true";
    }

    if (
      location.search.includes("redirect_status=failed") &&
      location.search.includes(clientSecret)
    ) {
      message.error("Payment not completed. Try again", 10);
    }
    // eslint-disable-next-line
  }, []);

  const handleRadioChange = (event: any) => {
    setPaymentType(event.target.value);
  };

  useEffect(() => {
    console.log("user", user);
    form.setFields([
      {
        name: "email",
        value: user.email,
      },
      {
        name: "first_name",
        value: user.first_name,
      },
      {
        name: "last_name",
        value: user.last_name,
      },
      {
        name: "company_name",
        value: user.company_name,
      },
      {
        name: "region",
        value: user.region?.id,
      },
    ]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Route exact path="/payment">
        <Modal
          closable={false}
          title={undefined}
          centered
          visible={modalVisible}
          footer={null}
          bodyStyle={{
            padding: 24,
            backgroundColor: "#f6f6f6",
          }}
          width={screens.md ? "auto" : "100%"}
          destroyOnClose={true}
        >
          {paymentType === 1 && (
            <>
              {!paymentSuccess ? (
                <Elements stripe={promise}>
                  <CheckoutFormIDeal
                    clientSecret={clientSecret}
                    name={`${user.first_name} ${user.last_name}`}
                    setClientSecret={setClientSecret}
                    setLoading={setLoading}
                    setModalVisible={setModalVisible}
                    setPaymentSuccess={setPaymentSuccess}
                  />
                </Elements>
              ) : (
                <div>
                  Betaling gelukt
                  <br />
                  <Button
                    size="large"
                    onClick={() => {
                      setModalVisible(false);
                      setLoading(false);
                    }}
                  >
                    Annuleren
                  </Button>
                </div>
              )}
            </>
          )}
          {paymentType === 4 && (
            <>
              {" "}
              {!paymentSuccess ? (
                <div>
                  <Elements stripe={promise}>
                    <CheckoutForm
                      clientSecret={clientSecret}
                      setClientSecret={setClientSecret}
                      setLoading={setLoading}
                      setModalVisible={setModalVisible}
                      setPaymentSuccess={setPaymentSuccess}
                    />
                  </Elements>
                  <br />
                  <Button
                    size="large"
                    onClick={() => {
                      setModalVisible(false);
                      setLoading(false);
                    }}
                  >
                    Annuleren
                  </Button>
                </div>
              ) : (
                <div>
                  {/* Payment Success */}
                  Betaling gelukt
                  <br />
                  <Button
                    size="large"
                    onClick={() => {
                      setModalVisible(false);
                      setLoading(false);
                    }}
                  >
                    Annuleren
                  </Button>
                </div>
              )}
            </>
          )}
        </Modal>

        <Layout className="container">
          <Row justify={"center"}>
            <Col xl={24} lg={24} md={24} sm={21} xs={21}>
              <Content
                style={
                  screens.md
                    ? {
                        marginBlockStart: "20px",
                        padding: "0 100px",
                        display: "grid",
                        placeItems: "center",
                      }
                    : {
                        marginBlockStart: "20px",
                        display: "grid",
                        placeItems: "center",
                      }
                }
              >
                <div style={{ maxWidth: "750px", backgroundColor: "#F6F6F6" }}>
                  <Row justify="center" style={rowStyle}>
                    <img src={CompanyLogo} width={74} height={74} alt="logo" />
                  </Row>
                  <Row justify="center" className="payment-header">
                    U ontvangt de factuur op de mail
                  </Row>
                  <Row
                    className="wallet-sub-header-text"
                    style={{ textAlign: "center" }}
                  >
                    Wij brengen u 10 euro (ex BTW) in rekening. Wij storten deze
                    10 euro als tegoed door in uw Wallet. Na de betaling zal de
                    verificatie opstarten en na afronding van de verificatie
                    heeft u volledige toegang tot job.nl
                  </Row>
                  <Row style={rowStyle}>
                    <Form
                      form={form}
                      name="payment-details-form"
                      layout="vertical"
                      requiredMark={false}
                      onFinish={onFinish}
                      onFinishFailed={() => console.log("FinishFailed")}
                      style={{ width: "100%" }}
                    >
                      <div
                        style={
                          screens.md === true
                            ? { padding: "24px 0px 24px 0px" }
                            : {}
                        }
                      >
                        <Row style={rowStyle} className="payment-sub-header">
                          Facturatie informatie
                        </Row>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item style={{ marginBottom: 0 }}>
                              <Form.Item
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Emailadres is verplicht",
                                  },
                                  {
                                    type: "email",
                                    message: "Email adres wordt niet herkend",
                                  },
                                ]}
                                style={
                                  screens.md === true ? singleInputStyle : {}
                                }
                              >
                                <Input
                                  placeholder="E-mail"
                                  className="edit-profile-input"
                                />
                              </Form.Item>
                            </Form.Item>
                            {screens.md === true && <br />}
                            <Form.Item style={{ marginBottom: 0 }}>
                              <Form.Item
                                name="first_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Voornaam is verplicht",
                                  },
                                ]}
                                style={
                                  screens.md === true ? formGroupInputStyle : {}
                                }
                              >
                                <Input
                                  placeholder="Voornaam"
                                  className="edit-profile-input"
                                />
                              </Form.Item>
                              <Form.Item
                                name="last_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Achternaam is verplicht",
                                  },
                                ]}
                                style={
                                  screens.md === true ? formGroupInputStyle : {}
                                }
                              >
                                <Input
                                  placeholder="Achternaam"
                                  className="edit-profile-input"
                                />
                              </Form.Item>
                            </Form.Item>
                            {screens.md === true && <br />}
                            <Form.Item style={{ marginBottom: 0 }}>
                              <Form.Item
                                name="company_name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Bedrijfsnaam is verplicht",
                                  },
                                ]}
                                style={
                                  screens.md === true ? singleInputStyle : {}
                                }
                              >
                                <Input
                                  placeholder="Bedrijfsnaam"
                                  className="edit-profile-input"
                                />
                              </Form.Item>
                              {/* <Form.Item
                                name="region"
                                rules={[
                                  {
                                    required: true,
                                    message: "Land is verplicht",
                                  },
                                ]}
                                style={
                                  screens.md === true ? formGroupInputStyle : {}
                                }
                              >
                                <SelectRegion
                                  placeholder="provincie"
                                  size="large"
                                />
                              </Form.Item> */}
                            </Form.Item>
                            {screens.md === true && <br />}
                            {/* <Form.Item style={{ marginBottom: 0 }}>
                              <Form.Item
                                name="btw-number"
                                rules={[
                                  {
                                    required: true,
                                    message: "BTW-nummer is verplicht",
                                  },
                                ]}
                                style={
                                  screens.md === true ? singleInputStyle : {}
                                }
                              >
                                <Input
                                  placeholder="BTW-nummer"
                                  className="edit-profile-input"
                                />
                              </Form.Item>
                            </Form.Item> */}
                          </Col>
                        </Row>

                        {screens.md === true && (
                          <div style={{ marginBlockStart: "30px" }} />
                        )}
                        <Row justify="space-between" style={rowStyle}>
                          <Col
                            span={screens.md ? 6 : 12}
                            className="payment-sub-header"
                          >
                            Betaalmethode
                          </Col>
                          <Col
                            span={screens.md ? 6 : 12}
                            style={{ textAlign: "end", paddingRight: "0px" }}
                          >
                            <img
                              src={PaymentsBy}
                              height={32}
                              alt="PaymentsBy"
                            />
                          </Col>
                        </Row>

                        <Row style={{ width: "100%" }}>
                          <Form.Item style={{ marginBottom: 0, width: "100%" }}>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please select paymeny type",
                                },
                              ]}
                              name="payment_type"
                              style={
                                screens.md === true
                                  ? {
                                      display: "inline-block",
                                      width: "calc(100% - 10px)",
                                      margin: "0 10px 0 0 ",
                                    }
                                  : {}
                              }
                            >
                              <div style={{ border: "2px solid #ddd" }}>
                                <Radio
                                  style={radioStyle}
                                  value={1}
                                  onChange={handleRadioChange}
                                  checked={paymentType === 1 ? true : false}
                                >
                                  <Row
                                    justify="space-between"
                                    style={{
                                      display: "inline-flex",
                                      width: "95%",
                                    }}
                                  >
                                    <Col>iDEAL</Col>
                                    <Col>
                                      <img
                                        src={IdeaLogo}
                                        alt="idea"
                                        height={30}
                                      />
                                    </Col>
                                  </Row>
                                </Radio>
                                <Row
                                  style={{
                                    textAlign: "center",
                                    backgroundColor: "#f6f6f6",
                                    display: "block",
                                    width: "100%",
                                    borderBottom: "2px solid #ddd",
                                    padding: "10px",
                                  }}
                                >
                                  <Col>
                                    <div>
                                      <ExportOutlined
                                        style={{
                                          fontSize: "100px",
                                          color: "#bbb",
                                        }}
                                      >
                                        Nadat je op "Afrekenen" hebt geklikt,
                                        word je doorgestuurd
                                        <br />
                                        naar iDEAL om je aankoop veilig te
                                        voltooien.
                                      </ExportOutlined>
                                    </div>
                                    <div>
                                      Nadat je op "Afrekenen" hebt geklikt, word
                                      je doorgestuurd
                                      <br />
                                      naar iDEAL om je aankoop veilig te
                                      voltooien.
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Radio style={radioStyle} value={2} onChange={handleRadioChange} checked={paymentType === 2 ? true : false}>
                                  <Row justify='space-between' style={{ display: 'inline-flex', width: '95%' }}>
                                    <Col>Klarna: Achteraf betalen</Col>
                                    <Col><img src={Klarna} alt='vvv' height={25} /></Col>
                                  </Row>
                                </Radio> */}
                                {/* <Radio style={radioStyle} value={3} onChange={handleRadioChange} checked={paymentType === 3 ? true : false}>
                                  <Row justify='space-between' style={{ display: 'inline-flex', width: '95%' }}>
                                    <Col>PayPal</Col>
                                    <Col><img src={Paypal} alt='paypal' height={25} /></Col>
                                  </Row>
                                </Radio> */}
                                <Radio
                                  style={radioStyle}
                                  value={4}
                                  onChange={handleRadioChange}
                                  checked={paymentType === 4 ? true : false}
                                >
                                  <Row
                                    justify="space-between"
                                    style={{
                                      display: "inline-flex",
                                      width: "95%",
                                    }}
                                  >
                                    <Col>Creditcard</Col>
                                    <Col>
                                      <img
                                        src={VisaLogo}
                                        alt="visa"
                                        height={25}
                                      />
                                      <img
                                        src={MaestroLogo}
                                        alt="maestro"
                                        height={25}
                                      />
                                      <img
                                        src={MasterLogo}
                                        alt="master"
                                        height={25}
                                      />
                                      <img src={Amex} alt="amex" height={25} />
                                    </Col>
                                  </Row>
                                </Radio>
                                {/* <Radio style={radioStyle} value={5} onChange={handleRadioChange} checked={paymentType === 5 ? true : false}>
                                  <Row justify='space-between' style={{ display: 'inline-flex', width: '95%' }}>
                                    <Col>VVV Giftcard</Col>
                                    <Col><img src={VVVLogo} alt='vvv' height={25} /></Col>
                                  </Row>
                                </Radio> */}
                                {/* <Radio style={radioStyle} value={6} onChange={handleRadioChange} checked={paymentType === 6 ? true : false}>
                                  <Row justify='space-between' style={{ display: 'inline-flex', width: '95%' }}>
                                    <Col>YourGift Card</Col>
                                    <Col><img src={YourGift} alt='yourgift' height={25} /></Col>
                                  </Row>
                                </Radio> */}
                                {/* <Radio style={{
                                  display: 'inline-block',
                                  padding: '12px',
                                  margin: '0px',
                                  width: '100%',
                                  backgroundColor: 'white'
                                }} value={7} onChange={handleRadioChange} checked={paymentType === 7 ? true : false}>STOX Gift Card</Radio> */}
                                {/* </Radio.Group> */}
                              </div>
                            </Form.Item>
                          </Form.Item>
                        </Row>
                      </div>
                      <Row style={{ width: "100%" }}>
                        {!paymentSuccess ? (
                          <Button
                            style={{
                              backgroundColor: "#35DF90",
                              color: "white",
                            }}
                            className="payment-button"
                            htmlType="submit"
                            block
                            loading={loading}
                            size="large"
                          >
                            {`Betalen & verificatie starten`}
                          </Button>
                        ) : (
                          <Link to="/dashboard" style={{ width: "100%" }}>
                            <Button
                              style={{
                                backgroundColor: "#000233",
                                color: "white",
                                width: "100%",
                              }}
                              className="payment-button"
                              block
                              size="large"
                            >
                              {`Betaling gelukt`}
                            </Button>
                          </Link>
                        )}
                      </Row>
                    </Form>
                  </Row>
                </div>
              </Content>
            </Col>
          </Row>
        </Layout>
      </Route>
    </>
  );
}
