import React, {
  MutableRefObject,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { Upload, Button, Grid } from "antd";
import "./styles.css";
import { RightCircleOutlined } from "@ant-design/icons";

const PickerPromise = import("emoji-picker-react");
const Picker = React.lazy(() => PickerPromise);

type MessageInputProps = {
  sendMessage: any;
  sendFile: any;
  messageInputRef: MutableRefObject<HTMLInputElement | null>;
  setProposalVisible: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  setProposal: Function;
};

const MessageInput = ({
  sendMessage,
  sendFile,
  messageInputRef,
  setProposalVisible,
  disabled,
  setProposal,
}: MessageInputProps) => {
  const [inputMessage, setInputMessage] = useState("");

  const onSubmitMessage = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!inputMessage) {
      return;
    }
    sendMessage(inputMessage);
    setInputMessage("");
  };

  const screens = Grid.useBreakpoint();

  return (
    <div>
      <form onSubmit={onSubmitMessage} className="message-input-form">
        {screens.md && (
          <Button
            className="message-input-send-proposal-btn"
            onClick={() => {
              setProposal(null);
              setProposalVisible(true);
            }}
            style={{ marginRight: "20px", paddingLeft: "15px" }}
            disabled={disabled}
          >
            Job voorstel
          </Button>
        )}
        <InputGroup>
          <InputLeftElement
            as="button"
            type="button"
            disabled={disabled}
            children={
              <Popover>
                <PopoverTrigger>
                  <Text>😀</Text>
                </PopoverTrigger>
                <PopoverContent
                  color="transparent"
                  bg="transparent"
                  borderWidth="0px"
                  width="300px"
                >
                  <PopoverBody borderWidth="0">
                    <React.Suspense fallback={<div></div>}>
                      <Picker
                        onEmojiClick={(event, emoji) =>
                          setInputMessage(inputMessage + emoji.emoji)
                        }
                      />
                    </React.Suspense>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            }
          />
          <Input
            disabled={disabled}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Stuur bericht..."
            ref={messageInputRef}
            style={{
              backgroundColor: "#FFF",
              paddingRight: "5rem",
              borderRadius: "2px",
            }}
          />
          <InputRightElement
            as="button"
            disabled={disabled}
            children={<RightCircleOutlined className="chat-send-button" />}
          />
          <InputRightElement
            as="button"
            mr="40px"
            disabled={disabled}
            children={
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  sendFile(file);
                  return false;
                }}
              >
                <AttachmentIcon />
              </Upload>
            }
            type="button"
          />
        </InputGroup>
      </form>
    </div>
  );
};

export default MessageInput;
