import React from "react";
import CompanyLogo from "src/company-logo.svg";
import { Modal, Row, Col, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/Common/AuthLayout";

import "./index.css";
import "src/index.css";

export function SelectProfile() {
  return (
    <AuthLayout>
      <Modal
        centered
        closable={false}
        visible={true}
        footer={null}
        width={789}
        bodyStyle={{ padding: "0" }}
      >
        <div className="login-body">
          <Row className="login-row">
            <Col
              xl={10}
              lg={10}
              md={0}
              sm={0}
              xs={0}
              className="login-design__column"
            >
              <div className="login-design__start">
                <div>
                  <img src={CompanyLogo} alt="Company Logo"></img>
                  <div className="login-header">
                    Meld u gratis <br />
                    aan bij job.nl
                  </div>
                  <Divider className="login-devider" plain></Divider>
                  <div className="login-text">Log in om verder te gaan!</div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={14}
              md={24}
              sm={24}
              xs={24}
              className="login-form__column"
            >
              <Row justify="space-between">
                <Col xl={0} lg={0} md={0} sm={24} xs={24}>
                  <Row
                    justify="space-between"
                    style={{ backgroundColor: "#f6f6f6" }}
                  >
                    <Col
                      style={{
                        paddingTop: "1rem",
                        paddingLeft: "1.5rem",
                      }}
                    >
                      <img
                        className="logo"
                        src={CompanyLogo}
                        alt="Company Logo"
                      ></img>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div style={{ textAlign: "center" }}>
                <div className="welcome-main">
                  <span className="welcome-text">
                    Welkom! <br />
                    Wil je een account aanmaken als ZZP'er of als opdrachtgever?
                  </span>
                  <div className="button-div">
                    <Button block className="select-profile__button">
                      <Link
                        to={{
                          pathname: "/register/profile",
                          state: "Freelancer",
                        }}
                      >
                        ZZP'er
                      </Link>
                    </Button>
                    <Button block className="select-profile__button">
                      <Link
                        to={{ pathname: "/register/profile", state: "Company" }}
                      >
                        Opdrachtgever
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </AuthLayout>
  );
}
