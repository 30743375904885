import {
  BuildOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  DownOutlined,
  EuroCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  LoginOutlined,
  RetweetOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Grid,
  Input,
  Layout,
  Menu,
  message,
  Row,
  Spin,
} from "antd";
import axios from "axios";
import * as _ from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CompanyLogo from "src/company-logo.svg";
import { checkPermission } from "src/hooks/checkPermission";
import { useUser } from "src/hooks/useUser";
import { ReactComponent as ChatLight } from "src/icons/comment.svg";
import { ReactComponent as ChatDark } from "src/icons/comment_dark.svg";
import { PermissionType } from "src/types/Permission";
import { UserTypes } from "src/types/user.type";
import { ChatContaxt } from "../../App";
import { logout } from "../../store/entity/auth";
import Notifications from "../notification/Notifications";
import "../styles.css";

type Response = {
  id: number;
  user: number;
  amount: string;
  referred_by: number;
  referrals: number[];
};

type HeaderProps = {
  searchInputValue?: string;
  setSearchInputValue?: Function;
};

export default function AppHeader({
  searchInputValue,
  setSearchInputValue,
}: HeaderProps) {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const { Header } = Layout;
  const dispatch = useDispatch();
  const location = useLocation();
  const [color, setColor] = useState("");
  const { user, getUserDetails } = useUser();
  const screens = Grid.useBreakpoint();
  const [openSearch, setOpenSearch] = useState(false);

  const [finqleLink, setFinqleLink] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function useQueryForQueryParam() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryForQueryParam();

  const [messageCount, setMessageCount] = useState(10);
  var chatState: any = useContext(ChatContaxt);

  const loadIcon = useMemo(
    () => (
      <LoadingOutlined
        style={{
          fontSize: screens.lg ? 24 : 18,
          color: "white",
        }}
        spin
      />
    ),
    [screens]
  );

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setMessageCount(chatState.unreadMessageCount);
  }, [chatState]);

  const { data, refetch } = useQuery<Response>(
    `WalletDetails${user.id}`,
    async () => {
      return (
        await axios({
          method: "GET",
          url: `${BASE_URL}api/users/wallet/`,
          headers: {
            Authorization: "Bearer " + token,
          },
        })
      ).data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  //Setting the current active page
  const getSelectedKey = () => {
    if (location.pathname.includes("/dashboard")) {
      if (screens.md) {
        return ["1"];
      } else {
        return [""];
      }
    } else if (location.pathname.includes("/search")) {
      if (screens.md) {
        return ["2"];
      } else {
        return ["1"];
      }
    } else if (location.pathname.includes("/connect")) {
      return ["3"];
    } else if (location.pathname.includes("/work")) {
      if (screens.md) {
        return ["4"];
      } else {
        return ["2"];
      }
    } else if (location.pathname.includes("/mobilesubmenu")) {
      return ["6"];
    } else if (location.pathname.includes("/user/accountauthentication")) {
      return ["6"];
    } else if (location.pathname.includes("/user/invoice")) {
      return ["7"];
    } else if (location.pathname.includes("/user/activitylog")) {
      return ["8"];
    } else if (location.pathname.includes("/user/profile")) {
      return ["5"];
    } else {
      return [""];
    }
  };

  const innderHeaders = [["5"], ["6"], ["7"], ["8"]];

  useEffect(() => {
    if (innderHeaders.indexOf(getSelectedKey()) === -1) {
      setColor("#FFF");
    } else {
      setColor("#35DF90");
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const menu = (
    <div className="menu">
      <Menu
        defaultSelectedKeys={getSelectedKey()}
        style={{ backgroundColor: "#002333", padding: "10px 10px 0px 10px" }}
      >
        <Menu.Item className="nav-button-settings" key="5">
          <BuildOutlined />
          <Link
            to={
              user.user_type === UserTypes.Company
                ? "/user/profile/editprofile"
                : "/user/profile/editprofilefreelancer"
            }
          >
            Bedrijf
          </Link>
        </Menu.Item>
        <Menu.Divider className="menu-divider" />

        {/* {UserTypes.Company === user.user_type && (
          <Menu.Item className="nav-button-settings" key="6">
            <UserOutlined />
            <Link to="/user/accountauthentication">Gebruikers</Link>
          </Menu.Item>
        )}
        {UserTypes.Child_User === user.user_type &&
          checkPermission({ user, can: PermissionType.CREATE_ASSIGNMENT }) && (
            <Menu.Item className="nav-button-settings" key="6">
              <UserOutlined />
              <Link to="/user/accountauthentication">Gebruikers</Link>
            </Menu.Item>
          )}
        {UserTypes.Company === user.user_type && (
          <Menu.Divider className="menu-divider" />
        )} */}
        {UserTypes.Child_User === user.user_type &&
          checkPermission({ user, can: PermissionType.CREATE_ASSIGNMENT }) && (
            <Menu.Divider className="menu-divider" />
          )}

        <Menu.Item className="nav-button-settings" key="7">
          <EuroCircleOutlined />
          <Link to="/user/invoice">Alle facturen</Link>
        </Menu.Item>
        <Menu.Divider className="menu-divider" />

        {(UserTypes.Company === user.user_type ||
          UserTypes.Child_User === user.user_type) && (
          <Menu.Item className="nav-button-settings" key="8">
            <ClockCircleOutlined />
            <Link to="/user/activitylog">Activiteitenlogboek</Link>
          </Menu.Item>
        )}
        {(UserTypes.Company === user.user_type ||
          UserTypes.Child_User === user.user_type) && (
          <Menu.Divider className="menu-divider" />
        )}

        <Menu.Item className="nav-button-settings" key="9">
          <ExclamationCircleOutlined />
          <a href="https://www.job.nl/help">Help</a>
        </Menu.Item>
        <Menu.Divider className="menu-divider" />
        <Menu.Item
          className="nav-button-settings"
          key="10"
          onClick={() => dispatch(logout())}
        >
          <LoginOutlined />
          <Link to="/login">Logout</Link>
        </Menu.Item>
      </Menu>
    </div>
  );

  const requestFinqleOnboarding = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}api/finqle/onboarding-link`,
        config
      );
      console.log("res.data.link ", res.data.link);
      if (res?.data?.link) {
        setFinqleLink(res?.data?.link);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 10000);
      } else
        message.info(
          `Kan Finqle onboarding-link niet krijgen. Probeer opnieuw.`
        );
    } catch (error: any) {
      if (error?.response?.data?.message === "finqle_user_does_not_exists")
        message.info(
          `Finqle werkt aan het genereren van uw onboarding-link. Probeer het na 5 minuten.`
        );
    }
  };

  const getBannerMessage = () => {
    if (!user.is_payment_active) {
      return (
        <Link to="/wallet" style={{ width: "100%", color: "#FFF" }}>
          {
            "Uw profiel is nog niet geverifieerd, klik hier voor meer informatie"
          }
        </Link>
      );
    } else if (
      !user.is_finqle_active ||
      query.get("showfinglemsg") === "true"
    ) {
      return (
        <>
          <Button
            type="link"
            onClick={requestFinqleOnboarding}
            style={{ padding: 3, fontSize: 16 }}
          >
            {" Klik hier "}
          </Button>
          om je Job.nl account te activeren
        </>
      );
    }
  };

  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
  const sendQuery = (query: any) => {
    if (setSearchInputValue) setSearchInputValue(query);
  };

  const handleOnchange = (e: any) => {
    delayedQuery(e.target.value);
    // setSearchByName(e.target.value);
  };

  // const goToPayment = async () => {
  //   try {
  //     const res = await axios.get(`${BASE_URL}api/payments/initial_payment`, {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });

  //     if (res?.data?.payment_made)
  //       message.info(
  //         "Wij hebben uw betaling ontvangen en wachten op de verificatie."
  //       );
  //     else history.push("/payment");
  //   } catch (error) {
  //     console.log("ERROR in AppHeader.tsx at line no 266: ", error);
  //   }
  // };

  // useEffect(() => {
  //   const requestFinqleOnboarding = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${BASE_URL}api/finqle/onboarding-link`,
  //         config
  //       );
  //       console.log("res.data.link ", res.data.link);
  //       if (res?.data?.link) {
  //         setFinqleLink(res?.data?.link);
  //         setShowAlert(true);
  //         setTimeout(() => setShowAlert(false), 10000);
  //       } else
  //         message.info(
  //           `Kan Finqle onboarding-link niet krijgen. Probeer opnieuw.`
  //         );
  //     } catch (error) {
  //       console.log("requestFinqleOnboarding ", error);
  //       if (error?.message === "finqle_user_does_not_exists")
  //         message.info(
  //           `Kan Finqle onboarding-link niet krijgen. Probeer opnieuw.`
  //         );
  //     }
  //   };

  //   // if (query.get("showfinglemsg") === "true")
  //   // eslint-disable-next-line
  // }, []);

  return (
    <section>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          {showAlert && (
            <Row
              style={{
                position: "absolute",
                top: 20,
                width: "90%",
                flex: 2,
                justifyContent: "center",
                zIndex: 3,
              }}
            >
              <Alert
                message="Finqle Onboarding"
                type="info"
                banner={true}
                action={
                  <Button
                    size="middle"
                    type="primary"
                    onClick={() => window.open(finqleLink, "_blank")}
                  >
                    Klik hier
                  </Button>
                }
                style={{
                  width: "50%",
                }}
                closable
              />
            </Row>
          )}
          <Header style={{ backgroundColor: "#002333" }}>
            <Row>
              <Col flex={3} style={{ textAlign: "right" }}>
                <Link to="/dashboard">
                  <img
                    className="logo"
                    src={CompanyLogo}
                    alt="Company Logo"
                  ></img>
                </Link>
              </Col>
              <Col flex={12}>
                <Menu
                  mode="horizontal"
                  className="nav-button"
                  selectedKeys={getSelectedKey()}
                >
                  <Menu.Item className="nav-button" key="1">
                    <Link to="/dashboard">Dashboard</Link>
                  </Menu.Item>
                  <Menu.Item className="nav-button" key="2">
                    <Link to="/search">Zoek</Link>
                  </Menu.Item>
                  <Menu.Item className="nav-button" key="3">
                    <Link to="/connect/connections">Verbind</Link>
                  </Menu.Item>
                  <Menu.Item className="nav-button" key="4">
                    <Link to="/work/project">Werk</Link>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col flex={3}>
                <Row justify="start">
                  <Col style={{ display: "grid", placeItems: "center" }}>
                    <Link to="/wallet" style={{ cursor: "pointer" }}>
                      <div className="header-wallet">
                        {data?.amount ? (
                          `€${Number(data?.amount)}`
                        ) : (
                          <Spin
                            indicator={loadIcon}
                            style={{ paddingTop: 3 }}
                          />
                        )}
                      </div>
                    </Link>
                  </Col>
                  <Col style={{ marginRight: "14px" }}>
                    <Link to="/connect/chat">
                      <Button
                        type="text"
                        className=""
                        icon={
                          <Avatar
                            shape="square"
                            className="chat-menu-icon-avatar"
                          >
                            <Badge
                              offset={[0, 20]}
                              size="small"
                              count={messageCount}
                            >
                              <ChatLight />
                            </Badge>
                          </Avatar>
                        }
                      />
                    </Link>
                  </Col>
                  <Col>
                    <Notifications currentPage={getSelectedKey()} />
                  </Col>
                  <Col>
                    <Dropdown
                      placement="bottomCenter"
                      overlay={menu}
                      trigger={["click"]}
                    >
                      <a className="ant-dropdown-link" href="/#">
                        <span
                          className="nav-button-dropdown"
                          style={{ color: color, padding: "0px 5px 0px 10px" }}
                        >
                          Instellingen{" "}
                          <DownOutlined
                            style={{
                              fontSize: "14px",
                              paddingLeft: "5px",
                              color: "#35DF90",
                            }}
                          />
                        </span>
                      </a>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <Row style={{ borderTop: "1px solid #FFF" }}>
            {(!user.is_payment_active || !user.is_finqle_active) && (
              <div className="account-activation">{getBannerMessage()}</div>
            )}
          </Row>
        </Col>
        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          <Row justify="space-between" style={{ backgroundColor: "#f6f6f6" }}>
            <Col
              style={{
                paddingTop: "1rem",
                paddingLeft: "1.5rem",
              }}
            >
              <Link to="/dashboard">
                <img
                  className="mobile-logo"
                  src={CompanyLogo}
                  alt="Company Logo"
                ></img>
              </Link>
            </Col>
            <Col>
              <Row>
                {getSelectedKey()[0] !== "loading" && (
                  <>
                    <Col style={{ display: "grid", placeItems: "center" }}>
                      <Link to="/wallet" style={{ cursor: "pointer" }}>
                        <div className="header-wallet-mobile">
                          {data?.amount ? (
                            `€${Number(data?.amount)}`
                          ) : (
                            <Spin
                              indicator={loadIcon}
                              style={{ paddingTop: 3 }}
                            />
                          )}
                        </div>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/connect/chat">
                        <Button
                          type="text"
                          className="mobile-menu-button"
                          icon={
                            <Avatar
                              shape="square"
                              size={64}
                              style={{ top: "3px" }}
                              className="mobile-menu-icon-avatar"
                            >
                              <Badge
                                offset={[0, 25]}
                                size="small"
                                count={messageCount}
                              >
                                <ChatDark />
                              </Badge>
                            </Avatar>
                          }
                        />
                        <Col></Col>
                      </Link>
                    </Col>
                  </>
                )}
                {setSearchInputValue && (
                  <Col>
                    <Button
                      type="text"
                      className="mobile-menu-button"
                      icon={
                        <Avatar
                          shape="square"
                          size={64}
                          className="mobile-menu-icon-avatar"
                        >
                          <Badge offset={[4, 25]} size="small" count={0}>
                            <SearchOutlined
                              className={
                                openSearch
                                  ? "mobile-menu-button-icon-active"
                                  : "mobile-menu-button-icon"
                              }
                            />
                          </Badge>
                        </Avatar>
                      }
                      onClick={() => setOpenSearch(!openSearch)}
                    />
                  </Col>
                )}
                <Col style={{ marginRight: "10px" }}>
                  <Notifications currentPage={getSelectedKey()} />
                </Col>
              </Row>
            </Col>
          </Row>

          {openSearch && (
            <Row justify="center" style={{ marginTop: "12px" }}>
              <Col span={21}>
                <Input
                  defaultValue={searchInputValue}
                  style={{ width: "100%" }}
                  placeholder="Zoeken"
                  onChange={handleOnchange}
                />
              </Col>
            </Row>
          )}

          <div className="mobile-nav-bar">
            <Menu
              defaultSelectedKeys={getSelectedKey()}
              style={{
                backgroundColor: "#002333",
              }}
            >
              <Menu.Item
                className="mobile-nav-items"
                icon={<SearchOutlined style={{ fontSize: "24px" }} />}
                key="1"
              >
                <Link to="/search" />
              </Menu.Item>
              <Menu.Item
                className="mobile-nav-items"
                icon={<RetweetOutlined style={{ fontSize: "24px" }} />}
                key="3"
              >
                <Link to="/connect/connections" />
              </Menu.Item>
              <Menu.Item
                className="mobile-nav-items"
                icon={<CreditCardOutlined style={{ fontSize: "24px" }} />}
                key="2"
              >
                <Link to="/work/project" />
              </Menu.Item>
              <Menu.Item
                className="mobile-nav-items"
                icon={<UserOutlined style={{ fontSize: "24px" }} />}
                key="6"
              >
                <Link to="/mobilesubmenu" />
              </Menu.Item>
            </Menu>
          </div>

          <Row style={{ borderTop: "1px solid #FFF" }}>
            {(!user.is_payment_active || !user.is_finqle_active) && (
              <div className="account-activation">{getBannerMessage()}</div>
            )}
          </Row>
        </Col>
      </Row>
    </section>
  );
}
