import { useState } from "react";
import { Row, Col, Layout, Input, Divider, Pagination, Grid } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import InvoiceItem from "./InvoiceItem";
import "./styles.css";
import { UserTypes } from "src/types/user.type";
import { useQuery } from "react-query";
import axios from "axios";
import { Invoice } from "src/types/work.type";
import { useUser } from "src/hooks/useUser";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import EmptyLogo from "src/empty-state.svg";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Invoice[];
};

export default function Invoices() {
  const { Content } = Layout;
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const screens = Grid.useBreakpoint();

  const { data } = useQuery<Response>("invoiceList", async () => {
    return (await axios.get(`${BASE_URL}api/invoices/`, getHttpHeader())).data;
  });

  const handlePagination = async (page: number) => {
    await setCurrentPage(page);
    // refetch();
  };

  return (
    <>
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout
            className={screens.md ? "container" : "mobile-invoice-layout"}
            style={{ minHeight: "85vh" }}
          >
            <Content
              className={screens.md ? "web-content" : ""}
              style={{ minWidth: "100%" }}
            >
              {screens.md && (
                <>
                  <Row justify="space-between" style={{ marginTop: 25 }}>
                    <Col flex="30%">
                      <div className="web-header">Facturen</div>
                    </Col>
                    <Col flex="auto">
                      <Input
                        size={"large"}
                        placeholder="Zoeken"
                        prefix={<SearchOutlined />}
                        style={{ maxHeight: "45px", padding: "0 11px" }}
                      />
                    </Col>
                  </Row>
                  <Divider className="web-divider-dark" />
                </>
              )}
              <div className={screens.md ? "" : "mobile-invoice-content"}>
                {/* {connectionList && connectionList.map(data => {
                  return <ConnectItem key={data.id} data={data} loading={isLoading} />
                })}
                {connectionListCompany && connectionListCompany.map(data => {
                  return <ConnectItemCompany key={data.id} data={data} loading={isLoading} />
                })} */}
                <div>
                  <Row className="invoice-table-header-row">
                    <Col
                      span={screens.md ? 8 : 4}
                      className="invoice-table-header"
                    >
                      Periode
                    </Col>
                    <Col
                      span={screens.md ? 3 : 4}
                      className="invoice-table-header data-center"
                    >
                      Factuurdatum
                    </Col>
                    <Col
                      span={screens.md ? 3 : 4}
                      className="invoice-table-header data-center"
                    >
                      Vervaldatum
                    </Col>
                    <Col
                      span={screens.md ? 3 : 4}
                      className="invoice-table-header data-center"
                    >
                      Totaalbedrag
                    </Col>
                    <Col
                      span={screens.md ? 4 : 4}
                      className="invoice-table-header data-center"
                    >
                      Download
                    </Col>
                    <Col
                      span={screens.md ? 3 : 4}
                      className="invoice-table-header data-center"
                    >
                      {UserTypes.Freelancer === user.user_type
                        ? `Ontvangen`
                        : `Betaal`}
                    </Col>
                  </Row>
                </div>
                {data &&
                  data?.results?.map((e) => {
                    return (
                      <InvoiceItem
                        proposalId={e.proposal}
                        key={e.id}
                        week={e.week}
                        invoiceId={e.id}
                      />
                    );
                  })}
              </div>

              {screens.md && data?.results?.length === 0 && (
                <Row justify="center" style={{ marginBottom: 20 }}>
                  <img alt="Empty Screen" src={EmptyLogo} width={700} />
                </Row>
              )}
              {!screens.md && data?.results?.length === 0 && (
                <Row justify="center" style={{ marginBottom: 20 }}>
                  <img alt="Empty Screen" src={EmptyLogo} width={300} />
                </Row>
              )}

              <Pagination
                total={data?.count}
                showTotal={(total) => `Totaal ${total} items`}
                pageSize={limit}
                current={currentPage}
                onChange={(value) => handlePagination(value)}
                className="pagination"
                responsive={!screens.md}
              />
            </Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
}
