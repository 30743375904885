import { Menu, Row, Col, Divider, Dropdown, Button } from "antd";
import "./style.css";
import Toggle from "../Connect/Icons/toggle-ff.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "src/store/entity/auth";
import { useState, useEffect } from "react";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";

enum Tabs {
  PROFILE,
  INVOICE,
  USER,
  ACTIVITYLOG,
}

export default function SubHeader() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentMenu, setCurrentMenu] = useState<Tabs>();
  const { user } = useUser();

  useEffect(() => {
    if (location.pathname.includes("/user/profile")) {
      setCurrentMenu(Tabs.PROFILE);
    } else {
      setCurrentMenu(undefined);
    }
  }, [location.pathname]);

  const getHeader = () => {
    if (location.pathname.includes("/user/profile")) {
      return "Bedrijf";
    } else if (location.pathname.includes("/user/invoice")) {
      return "Facturen";
    }
    // else if (location.pathname.includes("/user/accountauthentication")) {
    //   return "Gebruikers";
    // }
    else if (location.pathname.includes("/user/activitylog")) {
      return "Activiteitenlogboek";
    } else {
      return "";
    }
  };

  const getSelectedKey = () => {
    if (location.pathname.includes("/user/profile")) {
      return ["profile"];
    } else if (location.pathname.includes("/user/invoice")) {
      return ["invoice"];
    } else if (location.pathname.includes("/user/accountauthentication")) {
      return ["users"];
    } else if (location.pathname.includes("/user/activitylog")) {
      return ["activitylog"];
    }
    // else if (location.pathname.includes("/user/profile/editprofilefreelancer")) {
    //   return ["editprofilefreelancer"];
    // }
    else {
      return [""];
    }
  };

  const linkStyle = {
    color: "inherit",
    backgroundColor: "inherit",
  };

  const menu = (
    <Menu
      selectedKeys={getSelectedKey()}
      style={{
        backgroundColor: "#664FF3",
      }}
      mode="inline"
    >
      <Menu.ItemGroup
        key="editprofile"
        title={
          <div className="menu-group-item-header">
            <Link
              style={linkStyle}
              to={
                UserTypes.Company === user.user_type ||
                UserTypes.Child_User === user.user_type
                  ? "/user/profile/editprofile"
                  : "/user/profile/editprofilefreelancer"
              }
            >
              Bedrijf
            </Link>
          </div>
        }
      >
        {currentMenu === Tabs.PROFILE && (
          <Menu.Item
            className="mobile-sub-menu-items menu-group-item"
            key="editprofile"
          >
            <Link
              style={linkStyle}
              to={
                UserTypes.Company === user.user_type ||
                UserTypes.Child_User === user.user_type
                  ? "/user/profile/editprofile"
                  : "/user/profile/editprofilefreelancer"
              }
            >
              {UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type
                ? "Bedrijfsaccount"
                : "Persoonlijk account"}
            </Link>
          </Menu.Item>
        )}
        {currentMenu === Tabs.PROFILE &&
          user.user_type === UserTypes.Freelancer && (
            <Menu.Item
              className="mobile-sub-menu-items menu-group-item"
              key="editprofilefreelancer"
            >
              <Link style={linkStyle} to="/user/profile/changecompanydetails">
                Bedrijfsaccount
              </Link>
            </Menu.Item>
          )}
        {currentMenu === Tabs.PROFILE && (
          <Menu.Item
            className="mobile-sub-menu-items menu-group-item"
            key="changepassword"
          >
            <Link style={linkStyle} to="/user/profile/changepassword">
              Wachtwoord wijzigen
            </Link>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
      <Divider
        style={{ padding: "0px", margin: "0px", backgroundColor: "#5440CD" }}
      />
      {/* {UserTypes.Company === user.user_type && (
        <Menu.Item
          className="mobile-sub-menu-items menu-group-item-header"
          key="users"
        >
          <Link style={linkStyle} to="/user/accountauthentication">
            Gebruikers
          </Link>
        </Menu.Item>
      )}
      {UserTypes.Child_User === user.user_type &&
        checkPermission({ user, can: PermissionType.CREATE_ASSIGNMENT }) && (
          <Menu.Item
            className="mobile-sub-menu-items menu-group-item-header"
            key="users"
          >
            <Link style={linkStyle} to="/user/accountauthentication">
              Gebruikers
            </Link>
          </Menu.Item>
        )}
      {(UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type) && (
        <Divider
          style={{ padding: "0px", margin: "0px", backgroundColor: "#5440CD" }}
        />
      )} */}
      <Menu.Item
        className="mobile-sub-menu-items menu-group-item-header"
        key="invoice"
      >
        <Link style={linkStyle} to="/user/invoice">
          Facturen
        </Link>
      </Menu.Item>
      <Divider
        style={{ padding: "0px", margin: "0px", backgroundColor: "#5440CD" }}
      />
      {(UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type) && (
        <Menu.Item
          className="mobile-sub-menu-items menu-group-item-header"
          key="activitylog"
        >
          <Link style={linkStyle} to="/user/activitylog">
            Activiteitenlogboek
          </Link>
        </Menu.Item>
      )}
      {(UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type) && (
        <Divider
          style={{ padding: "0px", margin: "0px", backgroundColor: "#5440CD" }}
        />
      )}
      <Menu.Item
        className="mobile-sub-menu-items menu-group-item-header"
        key="logout"
        onClick={() => dispatch(logout())}
      >
        <Link style={linkStyle} to="/login">
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0} className="sub-menu-bar">
          <Menu
            selectedKeys={getSelectedKey()}
            mode="horizontal"
            className="sub-menu-item"
          >
            <Menu.Item className="sub-menu-item" key="profile">
              <Link
                className="sub-menu-item"
                to={
                  UserTypes.Company === user.user_type ||
                  UserTypes.Child_User === user.user_type
                    ? "/user/profile/editprofile"
                    : "/user/profile/editprofilefreelancer"
                }
              >
                Bedrijf
              </Link>
            </Menu.Item>
            {/* {UserTypes.Company === user.user_type && (
              <Menu.Item className="sub-menu-item" key="users">
                <Link
                  className="sub-menu-item"
                  to="/user/accountauthentication"
                >
                  Gebruikers
                </Link>
              </Menu.Item>
            )}
            {UserTypes.Child_User === user.user_type &&
              checkPermission({
                user,
                can: PermissionType.CREATE_ASSIGNMENT,
              }) && (
                <Menu.Item className="sub-menu-item" key="users">
                  <Link
                    className="sub-menu-item"
                    to="/user/accountauthentication"
                  >
                    Gebruikers
                  </Link>
                </Menu.Item>
              )} */}
            <Menu.Item className="sub-menu-item" key="invoice">
              <Link className="sub-menu-item" to="/user/invoice">
                Facturen
              </Link>
            </Menu.Item>
            {(UserTypes.Company === user.user_type ||
              UserTypes.Child_User === user.user_type) && (
              <Menu.Item className="sub-menu-item" key="activitylog">
                <Link className="sub-menu-item" to="/user/activitylog">
                  Activiteitenlogboek
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Col>
        <Col
          xl={0}
          lg={0}
          md={0}
          sm={24}
          xs={24}
          className="mobile-sub-title-container"
        >
          <div className="mobile-submenu-header">{getHeader()}</div>
          <Divider className="mobile-divider" />
          <div>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomCenter"
            >
              <Button className="mobile-sub-menu-button">
                <img style={{ marginRight: "5px" }} src={Toggle} alt="toggle" />
                Menu
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
}
