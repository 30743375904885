import {
  Input,
  Row,
  Col,
  Grid
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import FreelancerListItem from './FreelancerList-Item';
import { Vacancy } from 'src/types/work.type';

type ProjectDetailsProps = {
  id: number,
  setSelectedProposal: Function,
  projectDetails: Vacancy | undefined
}

export interface FreelancerData {
  id: number,
  name: string,
  rate: number,
  toTraval: number,
  expenses: number,
  outstanding: boolean,
}

export default function FreelancersList({ id, setSelectedProposal, projectDetails }: ProjectDetailsProps) {

  // const [selectedUser, setSelectedUser] = useState(-1);
  const screens = Grid.useBreakpoint();

  return (
    <>
      <Row>
        <Input size={'large'} placeholder="Zoeken" prefix={<SearchOutlined />} style={{ maxHeight: '45px', padding:'0 11px' }}/>
      </Row>
      <br />
      <Row className='freelancer-list-header'>
        <Col span={screens.md ? 9 : 14} style={{ textAlign: 'start' }}>
          Naam
        </Col>
        {screens.md && <Col span={3}>
          Tarief
        </Col>}
        {screens.md && <Col span={3}>
          Reizen
        </Col>}
        {screens.md && <Col span={3}>
          Onkosten
        </Col>}
        <Col span={screens.md ? 6 : 10}>
          Openstaand
        </Col>
      </Row>

      {projectDetails && projectDetails.proposals.map(e => {
        return <FreelancerListItem
          key={e.id}
          proposalData={e}
          setSelectedUser={setSelectedProposal} />
      })}
    </>
  );
}