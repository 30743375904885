import {
  EuroCircleOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  RetweetOutlined,
  SearchOutlined,
  SettingOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Col, Divider, Grid, Layout, Modal, Row } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { DashboardNavButton } from "src/components/atoms/DashboardNavButton";
import WalletWidget from "src/components/Wallet/Wallet-Widget";
import { useUser } from "src/hooks/useUser";
import { RootStateType } from "src/store/rootReducer";
import { NotificationContent } from "src/types/Notification.type";
import { UserTypes } from "src/types/user.type";
import AppHeader from "../../components/header/AppHeader";
import Avatar from "../../images/json/user_image.json";
import AddVacancy from "../Work/Vecancies/AddVecancyForm";
import "./styles.css";

export function Home() {
  const data: NotificationContent[] = useSelector(
    (state: RootStateType) => state.Notification.notifications
  );
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();
  const { user, getUserDetails } = useUser();
  const [isAdding, setAdding] = useState(false);

  const BASE_URL = process.env.REACT_APP_BACKEND;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const activityData = useQuery(`Activities`, async () => {
    return (await axios.get(`${BASE_URL}api/activities/`, config)).data;
  });

  useEffect(() => {
    if (!user?.user_type) getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getNotifications = () => {
    let tempList: any[] = [];
    if (data.length !== 0) {
      data &&
        data.forEach((e) => {
          tempList.push(
            <div className="dashboard-notification">{e.content.message}</div>
          );
        });
    } else {
      tempList.push(
        <div className="dashboard-notification">
          {"Er zijn geen notificaties."}
        </div>
      );
    }
    return tempList;
  };

  const getActivityLog = () => {
    let tempList: any[] = [];

    activityData.data?.results?.forEach((e: any) => {
      tempList.push(
        <div className="dashboard-notification">{e.activity_message}</div>
      );
    });

    tempList.push(
      <div className="dashboard-notification">
        {"Er zijn nog geen activiteiten."}
      </div>
    );
    return tempList;
  };

  return (
    <>
      <Modal
        visible={isAdding}
        onCancel={() => setAdding(false)}
        title={"Vacature aanmaken"}
        width={screens.md ? "75%" : "90%"}
        footer={null}
      >
        <AddVacancy setAdding={setAdding} />
      </Modal>
      <Route exact path="/dashboard">
        <AppHeader />
        <Layout className="container">
          <Row justify={"center"}>
            <Col xl={24} lg={24} md={24} sm={21} xs={21}>
              <div>
                <Content
                  style={
                    screens.md
                      ? {
                          marginTop: 20,
                          padding: "0 100px",
                        }
                      : {}
                  }
                >
                  <Row justify="space-between">
                    <Col span={screens.md ? 12 : 24}>
                      <Row justify="space-between">
                        <Col span={screens.md ? 18 : 24}>
                          {screens.md ? (
                            <div className="dashboard-header">
                              {`Hi ${
                                user?.first_name?.charAt(0)?.toUpperCase() +
                                user?.first_name?.substring(
                                  1,
                                  user?.first_name?.length
                                )
                              },`}
                            </div>
                          ) : (
                            <div className="mobile-header-dashboard">
                              Dashboard
                            </div>
                          )}
                          <Divider
                            style={{
                              backgroundColor: "darkgray",
                              padding: "0px 0px",
                              margin: "5px 0px 12px 0px",
                            }}
                          />
                          {screens.md ? (
                            <Row>
                              <Col span={24}>
                                <div className="sub-header-home">
                                  Je bent aangemeld op het account van: <br />
                                  {user.company_name}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            UserTypes.Company === user.user_type && (
                              <Row
                                justify="center"
                                className="dashboard-nav-btn-mobile"
                              >
                                {user.company_name}
                              </Row>
                            )
                          )}
                        </Col>
                        {UserTypes.Freelancer === user.user_type && (
                          <Col
                            span={screens.md ? 6 : 24}
                            style={
                              screens.md
                                ? { position: "relative", bottom: "-50px" }
                                : {
                                    textAlign: "center",
                                    marginBlockEnd: "24px",
                                    marginBlockStart: "24px",
                                  }
                            }
                          >
                            <img
                              className="dashboard-profile-img"
                              src={user.profile_pic ?? Avatar.img}
                              alt="user_pic"
                            />

                            {!screens.md && (
                              <div className="dashboard-nav-btn-mobile">
                                {user.company_name}
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col
                      span={screens.md ? 11 : 24}
                      style={screens.md ? {} : { marginBlockEnd: "20px" }}
                    >
                      <WalletWidget />
                    </Col>
                  </Row>

                  {screens.md && (
                    <>
                      <div className="dashboard-header">Ga direct naar:</div>
                      <Divider
                        style={{
                          backgroundColor: "darkgray",
                          padding: "0px 0px",
                          margin: "12px 0px 24px 0px",
                        }}
                      />
                    </>
                  )}
                  <Row>
                    <Col
                      span={24}
                      style={screens.md ? {} : { overflow: "scroll" }}
                    >
                      {UserTypes.Freelancer === user.user_type && (
                        <>
                          {screens.md ? (
                            <>
                              <Row
                                justify="space-between"
                                className="row-margin-end-20"
                              >
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<SearchOutlined />}
                                    title="Opdrachten zoeken"
                                    backgroundColor="#35DF90"
                                    color="white"
                                    gotoLink="/search"
                                  />
                                </Col>
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<SettingOutlined />}
                                    title="Mijn profiel bijwerken"
                                    backgroundColor="#664FF3"
                                    color="white"
                                    gotoLink="/user/profile/changecompanydetails"
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                className="row-margin-end-20"
                              >
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<RetweetOutlined />}
                                    title="Mijn netwerk beheren"
                                    gotoLink="connect/connections"
                                  />
                                </Col>
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<EuroCircleOutlined />}
                                    title="Mijn facturen inzien"
                                    gotoLink="/user/invoice"
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                className="row-margin-end-20"
                              >
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<FileTextOutlined />}
                                    title="Mijn projecten bekijken"
                                    gotoLink="work/project"
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <DashboardNavButton
                                icon={<SearchOutlined />}
                                title="Opdrachten zoeken"
                                backgroundColor="#35DF90"
                                color="white"
                                gotoLink="/search"
                              />
                              <DashboardNavButton
                                icon={<SettingOutlined />}
                                title="Mijn profiel bijwerken"
                                backgroundColor="#664FF3"
                                color="white"
                                gotoLink="/user/profile/changecompanydetails"
                              />
                              <DashboardNavButton
                                icon={<RetweetOutlined />}
                                title="Mijn netwerk beheren"
                                gotoLink="connect/connections"
                              />
                              <DashboardNavButton
                                icon={<FileTextOutlined />}
                                title="Mijn projecten bekijken"
                                gotoLink="work/project"
                              />
                              <DashboardNavButton
                                icon={<EuroCircleOutlined />}
                                title="Mijn facturen inzien"
                                gotoLink="/user/invoice"
                              />
                            </>
                          )}
                        </>
                      )}

                      {UserTypes.Company === user.user_type && (
                        <>
                          {screens.md ? (
                            <>
                              <Row
                                justify="space-between"
                                className="row-margin-end-20"
                              >
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<SearchOutlined />}
                                    title="ZZP’ers zoeken"
                                    gotoLink="/search"
                                  />
                                </Col>
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<PlusCircleOutlined />}
                                    title="Job plaatsen"
                                    backgroundColor="#35DF90"
                                    color="white"
                                    onClick={() => setAdding(true)}
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                className="row-margin-end-20"
                              >
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<RetweetOutlined />}
                                    title="Netwerk beheren"
                                    gotoLink="connect/connections"
                                  />
                                </Col>
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<SettingOutlined />}
                                    title="Bedrijfsgegevens bijwerken"
                                    backgroundColor="#664FF3"
                                    color="white"
                                    gotoLink="user/profile/editprofile"
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                className="row-margin-end-20"
                              >
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<FileTextOutlined />}
                                    title="Projecten beheren"
                                    gotoLink="work/project"
                                  />
                                </Col>
                                <Col flex={"49%"}>
                                  <DashboardNavButton
                                    icon={<SolutionOutlined />}
                                    title="Mijn vacatures bekijken"
                                    gotoLink="work/vacancies"
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <DashboardNavButton
                                icon={<SettingOutlined />}
                                title="Bedrijfsgegevens bijwerken"
                                backgroundColor="#664FF3"
                                color="white"
                                gotoLink="user/profile/editprofile"
                              />
                              <DashboardNavButton
                                icon={<PlusCircleOutlined />}
                                title="Job plaatsen"
                                backgroundColor="#35DF90"
                                color="white"
                                onClick={() => setAdding(true)}
                              />
                              <DashboardNavButton
                                icon={<SolutionOutlined />}
                                title="Mijn vacatures bekijken"
                                gotoLink="work/vacancies"
                              />
                              <DashboardNavButton
                                icon={<SearchOutlined />}
                                title="ZZP’ers zoeken"
                                gotoLink="/search"
                              />
                              <DashboardNavButton
                                icon={<RetweetOutlined />}
                                title="Netwerk beheren"
                                gotoLink="connect/connections"
                              />
                              <DashboardNavButton
                                icon={<FileTextOutlined />}
                                title="Projecten beheren"
                                gotoLink="work/project"
                              />
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>

                  {screens.md ? (
                    <>
                      <div className="dashboard-header">Informatie</div>
                      <Divider
                        style={{
                          backgroundColor: "darkgray",
                          padding: "0px 0px",
                          margin: "5px 0px 12px 0px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <br />
                    </>
                  )}
                  <Row
                    justify="space-between"
                    style={
                      screens.md
                        ? {
                            marginBlockStart: "23px",
                            marginBlockEnd: "38px",
                            // backgroundColor: 'red'
                          }
                        : { marginBlockEnd: "75px" }
                    }
                  >
                    <Col
                      flex={screens.md ? "49%" : "100%"}
                      style={
                        screens.md
                          ? {
                              backgroundColor: "#FFF",
                              padding: "10px",
                              textAlign: "center",
                              borderRadius: "10px",
                            }
                          : {
                              backgroundColor: "#FFF",
                              padding: "10px",
                              textAlign: "center",
                              borderRadius: "10px",
                              marginBlockEnd: "20px",
                            }
                      }
                    >
                      <div
                        className={
                          screens.md ? "chart-header" : "sub-headers-dashboard"
                        }
                        style={{ color: "#000233" }}
                      >
                        Notificaties
                      </div>
                      {getNotifications()}
                    </Col>
                    <Col
                      flex={screens.md ? "49%" : "100%"}
                      style={{
                        backgroundColor: "#FFF",
                        padding: "10px",
                        textAlign: "center",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        className={
                          screens.md ? "chart-header" : "sub-headers-dashboard"
                        }
                        style={{ color: "#000233" }}
                      >
                        Activiteiten Logboek
                      </div>
                      {getActivityLog()}
                    </Col>
                  </Row>
                </Content>
              </div>
            </Col>
          </Row>
        </Layout>
      </Route>
    </>
  );
}
