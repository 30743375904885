import React, { useEffect, useRef, Dispatch, useState } from "react";
import { Avatar, AvatarGroup, Box, Text } from "@chakra-ui/react";
import MessageBox from "./message-box";
import MessageInput from "./message-input";
import { Popconfirm, Divider } from "antd";
import {
  ActionType,
  Contact,
  initialState,
} from "../../store/reducers/chat.reducer";
import { ChatService } from "../../services/chat.service";
import { ChatMessage, MessageType } from "../../types/chat-message.type";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
// import nl from "dayjs/locale/nl";
import {
  JobProposal,
  ProposalAcceptedMessage,
} from "../../types/job-proposal.type";
import Proposal from "../proposal/proposal";
import "./styles.css";
import { Grid, Button } from "antd";
import { useQuery } from "react-query";
import { Company, User } from "src/types/user.type";
import axios from "axios";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import { useUser } from "src/hooks/useUser";
import AddProjectForm from "./add-project-form";
import userAvatar from "../../images/json/user_image.json";
import { formatRelative } from "date-fns";
import { nl } from "date-fns/esm/locale";

dayjs.extend(relativeTime);

const DeleteIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.25 4H14.25V2.125C14.25 1.29766 13.5773 0.625 12.75 0.625H5.25C4.42266 0.625 3.75 1.29766 3.75 2.125V4H0.75C0.335156 4 0 4.33516 0 4.75V5.5C0 5.60313 0.084375 5.6875 0.1875 5.6875H1.60312L2.18203 17.9453C2.21953 18.7445 2.88047 19.375 3.67969 19.375H14.3203C15.1219 19.375 15.7805 18.7469 15.818 17.9453L16.3969 5.6875H17.8125C17.9156 5.6875 18 5.60313 18 5.5V4.75C18 4.33516 17.6648 4 17.25 4ZM5.4375 2.3125H12.5625V4H5.4375V2.3125ZM14.1398 17.6875H3.86016L3.29297 5.6875H14.707L14.1398 17.6875Z"
      fill="#A5A6A7"
    />
  </svg>
);

type MessagesWindowProps = {
  dispatch: Dispatch<ActionType>;
  state: typeof initialState;
  selectedContact: Contact;
  chatService: ChatService;
};

const ChatDivider = ({ when }: { when: number | undefined }) => {
  let ret = "";
  if (!when) return <></>;
  const relativeDay = formatRelative(when, new Date(), {
    locale: nl,
    weekStartsOn: 1,
  }).split(" ")[0];

  if (relativeDay === "vandaag" || relativeDay === "gisteren")
    ret = relativeDay.charAt(0).toUpperCase() + relativeDay.slice(1);
  else ret = dayjs(when).format("DD-MM-YYYY");

  return (
    <Divider style={{ fontWeight: 500, color: "#A5A6A7" }} plain>
      {ret}
    </Divider>
  );
};

const MessagesWindow = ({
  dispatch,
  state,
  selectedContact,
  chatService,
}: MessagesWindowProps) => {
  const [proposalVisible, setProposalVisible] = useState(false);
  const [proposal, setProposal] = useState<JobProposal | null>(null);

  const [projectFormVisible, setProjectFormVisible] = useState(false);
  const [proposalId, setProposalId] = useState(null);

  const bottomRef = useRef<HTMLInputElement | null>(null);
  const messageInputRef = useRef<HTMLInputElement | null>(null);

  const messages = selectedContact.messages;
  const { user } = useUser();

  const scrollToBottom = () => {
    bottomRef.current!.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const sendMessage = (message: string) => {
    console.log(state.user);
    if (!state.user) return;
    const chatMessage: ChatMessage = {
      from: state.user,
      to: selectedContact,
      type: MessageType.text,
      message,
    };
    chatService.sendMessage(chatMessage);
  };

  const sendFile = (file: File) => {
    chatService.sendFile(file, selectedContact);
  };

  const sendProposal = (proposal: JobProposal) => {
    chatService.sendProposal({ ...proposal, to: selectedContact });
  };

  const sendProposalAcceptMessage = (content: ProposalAcceptedMessage) => {
    chatService.sendProposalAcceptedMessage(content);
  };

  const deleteHistory = () => {
    chatService.deleteHistory(selectedContact);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const showProposal = (proposal: JobProposal) => {
    setProposal(proposal);
    setProposalVisible(true);
  };
  useEffect(() => messageInputRef.current?.focus(), [selectedContact]);

  const screens = Grid.useBreakpoint();

  const userData = useQuery<User | Company>(
    `user_details_${selectedContact.id}`,
    async () => {
      if (selectedContact.company) {
        return (
          await axios.get(
            `${BASE_URL}api/companies/${selectedContact.id}`,
            getHttpHeader()
          )
        ).data;
      } else {
        return (
          await axios.get(
            `${BASE_URL}api/freelancers/${selectedContact.id}`,
            getHttpHeader()
          )
        ).data;
      }
    },
    { enabled: false }
  );

  return (
    <Box
      className={
        screens.md
          ? "message-window-container"
          : "message-window-container message-window-container-mobile"
      }
    >
      {!screens.md && (
        <Button
          block
          className="message-input-send-proposal-btn-mobile"
          onClick={() => {
            setProposal(null);
            setProposalVisible(true);
          }}
        >
          Job voorstel
        </Button>
      )}
      <Box
        className={
          screens.md ? "message-window-header" : "message-window-header-mobile"
        }
      >
        <AvatarGroup spacing="-1.5rem">
          <Avatar
            size="md"
            src={user.profile_pic || userAvatar.img}
            name={user.first_name}
            bg="teal.500"
          />
          <Avatar
            bg="white"
            src={userData.data?.profile_pic || userAvatar.img}
            name={userData.data?.first_name}
            zIndex="10"
          />
        </AvatarGroup>
        <Text fontWeight="600" ml="10px">
          {selectedContact.company
            ? `${selectedContact.name}`
            : `${selectedContact.firstName} ${selectedContact.lastName}`}
        </Text>
        <Box ml="auto">
          <Popconfirm
            placement="bottomRight"
            overlayClassName="chat-delete"
            title={
              <div
                style={{
                  width: "240px",
                  fontSize: "14px",
                  fontFamily: "Arimo",
                }}
              >
                Weet je zeker dat je de chat wilt verwijderen?
              </div>
            }
            onConfirm={deleteHistory}
            // onCancel={}
            okText="Ja, verwijderen"
            cancelText="Annuleren"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">
              <DeleteIcon />
            </a>
          </Popconfirm>
        </Box>
      </Box>
      <Box
        className={
          screens.md ? "message-display-panel" : "message-display-panel-mobile"
        }
      >
        {messages
          .sort((a, b) => a!.ts! - b!.ts!)
          .map((message, i) => {
            let divider;
            let hideTime = false;
            if (
              i === 0 ||
              !dayjs(message.ts).isSame(dayjs(messages[i - 1].ts), "day")
            ) {
              divider = <ChatDivider when={message.ts} />;
            }
            if (
              i > 0 &&
              dayjs(message.ts).diff(dayjs(messages[i - 1].ts), "minute") < 1
            ) {
              hideTime = true;
            }
            return (
              <>
                {divider}
                <MessageBox
                  showProposal={showProposal}
                  message={message}
                  hideTime={hideTime}
                  key={i}
                  sent={message.from?.id === state.user?.id}
                  setProjectFormVisible={setProjectFormVisible}
                  setProposalId={setProposalId}
                  selectedContact={selectedContact}
                />
              </>
            );
          })}
        <div ref={bottomRef} className="list-bottom"></div>
      </Box>
      <Proposal
        visible={proposalVisible}
        userId={selectedContact.id}
        proposal={proposal}
        contact={selectedContact}
        setVisible={setProposalVisible}
        sendProposal={sendProposal}
        setProposal={setProposal}
        sendProposalAcceptMessage={sendProposalAcceptMessage}
        setProjectFormVisible={setProjectFormVisible}
      />
      <MessageInput
        disabled={!!selectedContact.deleted}
        messageInputRef={messageInputRef}
        sendMessage={sendMessage}
        sendFile={sendFile}
        setProposalVisible={setProposalVisible}
        setProposal={setProposal}
      />
      {/* {UserTypes.Company === user.user_type && ( */}
      <AddProjectForm
        modalVisible={projectFormVisible}
        setModalVisible={setProjectFormVisible}
        proposalid={proposalId}
      />
      {/* )} */}
    </Box>
  );
};

export default MessagesWindow;
