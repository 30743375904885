import { Row, Col, Grid, Button } from "antd";
import MessageDark from "src/icons/comment_dark.svg";
import { Hours } from "src/types/work.type";
import moment from "moment";
import { Link } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMemo } from "react";

type HourlyOverviewItemProps = {
  hoursData: Hours;
  shouldRenderButtons: boolean;
  onAcceptHours: (invoiceId: number, hourId: number) => void;
  onRejectHours: (invoiceId: number, hourId: number) => void;
};

export default function HourlyOverviewItem({
  hoursData,
  shouldRenderButtons,
  onAcceptHours,
  onRejectHours,
}: HourlyOverviewItemProps) {
  const screens = Grid.useBreakpoint();

  // console.log("USER ===> ", user);

  //* company can accept/reject a hourset with finqle invoice
  const showButtons = useMemo(() => {
    if (
      shouldRenderButtons &&
      !hoursData.is_finqle_accepted_or_rejected &&
      hoursData.hours_submitted_to_finqle &&
      !hoursData.company_submitted
    ) {
      return true;
    }

    return false;
  }, [hoursData, shouldRenderButtons]);

  // console.log("Hours Data ===> ", hoursData);

  // console.log(
  //   "💥 showButtons ",
  //   "\n",
  //   showButtons,
  //   "\n",
  //   shouldRenderButtons,
  //   "\n",
  //   !hoursData.is_finqle_accepted_or_rejected,
  //   "\n",
  //   hoursData.hours_submitted_to_finqle,
  //   "\n",
  //   !hoursData.company_submitted
  // );

  return (
    <Row
      justify="space-between"
      style={
        screens.md
          ? { width: "100%", backgroundColor: "white" }
          : { minWidth: "700px" }
      }
    >
      <Col
        span={6}
        className="hourly-overview-item-freelancer"
        style={{ textAlign: "left" }}
      >
        {/* Maandag (02-05-2020) */}
        {moment(hoursData.date).format("dddd (DD-MM-YYYY)")}
      </Col>
      <Col span={3} className="hourly-overview-item-freelancer">
        {Number(hoursData.hours)} uur
      </Col>
      <Col span={3} className="hourly-overview-item-freelancer">
        {Number(hoursData.kilometers)} KM
      </Col>
      <Col span={3} className="hourly-overview-item-freelancer">
        €{Number(hoursData.expenses)}
      </Col>
      <Col span={5} className="hourly-overview-item-freelancer">
        {hoursData.description}
      </Col>
      <Col span={1} className="hourly-overview-item-freelancer">
        <Link to="/connect/chat">
          <img src={MessageDark} alt="chat" />
        </Link>
      </Col>
      <Col
        span={3}
        style={{ display: "flex" }}
        className={
          hoursData.company_submitted
            ? "hourly-overview-item-freelancer"
            : "hourly-overview-item-freelancer"
        }
      >
        {
          hoursData.company_submitted && (
            <Col span={12}>
              <CheckOutlined />
            </Col>
          )
        }
        {showButtons && (
          <>
            {(!hoursData.company_submitted || !hoursData.is_hours_rejected) && (
              <Col span={12}>
                <Button
                  ghost
                  size="small"
                  className="hourly-overview-btn"
                  style={{ color: "white", display: "inline-block" }}
                  onClick={() => onRejectHours(hoursData.invoice, hoursData.id)}
                  disabled={hoursData.company_submitted}
                >
                  <CloseOutlined />
                </Button>
              </Col>
            )}
            {(!hoursData.company_submitted || hoursData.is_hours_accepted) && (
              <Col span={12}>
                <Button
                  size="small"
                  className="hourly-overview-btn"
                  style={{
                    backgroundColor: "#35DF90",
                    color: "white",
                    display: "inline-block",
                  }}
                  onClick={() => onAcceptHours(hoursData.invoice, hoursData.id)}
                  disabled={hoursData.company_submitted}
                >
                  <CheckOutlined />
                </Button>
              </Col>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}
