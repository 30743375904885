import { useState } from "react";
import { Col, Row, Divider, Button, Grid } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  NotificationContent,
  NotificationType,
} from "src/types/Notification.type";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import getRelativeTime from "../../utils/getRelativeTime";

type NotificationItemProps = {
  notificationData: NotificationContent;
  markNotificationRead: Function;
  sendFetchEvent: Function;
};

export default function Notification({
  notificationData,
  markNotificationRead,
  sendFetchEvent,
}: NotificationItemProps) {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const screens = Grid.useBreakpoint();
  const history = useHistory();

  const [acceptLoading, setacceptLoading] = useState(false);
  const [cancelLoading, setcancelLoading] = useState(false);
  const [acceptDisabled, setacceptDisabled] = useState(false);
  const [cancelDisabled, setcancelDisabled] = useState(false);
  const [visible, setVisible] = useState(true);

  const enterLoading = (value: string) => {
    if (value === "cancel") {
      setacceptLoading(true);
      setcancelDisabled(true);
      acceptConnectionRequest(notificationData.id, false);
    } else if (value === "accept") {
      setcancelLoading(true);
      setacceptDisabled(true);
      acceptConnectionRequest(notificationData.id, true);
    }
  };

  const followCompany = async (companyId: any) => {
    console.log("~Notification-item.tsx ~ companyId ", companyId);
    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}api/follow/`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          follow_to_id: companyId,
        },
      });

      console.log("~Notification-item.tsx ~ followCompany ~ FOLLOW SUCCESS");
    } catch (error) {
      console.log("~Notification-item.tsx ~ followCompany ~ ", error);
    }
  };

  const acceptConnectionRequest = async (id: any, isAccept: boolean) => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const requestPayload = isAccept
        ? {
            is_accepted: true,
          }
        : { is_rejected: true };

      // console.log(requestPayload)

      await axios({
        method: "PUT",
        url: `${BASE_URL}api/connection/${notificationData.content.connection.id}/`,
        headers: config.headers,
        data: requestPayload,
      });

      await followCompany(notificationData.from);
      markNotificationRead(notificationData.id, true);
      sendFetchEvent();
      setVisible(false);

      setcancelLoading(false);
      setacceptDisabled(false);
      setacceptLoading(false);
      setcancelDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNotificationClicked = () => {
    markNotificationRead(notificationData.id, false);

    if (NotificationType.ConnectionAccepted === notificationData.type) {
      history.push(`/connect/connections/${notificationData.from}`);
    } else if (NotificationType.VacancyCreated === notificationData.type) {
      history.push(`/search/${notificationData.from}`);
    } else if (NotificationType.HourSubmitted === notificationData.type) {
      history.push(
        `/work/project/proposal/${notificationData.content.invoice.proposal}?inv_id=${notificationData.content.invoice.id}&week=${notificationData.content.invoice.week}`
      );
    } else if (NotificationType.InterestShown === notificationData.type) {
      history.push(
        `/work/vacancies?vacancyId=${notificationData.content.vacancy.id}`
      );
    }
  };

  return (
    <div
      style={{
        width: screens.md ? 402 : "auto",
        backgroundColor: "#002333",
        color: "white",
        padding: "0px 10px 0px 10px",
        fontFamily: "Basier Square",
        cursor: "pointer",
      }}
    >
      <Row justify="space-between" style={{ fontFamily: "Basier Square" }}>
        <Col
          span={16}
          onClick={handleNotificationClicked}
          style={{
            marginRight: "1rem",
          }}
        >
          <Row justify="start">
            {!notificationData.date_read && (
              <Col span={1}>
                <div className="dot" />
              </Col>
            )}
            <Col span={23}>
              <span className="notification-content">
                {notificationData && notificationData.content.message}
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#A5A6A7",
                    fontSize: "14px",
                  }}
                >
                  {getRelativeTime(notificationData.date_created * 1000)}
                </span>
              </span>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            paddingTop: "5px",
          }}
        >
          <div className="notification">
            {notificationData &&
              notificationData.type === NotificationType.ConnectionAccepted && (
                <Button
                  style={{
                    backgroundColor: "#664FF3",
                    color: "white",
                    border: "0px",
                  }}
                >
                  {/* <Link to={`/connect/connections/${data.content.connection.id}`}>Bekijk</Link> */}
                  <Link to={`/connect/chat/${notificationData.from}`}>
                    Chat
                  </Link>
                </Button>
              )}
            {notificationData &&
              notificationData.type === NotificationType.ConnectionRequested &&
              visible &&
              !notificationData.content.connection.is_accepted && (
                <>
                  <Button
                    ghost
                    icon={<CloseOutlined />}
                    loading={acceptLoading}
                    disabled={acceptDisabled}
                    onClick={() => enterLoading("cancel")}
                    className="notification-btn-cancel"
                  />
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    loading={cancelLoading}
                    disabled={cancelDisabled}
                    onClick={() => enterLoading("accept")}
                    className="notification-btn-accept"
                  />
                </>
              )}
            {notificationData &&
              notificationData.type === NotificationType.ConnectionRequested &&
              visible &&
              notificationData.content.connection.is_accepted && (
                <div className="notification-accepted-connection">
                  Geaccepteerd
                </div>
              )}
          </div>
        </Col>
      </Row>
      <div></div>
      <Divider
        style={{
          backgroundColor: "#A5A6A7",
        }}
      />
    </div>
  );
}
