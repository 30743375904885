import { useState, useEffect } from "react";
import { useUser } from "src/hooks/useUser";
import { UserTypes } from "src/types/user.type";

type useCalculateNetHourlyRateProps = {
  ratePerHourValue: string;
  paymentTermsValue: string;
};

export default function useCalculateNetHourlyRate({
  ratePerHourValue,
  paymentTermsValue,
}: useCalculateNetHourlyRateProps) {
  const { user } = useUser();
  const [netHourlyRate, setNetHourlyRate] = useState(0);

  useEffect(() => {
    if (paymentTermsValue.toString() === "14") {
      if (
        UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
      ) {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) + 1);
      } else {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) - 1);
      }
    } else {
      if (
        UserTypes.Company === user.user_type ||
        UserTypes.Child_User === user.user_type
      ) {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) + 1.5);
      } else {
        setNetHourlyRate(parseFloat(ratePerHourValue.toString()) - 1.5);
      }
    }
    // eslint-disable-next-line
  }, [ratePerHourValue, paymentTermsValue]);

  return { netHourlyRate };
}
