import { Modal, Button, Row, Col, Grid, message, Tooltip } from "antd";
import { useState } from "react";
import axios from "axios";
import Connected from "src/modules/Connect/Icons/connected.png";
import Pending from "src/modules/Connect/Icons/request_connection.png";
import Spinner from "src/components/Common/Spinner";
import { ConnectionStatusValues } from "src/types/common.type";
import { useQuery } from "react-query";
import { FollowConnection } from "src/types/connections.type";
import { Company } from "src/types/user.type";
import { Link } from "react-router-dom";
import { ReactComponent as ChatDark } from "src/icons/comment_dark.svg";
import userAvatar from "../../images/json/user_image.json";
import { ConnectionType } from "./Connections";

type CompanyDetailModalProps = {
  id: number | undefined;
  setSelectedUserId: Function;
  refetchSearchList: Function;
  connectionType: ConnectionType;
};

type Response = {
  count: number;
  next: string;
  previous: string;
  results: FollowConnection[];
};

export default function CompanyDetailModal({
  id,
  setSelectedUserId,
  refetchSearchList,
  connectionType,
}: CompanyDetailModalProps) {
  const screens = Grid.useBreakpoint();
  const [isFollow, setIsFollow] = useState(false);
  const [isLoadingSync, setLoadingSync] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);

  const BASE_URL = process.env.REACT_APP_BACKEND;
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const companyData = useQuery<Company>(`company_${id}`, async () => {
    if (id)
      return (await axios.get(`${BASE_URL}api/companies/${id}`, config)).data;
  });

  const followData = useQuery<Response>(
    `followData_${companyData.data?.id}`,
    async () => {
      if (companyData.data?.id)
        return (
          await axios.get(
            `${BASE_URL}api/follow?company_id=${companyData.data?.id}`,
            config
          )
        ).data;
    }
  );

  // console.log("===> followData ", followData.data);

  const followCompany = async () => {
    setLoadingSync(true);
    await axios({
      method: "POST",
      url: `${BASE_URL}api/follow/`,
      headers: config.headers,
      data: {
        follow_to_id: companyData.data?.id,
      },
    })
      .then((res) => {
        setLoadingSync(false);
        followData.refetch();
        refetchSearchList();
        setModalVisible(false);
      })
      .catch((err) => {
        setLoadingSync(false);
        console.log("followCompany ", err);
        message.error(err.response.data);
      });
  };

  const unfollowCompany = async () => {
    setLoadingSync(true);
    await axios({
      method: "DELETE",
      url: `${BASE_URL}api/follow/${followData.data?.results[0]?.id}`,
      headers: config.headers,
    })
      .then((res) => {
        setLoadingSync(false);
        followData.refetch();
        refetchSearchList();
        setModalVisible(false);
      })
      .catch((err) => {
        setLoadingSync(false);
        console.log("unfollowCompany ", err);
        // message.error(JSON.stringify(err.response.data));
      });
  };

  const updateConnectionRequest = async (isAccept: boolean) => {
    setLoadingSync(true);
    const requestPayload = {
      is_accepted: isAccept,
      is_unfriend: !isAccept,
    };

    try {
      const res = await axios({
        method: "PUT",
        url: `${BASE_URL}api/connection/${companyData.data?.connection?.id}/`,
        headers: config.headers,
        data: requestPayload,
      });
      setLoadingSync(false);
      console.log("updateConnectionRequest ", res);
    } catch (error) {
      setLoadingSync(false);
      console.log("updateConnectionRequest ", error);
    }
  };

  const handleAcceptRequest = async () => {
    console.log("handleAcceptRequest");
    await updateConnectionRequest(true);
    await followCompany();
  };

  const handleRejectRequest = async () => {
    console.log("handleRejectRequest");
    await updateConnectionRequest(false);
    await unfollowCompany();
  };

  return (
    <Modal
      title={undefined}
      centered
      visible={modalVisible && (id !== undefined ? true : false)}
      footer={null}
      width={screens.md ? "60%" : "90%"}
      onCancel={() => {
        setSelectedUserId(undefined);
        setIsFollow(false);
      }}
      bodyStyle={{
        padding: "0px",
        marginBlockStart: "75px",
        marginBlockEnd: "25px",
      }}
      style={{
        maxWidth: "780px",
      }}
      destroyOnClose={true}
    >
      {companyData.isFetching ? (
        <div
          style={{ minHeight: "612px", display: "grid", placeItems: "center" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <Row justify="space-between" className="border-style-row">
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <div className="parent" style={{ top: "-30px" }}>
                  <Row justify="center">
                    <Col
                      className=""
                      style={{
                        border: "3px solid #FFF",
                        borderRadius: "100%",
                        width: "78px",
                        backgroundColor: "#000233",
                        top: "1px",
                      }}
                    >
                      <img
                        className="image1"
                        style={
                          companyData.data?.connection?.status ===
                          ConnectionStatusValues.CONNECTED
                            ? {}
                            : { filter: "blur(3px)" }
                        }
                        src={companyData.data?.profile_pic || userAvatar.img}
                        alt="company"
                      />
                    </Col>
                  </Row>
                  {companyData.data?.connection?.status ===
                    ConnectionStatusValues.PENDING && (
                    <img
                      className="image2"
                      style={{ top: "50px", left: "52%", height: "28px" }}
                      src={Pending}
                      alt="status"
                    />
                  )}
                  {companyData.data?.connection?.status ===
                    ConnectionStatusValues.CONNECTED && (
                    <img
                      className="image2"
                      style={{ top: "50px", left: "52%", height: "28px" }}
                      src={Connected}
                      alt="status"
                    />
                  )}
                  <div
                    className="freelancer-detail-header-column"
                    style={{ marginBlockStart: "10px" }}
                  >
                    <span
                      className="comapny-detail-header-button"
                      style={{ border: 0 }}
                    >
                      {`${companyData.data?.company_name}`}
                      {companyData.data?.connection?.status ===
                        ConnectionStatusValues.CONNECTED && (
                        <Tooltip
                          title={`Chat with ${companyData?.data?.company_name}`}
                        >
                          <Link to={`/connect/chat/${companyData?.data?.id}`}>
                            <Button type="text">
                              <ChatDark />
                            </Button>
                          </Link>
                        </Tooltip>
                      )}
                    </span>
                  </div>
                  <div className="comapny-detail-header">
                    {companyData.data?.sector.name}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Sector</div>
                  <div>{companyData.data?.sector?.name}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Locatie</div>
                  <div>{companyData.data?.region?.name}</div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Werknemers</div>
                  <div>{companyData.data?.number_of_employees}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <div className="detail-label">Website</div>
                  <div>{companyData.data?.website}</div>
                </Col>
              </Row>
              <Row className="border-style-row">
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell border-style-cell-right"
                      : "freelancer-detail-cell border-style-cell-right"
                  }
                >
                  <div className="detail-label">Bedrijfsomschrijving</div>
                  <div>{companyData.data?.description}</div>
                </Col>
                <Col
                  span={12}
                  className={
                    screens.md
                      ? "comapny-detail-cell"
                      : "freelancer-detail-cell"
                  }
                >
                  <Link to={`/search/${companyData.data?.id}`}>
                    <Button type="link">Toon alle vacatures</Button>
                  </Link>
                </Col>
              </Row>
              <Row justify="end" style={{ marginBlockStart: "24px" }}>
                {connectionType === ConnectionType.FOLLOWING && (
                  <Button
                    block
                    size="large"
                    className="btn-font"
                    loading={isFollow && isLoadingSync}
                    style={{
                      backgroundColor: "#002333",
                      color: "#FFF",
                      border: "0px",
                    }}
                    onClick={() =>
                      followData.data?.count === 0
                        ? followCompany()
                        : unfollowCompany()
                    }
                  >
                    {followData.data?.count === 0
                      ? `Volg bedrijf`
                      : `Ontvolgen`}
                  </Button>
                )}
                {connectionType === ConnectionType.PENDING && (
                  <Button
                    block
                    size="large"
                    className="btn-font"
                    loading={isFollow && isLoadingSync}
                    style={{
                      backgroundColor: "#002333",
                      color: "#FFF",
                      border: "0px",
                    }}
                    onClick={handleAcceptRequest}
                  >
                    Accepteer verzoek
                  </Button>
                )}
                {connectionType === ConnectionType.ACCEPTED && (
                  <Button
                    block
                    size="large"
                    className="btn-font"
                    loading={isFollow && isLoadingSync}
                    style={{
                      backgroundColor: "#002333",
                      color: "#FFF",
                      border: "0px",
                    }}
                    onClick={handleRejectRequest}
                  >
                    Connectie opheffen
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}
