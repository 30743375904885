// import { useState } from 'react';
import SubHeader from "./SubHeader";
import AppHeader from "../../components/header/AppHeader";
import "./style.css";
import ProjectCompany from "./Projects/Company/Projects";
import FinishedProjects from "./FinishedProjects/FinishedProjects";
import Vacancies from "./Vecancies/Vacancies";
import { Route, useLocation } from "react-router-dom";
import ProjectsFreelancer from "./Projects/Freelancer/Projects";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import { checkPermission } from "src/hooks/checkPermission";
import { PermissionType } from "src/types/Permission";
import { useEffect } from "react";
import ProjectDetails from "./Projects/Company/ProjectDetails";

export default function Work() {
  const url: string = "/work";
  const { user } = useUser();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Route path={url}>
      <div style={{ position: "sticky", top: 0, zIndex: 10 }}>
        <AppHeader />
        <SubHeader />
      </div>
      <Route exact={true} path={url + "/project"}>
        {user.user_type === UserTypes.Freelancer && <ProjectsFreelancer />}
        {(user.user_type === UserTypes.Company ||
          (UserTypes.Child_User === user.user_type &&
            checkPermission({
              user,
              can: PermissionType.MANAGE_CONNECTIONS,
            }))) && <ProjectCompany />}
      </Route>
      <Route path={`${url}/project/proposal/:selectedProposal/`}>
        <ProjectDetails />
      </Route>
      <Route path={url + "/finishedproject"}>
        <FinishedProjects />
      </Route>
      <Route path={url + "/vacancies"}>
        <Vacancies />
      </Route>

      {UserTypes.Child_User === user.user_type &&
        checkPermission({ user, can: PermissionType.MANAGE_CONNECTIONS }) && (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              minHeight: "10vh",
            }}
          >
            U heeft geen toestemming voor deze pagina. Neem contact op met de
            beheerder.
          </div>
        )}
    </Route>
  );
}
