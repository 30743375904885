import { Row, Col, Grid } from "antd";
import useCalculateNetHourlyRate from "src/hooks/useCalculateNetHourlyRate";
import { Vacancy } from "src/types/work.type";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";

type ProjectCard = {
  id: number;
  selectedCard: number | undefined;
  setSelectedCard: Function;
  vacancyData: Vacancy | undefined;
};

type API_Reponse = {
  count: 123;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      hours: string;
      date: string;
      description: string;
      kilometers: string;
      expenses: string;
      is_submitted: true;
      proposal: number;
      invoice: 0;
    }
  ];
};

export default function ProjectItem({
  id,
  selectedCard,
  setSelectedCard,
  vacancyData,
}: ProjectCard) {
  const screens = Grid.useBreakpoint();

  const { netHourlyRate } = useCalculateNetHourlyRate({
    ratePerHourValue: String(vacancyData?.proposals?.[0]?.gross_rate),
    paymentTermsValue: String(vacancyData?.proposals?.[0]?.payment_terms),
  });

  const MultipleQueries = () => {
    const TotalHours = useQuery<API_Reponse>(
      `Total_Hours_${vacancyData?.proposals?.[0]?.id}`,
      async () => {
        return (
          await axios.get(
            `${BASE_URL}api/hours/?proposal=${vacancyData?.proposals?.[0]?.id}&limit=250`,
            getHttpHeader()
          )
        ).data;
      }
    );
    return { TotalHours };
  };

  const totalHoursForProposal = MultipleQueries()?.TotalHours?.data;
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    let totalHours = 0;
    totalHoursForProposal?.results?.forEach((e: { hours: any }) => {
      totalHours += Number(e.hours);
    });
    setTotalHours(totalHours);
  }, [totalHoursForProposal]);

  return (
    <>
      <div
        onClick={() => {
          setSelectedCard(id);
        }}
        className="projects-card-list-item"
        style={selectedCard === id ? { borderLeft: "3px solid #35DF90" } : {}}
      >
        <Row>
          <Col span={screens.md ? 16 : 24}>
            <div
              className="projects-card-header"
              style={
                screens.md
                  ? { borderBottom: "0px" }
                  : { borderBottom: "0px", padding: "16px 20px" }
              }
            >
              {vacancyData?.title.replaceAll(" - ", " ")}
            </div>
          </Col>
          <Col
            span={screens.md ? 4 : 8}
            style={
              screens.md
                ? { display: "flex", alignItems: "center" }
                : {
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }
            }
          >
            {vacancyData?.proposals?.[0] ? (
              // <span className="project-details-highlighted">
              //   {" "}
              //   €{Number(vacancyData?.proposals?.[0]?.weekly_net_rate)},-
              // </span>
              <span className="project-details-highlighted">
                {" "}
                €
                {(
                  totalHours * Number(vacancyData?.proposals?.[0]?.net_rate)
                ).toFixed(2)}
                ,-{" "}
              </span>
            ) : (
              <span className="project-details-highlighted">€0,-</span>
            )}
          </Col>
          <Col
            span={screens.md ? 4 : 8}
            style={
              screens.md
                ? { display: "flex", alignItems: "center" }
                : {
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }
            }
          >
            {vacancyData?.proposals?.[0] ? (
              <span className="sub-detail-2">€{Number(netHourlyRate)}p/u</span>
            ) : (
              <span className="sub-detail-2">{`€${Number(
                vacancyData?.minimum_rate
              )} - ${Number(vacancyData?.maximum_rate)}p/u`}</span>
            )}
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid #F6F6F6" }}>
          <Col
            flex="1"
            className="projects-card-detail-header"
            style={{
              borderRight: "1px solid #F6F6F6",
              display: "grid",
              placeItems: "center",
            }}
          >
            <span className="sub-detail-1" style={{ marginBottom: "4px" }}>
              Openstaande bedragen
            </span>
            {vacancyData?.proposals?.[0] ? (
              <span className="">
                {vacancyData?.proposals?.[0].invoices.length}
              </span>
            ) : (
              <span className="">0</span>
            )}
          </Col>
          <Col
            flex="1"
            className="projects-card-detail-header"
            style={{
              borderRight: "1px solid #F6F6F6",
              display: "grid",
              placeItems: "center",
            }}
          >
            <span className="sub-detail-1" style={{ marginBottom: "4px" }}>
              ZZP’ers
            </span>
            <span className="">{vacancyData?.proposals.length}</span>
          </Col>
          <Col
            flex="1"
            className="projects-card-detail-header"
            style={{ display: "grid", placeItems: "center" }}
          >
            <span className="sub-detail-1" style={{ marginBottom: "4px" }}>
              Datum
            </span>
            {vacancyData?.proposals?.[0] ? (
              <span className="sub-detail-3">{`${vacancyData?.proposals?.[0]?.start_date}`}</span>
            ) : (
              <span className="sub-detail-3">{`${vacancyData?.start_date}`}</span>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
