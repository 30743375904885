import { Row, Col, Card } from "antd";
import "./style.css";
import { FileSearchOutlined, EnvironmentOutlined } from "@ant-design/icons";
import Connected from "./Icons/connected.png";
import Pending from "./Icons/request_connection.png";
import { CompanyConnection } from "src/types/connections.type";
import { User } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import userAvatar from "../../images/json/user_image.json";

type CompanyConnectionsProps = {
  data: CompanyConnection;
  loading: boolean;
  setSelectedUserId: Function;
};

export default function ConnectItemCompany({
  loading,
  data,
  setSelectedUserId,
}: CompanyConnectionsProps) {
  const { user } = useUser();
  let userData: User =
    data.request_by === user.id
      ? (data.request_to as User)
      : (data.request_by as User);

  const { Meta } = Card;

  return (
    <>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                marginBottom: "10px",
                // minWidth: '1366',
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "8px",
                minWidth: "1366",
                cursor: "pointer",
              }}
              loading={loading}
              bodyStyle={{
                padding: "24px 0px 0px 0px",
              }}
              onClick={() => setSelectedUserId(userData?.id)}
            >
              <Row style={{ paddingBottom: "0px" }}>
                <Col span={20}>
                  <Meta
                    style={{
                      padding: "10px 10px 10px 45px",
                    }}
                    avatar={
                      <div style={{ paddingRight: "20px" }}>
                        <div className="parent">
                          <img
                            className="image1"
                            style={
                              data.is_accepted ? {} : { filter: "blur(3px)" }
                            }
                            src={userData?.profile_pic || userAvatar.img}
                            alt="userData"
                          />
                          {!data.is_accepted ? (
                            <img
                              className="image2"
                              src={Pending}
                              alt="status"
                            />
                          ) : (
                            <img
                              className="image2"
                              src={Connected}
                              alt="status"
                            />
                          )}
                        </div>
                      </div>
                    }
                    title={
                      <span className="web-card-title">
                        {userData?.position && userData?.position?.name}
                      </span>
                    }
                    description={
                      <div>
                        <div>
                          {/* <div className='webspecialization' style={{ marginBlockEnd: '10px' }}>{userData?.sector && userData?.sector.name}</div> */}
                          {userData?.specialty?.map((e: any, index: number) => (
                            <span className="webspecialization">{`${e.name}${
                              index !== userData?.specialty?.length - 1
                                ? ", "
                                : ""
                            }`}</span>
                          ))}
                        </div>
                        <div className="card-body-content">
                          {userData?.description}
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingTop: "40px",
                    paddingLeft: "20px",
                  }}
                >
                  <span className="rate">
                    € {userData?.min_rate} - {userData?.max_rate}
                  </span>
                </Col>
              </Row>
              <Row justify="space-around">
                <Col className="card-inner-border" span={6}>
                  <span className="card-name">
                    {data.is_accepted
                      ? `${userData.first_name} ${userData.last_name}`
                      : `${userData.first_name}`}
                  </span>
                </Col>
                <Col className="card-inner-border" span={6}>
                  <span className="web-card-bottom">
                    {Math.trunc(
                      parseInt(
                        userData?.experience ? userData?.experience : "0"
                      )
                    )}{" "}
                    jaar ervaring
                  </span>
                </Col>
                <Col className="card-inner-border" span={6}>
                  <span className="web-card-bottom">{`${userData.finished_project_count} voltooide jobs`}</span>
                </Col>
                <Col
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #F6F6F6",
                    paddingTop: "12px",
                    paddingBottom: "14px",
                  }}
                  span={6}
                >
                  <span className="web-card-bottom">
                    {userData?.region && userData?.region.name}
                  </span>
                </Col>
              </Row>
            </Card>
          )}
        </Col>

        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          {loading ? (
            <Card
              style={{
                width: "100%",
                marginTop: "16px",
                marginBottom: "10px",
                minWidth: "1366",
              }}
              loading={loading}
              bodyStyle={{
                paddingBottom: "14px",
              }}
            ></Card>
          ) : (
            <Card
              style={{
                width: "100%",
                marginTop: "16px",
                borderRadius: "5px",
              }}
              loading={loading}
              bodyStyle={{
                padding: 14,
              }}
              onClick={() => setSelectedUserId(userData?.id)}
            >
              <Row justify="space-between">
                <Col span={16}>
                  <div className="card-mobile-name-bold">
                    {userData?.first_name}
                  </div>
                  <div className="card-mobile-sub">
                    {userData?.position && userData?.position?.name}
                  </div>
                  <div className="card-mobile-content1">
                    {Math.trunc(
                      parseInt(
                        userData?.experience ? userData?.experience : "0"
                      )
                    )}{" "}
                    jaar ervaring
                  </div>
                  {/* <div className='card-mobile-content2'>0 voltooide jobs</div> */}
                  <Row>
                    <Col style={{ marginRight: "10px" }}>
                      <FileSearchOutlined
                        style={{ margin: "0px 5px", fontSize: "12px" }}
                      />
                      {/* {userData?.specialty?.map((e: any, index: number) =>
                        <span className='mobile-specialization'>{`${e.name}${index !== userData?.specialty?.length - 1 ? ", " : ""}`}</span>
                      )} */}
                      <span className="mobile-specialization">
                        {userData?.sector && userData?.sector.name}
                      </span>
                    </Col>
                    <Col>
                      <EnvironmentOutlined
                        style={{ margin: "0px 5px", fontSize: "12px" }}
                      />
                      <span className="mobile-specialization">
                        {userData?.region && userData?.region.name}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={8}
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <Row justify="end">
                    <Col>
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          paddingRight: "10px",
                        }}
                      >
                        <div className="parent">
                          <img
                            className="image1"
                            style={
                              data.is_accepted ? {} : { filter: "blur(3px)" }
                            }
                            src={userData?.profile_pic || userAvatar.img}
                            alt="userData"
                          />
                          {!data.is_accepted ? (
                            <img
                              className="image2"
                              src={Pending}
                              alt="status"
                            />
                          ) : (
                            <img
                              className="image2"
                              src={Connected}
                              alt="status"
                            />
                          )}
                        </div>
                        <div
                          style={{
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <span className="mobile-rate">
                            {" "}
                            € {userData?.min_rate} - {userData?.max_rate}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}
