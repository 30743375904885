// import { useState } from 'react';
import SubHeader from './SubHeader';
import AppHeader from '../../components/header/AppHeader';
import './style.css';

import User from './User/Users';
import Invoice from './Invoice/Invoice';
import Profile from './Profile/Profile';
import ActivityLog from './Activitylog/ActivityLog';

import { Route } from "react-router-dom";

export default function Profiles() {

  const url: string = '/user'

  return (
    <Route path={url}>
      <AppHeader />
      <SubHeader />
      <Route path={url + "/accountauthentication"} component={User} />
      <Route path={url + "/invoice"} component={Invoice} />
      <Route path={url + "/profile"} component={Profile} />
      <Route path={url + "/activitylog"} component={ActivityLog} />
    </Route >
  );
}