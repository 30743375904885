import {
  Row,
  Col,
  Grid,
  Input,
  message,
  Form
} from 'antd';
import {
  RightOutlined
} from '@ant-design/icons'
import axios from 'axios';

const re = new RegExp(/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/);

export default function Wallet() {

  const screens = Grid.useBreakpoint();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [form] = Form.useForm();

  const handlePressEnter = async (e: any) => {

    const email = form.getFieldValue("email");

    if (re.test(email)) {
      axios({
        method: "POST",
        url: `${BASE_URL}api/account/refer_user/`,
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: {
          "email": email
        }
      }).then(res => {
        message.success("Email is verstuurd, zodra de ontvanger zichzelf heeft geverifieerd zal uw tegoed worden bijgeschreven.")
      }).catch(err => {
        message.error(JSON.stringify(err.response.data))
      })
    } else {
      message.error("Ongeldig e-mailadres")
    }
  }

  return (
    <div className='wallet-widget-web' style={screens.md ? {} : { padding: '20px', minHeight: 'auto' }}>
      <Row justify='center' className='font-bold-arimo' style={screens.md ? { marginBottom: '24px' } : { fontSize: '24px', lineHeight: '32px', marginBlockEnd: '20px' }}>
        Ontvang €10 in je wallet door iemand uit te nodigen
      </Row>
      <Row justify={screens.md ? 'center' : 'space-between'}>
        {/* <Col span={screens.md ? 24 : 4} style={{ textAlign: 'center', marginBlockEnd: '14px' }}>
          <Link to='/wallet'><Button size='large' className='wallet-widget-btn' style={{ backgroundColor: '#664FF3' }}>{screens.md ? `Meer informatie` : <QuestionCircleOutlined style={{ fontSize: '20px' }} />}</Button></Link>
        </Col> */}
        <Col span={24}>
          <Form
            form={form}
            name="wallet-send-form"
            onFinish={handlePressEnter}
            style={{ width: '100%' }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "E-mailadres verplicht",
                }, {
                  type: 'email',
                  message: 'Ongeldig e-mailadres'
                }
              ]}
            >
              <Input type="email" width='100%' size='middle' placeholder='Email' suffix={<RightOutlined size={24} style={{ color: '#BFBFBF' }} onClick={handlePressEnter} />} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}