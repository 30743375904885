import {
  Menu,
  Row,
  Col,
  Divider,
} from 'antd';
import { Link } from 'react-router-dom';
import './style.css'
import 'src/App.less'

export default function SubHeader(props: any) {

  const linkStyle = {
    color: 'inherit'
  }

  return (
    <div className="submenu">
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0} className="sub-menu-bar">
          <Menu
            selectedKeys={[props.currentSubHeader]}
            mode="horizontal"
            className='sub-menu-item'
          >
            <Menu.Item key="network" className='sub-menu-item'><Link style={linkStyle} to="/connect/connections">Netwerk</Link></Menu.Item>
            <Menu.Item key="chat" className='sub-menu-item'><Link style={linkStyle} to="/connect/chat">Chat</Link></Menu.Item>
          </Menu>
        </Col>
        <Col xl={0} lg={0} md={0} sm={24} xs={24} className="mobile-sub-title-container">
          <div className='mobile-submenu-header'>Netwerk</div>
          <Divider className='mobile-divider' />
        </Col>
      </Row>
    </div>
  );
}