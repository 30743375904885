import {
  Row,
  Col,
  Form,
  Grid,
  Input,
  Button,
  InputNumber,
  message,
  DatePicker,
  Modal,
  Divider,
} from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useQuery } from "react-query";
import { Vacancy } from "src/types/work.type";
import ProjectItem from "./Project-Item";
import { SearchOutlined } from "@ant-design/icons";
import { useParams, Redirect } from "react-router-dom";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import SectorSelect from "../../../components/common-inputs/Sector-Select";
import RegionSelect from "../../../components/common-inputs/Region-Select";
import PositionSelect from "../../../components/common-inputs/Position-Select";
import SpecialySelect from "../../../components/common-inputs/Speciality-Select";
import { BASE_URL } from "src/config/default";
import { getHttpHeader } from "src/hooks/useHttpHeader";

enum Options {
  CREATE_PROJECT,
  SELECT_PROJECT,
}

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Vacancy[];
};

export default function AddVacancyForm() {
  const { user } = useUser();

  // @ts-ignore
  const { proposalid } = useParams();

  const screens = Grid.useBreakpoint();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [selectedSector, setSelectedSector] = useState<number | undefined>();
  const [selectedDate, setSeletedDate] = useState<any | undefined>();

  useEffect(() => {
    form.setFields([
      {
        name: "position",
        value: undefined,
      },
    ]);
    // eslint-disable-next-line
  }, [selectedSector]);

  const [modalVisible, setModalVisible] = useState(true);
  const [projectCreateOption, setProjectCreateOption] = useState<Options>();
  const [selectedProject, setSelectedProject] = useState<number | undefined>();

  // const Proposal = useQuery<Proposal>(`Proposal${proposalid}`, async () => {
  //   return (
  //     await axios.get(
  //       `${BASE_URL}api/proposals/${proposalid}`,
  //       getHttpHeader()
  //     )
  //   ).data;
  // });

  // const Freelancer = useQuery<User>(`FreelancerData${Proposal.data?.freelancer}`, async () => {
  //   return (await axios.get(`${BASE_URL}api/freelancers/${Proposal.data?.freelancer}`, config)).data;
  // })

  const { data: vacancyData } = useQuery<Response>("VacancyList", async () => {
    return (
      await axios.get(
        `${BASE_URL}api/vacancies/?limit=50&deleted=false`,
        getHttpHeader()
      )
    ).data;
  });

  const formGroupInputStyleLeftColumn = {
    display: "inline-block",
    width: "calc(45% - 40px)",
    borderRight: "3px solid #f6f6f6",
    padding: "20px 60px",
    marginBottom: "0px",
  };

  const formGroupInputStyleRightColumn = {
    display: "inline-block",
    width: "calc(45% - 40px)",
    margin: "0 40px 0 0 ",
    padding: "20px 40px",
  };

  const formSingleInputStyle = { width: "80%" };

  const onFinish = async (value: any) => {
    console.log("ON FINISH");
    if (
      value.maximum_rate <= value.minimum_rate &&
      value.maximum_rate <= 500 &&
      value.minimum_rate < 500
    ) {
      message.error("Maximum tarief kan niet lager zijn dan minimum tarief.");
    } else if (
      moment(value.start_date, true).isAfter(moment(value.end_date, true))
    ) {
      message.error("De einddatum kan niet voor de startdatum liggen.");
    } else {
      const newData = {
        title: value.title,
        position: value.position,
        position_extra: undefined,
        start_date: moment(value.start_date, true).format("YYYY-MM-DD"),
        end_date: moment(value.end_date, true).format("YYYY-MM-DD"),
        minimum_rate: value.minimum_rate,
        maximum_rate: value.maximum_rate,
        hours_per_week: value.hours_per_week,
        description: value.description,
        sector: value.sector,
        region: value.region,
        specialty: value.specialty,
        finished: false,
      };

      console.log("⚡️⚡️⚡️ Render");

      try {
        const res = await axios({
          method: "POST",
          url: `${BASE_URL}api/vacancies/`,
          data: newData,
          headers: getHttpHeader().headers,
        });
        // message.success("Project aangemaakt");
        await updateProposalWithVacancy(res.data.id);
      } catch (error) {
        console.log("onFinish ", error);
        // errorMessage(err.response.data)
        message.error("Kan project niet maken. Probeer opnieuw.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const updateProposalWithVacancy = async (vacancyId: number | undefined) => {
    console.log("updateProposalWithVacancy ---> Proposal Id ", proposalid);
    try {
      await axios({
        method: "PATCH",
        url: `${BASE_URL}api/proposals/${proposalid}/`,
        data: {
          vacancy: vacancyId,
        },
        headers: getHttpHeader().headers,
      });
      message.success("Project is aangemaakt");
      window.location.href = "/work/project";
    } catch (error: any) {
      console.log("updateProposalWithVacancy ---> Error ", error);
      if (
        error?.response?.data?.vacancy ===
        `Can't assign a vacancy to a proposal without freelancer agreement`
      )
        message.error(
          `Kan geen vacature toewijzen aan een offerte zonder freelancerovereenkomst`
        );
    }
  };

  return (
    <>
      {UserTypes.Freelancer === user.user_type && <Redirect to="/dashboard" />}
      <Modal
        closable={false}
        title={
          <div>
            <Row>
              <Col
                style={{
                  fontFamily: "Arimo",
                  fontWeight: 700,
                  fontSize: "16px",
                  padding: "25px",
                  textAlign: "center",
                }}
              >
                {`Zou je voor dit goedgekeurde job voorstel een nieuw project willen aanmaken of wil je deze toevoegen aan bestaande vacature?`}
              </Col>
            </Row>
          </div>
        }
        centered
        visible={modalVisible}
        footer={null}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Row>
          <Col span={12}>
            <Button
              size="large"
              style={{
                width: "100%",
                fontFamily: "Arimo",
                fontWeight: 700,
                fontSize: "16px",
                backgroundColor: "#002333",
                color: "white",
                border: "0px",
              }}
              onClick={() => {
                setModalVisible(false);
                setProjectCreateOption(Options.SELECT_PROJECT);
              }}
            >
              Bestaande vacature/project
            </Button>
          </Col>
          <Col span={12}>
            <Button
              size="large"
              style={{
                width: "100%",
                fontFamily: "Arimo",
                fontWeight: 700,
                fontSize: "16px",
                color: "#002333",
              }}
              onClick={() => {
                setModalVisible(false);
                setProjectCreateOption(Options.CREATE_PROJECT);
              }}
            >
              Nieuw project aanmaken
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        closable={false}
        title={undefined}
        centered
        visible={projectCreateOption === Options.CREATE_PROJECT ? true : false}
        footer={null}
        bodyStyle={{
          padding: 0,
        }}
        width={screens.md ? "80%" : "80%"}
      >
        <Row justify="center" style={{ backgroundColor: "white" }}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form
              form={form}
              name="regiter-form"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
              style={{ width: "100%" }}
            >
              <div
                style={
                  screens.md === true
                    ? // ? { minHeight: '80vh', display: 'grid', placeItems: 'center' }
                      { minHeight: "60vh" }
                    : { marginBlockStart: "35px", backgroundColor: "white" }
                }
              >
                <Row
                  justify="space-between"
                  style={{ marginBlockStart: "25px", marginBlockEnd: "8px" }}
                >
                  <Col span={16} style={{ paddingLeft: "60px" }}>
                    <div className="web-header">Project aanmaken</div>
                  </Col>
                  <Col span={8} style={{ display: "grid", placeItems: "end" }}>
                    <div className="web-header" style={{ fontSize: "14px" }}>
                      {`Projectnummer #123456789`}
                    </div>
                  </Col>
                  <Divider className="web-divider-dark" />
                </Row>
                <Row
                  style={
                    screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}
                  }
                  justify="center"
                >
                  <Form.Item
                    label={<span className="form-label">Sector</span>}
                    name="sector"
                    rules={[{ required: true, message: "Sector is verplicht" }]}
                    style={
                      screens.md === true
                        ? formGroupInputStyleLeftColumn
                        : formSingleInputStyle
                    }
                  >
                    <SectorSelect
                      placeholder="Sector"
                      size="large"
                      onChange={(value: number) => setSelectedSector(value)}
                    />
                  </Form.Item>

                  <Form.Item
                    style={
                      screens.md === true
                        ? formGroupInputStyleRightColumn
                        : formSingleInputStyle
                    }
                    rules={[
                      { required: true, message: "Alias geven is verplicht" },
                    ]}
                  >
                    <Form.Item
                      label={<span className="form-label">Alias geven</span>}
                      name="title"
                    >
                      <Input
                        placeholder="Alias geven"
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                </Row>
                <Row
                  style={
                    screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}
                  }
                  justify="center"
                >
                  <Form.Item
                    label={<span className="form-label">Functie</span>}
                    name="position"
                    rules={[
                      { required: true, message: "Functie is verplicht" },
                    ]}
                    style={
                      screens.md === true
                        ? formGroupInputStyleLeftColumn
                        : formSingleInputStyle
                    }
                  >
                    <PositionSelect
                      placeholder="Functie"
                      size="large"
                      sectorId={selectedSector}
                    />
                  </Form.Item>

                  <Form.Item
                    label={<span className="form-label">Werklast</span>}
                    style={
                      screens.md === true
                        ? {
                            display: "inline-block",
                            width: "calc(45% - 40px)",
                            margin: "0 40px 0 0 ",
                            padding: "20px 40px",
                          }
                        : {
                            display: "inline-block",
                            width: "calc(100% - 40px)",
                            margin: "0 40px 0 10% ",
                            paddingTop: "16px",
                          }
                    }
                  >
                    <Form.Item
                      name="hours_per_week"
                      rules={[
                        {
                          required: true,
                          message: "Werklast is verplicht",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(95px - 16px)",
                      }}
                    >
                      <InputNumber
                        placeholder="Werklast"
                        size="large"
                        min={0}
                      />
                    </Form.Item>
                    <span
                      style={
                        screens.md
                          ? {
                              marginLeft: "14px",
                              marginTop: "10px",
                              display: "inline-flex",
                            }
                          : {
                              display: "inline-flex",
                              marginLeft: "14px",
                              paddingTop: "10px",
                            }
                      }
                    >{` uur per week`}</span>
                  </Form.Item>
                </Row>
                <Row
                  style={
                    screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}
                  }
                  justify="center"
                >
                  <Form.Item
                    name="specialty"
                    label={<span className="form-label">Specialisme</span>}
                    rules={[
                      {
                        required: false,
                        message: "Specialisme is verplicht",
                        type: "array",
                      },
                    ]}
                    style={
                      screens.md === true
                        ? formGroupInputStyleLeftColumn
                        : formSingleInputStyle
                    }
                  >
                    <SpecialySelect
                      placeholder="Specialisme"
                      mode="multiple"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label={<span className="form-label">Vergoeding</span>}
                    style={
                      screens.md === true
                        ? {
                            display: "inline-block",
                            width: "calc(45% - 40px)",
                            margin: "0 40px 0 0 ",
                            padding: "20px 40px",
                          }
                        : {
                            display: "inline-block",
                            width: "calc(100% - 40px)",
                            margin: "0 40px 0 10% ",
                            paddingTop: "16px",
                          }
                    }
                  >
                    <Form.Item
                      name="minimum_rate"
                      rules={[
                        {
                          required: true,
                          message: "Minimaal tarief is verplicht",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(95px - 0px)",
                      }}
                    >
                      <InputNumber
                        // onChange={handleEditForm}
                        placeholder="Min"
                        size="large"
                        min={0}
                      />
                    </Form.Item>
                    <span style={{ fontSize: "24px" }}>-</span>
                    <Form.Item
                      name="maximum_rate"
                      rules={[
                        {
                          required: true,
                          message: "Maximaal tarief is verplicht",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(95px - 16px)",
                        margin: "0 8px",
                      }}
                    >
                      <InputNumber
                        // onChange={handleEditForm}
                        placeholder="Max"
                        size="large"
                        min={0}
                      />
                    </Form.Item>
                    <span
                      style={screens.md ? {} : { display: "inline-flex" }}
                    >{` Euro per uur`}</span>
                  </Form.Item>
                </Row>
                <Row
                  style={
                    screens.md ? { borderBottom: "3px solid #f6f6f6" } : {}
                  }
                  justify="center"
                >
                  <Form.Item
                    label={<span className="form-label">Regio</span>}
                    name="region"
                    rules={[{ required: true, message: "Regio is verplicht" }]}
                    style={
                      screens.md === true
                        ? formGroupInputStyleLeftColumn
                        : formSingleInputStyle
                    }
                  >
                    <RegionSelect placeholder="Regio" size="large" />
                  </Form.Item>

                  <Form.Item
                    label={<span className="form-label">Periode</span>}
                    style={
                      screens.md === true
                        ? {
                            display: "inline-block",
                            width: "calc(45% - 40px)",
                            margin: "0 40px 0 0 ",
                            padding: "20px 40px",
                          }
                        : {
                            display: "inline-block",
                            width: "calc(100% - 40px)",
                            margin: "0 40px 0 10% ",
                            paddingTop: "16px",
                          }
                    }
                  >
                    <Form.Item
                      name="start_date"
                      rules={[
                        {
                          required: true,
                          message: "Start date is verplicht",
                        },
                      ]}
                      style={
                        screens.md
                          ? {
                              display: "inline-block",
                              width: "calc(140px - 0px)",
                            }
                          : {
                              display: "inline-block",
                              width: "calc(42% - 0px)",
                            }
                      }
                    >
                      <DatePicker
                        size="large"
                        onChange={(value) => setSeletedDate(value)}
                      />
                    </Form.Item>
                    <span style={{ fontSize: "24px", marginLeft: "5px" }}>
                      -
                    </span>
                    <Form.Item
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          message: "End date is verplicht",
                        },
                      ]}
                      style={
                        screens.md
                          ? {
                              display: "inline-block",
                              width: "calc(140px - 8px)",
                              margin: "0 8px",
                            }
                          : {
                              display: "inline-block",
                              width: "calc(42% - 0px)",
                              marginLeft: "5px",
                            }
                      }
                    >
                      <DatePicker
                        size="large"
                        disabledDate={(current) => {
                          return current < selectedDate;
                        }}
                      />
                    </Form.Item>
                  </Form.Item>
                </Row>

                <Row
                  justify="center"
                  style={screens.md ? { padding: "20px 0px" } : {}}
                >
                  <Form.Item
                    label={
                      <span className="form-label">Opdrachtomschrijving</span>
                    }
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Opdrachtomschrijving is verplicht",
                      },
                    ]}
                    style={
                      // screens.md === true ? formGroupInputStyle : {}
                      screens.md === true
                        ? { width: "85%", padding: "0px 40px" }
                        : formSingleInputStyle
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 7, maxRows: 7 }}
                      style={{ borderRadius: "2px" }}
                    />
                  </Form.Item>
                </Row>
              </div>
              <Form.Item>
                <Row
                  justify="end"
                  style={screens.md ? { margin: "0px 20px" } : {}}
                >
                  <Col span={12}>
                    <Button
                      size="large"
                      onClick={() => {
                        setProjectCreateOption(undefined);
                        setModalVisible(true);
                      }}
                      block
                      type="primary"
                      // className={screens.md ? "form-button" : ""}
                      style={{
                        backgroundColor: "#664FF3",
                        marginRight: "20px",
                      }}
                    >
                      Annuleren
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      block
                      type="primary"
                      // className={screens.md ? "form-button" : ""}
                      htmlType="submit"
                    >
                      Opslaan
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        closable={false}
        title={undefined}
        centered
        visible={projectCreateOption === Options.SELECT_PROJECT ? true : false}
        footer={null}
        bodyStyle={{
          padding: 0,
          backgroundColor: "#f6f6f6",
        }}
        width={screens.md ? "700px" : "90%"}
      >
        <div style={{ padding: "20px" }}>
          <div className="create-project-header">
            Bestaande projecten/vacatures
          </div>
          <Input
            size={"large"}
            placeholder="Zoeken"
            prefix={<SearchOutlined />}
            style={{ margin: "24px 0px" }}
          />
          {vacancyData?.results?.map((vacancy) => {
            if (!vacancy.finished) {
              return (
                <ProjectItem
                  key={vacancy.id}
                  id={vacancy.id}
                  selectedCard={selectedProject}
                  setSelectedCard={setSelectedProject}
                  vacancyData={vacancy}
                />
              );
            } else {
              return undefined;
            }
          })}
        </div>
        <Button
          block
          size="large"
          disabled={selectedProject === undefined ? true : false}
          onClick={() => updateProposalWithVacancy(selectedProject)}
          style={{ backgroundColor: "#000233", color: "white", border: 0 }}
          className="add-project-btn"
        >{`toevoegen`}</Button>
      </Modal>
    </>
  );
}
