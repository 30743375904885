import { JobProposal } from "./job-proposal.type";
import { Attachment } from "./attachment.type";
import { ChatUser } from "./chat-user.type";

export enum MessageType {
  text,
  jobProposal,
  attachment,
  textWithUrl,
}

export interface ChatMessage {
  id?: string;
  from: ChatUser;
  to: ChatUser;
  type: MessageType;
  url?: string;
  message: string | JobProposal | Attachment;
  ts?: number;
  readTime?: number;
}
