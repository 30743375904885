import { Row, Col, Grid } from "antd";
import HourlyOverviewForm from "./Hourly-Overview-Form";
import axios from "axios";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { Hours, Proposal } from "src/types/work.type";
import moment from "moment";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Hours[];
};

type HourlyOverviewProps = {
  proposalData: Proposal | undefined;
  selectedWeek: moment.Moment;
};

export default function HourlyOverview({
  proposalData,
  selectedWeek,
}: HourlyOverviewProps) {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const screens = Grid.useBreakpoint();
  const endDate = proposalData?.end_date
    ? moment(proposalData?.end_date)
    : null;
  const startDate = proposalData?.start_date
    ? moment(proposalData?.start_date)
    : null;
  const [totalHours, setTotalHours] = useState(0);
  const [totalKilometers, setTotalKilometers] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [showForm, setShowForm] = useState(true);

  const weekStart = selectedWeek.clone().startOf("week");

  console.log("=====> WEEKE ", weekStart);

  useEffect(() => {
    console.log("STATE CHANGED", showForm);
  }, [showForm]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const fetchHourlyDetails = async () => {
    return (
      await axios.get(
        `${BASE_URL}api/hoursets?proposal=${
          proposalData?.id
        }&week=${selectedWeek.isoWeek()}`,
        config
      )
    ).data;
  };

  const { data, refetch } = useQuery<Response>(
    `HourlyDetails${proposalData?.id}Week=${selectedWeek.isoWeek()}`,
    fetchHourlyDetails,
    {
      enabled: !!proposalData?.id,
    }
  );

  useEffect(() => {
    if (data) {
      console.log("DATA ===> ", data?.results);
      let hours = 0;
      let kms = 0;
      let expenses = 0;
      data.results.forEach((e) => {
        hours = hours + Number(e.hours);
        kms = kms + e.kilometers;
        expenses = expenses + Number(e.expenses);

        if (
          moment(moment(e?.date).format("DD/MM/YYYY")).isSame(
            moment(new Date()).format("DD/MM/YYYY")
          )
        )
          setShowForm(false);
      });
      setTotalHours(hours);
      setTotalExpenses(expenses);
      setTotalKilometers(kms);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [proposalData, selectedWeek, refetch]);

  // const downloadInvoicePDF = async (invoiceId: string) => {
  //   try {
  //     const res = await axios.get(`${BASE_URL}api/invoices/${5}/get_pdf/`);
  //     console.log("PDFLink ", res);

  //     await axios({
  //       url: res?.data?.pdf,
  //       method: "GET",
  //       responseType: "blob",
  //     }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "invoice.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  //   } catch (error) {
  //     console.log("PDFLink ", error);
  //     message.error("Factuur is niet goedgekeurd door Finqle.");
  //   }
  // };

  return (
    <>
      <div
        className={
          screens.md
            ? "project-details-inner fl-projects__hr_list__wrapper custom__scrollbar"
            : "project-details-inner-mobile"
        }
      >
        {/* {!!data?.results?.[0]?.invoice && (
          <Row justify="center">
            <Link to="/user/invoice" className="hourly-detail-item-title">
              Bekijk factuur
            </Link>
          </Row>
        )} */}
        <Row justify="space-between">
          <Col
            className={
              screens.md
                ? "hourly-overview-colum fl-projects__stats"
                : "hourly-overview-colum-mobile fl-projects__stats"
            }
            style={
              screens.md ? { marginRight: "18px" } : { marginRight: "6px" }
            }
          >
            <div className="projects-overview-header">Totale tijd</div>
            <span className="sub-detail-2"> {totalHours} uur</span>
          </Col>
          <Col
            className={
              screens.md
                ? "hourly-overview-colum fl-projects__stats"
                : "hourly-overview-colum-mobile fl-projects__stats"
            }
          >
            <div className="projects-overview-header">Totale afstand</div>
            <span className="sub-detail-2"> {totalKilometers}km</span>
          </Col>
          <Col
            className={
              screens.md
                ? "hourly-overview-colum fl-projects__stats"
                : "hourly-overview-colum-mobile fl-projects__stats"
            }
            style={screens.md ? { marginLeft: "18px" } : { marginLeft: "6px" }}
          >
            <div className="projects-overview-header">Totale onkosten</div>
            <span className="sub-detail-2"> €{totalExpenses}</span>
          </Col>
        </Row>

        {Array(7)
          .fill(1)
          .map((_, i) => {
            const momentDate = moment(weekStart).add(i, "days");
            const hourlyData = data?.results?.find(
              (hourlyData) =>
                hourlyData.date === momentDate?.format("YYYY-MM-DD")
            );

            if (endDate && momentDate > endDate) return null;

            if (startDate && momentDate < startDate) return null;

            return (
              <HourlyOverviewForm
                key={i}
                hourlyData={hourlyData}
                refetchList={refetch}
                proposalData={proposalData}
                selectedWeek={momentDate}
              />
            );
          })}
      </div>
    </>
  );
}
