import React from "react";
import BG from "../../images/auth-bg.png";

export default function AuthLayout({ children }: any) {
  return (
    <div>
      <img src={BG} alt="Background" height="100%" width="100%" />
      {children}
    </div>
  );
}
