let API_URL = '';

if(process.env.NODE_ENV === 'production'){
    API_URL = `${process.env.REACT_APP_BACKEND}api/`;
}
else{
    // API_URL = 'http://localhost:1337'
    API_URL = `${process.env.REACT_APP_BACKEND}api/`;
}

export {API_URL};