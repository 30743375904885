import React, { useMemo, useReducer, createContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./App.less";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Home } from "./modules/Home";
import { Login } from "./modules/Login";
import { Register } from './modules/Login/register';
import { SelectProfile } from './modules/Login/register_step_one';
import { Profile } from './modules/Login/profile';
import { Verify } from './modules/Login/verify';
import { VerifySuccess } from './modules/Login/verify-success';
import { RequestResetPassword } from './modules/Login/request-password';
import { ResetEmailSent } from './modules/Login/reset-email-sent';
import { SetPassword } from './modules/Login/set-password';
import { ResetSuccessfull } from './modules/Login/reset-successfull';
import Connections from './modules/Connect/Connections';
import Chat from './modules/Connect/Chat';
import UserProfile from './modules/Profile/index';
import Work from './modules/Work/index';
import MobileSubMenu from './components/header/MobileSubMenu';
import Search from 'src/modules/Search/Index';
import Wallet from 'src/components/Wallet/Wallet';
import Payment from 'src/components/Wallet/Payment';
import CreateProject from 'src/modules/Work/Projects/AddProjectForm';

import { createStore } from "./store";
import { Provider as ReduxProvider } from "react-redux";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { ConfigProvider } from "antd";
import nlNL from "antd/lib/locale/nl_NL";
import { chatReducer, initialState } from "./store/reducers/chat.reducer";
import { ChatService } from "./services/chat.service";

const queryCache = new QueryCache();

//@ts-ignore
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        (localStorage.getItem("token") && localStorage.getItem("user")) ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export const ChatContaxt = createContext(initialState);
let chatService: ChatService | null = null;

function App() {

  const store = useMemo(() => createStore(), []);
  const [chatState, chatDispatch] = useReducer(chatReducer, {
    ...initialState,
  });

  // const chatService = useMemo(() => new ChatService(chatDispatch, chatState), [])

  useEffect(() => {
    if (!chatService && localStorage.getItem('token'))
      chatService = new ChatService(chatDispatch, chatState);
    // eslint-disable-next-line
  }, [])

  return (
    <ConfigProvider locale={nlNL}>
      <ReduxProvider store={store}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <ChatContaxt.Provider value={chatState}>
            <div className="App">
              <Helmet>
                <title>Job.nl</title>
              </Helmet>
              <Router>
                <Switch>
                  <PrivateRoute path="/createproject/:proposalid" component={CreateProject} />
                  <PrivateRoute exact path="/payment" component={Payment} />
                  <PrivateRoute exact path="/wallet" component={Wallet} />
                  <PrivateRoute exact path={["/search", "/search/:companyid"]} component={Search} />
                  <PrivateRoute exact path="/mobilesubmenu" component={MobileSubMenu} />
                  <PrivateRoute path="/user" component={UserProfile} />
                  <PrivateRoute path="/work" component={Work} />
                  <PrivateRoute path={["/connect/connections", "/connect/connections/:id"]} component={Connections} />
                  <Route exact path={["/connect/chat", "/connect/chat/:id"]} >
                    <Chat state={chatState} dispatch={chatDispatch} chatService={chatService} />
                  </Route>
                  <PrivateRoute path="/dashboard" component={Home} />
                  <Route path="/register/profile" component={Profile} />
                  <Route path="/register/selectprofile" component={SelectProfile} />
                  <Route path="/register/verify" component={Verify} />
                  <Route path="/register" component={Register} />
                  <Route path="/verified" component={VerifySuccess} />
                  <Route path="/request-reset-password" component={RequestResetPassword} />
                  <Route path="/reset-password-sent" component={ResetEmailSent} />
                  <Route path="/reset-password" component={SetPassword} />
                  <Route path="/reset-successfull" component={ResetSuccessfull} />
                  <Route path="/login" component={Login} />
                  <Route path="/" component={Login} />
                </Switch>
              </Router>
            </div>
          </ChatContaxt.Provider>
        </ReactQueryCacheProvider>
      </ReduxProvider>
    </ConfigProvider >
  );
}

export default App;
