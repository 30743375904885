import {
  Layout,
  Divider,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Grid,
  Drawer,
  Pagination,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { SearchOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { useQuery } from "react-query";
import ProjectItem from "./Project-Item";
import ProjectDetails from "./ProjectDetails";
import { Vacancy } from "src/types/work.type";
import * as _ from "lodash";
import { getHttpHeader } from "src/hooks/useHttpHeader";
import EmptyLogo from "src/empty-state.svg";

type Response = {
  count: number;
  next: string;
  previous: string;
  results: Vacancy[];
};

type SummaryResponse = {
  hours_this_week: string;
  turnover_this_week: string;
  total_hours: string;
  total_turnover: string;
};

export default function Projects() {
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const [searchByName, setSearchByName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWeek, setSelectedWeek] = useState(moment().startOf("isoWeek"));
  const [selectedCard, setSelectedCard] = useState<number | undefined>();

  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
  const sendQuery = (query: any) => setSearchByName(query);

  const handleOnchange = (e: any) => {
    delayedQuery(e.target.value);
  };

  const { data, refetch } = useQuery<Response>(
    ["freelancer_projects", searchByName, startDate, endDate, currentPage],
    async () => {
      return (
        await axios.get(`${BASE_URL}api/vacancies`, {
          ...getHttpHeader(),
          params: {
            title__icontains: searchByName,
            limit: 10,
            proposal_start_date: endDate,
            proposal_end_date: startDate,
            isProject: true,
            offset: (currentPage - 1) * 10,
            proposal_start_date_sort: true,
          },
        })
      ).data;
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const summaryDetails = useQuery<SummaryResponse>(
    ["SummaryDetails", selectedWeek.isoWeek(), data?.results?.[0]?.id],
    async () => {
      if (data?.results?.[0]?.id)
        return (
          await axios.get(
            `${BASE_URL}api/vacancies/${data?.results?.[0]?.id}/get_summary/`,
            {
              ...getHttpHeader(),
              params: {
                week: selectedWeek.isoWeek(),
              },
            }
          )
        ).data;
    },
    {
      enabled: data?.results?.[0]?.id,
    }
  );

  useEffect(() => {
    // adding one week sinc the api will use less than
    // to compare
    const newDate = moment(selectedWeek).add(1, "week");
    setStartDate(selectedWeek.format("YYYY-MM-DD"));
    setEndDate(newDate.format("YYYY-MM-DD"));
  }, [selectedWeek]);

  const onChange = async (date: any, dateString: string) => {
    await setSelectedWeek(date);
  };

  const customFormat = (value: any) => {
    const newDate = moment(value).add(6, "days");
    return `${value.format("DD MMM")} t/m ${newDate.format("DD MMM")}`;
  };

  const handleBackForwardDate = (operation: string) => {
    if (operation === "add") {
      const newDate = moment(selectedWeek).add(1, "week");
      setSelectedWeek(newDate);
    } else if (operation === "subtract") {
      const newDate = moment(selectedWeek).subtract(1, "week");
      setSelectedWeek(newDate);
    }
  };

  const handlePagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {!screens.md && (
        <>
          <Drawer
            title={undefined}
            placement="right"
            closable={false}
            onClose={() => setSelectedCard(undefined)}
            visible={selectedCard !== undefined ? true : false}
            width="90%"
            bodyStyle={{
              // backgroundColor: 'red',
              padding: "0px",
            }}
          >
            {selectedCard !== undefined && (
              <ProjectDetails
                id={selectedCard}
                vacancyData={data?.results.find((e) => e?.id === selectedCard)}
                selectedWeek={selectedWeek}
                onDataUpdated={refetch}
              />
            )}
          </Drawer>
        </>
      )}

      <Row
        justify={"center"}
        style={{ backgroundColor: "#f6f6f6", minHeight: "72vh" }}
      >
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Layout className="container">
            <Content className="fl-projects__wrapper custom__scrollbar web-content">
              <div>
                <Row
                  justify="end"
                  style={{ backgroundColor: "#f6f6f6", padding: "0px 24px" }}
                >
                  <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                    {screens.md && (
                      <Row style={{ marginTop: 25 }}>
                        <Col span={10}>
                          <div className="web-header">Projecten</div>
                        </Col>
                        <Col span={14}>
                          <Input
                            size={"large"}
                            placeholder="Zoeken"
                            prefix={<SearchOutlined />}
                            onChange={handleOnchange}
                            style={{ maxHeight: "45px", padding: "0 11px" }}
                          />
                        </Col>
                        <Divider className="web-divider-dark" />
                      </Row>
                    )}
                    <Row justify="space-between" className="project-bar">
                      <Col span={2} className="project-bar-icons">
                        <Button
                          type="link"
                          icon={<LeftOutlined className="project-bar-icons" />}
                          onClick={() => handleBackForwardDate("subtract")}
                        />
                      </Col>
                      <Col span={20} style={{ textAlign: "center" }}>
                        <div className="project-date-bar">
                          Week {selectedWeek.isoWeek()}
                        </div>
                        <DatePicker
                          allowClear={false}
                          bordered={false}
                          onChange={onChange}
                          picker="week"
                          className="project-date-picker"
                          inputReadOnly={true}
                          format={customFormat}
                          value={selectedWeek}
                          size="small"
                        />
                      </Col>
                      <Col span={2} className="project-bar-icons">
                        <Button
                          type="link"
                          icon={<RightOutlined className="project-bar-icons" />}
                          onClick={() => handleBackForwardDate("add")}
                          // disabled={
                          //   selectedWeek.isoWeek() ===
                          //   moment().subtract(1, "week").isoWeek()
                          // }
                        />
                      </Col>
                    </Row>

                    <Row justify="space-between" style={{ margin: "20px 0px" }}>
                      <Col
                        flex="1"
                        className="projects-overview"
                        style={
                          screens.md
                            ? { marginRight: "10px" }
                            : { marginRight: "6px", padding: "24px 10%" }
                        }
                      >
                        <div className="projects-overview-header">
                          Totale uren
                        </div>
                        <span className="sub-detail-2">
                          {summaryDetails.data?.hours_this_week} uur
                        </span>
                      </Col>
                      <Col
                        flex="1"
                        className="projects-overview"
                        style={
                          screens.md
                            ? { marginLeft: "10px" }
                            : { marginLeft: "6px", padding: "24px 10%" }
                        }
                      >
                        <div className="projects-overview-header">
                          Totale omzet
                        </div>
                        <span className="project-details-highlighted">
                          €{summaryDetails?.data?.total_turnover}-
                        </span>
                      </Col>
                    </Row>
                    <Row style={screens.md ? {} : { marginBlockEnd: "50px" }}>
                      {data?.results.map((e) => {
                        if (!e.proposals.length) {
                          return null;
                        }

                        return (
                          <ProjectItem
                            key={e?.id}
                            proposalData={e.proposals[0]}
                            isHoursRejected={e.hours_rejected}
                            id={e?.id}
                            selectedCard={
                              selectedCard !== undefined ? selectedCard : -1
                            }
                            setSelectedCard={setSelectedCard}
                            projectTitle={e.position_extra}
                            companyName={e?.company?.company_name}
                          />
                        );
                      })}
                    </Row>
                    {/* {screens.md && data?.results?.length === 0 && (
                      <Row justify="center" style={{ marginBottom: 20 }}>
                        <img alt="Empty Screen" src={EmptyLogo} width={700} />
                      </Row>
                    )}
                    {!screens.md && data?.results?.length === 0 && (
                      <Row justify="center" style={{ marginBottom: 20 }}>
                        <img alt="Empty Screen" src={EmptyLogo} width={300} />
                      </Row>
                    )} */}
                    <Row justify="center">
                      <Pagination
                        total={data?.count}
                        showTotal={(total) => `Totaal ${total} items`}
                        pageSize={10}
                        current={currentPage}
                        onChange={(value) => handlePagination(value)}
                        className="pagination"
                        style={{ margin: "0px" }}
                        responsive={true}
                      />
                    </Row>
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={0}
          xs={0}
          style={{ backgroundColor: "white" }}
        >
          {selectedCard !== undefined && (
            <ProjectDetails
              id={selectedCard}
              vacancyData={data?.results.find((e) => e?.id === selectedCard)}
              selectedWeek={selectedWeek}
              onDataUpdated={refetch}
            />
          )}

          {screens.lg && selectedCard === undefined && (
            <Row justify="center" style={{ marginTop: 100 }}>
              <img alt="Empty Screen" src={EmptyLogo} width={600} />
            </Row>
          )}
          {!screens.lg && screens.md && selectedCard === undefined && (
            <Row justify="start" style={{ marginTop: 20 }}>
              <img alt="Empty Screen" src={EmptyLogo} width={550} />
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}
