import { ChatUser } from "src/types/chat-user.type";
import { ChatMessage } from "../../types/chat-message.type";

export interface Contact extends ChatUser {
  messages: ChatMessage[];
  selected: boolean;
}

export type state = {
  user: ChatUser | null;
  contacts: {
    [userId: number]: Contact;
  };
  connected: boolean;
  otherLogin: boolean;
  unreadMessageCount: number;
};

export const initialState: state = {
  user: null,
  contacts: [],
  connected: false,
  otherLogin: false,
  unreadMessageCount: 0,
};

export type ActionType =
  | {
      type: "setUserData";
      payload: ChatUser;
    }
  | {
      type: "addMessageToContact";
      payload: ChatMessage;
    }
  | {
      type: "selectContact";
      payload: ChatUser | null;
    }
  | {
      type: "connect";
    }
  | {
      type: "disconnect";
    }
  | {
      type: "addUpdateContact";
      payload: ChatUser;
    }
  | {
      type: "clearHistory";
      payload: ChatUser;
    }
  | {
      type: "otherLogin";
    }
  | {
      type: "unreadMessageCount";
      payload: Number;
    };

export const chatReducer = (state: state, action: ActionType) => {
  switch (action.type) {
    case "setUserData":
      const newUserState = {
        ...state,
        user: action.payload,
      };
      return newUserState;
    case "addMessageToContact":
      const user =
        action.payload.from.id === state.user?.id
          ? action.payload.to
          : action.payload.from;

      if (!(user.id in state.contacts)) {
        // The user is not in the contacts
        return {
          ...state,
          contacts: {
            ...state.contacts,
            [user.id]: {
              ...user,
              selected: false,
              messages: [action.payload],
            },
          },
        };
      } else {
        // The user is already in the contact list
        return {
          ...state,
          contacts: {
            ...state.contacts,
            [user.id]: {
              ...state.contacts[user.id],
              messages: [
                ...state.contacts[user.id].messages.filter(
                  (m) => m.id !== action.payload.id
                ),
                action.payload,
              ].sort((a, b) => a.ts! - b.ts!),
            },
          },
        };
      }

    case "selectContact":
      const contacts = { ...state.contacts };
      (Object.keys(contacts) as Array<unknown> as Array<number>).forEach(
        (key) => {
          contacts[key].selected = false;
        }
      );
      if (!action.payload || !(action.payload.id in state.contacts)) {
        return {
          ...state,
          contacts: { ...contacts, name: action.payload?.name },
        };
      }
      // We need to deselect all other contacts...

      return {
        ...state,
        contacts: {
          ...contacts,
          [action.payload.id]: {
            ...state.contacts[action.payload.id],
            name: action.payload?.name,
            selected: true,
          },
        },
      };
    case "connect":
      return {
        ...initialState,
        connected: true,
      };
    case "disconnect":
      return {
        ...state,
        connected: false,
        username: "",
      };
    case "addUpdateContact":
      const messages = state.contacts[action.payload.id]?.messages;
      const selected = state.contacts[action.payload.id]?.selected;
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.id]: {
            ...action.payload,
            messages: messages || [],
            selected: !!selected,
          },
        },
      };
    case "clearHistory":
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.id]: {
            ...state.contacts[action.payload.id],
            messages: [],
          },
        },
      };
    case "otherLogin":
      console.log("oitherlogin");
      return {
        ...state,
        otherLogin: true,
      };
    case "unreadMessageCount":
      console.log("setting message count", action.payload);
      const count = action.payload;
      return {
        ...state,
        unreadMessageCount: count as number,
      };
    default:
      throw new Error();
  }
};
