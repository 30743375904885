import {
  EuroCircleOutlined,
  InfoCircleOutlined,
  RetweetOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Grid,
  Layout,
  message,
  Row,
  Steps,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import { Route, useHistory } from "react-router-dom";
import AppHeader from "src/components/header/AppHeader";
import { BASE_URL } from "src/config/default";
import { useUser } from "src/hooks/useUser";
import { getHttpHeader } from "../../hooks/useHttpHeader";
import image2 from "../../images/image2.jpg";
import { UserTypes } from "../../types/user.type";
import WalletWidget from "../Wallet/Wallet-Widget";

const btnStyle = {
  color: "white",
  borderRadius: "50px",
  minWidth: "200px",
  height: "50px",
  padding: "10px",
};

const dataSource = [
  {
    key: 1,
    datum: "29/09/2021",
    bedrag: 0,
    saldo: 10,
    saldo2: "Nog geen nieuw Job - voorstel aangegaan",
  },
  {
    key: 2,
    datum: "31/07/2021",
    bedrag: 10,
    saldo: 10,
    saldo2: "100 uur gewerkt, beschikbaar voor de eerst volgende opdracht",
  },
  {
    key: 3,
    datum: "16/07/2021",
    bedrag: 0,
    saldo: 0,
    saldo2: "Geen saldo in Job Wallet",
  },
  {
    key: 4,
    datum: "01/07/2021",
    bedrag: -20,
    saldo: 0,
    saldo2: "KORTING verrekend op factuur",
  },
  {
    key: 5,
    datum: "16/06/2021",
    bedrag: -40,
    saldo: 20,
    saldo2: "KORTING verrekend op factuur",
  },
  {
    key: 6,
    datum: "01/06/2021",
    bedrag: 10,
    saldo: 60,
    saldo2: "Uitgenodigde gebruiker [email] heeft zich geverifieerd",
  },
  {
    key: 7,
    datum: "01/06/2021",
    bedrag: 10,
    saldo: 50,
    saldo2: "Uitgenodigde gebruiker [email] heeft zich geverifieerd",
  },
  {
    key: 8,
    datum: "01/06/2021",
    bedrag: 10,
    saldo: 40,
    saldo2: "Uitgenodigde gebruiker [email] heeft zich geverifieerd",
  },
  {
    key: 9,
    datum: "01/06/2021",
    bedrag: 10,
    saldo: 30,
    saldo2: "Uitgenodigde gebruiker [email] heeft zich geverifieerd",
  },
  {
    key: 10,
    datum: "01/06/2021",
    bedrag: 10,
    saldo: 20,
    saldo2: "Uitgenodigde gebruiker [email] heeft zich geverifieerd",
  },
  {
    key: 11,
    datum: "27/05/2021",
    bedrag: 10,
    saldo: 10,
    saldo2: "Verificatie van account is geslaagd",
  },
  {
    key: 12,
    datum: "22/05/2021",
    bedrag: 0,
    saldo: 0,
    saldo2: "",
  },
];

const columns = [
  {
    title: "Datum",
    dataIndex: "datum",
    key: "datum",
  },
  {
    title: "Bedrag",
    dataIndex: "bedrag",
    key: "bedrag",
  },
  {
    title: "Saldo",
    dataIndex: "saldo",
    key: "saldo",
  },
  {
    title: "Saldo",
    dataIndex: "saldo2",
    key: "saldo2",
  },
];

export default function Wallet() {
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();
  const { user } = useUser();
  const history = useHistory();

  const requestFinqleOnboarding = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}api/finqle/onboarding-link`,
        getHttpHeader()
      );
      console.log("res.data.link ", res.data.link);
      if (res?.data?.link) {
        history.push(res?.data?.link);
      } else
        message.info(
          `Kan Finqle onboarding-link niet krijgen. Probeer opnieuw.`
        );
    } catch (error: any) {
      if (error?.response?.data?.message === "finqle_user_does_not_exists")
        message.info(
          `Finqle werkt aan het genereren van uw onboarding-link. Probeer het na 5 minuten.`
        );
    }
  };

  return (
    <>
      <Route exact path="/wallet">
        <AppHeader />
        <Layout>
          <Row justify={"center"}>
            <Col xl={24} lg={24} md={24} sm={21} xs={21}>
              <div>
                <Content style={screens.md ? {} : {}}>
                  <Row
                    justify="center"
                    className="container"
                    style={{
                      padding: "54px 0px",
                    }}
                  >
                    {user.is_payment_active && user.is_finqle_active ? (
                      <WalletWidget />
                    ) : (
                      <Col style={{ display: "grid", placeItems: "center" }}>
                        <div
                          className="wallet-bold-text"
                          style={{
                            fontSize: "30px",
                            marginBlockEnd: "35px",
                            textAlign: "center",
                          }}
                        >
                          Krijg volledige toegang tot het platform door te
                          verifiëren!
                        </div>
                        <div
                          className="wallet-large-text"
                          style={
                            screens.md
                              ? {
                                  marginBlockEnd: "14px",
                                  width: "60%",
                                  textAlign: "center",
                                }
                              : {
                                  textAlign: "center",
                                }
                          }
                        >
                          {user.user_type === UserTypes.Freelancer ? (
                            <p className={"wallet-sub-header-text"}>
                              Er worden helaas regelmatig neppe accounts
                              aangemaakt. Om onze opdrachtgevers te ontzorgen,
                              verifiëren wij al onze gebruikers bij de
                              aanmelding op het platform!
                            </p>
                          ) : (
                            <p className="wallet-sub-header-text">
                              Wij willen voorkomen dat anderen op naam van jouw
                              bedrijf zzp’ers inhuren.
                              <br />
                              <br />
                              In dit proces zorgen we dat jouw bedrijfsgegevens
                              daarbij direct goed in onze database worden
                              verwerkt.
                            </p>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>

                  {(!user.is_payment_active || !user.is_finqle_active) && (
                    <Row
                      justify="center"
                      style={
                        screens.md
                          ? { backgroundColor: "white", padding: "75px" }
                          : { padding: "0px" }
                      }
                    >
                      <Row
                        className="container"
                        style={
                          screens.md
                            ? { backgroundColor: "white", padding: "0 75px" }
                            : { backgroundColor: "white", padding: "24px" }
                        }
                      >
                        <Col
                          span={screens.md ? 12 : 24}
                          style={
                            screens.md
                              ? { display: "grid", placeItems: "start" }
                              : { marginBlockEnd: "24px" }
                          }
                        >
                          <Steps direction="vertical">
                            <Steps.Step
                              status="process"
                              title={
                                <div
                                  className={
                                    screens.md
                                      ? "wallet-bold-text wallet-padding-left-50px"
                                      : "wallet-bold-text wallet-padding-left-5px"
                                  }
                                >
                                  Borgbetaling
                                </div>
                              }
                              subTitle={
                                <>
                                  <div
                                    className={
                                      screens.md
                                        ? "wallet-padding-left-50px wallet-padding-bottom-52px text-line-height"
                                        : "wallet-padding-left-5px wallet-padding-bottom-52px text-line-height"
                                    }
                                  >
                                    {user.user_type === UserTypes.Freelancer ? (
                                      <p>
                                        Betaal éénmalig een bedrag van 10 euro
                                        (ex BTW). Na betaling storten wij dit
                                        als tegoed in jouw Wallet en verrekenen
                                        wij dit bij jouw eerste opdracht in de
                                        werkhub!
                                      </p>
                                    ) : (
                                      <p>
                                        Betaal éénmalig een bedrag van 10 euro
                                        (ex BTW). Na betaling storten wij dit
                                        tegoed in de Wallet en wordt verrekend
                                        met de eerste opdracht die via het
                                        platform loopt.
                                      </p>
                                    )}
                                    <Button
                                      className="payment-button"
                                      style={
                                        user.is_payment_active
                                          ? {
                                              ...btnStyle,
                                              backgroundColor: "#D3D3D3",
                                            }
                                          : {
                                              ...btnStyle,
                                              backgroundColor: "#35DF90",
                                            }
                                      }
                                      disabled={user.is_payment_active}
                                      onClick={() => history.push("/payment")}
                                    >
                                      START BETALING
                                    </Button>
                                  </div>
                                </>
                              }
                              icon={
                                <EuroCircleOutlined className="wallet-stepper-icon" />
                              }
                            />
                            <Steps.Step
                              status="process"
                              title={
                                <div
                                  className={
                                    screens.md
                                      ? "wallet-bold-text wallet-padding-left-50px"
                                      : "wallet-bold-text wallet-padding-left-5px"
                                  }
                                >
                                  De volwaardige onboarding
                                </div>
                              }
                              subTitle={
                                <>
                                  <div
                                    className={
                                      screens.md
                                        ? "wallet-padding-left-50px wallet-padding-bottom-52px text-line-height"
                                        : "wallet-padding-left-5px wallet-padding-bottom-52px text-line-height"
                                    }
                                  >
                                    {user.user_type === UserTypes.Freelancer ? (
                                      <p>
                                        Na de betaling linken we je door naar de
                                        onboardingtool. We vragen hierbij om
                                        persoonlijke gegevens in te vullen.
                                        <br />
                                        <br />
                                        Zorg dat je de volgende gegevens bij de
                                        hand hebt :
                                        <br />
                                        - Bankrekeningnummer (zakelijk)
                                        <br />
                                        - BTW nummer
                                        <br />
                                        - ID Bewijs / paspoort
                                        <br />
                                        <br />
                                        De onboarding kost ongeveer 3-5 minuten
                                        <br />
                                        <br />
                                        <Tooltip
                                          overlayInnerStyle={{
                                            width: "400px",
                                          }}
                                          title={
                                            <>
                                              • Bankrekeningnummer - om jou uit
                                              te betalen
                                              <br />
                                              • BTW nummer - Om te kunnen
                                              factureren aan de opdrachtgever
                                              <br />• ID bewijs / paspoort om
                                              jouw identiteit vast te stellen.
                                            </>
                                          }
                                        >
                                          <span style={{ color: "#664FF3" }}>
                                            Waarom hebben wij jouw gegevens
                                            nodig?
                                          </span>
                                        </Tooltip>
                                      </p>
                                    ) : (
                                      <p>
                                        Wij linken u na de betaling door naar de
                                        onboarding tool.
                                        <br /> <br />
                                        Zorg dat u uw bedrijfsgegevens paraat
                                        hebt:
                                        <br />
                                        - Bankrekeningnummer
                                        <br />
                                        - BTW nummer
                                        <br />
                                        - ID Bewijs / paspoort
                                        <br /> <br />
                                        De onboarding kost ongeveer 3-5 minuten.
                                      </p>
                                    )}

                                    <Button
                                      className="payment-button"
                                      style={
                                        !user.is_payment_active ||
                                        user.is_finqle_active
                                          ? {
                                              ...btnStyle,
                                              backgroundColor: "#D3D3D3",
                                            }
                                          : {
                                              ...btnStyle,
                                              backgroundColor: "#35DF90",
                                            }
                                      }
                                      onClick={() => requestFinqleOnboarding()}
                                      disabled={
                                        !user.is_payment_active ||
                                        user.is_finqle_active
                                      }
                                    >
                                      START ONBOARDING
                                    </Button>
                                  </div>
                                </>
                              }
                              icon={
                                <SafetyOutlined className="wallet-stepper-icon" />
                              }
                            />
                            <Steps.Step
                              status="process"
                              title={
                                <div
                                  className={
                                    screens.md
                                      ? "wallet-bold-text wallet-padding-left-50px"
                                      : "wallet-bold-text wallet-padding-left-5px"
                                  }
                                >
                                  {user.user_type === UserTypes.Freelancer
                                    ? "Reageer op opdrachten! "
                                    : "Connect met ZZP’ers"}
                                </div>
                              }
                              subTitle={
                                <>
                                  <div
                                    className={
                                      screens.md
                                        ? "wallet-padding-left-50px wallet-padding-bottom-52px text-line-height"
                                        : "wallet-padding-left-5px wallet-padding-bottom-52px text-line-height"
                                    }
                                  >
                                    {user.user_type === UserTypes.Freelancer ? (
                                      <p>
                                        Als de banner bovenin het scherm
                                        verdwenen is, dan is de onboarding
                                        geslaagd!
                                        <br />
                                        <br />
                                        Oftewel je hebt volledige toegang tot
                                        het Platform.
                                        <br />- Onbeperkt reageren op vacatures
                                        die voor jou interessant zijn
                                        <br />- Netwerken en chatten met
                                        opdrachtgevers
                                        <br />
                                        <br />
                                        Deze twee modules zijn geheel gratis en
                                        onbeperkt te gebruiken!
                                      </p>
                                    ) : (
                                      <p>
                                        Na enkele minuten verdwijnt de banner
                                        bovenin het scherm. Dit betekent dat de
                                        onboarding is geslaagd en u volledige
                                        toegang heeft tot Job.nl.
                                        <br />
                                        <br />
                                        - Onbeperkt Database filteren
                                        <br />- Connectieverzoeken versturen
                                      </p>
                                    )}
                                  </div>
                                </>
                              }
                              icon={
                                <RetweetOutlined className="wallet-stepper-icon" />
                              }
                            />
                            <Steps.Step
                              status="process"
                              title={
                                <div
                                  className={
                                    screens.md
                                      ? "wallet-bold-text wallet-padding-left-50px"
                                      : "wallet-bold-text wallet-padding-left-5px"
                                  }
                                >
                                  Verder belangrijk om te weten:
                                </div>
                              }
                              subTitle={
                                <>
                                  <div
                                    className={
                                      screens.md
                                        ? "wallet-padding-left-50px wallet-padding-bottom-52px text-line-height"
                                        : "wallet-padding-left-5px wallet-padding-bottom-52px text-line-height"
                                    }
                                  >
                                    {user.user_type === UserTypes.Freelancer ? (
                                      <p>
                                        Met onze onboarding ben je ook gelijk
                                        bij onze factoring-partner (Finqle)
                                        geregistreerd. Zij zorgen voor de
                                        directe uitbetaling van jouw facturen.
                                        <br />
                                        <br />
                                        Als de onboarding is geslaagd, ontvang
                                        je ook van onze partner een bevestiging
                                        via de mail.
                                        <br />
                                        <br />
                                        Heb je vragen over ons onboardingproces?
                                        neem dan een kijkje bij onze
                                        veelgestelde vragen
                                        <Button
                                          href="https://www.job.nl/help"
                                          type="link"
                                          target="_blank"
                                        >
                                          veelgestelde vragen
                                        </Button>
                                      </p>
                                    ) : (
                                      <p>
                                        Wij gebruiken de gedetailleerde
                                        bedrijfsinformatie voor administratieve
                                        doeleinden. Specifiek om de facturatie
                                        namens de ZZP’er te kunnen voldoen en de
                                        facturen te kunnen aanbieden aan onze
                                        factoringpartner (Finqle).
                                        <br />
                                        <br />
                                        Heb je vragen over ons onboardingproces?
                                        neem dan een kijkje bij onze
                                        veelgestelde vragen
                                        <Button
                                          href="https://www.job.nl/help"
                                          type="link"
                                          target="_blank"
                                        >
                                          veelgestelde vragen
                                        </Button>
                                      </p>
                                    )}
                                  </div>
                                </>
                              }
                              icon={
                                <InfoCircleOutlined className="wallet-stepper-icon" />
                              }
                            />
                          </Steps>
                        </Col>
                        <Col
                          span={screens.md ? 12 : 24}
                          style={screens.md ? { textAlign: "end" } : {}}
                        >
                          <img
                            src={image2}
                            alt="img"
                            style={screens.md ? {} : { width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Row>
                  )}

                  <Row
                    justify="space-between"
                    style={
                      screens.md
                        ? { padding: "75px" }
                        : { marginBlockStart: "14px" }
                    }
                    className="container"
                  >
                    <Col
                      span={screens.md ? 12 : 24}
                      style={
                        screens.md
                          ? {}
                          : { overflow: "scroll", backgroundColor: "white" }
                      }
                    >
                      <Table
                        dataSource={dataSource}
                        columns={columns}
                        style={
                          screens.md
                            ? { width: "90%" }
                            : { width: "600px", backgroundColor: "white" }
                        }
                        bordered
                        pagination={false}
                        rowClassName="wallet-table-row"
                      />
                    </Col>
                    <Col
                      span={screens.md ? 12 : 24}
                      style={
                        screens.md
                          ? { paddingLeft: "24px" }
                          : { marginBlockStart: "32px" }
                      }
                    >
                      <div
                        className="wallet-bold-text"
                        style={{ marginBlockEnd: "28px", justifySelf: "left" }}
                      >
                        Hoe werkt de Job-wallet?
                      </div>
                      <div
                        className="wallet-normal-text"
                        style={{
                          marginBlockEnd: "35px",
                          maxWidth: "600px",
                          lineHeight: "24px",
                        }}
                      >
                        Kort samengevat: Extra korting!
                        <br />
                        <br />
                        De zoek- hub en de verbind-hub zijn geheel gratis te
                        gebruiken. De werk-hub maakt Job.nl tot een all-in-one
                        aanbieder. Wij vragen voor het gebruik van de werk-hub
                        een kleine vergoeding per gewerkt uur. Deze vergoeding
                        zetten wij in voor de verdere doorontwikkeling van het
                        platform.
                        <br />
                        <br />
                        Het tegoed in de Job-wallet is een extraatje voor de
                        actieve gebruikers onder het motto Samen, Zelfstandig
                        zijn! Bouw jij actief mee aan de groei van het platform?
                        Dan regelen wij voor jou extra korting, wel zo fair!
                        <br />
                        <br />
                        Je bouwt op drie manieren tegoed op in de Job-wallet,
                        namelijk door:
                        <br />
                        (1) Je account te verifiëren. Wij brengen hiervoor op
                        dit moment €10,- in rekening. Dit bedrag wordt één op
                        één doorgestort in de Job-wallet;
                        <br />
                        (2) Andere gebruikers uit te nodigen. Of dit nu ZZP'ers
                        zijn, of Bedrijven, dat maakt niet uit! Is degene die
                        jij hebt uitgenodigd geverifieerd? Dan ontvang je op dit
                        moment een tegoed van €10,-
                        <br />
                        (3) Te werken via het het platform. Maak je actief
                        gebruik van de werk-hub? Dan ontvang je op dit moment
                        voor elke 100 uur die je hebt gewerkt een extra tegoed
                        van €10,-
                        <br />
                        <br />
                        Het tegoed wordt automatisch wekelijks verrekend bij de
                        eerst volgende opdracht met de door Job.nl in rekening
                        te brengen kosten.
                        <br />
                        <br />
                        Wil je meer uitleg wat de verschillende hubs voor jou
                        kunnen betekenen? Ga dan naar{" "}
                        <a
                          style={{ color: "#664FF3" }}
                          target="_blank"
                          rel="noreferrer"
                          href={
                            user.user_type === UserTypes.Company
                              ? "https://www.job.nl/opdrachtgevers"
                              : "https://www.job.nl/zzp"
                          }
                        >
                          meer informatie
                        </a>
                        .
                        <br />
                        <br />
                        Op de Job- wallet zijn de{" "}
                        <a
                          href="/docs/algemene-voorwaarden.pdf"
                          download="Algemene voorwaarden.pdf"
                        >
                          algemene voorwaarden
                        </a>
                        van toepassingen.
                      </div>
                    </Col>
                  </Row>
                </Content>
              </div>
            </Col>
          </Row>
          {/* <Row style={{ backgroundColor: '#002333', height: '317px', display: 'grid', placeItems: 'center' }}>
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div className='wallet-bold-text' style={{ marginBlockEnd: '34px', color: 'white' }}>
                Terug gestort na de eerste factuur
              </div>
              {(!is_finqle_active || !is_payment_active) &&
                <Link to='/payment'>
                  <Button className='payment-button'
                    style={{
                      backgroundColor: 'white',
                      color: '#002333',
                      borderRadius: '50px',
                      minWidth: '240px',
                      height: '50px',
                    }}>START NU
                  </Button>
                </Link>}
            </Col>
          </Row> */}
        </Layout>
      </Route>
    </>
  );
}
