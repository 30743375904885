import {
  Row,
  Col,
  Form,
  Grid,
  Typography,
  Input,
  Select,
  Button,
  message,
  InputNumber,
} from "antd";
import "./styles.css";
import { useState, useEffect } from "react";
import { UserTypes, User } from "src/types/user.type";
import axios from "axios";
import { useQuery } from "react-query";
import { useUser } from "src/hooks/useUser";
import SectorSelect from "src/components/common-inputs/Sector-Select";
import RegionSelect from "src/components/common-inputs/Region-Select";
import PositionSelect from "src/components/common-inputs/Position-Select";
import "antd/lib/input-number/style/css";

export default function ChangeCompanyForFreelancer() {
  const screens = Grid.useBreakpoint();
  const { Title } = Typography;
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [sector, setSector] = useState<number | undefined>();
  const [spacialities, setspacialities] =
    useState<[{ id: number; name: string }]>();
  const [isEditing, setEditing] = useState(false);

  const BASE_URL = process.env.REACT_APP_BACKEND;
  const { user } = useUser();

  const { data, refetch } = useQuery<User>("userdata", async () => {
    return (
      await axios({
        method: "GET",
        url: `${BASE_URL}api/freelancers/${user.id}/`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
    ).data;
  });

  const getOptions = () => {
    let optionSet = [];
    for (let i = 0; i <= 50; i++) {
      optionSet.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }
    return optionSet;
  };

  useEffect(() => {
    form.setFields([
      {
        name: "position_id",
        value: undefined,
      },
    ]);

    // eslint-disable-next-line
  }, [sector]);

  useEffect(() => {
    if (data) {
      let val: number[] = [];
      data.specialty.forEach((e) => {
        val.push(e.id);
      });

      form.setFields([
        {
          name: "company_name",
          value: data.company_name,
        },
        {
          name: "sector_id",
          value: data.sector?.id,
        },
        {
          name: "kvk_number",
          value: data.kvk_number,
        },
        {
          name: "specialties",
          value: val,
        },
        {
          name: "position_id",
          value: data.position?.id,
        },
        {
          name: "region_id",
          value: data.region?.id,
        },
        {
          name: "min_rate",
          value: data.min_rate,
        },
        {
          name: "max_rate",
          value: data.max_rate,
        },
        {
          name: "experience",
          value: Number(data.experience),
        },
        {
          name: "job_description",
          value: data.description,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [data]);

  const formGroupInputStyle = {
    display: "inline-block",
    width: "calc(50% - 40px)",
    margin: "0 40px 0 0 ",
    maxHeight: "70px",
  };

  const formSingleInputStyle = { width: "95%" };

  // const [companyDetails, setCompanyDetails] = useState()

  const onFinish = async (values: any) => {
    const speciality: number[] = values.specialties;

    console.log("value.specialties", values);

    const newData = {
      experience: values.experience,
      min_rate: values.min_rate,
      max_rate: values.max_rate,
      sector: { id: values.sector_id },
      position: { id: values.position_id },
      region: { id: values.region_id },
      description: values.job_description,
      specialty: speciality.map((e) => {
        return { id: e };
      }),
    };

    try {
      await axios({
        method: "PATCH",
        url: `${BASE_URL}api/freelancers/${user.id}/`,
        data: newData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      message.success("Opgeslagen.");
      setEditing(false);
      refetch();
    } catch (error) {
      message.error("Something went wrong. Try again.");
      console.log(error);
    }
  };

  useEffect(() => {
    axios
      .get(BASE_URL + "api/specialty?limit=400")
      .then((res) => {
        setspacialities(res.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [BASE_URL]);

  const handleEditForm = () => {
    if (!isEditing) {
      setEditing(true);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      form.setFields([
        {
          name: "company_name",
          errors: undefined,
        },
        {
          name: "sector_id",
          errors: undefined,
        },
        {
          name: "kvk_number",
          errors: undefined,
        },
        {
          name: "specialties",
          errors: undefined,
        },
        {
          name: "position_id",
          errors: undefined,
        },
        {
          name: "region_id",
          errors: undefined,
        },
        {
          name: "min_rate",
          errors: undefined,
        },
        {
          name: "max_rate",
          errors: undefined,
        },
        {
          name: "experience",
          errors: undefined,
        },
        {
          name: "job_description",
          errors: undefined,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  return (
    <>
      {screens.md && <div className="inner-sub-header">Bedrijfsaccount</div>}
      <Row>
        <Form
          form={form}
          name="regiter-form"
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          style={{ width: "100%" }}
        >
          <div style={screens.md === true ? { padding: "24px" } : {}}>
            <Row justify="center">
              <Col
                xl={0}
                lg={0}
                md={0}
                sm={24}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <Title level={5} className="mobile-header">
                  {UserTypes.Company === user.user_type ||
                  UserTypes.Child_User === user.user_type
                    ? "Bedrijfsaccount"
                    : "Persoonlijk account"}
                </Title>
                <br />
              </Col>
            </Row>

            <div>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  {/* Company Profile Edit */}
                  {UserTypes.Freelancer === user.user_type && (
                    <>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          label={
                            <span className="form-label">Bedrijfsnaam</span>
                          }
                          name="company_name"
                          rules={[
                            {
                              // required: true,
                              message: "Bedrijfsnaam is verplicht",
                            },
                          ]}
                          style={screens.md === true ? formGroupInputStyle : {}}
                        >
                          <Input
                            disabled={true}
                            onChange={handleEditForm}
                            placeholder="Bedrijfsnaam"
                            className="edit-profile-input"
                          />
                        </Form.Item>
                        <Form.Item
                          label={<span className="form-label">Sector</span>}
                          name="sector_id"
                          rules={[
                            { required: true, message: "Sector is verplicht" },
                          ]}
                          style={screens.md === true ? formGroupInputStyle : {}}
                        >
                          <SectorSelect
                            disabled={!isEditing}
                            size="large"
                            placeholder="Sector"
                            onChange={(value: number) => {
                              handleEditForm();
                              setSector(value);
                            }}
                          />
                        </Form.Item>
                      </Form.Item>
                      {screens.md === true && <br />}
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          label={<span className="form-label">KVK nummer</span>}
                          name="kvk_number"
                          rules={[
                            {
                              // required: true,
                              message: "KVK nummer is verplicht",
                            },
                          ]}
                          style={screens.md === true ? formGroupInputStyle : {}}
                        >
                          <Input
                            onChange={handleEditForm}
                            disabled={true}
                            placeholder="KVK nummer"
                            className="edit-profile-input"
                          />
                        </Form.Item>

                        <Form.Item
                          name="specialties"
                          label={
                            <span className="form-label">Specialisme</span>
                          }
                          rules={[
                            {
                              required: false,
                              message: "Specialisme is verplicht",
                              type: "array",
                            },
                          ]}
                          style={
                            screens.md === true
                              ? {
                                  display: "inline-block",
                                  width: "calc(50% - 40px)",
                                  margin: "0 40px 0 0 ",
                                  // maxHeight: '70px'
                                }
                              : {}
                          }
                        >
                          <Select
                            disabled={!isEditing}
                            onChange={() => handleEditForm()}
                            placeholder="Specialisme"
                            mode="multiple"
                            size="large"
                            filterOption={(inputValue, option) => {
                              return String(option?.children)
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          >
                            {spacialities
                              ?.sort((a, b) => {
                                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                                if (nameA < nameB) {
                                  return -1;
                                }
                                if (nameA > nameB) {
                                  return 1;
                                }
                                // names must be equal
                                return 0;
                              })
                              .map((ele: any) => (
                                <Option
                                  style={{ height: "40px" }}
                                  key={ele["id"]}
                                  value={ele["id"]}
                                >
                                  {ele["name"]}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                      {screens.md === true && <br />}
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          label={<span className="form-label">Functie</span>}
                          name="position_id"
                          rules={[
                            { required: true, message: "Functie is verplicht" },
                          ]}
                          style={screens.md === true ? formGroupInputStyle : {}}
                        >
                          <PositionSelect
                            disabled={!isEditing}
                            onChange={handleEditForm}
                            placeholder="Functie"
                            size="large"
                            sectorId={sector}
                          />
                        </Form.Item>

                        <Form.Item
                          label={<span className="form-label">Regio</span>}
                          name="region_id"
                          rules={[
                            { required: true, message: "Regio is verplicht" },
                          ]}
                          style={screens.md === true ? formGroupInputStyle : {}}
                        >
                          <RegionSelect
                            disabled={!isEditing}
                            onChange={handleEditForm}
                            placeholder="Regio"
                            size="large"
                          />
                        </Form.Item>
                      </Form.Item>
                      {screens.md === true && <br />}
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        className="form-column"
                      >
                        <Form.Item
                          label={<span className="form-label">Tarief</span>}
                          style={
                            screens.md === true
                              ? {
                                  display: "inline-block",
                                  width: "calc(50% - 40px)",
                                  margin: "0 40px 0 0 ",
                                }
                              : {}
                          }
                        >
                          <Form.Item
                            name="min_rate"
                            rules={[
                              {
                                required: true,
                                message: "Minimaal tarief is verplicht",
                              },
                            ]}
                            style={{
                              display: "inline-block",
                              width: "calc(100px - 8px)",
                            }}
                          >
                            <InputNumber
                              disabled={!isEditing}
                              onChange={handleEditForm}
                              placeholder="Min"
                              size="large"
                              className="textBlack"
                            />
                          </Form.Item>
                          <span style={{ fontSize: "24px" }}> -</span>
                          <Form.Item
                            name="max_rate"
                            rules={[
                              {
                                required: true,
                                message: "Maximaal tarief is verplicht",
                              },
                            ]}
                            style={{
                              display: "inline-block",
                              width: "calc(40% - 8px)",
                              margin: "0 8px",
                            }}
                          >
                            <InputNumber
                              disabled={!isEditing}
                              onChange={handleEditForm}
                              placeholder="Max"
                              size="large"
                            />
                          </Form.Item>
                        </Form.Item>

                        <Form.Item
                          label={
                            <span className="form-label">Ervaringsjaren</span>
                          }
                          name="experience"
                          rules={[
                            {
                              required: true,
                              message: "Ervaringsjaren is verplicht",
                            },
                          ]}
                          style={screens.md === true ? formGroupInputStyle : {}}
                        >
                          <Select
                            disabled={!isEditing}
                            onChange={handleEditForm}
                            placeholder="Ervaringsjaren"
                            size="large"
                          >
                            {getOptions()}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                      {screens.md === true && <br />}
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        className="form-column"
                      >
                        <Form.Item
                          label={
                            <span className="form-label">
                              Profielomschrijving
                            </span>
                          }
                          name="job_description"
                          rules={[
                            {
                              required: true,
                              message: "Profielomschrijving is verplicht",
                            },
                          ]}
                          style={
                            screens.md === true ? formSingleInputStyle : {}
                          }
                        >
                          <TextArea
                            disabled={!isEditing}
                            onChange={handleEditForm}
                            autoSize={{ minRows: 7, maxRows: 7 }}
                            style={{ borderRadius: "2px" }}
                          />
                        </Form.Item>
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Form.Item>
            <Row justify="end" style={screens.md ? { margin: "0px 20px" } : {}}>
              {!isEditing ? (
                <Col span={screens.md ? 12 : 24}>
                  <Button
                    onClick={() => setEditing(true)}
                    block={screens.md ? false : true}
                    type="primary"
                    className={screens.md ? "form-button-block" : ""}
                    style={{ backgroundColor: "#002333" }}
                  >
                    Bewerken
                  </Button>
                </Col>
              ) : (
                <>
                  <Col span={screens.md ? 6 : 12}>
                    <Button
                      onClick={() => {
                        // form.resetFields()
                        refetch();
                        setEditing(false);
                      }}
                      block={screens.md ? false : true}
                      type="primary"
                      className={screens.md ? "form-button" : ""}
                      style={{
                        backgroundColor: "#664FF3",
                        marginRight: "20px",
                      }}
                    >
                      Annuleren
                    </Button>
                  </Col>
                  <Col span={screens.md ? 6 : 12}>
                    <Button
                      block={screens.md ? false : true}
                      type="primary"
                      className={screens.md ? "form-button" : ""}
                      htmlType="submit"
                    >
                      Opslaan
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </>
  );
}
