import React, { useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ChildUser } from 'src/types/user.type'

export default function UserItem(props: any) {

  let data: ChildUser = props.data;
  const [modal2Visible, setModal2Visible] = useState(false);

  const handleDetele = () => {
    props.removeUser(data);
    setModal2Visible(false);
  }

  return (
    <>
      <div className='user-item-container'>
        <Row>
          <Col span={20}>
            {props.key}
            <div onClick={() => { props.setEnableVisibleForm([!props.enableVisibleForm, false, data.id]) }} className='account-authentication' style={{ cursor: 'pointer' }}>
              {`${data.first_name} ${data.last_name}`}
            </div >
          </Col>
          <Col span={4}>
            <div className='account-authentication' style={{ textAlign: 'center' }}>
              <DeleteOutlined onClick={() => setModal2Visible(true)} style={{
                fontSize: 20,
                textAlign: 'left'
              }} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        closable={false}
        title={
          <div>
            <Row>
              <Col style={{
                fontFamily: 'Arimo',
                fontWeight: 700,
                fontSize: '16px',
                padding: '25px',
                textAlign: 'center',
              }}>
                Weet u zeker dat u
                {` ${data.first_name} ${data.last_name} `} wilt verwijderen?
            </Col>
            </Row>
          </div>
        }
        centered
        visible={modal2Visible}
        footer={null}
        bodyStyle={{
          padding: 0
        }}
      >

        <Row>
          <Col span={12}>
            <Button size='large' style={{
              width: '100%',
              fontFamily: 'Arimo',
              fontWeight: 700,
              fontSize: '16px',
              backgroundColor: '#002333',
              color: 'white',
              border: '0px'
            }}
              onClick={() => setModal2Visible(false)}
            >Annuleren</Button>
          </Col>
          <Col span={12}>
            <Button size='large' style={{
              width: '100%',
              fontFamily: 'Arimo',
              fontWeight: 700,
              fontSize: '16px',
              color: '#002333'
            }}
              onClick={handleDetele}
            >Verwijderen</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}