import "./styles.css";
import { Row, Col, Layout, Grid, Menu, Divider } from "antd";
import { SettingOutlined, BulbOutlined } from "@ant-design/icons";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import { Route, Link, useLocation } from "react-router-dom";
import ChangeCompanyForFreelancer from "./ChangeCompanyForFreelancer";
import EditFreelancerProfile from "./EditFreelancerProfile";
import { UserTypes } from "src/types/user.type";
import { useUser } from "src/hooks/useUser";
import { PermissionType } from "src/types/Permission";
import { checkPermission } from "src/hooks/checkPermission";

export default function Profile() {
  const { Content } = Layout;
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const { user } = useUser();

  const linkStyle = { color: "inherit", backgroundColor: "inherit" };

  const getCurrentSelect = () => {
    switch (location.pathname) {
      case "/user/profile/editprofile":
        return ["0"];
      case "/user/profile/editprofilefreelancer":
        return ["1"];
      case "/user/profile/changecompanydetails":
        return ["2"];
      case "/user/profile/changepassword":
        return ["3"];
      default:
        return [""];
    }
  };

  const menu = (
    <Menu
      selectedKeys={getCurrentSelect()}
      mode="inline"
      className="profile-sub-selection"
      style={screens.md ? { border: "0px" } : { backgroundColor: "#FFF" }}
    >
      {UserTypes.Company === user.user_type && (
        <Menu.Item
          className={
            screens.md ? "profile-sub-selection" : "mobile-sub-menu-items"
          }
          key="0"
        >
          <Link style={linkStyle} to="/user/profile/editprofile">
            <SettingOutlined />
            Bedrijf
          </Link>
        </Menu.Item>
      )}
      {UserTypes.Child_User === user.user_type &&
        checkPermission({ user, can: PermissionType.CREATE_ASSIGNMENT }) && (
          <Menu.Item
            className={
              screens.md ? "profile-sub-selection" : "mobile-sub-menu-items"
            }
            key="0"
          >
            <Link style={linkStyle} to="/user/profile/editprofile">
              <SettingOutlined />
              Bedrijf
            </Link>
          </Menu.Item>
        )}
      {user.user_type === UserTypes.Freelancer && (
        <Menu.Item
          className={
            screens.md ? "profile-sub-selection" : "mobile-sub-menu-items"
          }
          key="1"
        >
          <Link style={linkStyle} to="/user/profile/editprofilefreelancer">
            <BulbOutlined />
            Persoonlijk account
          </Link>
        </Menu.Item>
      )}
      {user.user_type === UserTypes.Freelancer && (
        <Menu.Item
          className={
            screens.md ? "profile-sub-selection" : "mobile-sub-menu-items"
          }
          key="2"
        >
          <Link style={linkStyle} to="/user/profile/changecompanydetails">
            <SettingOutlined />
            Bedrijf account
          </Link>
        </Menu.Item>
      )}
      {(user.user_type === UserTypes.Company ||
        user.user_type === UserTypes.Freelancer ||
        user.user_type === UserTypes.Child_User) && (
        <Menu.Item
          className={
            screens.md ? "profile-sub-selection" : "mobile-sub-menu-items"
          }
          key="3"
        >
          <Link style={linkStyle} to="/user/profile/changepassword">
            <BulbOutlined />
            Wachtwoord wijzigen
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={21} xs={21}>
          <Layout
            className={screens.md ? "container" : ""}
            style={{ minHeight: "85vh" }}
          >
            <Content
              className={screens.md ? "web-content" : ""}
              style={{ minWidth: "100%" }}
            >
              {screens.md && (
                <>
                  <Row justify="space-between" style={{ marginTop: 25 }}>
                    <Col flex="200px">
                      <div className="web-header">Bedrijf</div>
                    </Col>
                  </Row>
                  <Divider className="web-divider-dark" />
                </>
              )}
              <Row style={{ backgroundColor: "white" }} justify="center">
                <Col span={screens.md ? 5 : 24}>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={0} xs={0}>
                      {menu}
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={screens.md ? 19 : 22}
                  style={{ backgroundColor: "white" }}
                >
                  <div className={screens.md ? "" : ""}>
                    <div
                      style={
                        screens.md
                          ? {
                              backgroundColor: "white",
                              borderLeft: "1px solid #E8E8E8",
                            }
                          : { marginBlockEnd: "40px" }
                      }
                    >
                      <Route
                        path="/user/profile/editprofile"
                        component={EditProfile}
                      />
                      <Route
                        path="/user/profile/changepassword"
                        component={ChangePassword}
                      />
                      <Route
                        path="/user/profile/changecompanydetails"
                        component={ChangeCompanyForFreelancer}
                      />
                      <Route
                        path="/user/profile/editprofilefreelancer"
                        component={EditFreelancerProfile}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
}
