import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootStateType } from "../rootReducer";
// import jwt_decoder from 'jwt-decode';
// import { stat } from "fs";

interface IAuthState {
  token: string | null;
  // tokenDecoded: any;
}

let initialState: IAuthState = {
  token: null,
  // tokenDecoded: null,
};

if (typeof window !== "undefined") {
  const token = localStorage.getItem("token");
  initialState.token = token;
  // initialState.tokenDecoded = jwt_decoder(token !== null ? token : "");
}

export const authEntity = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      return { ...state, token: action.payload };
    },
    logout() {
      localStorage.clear();
      return { token: null };
    },
  },
});

/** selectors */

const tokenSelector = (state: RootStateType) => state.auth.token;

/** Hooks */

export const useToken = () => useSelector(tokenSelector);

export const {
  setToken,
  logout,
} = authEntity.actions;