import { combineReducers } from "@reduxjs/toolkit";
import { authEntity } from "./entity/auth";
import appSetting from './reducers/appSettings.reducer';
import Notification from './reducers/Notification.reducer';

export const rootReducer = combineReducers({
  [authEntity.name]: authEntity.reducer,
  appSetting: appSetting,
  Notification: Notification,
});

export type RootStateType = ReturnType<typeof rootReducer>;
