import { formatDistanceToNowStrict } from "date-fns";
import { nl } from "date-fns/esm/locale";

export default function getRelativeTime(ts: number) {
  const time = formatDistanceToNowStrict(new Date(ts), {
    locale: nl,
  });

  if (time?.split(" ")?.[1] === "seconden") return "Nu";
  else if (time === "een minuut") return "Nu";
  else return time;
}
