import { Row, Col, Divider, Grid, Select, message } from "antd";
import FreelancerDetail from "./FreelancerDetail";
import axios from "axios";
import { Vacancy } from "src/types/work.type";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { useUser } from "src/hooks/useUser";

function toQueryString(key: any, value: any): string {
  return queryString.stringify(
    { [key]: value },
    {
      arrayFormat: "comma",
      arrayFormatSeparator: ",",
      skipNull: true,
    }
  );
}

type VacancyDetailsProps = {
  id: number;
  vacancyDetails: Vacancy | undefined;
  refetch: Function;
};

export default function VacancyDetails({
  id,
  vacancyDetails,
  refetch,
}: VacancyDetailsProps) {
  const BASE_URL = process.env.REACT_APP_BACKEND;
  const { user } = useUser();

  const screens = Grid.useBreakpoint();
  const { Option } = Select;

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleChange = (value: any) => {
    // console.log(`selected ${value}`);
  };

  const removeFreelancerInterestedList = async (freelancerId: number) => {
    if (vacancyDetails) {
      let list: number[] = vacancyDetails.interested_freelancer.map((e) => {
        return e.id;
      });
      if (list.includes(freelancerId)) {
        list = list.filter((e) => {
          return e !== freelancerId;
        });
      }

      await axios({
        method: "POST",
        url: `${BASE_URL}api/vacancies/${vacancyDetails.id}/remove_interest/`,
        headers: config.headers,
        data: {
          freelancer_ids: [freelancerId],
        },
      })
        .then((res) => {
          refetch();
          message.success("ZZP'er ontkoppeld van vacature");
          // successMessage("Success");
          // setLoadingSync(false);
          // refetchSearchList();
        })
        .catch((err) => {
          console.error(err);
          // ErrorMsg("Error");
          // setLoadingSync(false);
        });
    }
  };

  // console.log(
  //   "vacancyDetails ",
  //   vacancyDetails?.sector,
  //   vacancyDetails?.position,
  //   vacancyDetails?.specialty,
  //   vacancyDetails?.region
  // );

  const sector = toQueryString("sector", vacancyDetails?.sector);
  const position = toQueryString("position", vacancyDetails?.position);
  const specialty = toQueryString("specialty", vacancyDetails?.specialty);
  const region = toQueryString("region", vacancyDetails?.region);
  const queryParams = [sector, position, specialty, region].join("&");

  return (
    <>
      <div
        className={
          screens.md
            ? "project-details-layout project-details-layout__vacancy custom__scrollbar"
            : "project-details-layout-mobile custom__scrollbar"
        }
      >
        {screens.md && (
          <>
            <Row justify="space-between">
              <Col className="project-details-header">
                {vacancyDetails?.position_extra
                  ? `${vacancyDetails?.title.replaceAll("-", " ")} - ${
                      vacancyDetails?.position_extra
                    }`
                  : vacancyDetails?.title.replaceAll("-", " ")}
              </Col>
              <Col style={{ textAlign: "end" }}>
                <span className="sub-detail-2">{`€${Number(
                  vacancyDetails?.minimum_rate
                )} - ${Number(vacancyDetails?.maximum_rate)} p/u`}</span>
              </Col>
              <Divider style={{ backgroundColor: "#002333" }} />
            </Row>
          </>
        )}
        <Row justify="space-between">
          <div style={{ marginBlockEnd: "20px", width: "100%" }}>
            <Select
              defaultValue="1"
              style={screens.md ? { width: "215px" } : { width: "100%" }}
              onChange={handleChange}
            >
              <Option value="1">Sorteer op</Option>
            </Select>
          </div>
        </Row>
        {!(user?.is_finqle_active && user?.is_payment_active) && (
          <h4 style={{ textAlign: "center", color: "#664ff3" }}>
            U kunt op dit moment alleen vacatures plaatsen. Voltooi de
            verificatie om te connecten
          </h4>
        )}
        <div
          style={{
            filter: !(user?.is_finqle_active && user?.is_payment_active)
              ? "blur(3px)"
              : "",
          }}
        >
          {vacancyDetails?.interested_freelancer.map((e) => {
            return (
              <FreelancerDetail
                id={e.id}
                key={e.id}
                removeFreelancerInterestedList={removeFreelancerInterestedList}
              />
            );
          })}
        </div>
      </div>
      <Link to={`/search?${queryParams}`}>
        <div className="vacancies-freelancer-detail-serach-bar">
          <SearchOutlined style={{ fontSize: "16px", margin: "0px 5px" }} />{" "}
          Vergelijkbare resultaten
        </div>
      </Link>
    </>
  );
}
