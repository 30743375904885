import { Permission } from "./Permission";
import { ConnectionStatus } from "./common.type";

export enum UserTypes {
  Company = "company",
  Freelancer = "freelancer",
  Child_User = "child_user",
}
export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  user_type: string | null;
  sector: Sector;
  region: Region;
  position: Position;
  experience: string | null;
  max_rate: number | null;
  min_rate: number | null;
  profile_pic: string | null;
  connection: ConnectionStatus | null;
  kvk_number: number;
  address: string;
  description: string;
  company_name: string;
  specialty: Specialty[];
  phone_number: string;
  website: string;
  finished_project_count: number;
  project_count: number;
}

export interface Region {
  id: number;
  name: string;
}

export interface Position {
  id: number;
  name: string;
}

export interface Specialty {
  id: number;
  name: string;
}

export interface Company {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  user_type: string;
  kvk_number: string;
  sector: Sector;
  child_users: ChildUser[];
  open_project_count: number;
  open_vacancy_count: number;
  finished_project_count: number;
  region: Region;
  connection: ConnectionStatus | null;
  company_name: string;
  description: string;
  established: string;
  number_of_employees: string;
  profile_pic: string;
  website: string;
  project_count: number;
}

export interface Sector {
  id: number;
  name: string;
}

export interface ChildUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  location: string;
  website: string;
  permissions: Permission[];
  role: any;
}

export interface NewChildUser {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  location: string;
  website: string;
  permissions: Permission[];
}

export interface LoginUser {
  child_users: any[];
  company_name: string;
  connection: ConnectionStatus;
  description: string;
  email: string;
  established: string;
  finished_project_count: number;
  first_name: string;
  id: number;
  is_email_validated: boolean;
  is_finqle_active: boolean;
  is_payment_active: boolean;
  kvk_number: string;
  last_name: string;
  number_of_employees: string;
  open_project_count: number;
  profile_pic: string;
  region: Region;
  sector: Sector;
  user_type: string;
  username: string;
  website: string;
  specialty: Sector[];
  position: Position;
  experience: string;
  max_rate: string;
  min_rate: string;
  phone_number: string;
  verified_hours: boolean;
  permissions: Permission[];
  project_count: number;
}

export interface JWTDecodedUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
}
