import { useState, useMemo } from 'react';
import {
  Popover,
  Divider,
  Button,
  Avatar,
  Badge,
  Row,
  Col,
  Drawer,
} from 'antd';
import {
  BellOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';

import Notification from './Nofitication-item';
import '../styles.css'
import { useSelector, useDispatch } from 'react-redux';
import { RootStateType } from 'src/store/rootReducer';
import { NotificationContent, NotificationUpdate } from 'src/types/Notification.type';
import { fetchNotifications, fetchNotification, addNewNotification } from 'src/store/reducers/Notification.reducer';

let socket: WebSocket | null = null;

export default function Notifications(props: any) {

  const data: NotificationContent[] = useSelector((state: RootStateType) => state.Notification.notifications);
  const dispatch = useDispatch();
  const token = useSelector((state: RootStateType) => state.auth.token);
  const height: number = window.innerHeight - 45;

  if (!socket)
    socket = new WebSocket(`${process.env.REACT_APP_WS_BACKEND}ws/notifications?token=${token}`);

  socket.onmessage = (event) => {
    const message = JSON.parse(event.data)
    console.log("onmessage", message)
    switch (message.type) {
      case "notification_history":
        // console.log("notification_history", message.content)
        dispatch(fetchNotifications(message.content));
        break;
      default:
        // console.log("response notification", message)
        if (message.date_hidden === null && message.date_read === null) {
          const notification = data.find(e => { return e.id === message.id })
          if (notification !== undefined) {
            dispatch(addNewNotification(message.content));
          }
        }
        dispatch(fetchNotification(message));
    }
  }

  const sendFetchEvent = () => {
    if (socket?.readyState === 1) {
      socket.send(JSON.stringify({
        "command": "history"
      }));
    }
  }

  // const markNotificationsRead = () => {
  //   try {
  //     // console.log("Marking Notifications seen")
  //     // data && data.forEach(e => {
  //     //   const sendData: NotificationUpdate = {
  //     //     command: "update",
  //     //     id: e.id,
  //     //     data: {
  //     //       date_read: new Date().getDate(),
  //     //       date_hidden: new Date().getDate(),
  //     //     }
  //     //   }
  //     //   socket.onopen = () => {
  //     //     socket.send(JSON.stringify(sendData));
  //     //   }
  //     // })
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  const markNotificationRead = (id: number, connetionAccepted: boolean) => {
    try {
      // console.log(`Marking Notification ${id} seen`)
      const oldData = data.find(e => { return (e.id === id) })
      let sendData: NotificationUpdate = {
        command: "update",
        id: id,
        data: {
          date_read: Date.now() / 1000,
          date_hidden: Date.now() / 1000,
        }
      }

      if (oldData && connetionAccepted) {
        sendData.data.date_read = oldData.date_read === null ? Date.now() / 1000 : oldData.date_read;
        sendData.data.type = "connection_accepted";
      }

      // socket?.send(JSON.stringify(sendData));
      if (socket?.readyState === 1) {
        console.log(`Marking Notification ${id} seen`, sendData)
        socket.send(JSON.stringify(sendData));
      }
    } catch (err) {
      console.error(err)
    }
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const count = useMemo(() => {
    let count: number = 0;
    data && data.forEach(value => {
      if (value && value.date_read === null && value.date_hidden === null) {
        count++;
      }
    })
    return count;
  }, [data])

  return (
    <section>
      <Row>
        <Col xl={24} lg={24} md={24} sm={0} xs={0}>
          <div>
            <Popover
              // onVisibleChange={e => { !e && markNotificationsRead() }}
              trigger="click"
              placement="bottom"
              overlayClassName="notification-content"
              content={
                <div className='notification' >
                  <h4 className='notification-header-web'>Notificaties</h4>
                  <Divider className="web-divider" />
                  <div className='notification-body'>
                    {data && data.map((notificationData, index: number) => {
                      return <Notification notificationData={notificationData} key={notificationData.id} markNotificationRead={markNotificationRead} sendFetchEvent={sendFetchEvent} />
                    })}
                  </div>
                  {/* <Button onClick={() => { dispatch(fetchNotifications(newNotifications)) }}>New</Button> */}
                  {/* <Button onClick={() => { dispatch(markAllNotificationsRead()) }}>Mark Read</Button>
                  <Button onClick={() => { markNotificationsRead() }}>Mark Read</Button> */}
                </div>
              }>
              <Button type="text" size={"large"} style={{
                marginRight: '8px',
              }} icon={
                <Avatar shape="square" size={50} className="notification-icon">
                  <Badge
                    offset={[0, 25]}
                    size="small" count={count}>
                    <BellOutlined className='notification-icon-badge' />
                  </Badge>
                </Avatar>}
              />
            </Popover>
          </div>
        </Col>

        {/* Mobile Layout */}
        <Col xl={0} lg={0} md={0} sm={24} xs={24}>
          <Row>
            <Col>
              <Button type="text"
                className="mobile-menu-button"
                onClick={showDrawer}
                icon={
                  <Avatar shape="square" size={64} className="mobile-menu-icon-avatar">
                    <Badge
                      offset={[0, 25]}
                      size="small"
                      count={count}>
                      <BellOutlined className="mobile-menu-button-icon" />
                    </Badge>
                  </Avatar>} />
            </Col>
          </Row>

          {/* Drower Properties Mobile*/}
          <div>
            <Drawer
              title={<div><ArrowLeftOutlined className='notification-back-icon' onClick={onClose} /><span className="notification-header">Notificaties</span></div>}
              placement="right"
              closable={false}
              onClose={onClose}
              visible={visible}
              width={'100%'}
              getContainer={false}
              style={{
                textAlign: 'start',
                height: height
              }}
              headerStyle={{
                display: 'grid',
                border: '0px'
              }}
              bodyStyle={{
                marginBottom: '5px'
              }}
              className='mobile-notification'
            >
              {data && data.map((notificationData: NotificationContent, index: number) => {
                return <Notification notificationData={notificationData} key={index} markNotificationRead={markNotificationRead} sendFetchEvent={sendFetchEvent} />
              })}
            </Drawer>
          </div>
        </Col>
      </Row>
    </section>
  )
}