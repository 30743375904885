import {
  Input,
  Row,
  Form,
  Grid,
  Select,
  Slider,
  Checkbox,
  Button,
  Col,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserTypes } from "src/types/user.type";
import Toggle from "src/icons/toggle-ff.png";
import * as _ from "lodash";
import { useUser } from "src/hooks/useUser";
import { useQuery } from "react-query";

type FilterPanelProps = {
  setSearchFiltersList: Function;
  setFileterTags: Function;
  resultCount: Number | undefined;
  filterTags: any[];
  queryParams: any;
  isUserActive?: boolean;
};

type Response = {
  count: number;
  next: string;
  previous: string;
  results: [{ name: string; id: number }];
};

export default function FilterPanel({
  setFileterTags,
  setSearchFiltersList,
  resultCount,
  filterTags,
  queryParams,
  isUserActive,
}: FilterPanelProps) {
  const [form] = Form.useForm();
  const screens = Grid.useBreakpoint();
  const { Option } = Select;
  const { user } = useUser();

  const { sector, position, specialty, region } = queryParams;

  const [sectors, setSectors] = useState<[{ id: number; name: string }]>();
  const [regions, setRegions] = useState<[{ id: number; name: string }]>();
  // const [position, setPosition] = useState<[{ id: number, name: string }]>();
  const [speciality, setSpeciality] = useState<
    [{ id: number; name: string }] | { id: number; name: string }[] | undefined
  >();
  const [unfilteredSpeciality, setUnfilteredSpeciality] = useState<
    [{ id: number; name: string }] | { id: number; name: string }[] | undefined
  >();

  const [rate, setRate] = useState<any[] | undefined>();
  const [experienceValues, setExperienceValues] = useState<any[] | undefined>();
  // const [removeExperience, setRemoveExperience] = useState(false);
  // const [removeRate, setRemoveRate] = useState(false);
  // Sector
  const [selectedSector, setSelectedSector] = useState<number | undefined>();
  const [currentPagePosition, setCurrentPagePosition] = useState(1);
  const [currentPageSpecility, setCurrentPageSpecility] = useState(1);

  const [searchFilters, setSeachFilter] = useState<any>([]);

  const BASE_URL = process.env.REACT_APP_BACKEND;

  const Positions = useQuery<Response>("positions", async () => {
    return (
      await axios.get(
        BASE_URL +
          `api/position/?sector=${selectedSector ? selectedSector : ""}&limit=${
            currentPagePosition * 10
          }`
      )
    ).data;
  });

  useEffect(() => {
    Positions.refetch();
    // eslint-disable-next-line
  }, [currentPagePosition, selectedSector]);

  useEffect(() => {
    axios
      .get(BASE_URL + "api/region")
      .then((res) => {
        setRegions(res.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(BASE_URL + "api/specialty?limit=400")
      .then((res) => {
        setUnfilteredSpeciality(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(BASE_URL + "api/sector?limit=50")
      .then((res) => {
        setSectors(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filterObj = {
      sector_id: searchFilters?.sector_id,
      position_id: searchFilters?.position_id,
      region: searchFilters?.region,
      specialties: searchFilters?.specialties,
      others: searchFilters?.others,
    };
    form.setFieldsValue(filterObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters]);

  const setFilters = () => {
    const positionsId: number[] = searchFilters.position_id;
    const sectorId = searchFilters.sector_id;
    const period_start: number = searchFilters.period_start;
    const period_end: number = searchFilters.period_end;
    const regionId: number[] = searchFilters.region;
    const specialties: number[] = searchFilters.specialties;
    const others: number[] = searchFilters.others;

    const listTemp: any[] = [];
    let count = 0;
    let searchFiltersTemp: string = "";

    positionsId &&
      positionsId.forEach((e) => {
        const temp = Positions.data?.results?.find((em) => em.id === e);
        // listTemp.push(temp);
        if (temp) {
          searchFiltersTemp = searchFiltersTemp + `&position=${temp.id}`;

          listTemp.push(
            <span key={count} className="search-filter-selected">
              {temp.name}
              <CloseOutlined
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setSeachFilter((prev: any) =>
                    Object.create({
                      ...prev,
                      position_id: prev.position_id.filter(
                        (id: number) => id !== temp.id
                      ),
                    })
                  );
                }}
              />
            </span>
          );
          count++;
        }
      });

    const tempSector = sectors?.find((em) => em.id === sectorId);
    if (tempSector && selectedSector) {
      searchFiltersTemp = searchFiltersTemp + `&sector=${tempSector.id}`;
      listTemp.push(
        <span key={count} className="search-filter-selected">
          {tempSector.name}
          <CloseOutlined
            style={{ marginLeft: "5px" }}
            onClick={() => {
              // setRemoveSector(true)
              setSelectedSector(undefined);
            }}
          />
        </span>
      );
      count++;
    }

    if (
      experienceValues !== undefined &&
      experienceValues[0] !== undefined &&
      experienceValues[1] !== undefined
    ) {
      const temp = {
        id: 0,
        name: `Werkervaring ${experienceValues[0]} - ${experienceValues[1]}`,
      };
      searchFiltersTemp =
        searchFiltersTemp +
        `&experience__gte=${experienceValues[0]}&experience_lte=${experienceValues[1]}`;
      listTemp.push(
        <span key={count} className="search-filter-selected">
          {temp.name}
          <CloseOutlined
            style={{ marginLeft: "5px" }}
            onClick={() => setExperienceValues(undefined)}
          />
        </span>
      );
      count++;
    }

    if (rate !== undefined && rate[0] !== undefined && rate[1] !== undefined) {
      const temp = {
        id: 0,
        name: `Tarief ${rate[0]} - ${rate[1]}`,
      };
      searchFiltersTemp =
        searchFiltersTemp +
        `&minimum_rate__gte=${rate[0]}&maximum_rate__lte=${rate[1]}`;
      listTemp.push(
        <span key={count} className="search-filter-selected">
          {temp.name}
          <CloseOutlined
            style={{ marginLeft: "5px" }}
            onClick={() => setRate(undefined)}
          />
        </span>
      );
      count++;
    }

    if (period_start || period_end) {
      const temp = {
        id: 0,
        name: `Period ${period_start ? period_start : 0} - ${
          period_end ? period_end : 0
        }`,
      };
      searchFiltersTemp =
        searchFiltersTemp +
        `&period_start=${period_start ? period_start : 0}&period_end=${
          period_end ? period_end : 0
        }`;
      listTemp.push(
        <span key={count} className="search-filter-selected">
          {temp.name}
        </span>
      );
      count++;
    }

    regionId &&
      regionId.forEach((e) => {
        const temp = regions?.find((em) => em.id === e);
        if (temp) {
          searchFiltersTemp = searchFiltersTemp + `&region=${temp.id}`;
          listTemp.push(
            <span key={count} className="search-filter-selected">
              {temp.name}
              <CloseOutlined
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setSeachFilter((prev: any) =>
                    Object.create({
                      ...prev,
                      region: prev.region.filter(
                        (id: number) => id !== temp.id
                      ),
                    })
                  );
                }}
              />
            </span>
          );
          count++;
        }
      });

    specialties &&
      specialties.forEach((e) => {
        const temp = speciality?.find((em) => em.id === e);
        if (temp) {
          searchFiltersTemp = searchFiltersTemp + `&specialty=${temp.id}`;
          listTemp.push(
            <span key={count} className="search-filter-selected">
              {temp.name}
              <CloseOutlined
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setSeachFilter((prev: any) =>
                    Object.create({
                      ...prev,
                      specialty: prev.specialty.filter(
                        (id: number) => id !== temp.id
                      ),
                    })
                  );
                }}
              />
            </span>
          );
          count++;
        }
      });

    if (others && others.length === 1) {
      searchFiltersTemp =
        searchFiltersTemp +
        `&interested_vacancies=${others[0] === 0 ? true : false}`;
      listTemp.push(
        <span key={count} className="search-filter-selected">
          {others[0] === 0 ? "Eerder in geinteresseerd" : "Eerder afgewezen"}
          <CloseOutlined
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setSeachFilter((prev: any) =>
                Object.create({
                  ...prev,
                  others: prev.others.filter((id: number) => others[0] !== id),
                })
              );
            }}
          />
        </span>
      );
      count++;
    }

    setSearchFiltersList(searchFiltersTemp);

    const companyFilter = filterTags.find((e) => e.key === "company");
    if (companyFilter) {
      const temp: any[] = [companyFilter, ...listTemp];
      setFileterTags(temp);
    } else {
      setFileterTags(listTemp);
    }
  };

  useEffect(() => {
    setFilters();
    // eslint-disable-next-line
  }, [searchFilters, rate, experienceValues, selectedSector]);

  const handleChange = (changedValue: any, allValues: any) => {
    // if (!isUserActive) {
    //   form.resetFields();
    //   message.warn("U moet verifiëren om details te bekijken");
    //   return;
    // }
    setSeachFilter(allValues);
  };

  const [specializationByName, setSpecializationByName] = useState<string>("");

  // Delaying the keyboard input
  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
  const sendQuery = (query: any) => setSpecializationByName(query);

  const handleOnchange = (e: any) => {
    delayedQuery(e.target.value);
  };

  useEffect(() => {
    setSpeciality(unfilteredSpeciality?.slice(0, currentPageSpecility * 10));
    // eslint-disable-next-line
  }, [currentPageSpecility]);

  useEffect(() => {
    setCurrentPageSpecility(1);
  }, [specializationByName]);

  // Filtering the specialization with specialize input because filtering not available on API
  const [specialtyFilteredCount, setSpecialtyFilteredCount] = useState(0);
  useEffect(() => {
    const results = unfilteredSpeciality?.filter((e) =>
      e.name
        .toLocaleLowerCase()
        .includes(specializationByName.toLocaleLowerCase())
    );
    setSpecialtyFilteredCount(results ? results.length : 0);
    setSpeciality(results?.slice(0, currentPageSpecility * 10));
    // eslint-disable-next-line
  }, [specializationByName, unfilteredSpeciality]);

  const other = [
    {
      id: 0,
      value: "Eerder in geinteresseerd",
    },
    {
      id: 1,
      value: "Eerder afgewezen",
    },
  ];

  const resetFilters = () => {
    form.resetFields();
    setSeachFilter(form.getFieldsValue());
    setRate(undefined);
    setExperienceValues(undefined);
  };

  useEffect(() => {
    if (sector && position && specialty && region) {
      const filterObj = {
        sector_id: Number(sector),
        position_id: [...position?.split(",")]?.map(Number),
        region: [...region?.split(",")]?.map(Number),
        specialties: [...specialty?.split(",")]?.map(Number),
      };

      form.setFieldsValue(filterObj);

      setSeachFilter(filterObj);
    }
  }, [form, sector, position, specialty, region]);

  return (
    <>
      <Form
        form={form}
        name="regiter-form"
        layout="vertical"
        requiredMark={false}
        style={{ width: "100%", backgroundColor: "white" }}
        onValuesChange={handleChange}
        // initialValues={}
      >
        <div
          style={
            screens.md === true
              ? { padding: "24px", minHeight: "80vh", width: "100%" }
              : {}
          }
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label={<span className="search-filter-label">Sector</span>}
                name="sector_id"
                style={{ width: "100%" }}
              >
                <Select
                  size="large"
                  placeholder="Sector"
                  onChange={(value: number) => {
                    setCurrentPagePosition(1);
                    setSelectedSector(value);
                  }}
                  // defaultValue={parseInt(sector)}
                >
                  {sectors &&
                    sectors
                      ?.sort((a, b) => {
                        if (a["name"] < b["name"]) return -1;
                        else return 1;
                      })
                      ?.map((ele: any) => {
                        if (
                          ele["name"] === "Anders" ||
                          ele["name"] === "Anders (geef door aan info@job.nl)"
                        )
                          return null;
                        return (
                          <Option
                            style={{ height: "40px" }}
                            key={ele["id"]}
                            value={ele["id"]}
                          >
                            {ele["name"]}
                          </Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={<span className="search-filter-label">Functie</span>}
                name="position_id"
                style={
                  Positions.data?.next
                    ? { width: "100%", marginBottom: 0 }
                    : { width: "100%", marginBottom: 24 }
                }
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  {Positions?.data?.results
                    ?.sort((a, b) => {
                      if (a["name"] < b["name"]) return -1;
                      else return 1;
                    })
                    ?.map((e) => {
                      if (
                        e["name"] === "Anders" ||
                        e["name"] === "Anders (geef door aan info@job.nl)"
                      )
                        return null;
                      return (
                        <Row key={e["id"]}>
                          <Checkbox
                            key={e["id"]}
                            value={e["id"]}
                            // defaultChecked={position}
                          >
                            {e["name"]}
                          </Checkbox>
                        </Row>
                      );
                    })}
                </Checkbox.Group>
              </Form.Item>
              {Positions.data?.next && (
                <Button
                  type="link"
                  style={{ marginBlockEnd: "24px" }}
                  onClick={() => {
                    setCurrentPagePosition(currentPagePosition + 1);
                  }}
                  loading={Positions.isFetching}
                >
                  Meer laden
                </Button>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name="specialties"
                label={
                  <div style={{ width: "100%" }}>
                    <span className="search-filter-label">Specialisme</span>
                    <div style={{ width: "100%" }}>
                      <Input
                        size="large"
                        onChange={handleOnchange}
                        style={{ margin: "5px auto", width: "100%" }}
                        placeholder={"Specialisme"}
                      ></Input>
                    </div>
                  </div>
                }
                style={
                  specialtyFilteredCount > currentPageSpecility * 10
                    ? { width: "100%", marginBottom: 0 }
                    : { width: "100%", marginBottom: 24 }
                }
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  {speciality
                    ?.sort((a, b) => {
                      if (a["name"] < b["name"]) return -1;
                      else return 1;
                    })
                    ?.map((e) => {
                      if (
                        e["name"] === "Anders" ||
                        e["name"] === "Anders (geef door aan info@job.nl)"
                      )
                        return null;
                      return (
                        <Row key={e["id"]}>
                          <Checkbox key={e["id"]} value={e["id"]}>
                            {e["name"]}
                          </Checkbox>
                        </Row>
                      );
                    })}
                </Checkbox.Group>
              </Form.Item>
              {specialtyFilteredCount > currentPageSpecility * 10 && (
                <Button
                  type="link"
                  style={{ marginBlockEnd: "24px" }}
                  onClick={() => {
                    setCurrentPageSpecility(currentPageSpecility + 1);
                  }}
                >
                  Meer laden
                </Button>
              )}
            </Col>
          </Row>

          {(UserTypes.Company === user.user_type ||
            UserTypes.Child_User === user.user_type) && (
            <Row>
              <Col span={24}>
                <Form.Item
                  // name="work_experience"
                  label={
                    <div style={{ marginBlockEnd: "10px" }}>
                      <span className="search-filter-label">Werkervaring</span>
                    </div>
                  }
                  style={{ width: "100%" }}
                >
                  <Slider
                    disabled={!isUserActive}
                    min={0}
                    max={50}
                    onAfterChange={(value) => {
                      setExperienceValues(value);
                    }}
                    tooltipPrefixCls="custom-tooltip"
                    tooltipVisible={true}
                    getTooltipPopupContainer={(value) => {
                      return value;
                    }}
                    className="search-filter"
                    range
                    defaultValue={[0, 50]}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={24}>
              <Form.Item
                name="region"
                label={<span className="search-filter-label">Regio</span>}
                style={{ width: "100%" }}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  {regions &&
                    regions
                      ?.sort((a, b) => {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        // names must be equal
                        return 0;
                      })
                      .map((e) => {
                        return (
                          <Row key={e["id"]}>
                            <Checkbox key={e["id"]} value={e["id"]}>
                              {e["name"]}
                            </Checkbox>
                          </Row>
                        );
                      })}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* {UserTypes.Freelancer === user.user_type && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label={<span className="search-filter-label">Period</span>}
                  style={
                    screens.md === true
                      ? {
                          display: "inline-block",
                          width: "calc(100% - 40px)",
                          margin: "0 40px 0 0 ",
                        }
                      : {}
                  }
                >
                  <Form.Item
                    name="period_start"
                    style={{
                      display: "inline-block",
                      width: "calc(105px - 8px)",
                    }}
                  >
                    <InputNumber placeholder="Min" size="large" />
                  </Form.Item>
                  <span style={{ fontSize: "24px" }}>-</span>
                  <Form.Item
                    name="period_end"
                    style={{
                      display: "inline-block",
                      width: "calc(40% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <InputNumber placeholder="Max" size="large" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          )} */}

          {UserTypes.Freelancer === user.user_type && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <div style={{ marginBlockEnd: "10px" }}>
                      <span className="search-filter-label">Tarief</span>
                    </div>
                  }
                  style={{ width: "100%" }}
                >
                  <Slider
                    disabled={!isUserActive}
                    min={0}
                    max={150}
                    tipFormatter={(value) => {
                      if (value === 150) {
                        return "Max";
                      }
                      return value;
                    }}
                    onAfterChange={(value) => {
                      setRate(value);
                    }}
                    tooltipPrefixCls="custom-tooltip"
                    tooltipVisible={true}
                    getTooltipPopupContainer={(value) => {
                      document.querySelector(".ant-slider-step");
                      return value;
                    }}
                    style={{
                      touchAction: "none",
                    }}
                    className="search-filter"
                    range
                    defaultValue={[0, 150]}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {UserTypes.Freelancer === user.user_type && (
            <Row>
              <Col span={24}>
                <Form.Item
                  name="others"
                  label={<span className="search-filter-label">Overig</span>}
                  style={{ width: "100%" }}
                >
                  <Checkbox.Group style={{ width: "100%" }}>
                    {other.map((e) => {
                      return (
                        <Row key={e["id"]}>
                          <Checkbox key={e["id"]} value={e["id"]}>
                            {e["value"]}
                          </Checkbox>
                        </Row>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
        {(UserTypes.Company === user.user_type ||
          UserTypes.Child_User === user.user_type) &&
          !screens.md && (
            <>
              <Button
                size="large"
                block
                className="search-mobile-filter-btn"
                style={{
                  backgroundColor: "#664FF3",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={Toggle}
                  alt="toggle"
                  style={{ marginRight: "10px" }}
                />{" "}
                {`Filter ${resultCount} resultaten`}
              </Button>
            </>
          )}
        {!screens.md && (
          <>
            <Button
              size="large"
              block
              danger
              className="search-mobile-filter-btn"
              style={{
                marginBlockStart: "14px",
                borderRadius: "5px",
              }}
              onClick={resetFilters}
            >
              {`Filters verwijderen`}
            </Button>
          </>
        )}
      </Form>
    </>
  );
}
