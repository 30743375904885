type props = {
  content: string
}

export default function ActivityListItem({ content }: props) {

  return (
    <div className='activity-list-item'>
      {content}
    </div>
  )
}